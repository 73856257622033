import {
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST,
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
	DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
	DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST,
	DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_ERROR,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_REQUEST,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_SUCCESS,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST,
	GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
	UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
	UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST,
	UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addInvTransferNewLine = (token, object) => (dispatch) => {

	dispatch({ type: ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/InvStockAdjustmentLines`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: object },
	})
		.then((response) => {
			return dispatch({
				type: ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};


export const updateInvTransferNewLine = (token, object, invTransferId) => (dispatch) => {


	let data = object.map((item) => {
		delete item.style_name;
		delete item.cut_name;
		delete item.fabric_name;
		delete item.gender_category_name;
		delete item.gender_name;
		delete item.inseam;
		delete item.isSelected;
		delete item.quantity_available;
		delete item.quantity_backorder;
		delete item.quantity_committed;
		delete item.quantity_on_hand;
		delete item.rate;
		delete item.size;
		delete item.style_id;
		delete item.wash_id;
		delete item.wash_name;
		delete item.amount;
		delete item.commit_status;
		delete item.already;
		delete item.adjustment_header_id;
		delete item.last_updated_by;
		delete item.last_updated_date;
		delete item.quantity_packed;
		delete item.quantity_shipped;
		delete item.quantity_billed;
		delete item.pod_quantity;
		delete item.brand_name;
		delete item.brand_id;
		return (
			{
				...item,
				current_value: 0,
				estimated_cost: Number(item.estimated_cost),
				quantity: Number(item.quantity),
			}
		)
	});
	dispatch({ type: UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/InvStockAdjustmentLines/${invTransferId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			data: data,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};