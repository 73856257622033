import {
    GET_CUSTOMER_REFUND_REPORT_REQUEST,
    GET_CUSTOMER_REFUND_REPORT_SUCCESS,
    GET_CUSTOMER_REFUND_REPORT_ERROR,
    GET_CUSTOMER_REFUND_REPORT_NEW_REQUEST,
    GET_CUSTOMER_REFUND_REPORT_NEW_SUCCESS,
    GET_CUSTOMER_REFUND_REPORT_NEW_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
export const getCustomerRefundReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_REFUND_REPORT_REQUEST });
    const newData = {
        payment_header_id: null,
        payment_header_no: null,
        credit_account_name: null,
        gender_id: null,
        payment_mode_id: null,
        payment_term_id: null,
        payment_type_id: null,
        gl_period_name: null,
        debit_account_name: null,
        customer_name: null,
        ar_account_id: null,
        from_date: "01-01-2010",
        to_date: moment().format("MM-DD-YYYY"),
        from_amount: null,
        to_amount: null,
        invoice_header_id: values.invoice_id,
        invoice_header_no: null,
    };

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerPaymentDetailsList`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_CUSTOMER_REFUND_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_CUSTOMER_REFUND_REPORT_ERROR,
                payload: [],
            });
        });
};

export const getCustomerRefundNewReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_REFUND_REPORT_NEW_REQUEST });
    const newData = {
            payment_header_id: null,
            payment_header_no: null,
            credit_account_name: null,
            gender_id: null,
            payment_mode_id: null,
            payment_term_id: null,
            payment_type_id: null,
            gl_period_name: null,
            debit_account_name: null,
            customer_name: null,
            ar_account_id: null,
            from_date: "01-01-2010",
            to_date: moment().format("MM-DD-YYYY"),
            from_amount: null,
            to_amount: null,
            invoice_header_id: values.invoice_id,
            invoice_header_no: null,
    };
    
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/customerRefundDetails`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_CUSTOMER_REFUND_REPORT_NEW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CUSTOMER_REFUND_REPORT_NEW_ERROR,
          payload: [],
        });
      });
  };