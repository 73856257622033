import React, { useEffect, useMemo } from "react";

import { getSalesReportOrderItem, getSalesReportOrderPrint, getSalesReportColumnValue, getSalesReportColumn, getNewSalesReport } from "../../redux/actions/salesReport.action";
import SalesReportHeader from "./SalesReportHeader/SalesReportHeader";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./NewSalesReport.css";
import NewSalesReportHeader from "./SalesReportHeader/NewSalesReportHeader";
import { Button } from "antd";
import { useState } from "react";
import { currencySymbol } from "../../utils/constants";




const NewSalesReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const { salesReportOrderPrint, salesReportColumnValue, salesReportOrderColumn, newSalesReport } = useSelector((state) => state);



  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let order_header_id = query.get("order_header_id")

  const footerData =
    newSalesReport && newSalesReport?.payload?.data[0];

  let groupValues = newSalesReport && newSalesReport?.payload?.data.reduce(function (r, a) {
    r[a.gender_category_id] = r[a.gender_category_id] || [];
    r[a.gender_category_id].push(a);
    return r;
  }, Object.create(null));

  const groupedColumnValue = groupValues && Object.values(groupValues);

  const values = {
    order_header_id: order_header_id,
  };

  useEffect(() => {


    dispatch(getSalesReportColumn(token, values));
    dispatch(getNewSalesReport(token, values));
  }, [dispatch, menuId, token]);


  const key = 'gender_category_name';

  const arrayUniqueByKey = newSalesReport?.payload?.data && [...new Map(newSalesReport?.payload?.data.map(item =>
    [item[key], item])).values()];



  const myData = useMemo(() => {

    return salesReportOrderColumn && salesReportOrderColumn?.payload?.data.map((it, i) => {
      return { column: it, detail: groupedColumnValue && groupedColumnValue[i] };
    });
  }, [groupedColumnValue, salesReportOrderColumn]);




  return (
    <div>




      {newSalesReport?.loading ?
        <div className='loaderDiv' >
          <Loader
            type="Grid"
            color="#212121"
            height={40}
            width={40}
          />
        </div> :
        <div
          className="sales_report_view"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table>
            <thead>
              <tr>
                <td className="new-sales-report-footer">
                  <div>
                    <NewSalesReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block">
                    <div className="sales-report-Table-data">
                      {myData?.map((size, index) => {
                        return (
                          <table className="sales-report-table" key={index}>
                            <tbody>
                              <tr>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "36px",
                                  }}
                                >
                                  S.No
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "88px",
                                  }}
                                >
                                  SKU #
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "80px",
                                  }}
                                >
                                  Brand
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "240px",
                                  }}
                                >
                                  Style/Desc
                                  <span style={{ marginLeft: "5px" }}>
                                    {size?.column?.gender_category_name}
                                  </span>
                                </td>
                                {/* <td
                                  key={index}
                                  className="sales_table_col font_weight text-center pl-0"
                                >
                                  {size.column.size_chart && size.column.size_chart.split(",")[0]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[1]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[2]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[3]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[4]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[5]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[6]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[7]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[8]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[9]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[10]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[11]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[12]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[13]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[14]}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.size_chart && size.column.size_chart.split(",")[15]}
                                </td> */}
                                <td
                                  key={index}
                                  className="sales_table_col font_weight text-center pl-0"
                                >
                                  {size.column.hq23 && size.column.hq23}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq24 && size.column.hq24}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq25 && size.column.hq25}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq26 && size.column.hq26}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq27 && size.column.hq27}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq28 && size.column.hq28}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq29 && size.column.hq29}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq30 && size.column.hq30}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq31 && size.column.hq31}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq32 && size.column.hq32}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq33 && size.column.hq33}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq34 && size.column.hq34}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq35 && size.column.hq35}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq36 && size.column.hq36}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq37 && size.column.hq37}
                                </td>
                                <td className="sales_table_col font_weight text-center pl-0">
                                  {size.column.hq38 && size.column.hq38}
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "50px",
                                  }}
                                >
                                  Quantity
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "90px",
                                  }}
                                >
                                  Rate
                                </td>
                                <td
                                  className="font_weight"
                                  style={{
                                    width: "90px",
                                  }}
                                >
                                  Amount
                                </td>
                              </tr>
                              {size.detail?.map((it, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td
                                      style={{
                                        marginLeft: "0",
                                      }}
                                    >
                                      {it.item_code}
                                    </td>
                                    <td>
                                      {it?.sku_brand_name}
                                    </td>
                                    <td>
                                      {it.description}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_1 && Number(it.qty_1).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_2 && Number(it.qty_2).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_3 && Number(it.qty_3).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_4 && Number(it.qty_4).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_5 && Number(it.qty_5).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_6 && Number(it.qty_6).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_7 && Number(it.qty_7).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_8 && Number(it.qty_8).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_9 && Number(it.qty_9).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_10 && Number(it.qty_10).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_11 && Number(it.qty_11).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_12 && Number(it.qty_12).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_13 && Number(it.qty_13).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_14 && Number(it.qty_14).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_15 && Number(it.qty_15).toFixed(0)}
                                    </td>
                                    <td className="text-center pl-0">
                                      {it.qty_16 && Number(it.qty_16).toFixed(0)}
                                    </td>
                                    <td>
                                      {it.quantity && Number(it.quantity).toFixed(0)}
                                    </td>
                                    <td>{currencySymbol} {it.rate && it.rate}</td>
                                    <td>{currencySymbol} {it.amount && Number(it.amount).toFixed(2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        );
                      })}
                    </div>
                  </div>
                  <table className="sales-report-table-total">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          style={{
                            width: "130px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                          className="font_weight total_cell"
                        >
                          Total
                        </td>
                        <td
                          className="sum_cell"
                          style={{
                            width: "48px",
                          }}
                        >
                          {footerData && footerData.gross_quantity}
                        </td>
                        <td
                          className="empty_cell"
                          style={{
                            width: "43px",
                          }}
                        ></td>
                        <td
                          className="total_amount"
                          style={{
                            width: "70px",
                          }}
                        >
                          {currencySymbol} {footerData && Number(footerData.gross_amount).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                          className="font_weight"
                        >
                          Disc %
                        </td>
                        <td></td>
                        <td></td>
                        <td>{footerData && Number(footerData.discount_percent).toFixed(2)} %</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Disc Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>{currencySymbol} {footerData && Number(footerData.discount).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          S.Tax Amount
                        </td>
                        <td>{footerData?.tax_percent}%</td>
                        <td></td>
                        <td>{currencySymbol} {footerData && Number(footerData.tax_amount).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Freight Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>{currencySymbol} {footerData && Number(footerData.shipping_cost).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Surcharge Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>{currencySymbol} {footerData && Number(footerData.surcharge_amount).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Net Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>{currencySymbol} {footerData && Number(footerData.net_amount).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

    </div>
  );
};





export default NewSalesReport;