import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import PageHeader from "../../components/PageHeader/PageHeader";

import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Input, Select, DatePicker, Table, InputNumber, Spin } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import xlsx from "../../assets/images/ico_xls_sm.gif";

import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getOMSDetailReport } from "../../redux/actions/omsBookDetailReport.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral, { Numeral } from "numeral";
import { CSVLink } from "react-csv";
import { useLocation } from 'react-router-dom';
import { TabTitle } from "../../utils/GeneralFunction";
import { companyID, currencySymbol } from "../../utils/constants";
import { getBrands } from "../../redux/actions/brand.action";
import filterHelper from "../../utils/filterHelper";
const { Option } = Select;


const BookDetail = () => {
    TabTitle("Booked Detail")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bookDetailList, setBookDetailList] = useState([]);
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const { gender, brand, region, style, salePersonForm, defaultCompany, paymentterm, customerTypes, omsBookedDetailReport, saleOrderFormData, adminLocation } = useSelector((state) => state);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    const [excelData, setExcelData] = useState([])
    const location = useLocation();
    const initialDate = moment('2025-01-01')
    const initialLastDate = moment('2026-12-31')

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getBrands(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
    }, [dispatch, menuId, token]);

    const BDCols = [
        {
            title: '#',
            key: Math.ceil(Math.random() * 10000),
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'Order #',
            dataIndex: "order_header_no",
            key: "order_header_no",
            render: (text, record) => (
                <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                    to={"/sale-order?mode=edit&order_id=" + record.order_header_id + "/&customer_id/" + record.customer_id + "?edit=yes"}
                >
                    {text}
                </Link>
            ),
            ...filterHelper('order_header_no','num')
        },
        {
            title: "Customer Category",
            dataIndex: "customer_type_name",
            key: "customer_type_name",
            ...filterHelper('customer_type_name')
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            key: "customer_name",
            ...filterHelper('customer_name')
        },
        {
            title: "Billing Addressee",
            dataIndex: "billing_addressee",
            key: "billing_addressee",
            ...filterHelper('billing_addressee')
        },
        {
            title: "PO #",
            dataIndex: "reference_no",
            key: "reference_no",
            ...filterHelper('reference_no')
        },
        {
            title: 'Store',
            dataIndex: "store_id",
            key: 'store_id',
            render: (text, record) => (record.store_id === "-1" || record.store_id === null) ? "N/A" : record.store_id,
            ...filterHelper('store_id', 'num')
        },
        {
            title: "Order Date",
            render: (text, record) => {
                return <>{moment(record.order_date).format("MM-DD-YY")}</>
            },
            ...filterHelper('order_date', 'date')
        },
        {
            title: "Start Date",
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YY")}</>
            },
            ...filterHelper('start_date', 'date')
        },
        {
            title: "Cancel Date",
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
            },
            ...filterHelper('cancel_date', 'date')
        },
        {
            title: "Product Status",
            dataIndex: "productstatus",
            key: "productstatus",
            ...filterHelper('productstatus')
        },
        {
            title: "SKU",
            dataIndex: "parent_item_code",
            key: "parent_item_code",
            align: "center",
            ...filterHelper('parent_item_code')
        },
        {
            title: "Inseam ",
            dataIndex: "inseam",
            key: "inseam",
            align: "center",
            ...filterHelper('inseam')
        },

        {
            title: "Style",
            dataIndex: "style_name",
            key: "style_name",
            ...filterHelper('style_name')
        },

        {
            title: "Wash",
            dataIndex: "wash_name",
            key: "wash_name",
            ...filterHelper('wash_name')
        },
        {
            children: [
                {
                    title: 'T-Jacket',
                    dataIndex: 'stage',
                    key: 'stage',
                    width: 90,
                    children: [
                        {
                            title: 'Shirt/RTW-P',
                            dataIndex: 'stage',
                            key: 'stage',
                            width: 90,
                            children: [
                                {
                                    title: 'Shirt/RTW-R',
                                    dataIndex: 'stage',
                                    key: 'stage',
                                    width: 90,
                                    children: [
                                        {
                                            title: 'Y-Adult',
                                            dataIndex: 'stage',
                                            key: 'stage',
                                            width: 90,
                                            children: [
                                                {
                                                    title: 'W-Plus',
                                                    dataIndex: 'stage',
                                                    key: 'stage',
                                                    width: 90,
                                                    children: [
                                                        {
                                                            title: 'Kids',
                                                            dataIndex: 'stage',
                                                            key: 'stage',
                                                            width: 90,
                                                            children: [
                                                                {
                                                                    title: 'Men',
                                                                    dataIndex: 'stage',
                                                                    key: 'stage',
                                                                    width: 90,
                                                                    children: [
                                                                        {
                                                                            title: 'Women',
                                                                            dataIndex: 'stage',
                                                                            key: 'stage',
                                                                            width: 90,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"2/3"',
                    align: 'center',
                    dataIndex: 'size1qty1',
                    key: 'size1qty1',
                    width: 50,
                    children: [
                        {
                            title: '1XL',
                            dataIndex: 'size1qty1',
                            key: 'size1qty1',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'XS',
                                    dataIndex: 'size1qty1',
                                    key: 'size1qty1',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '00',
                                            dataIndex: 'size1qty1',
                                            key: 'size1qty1',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '10W',
                                                    dataIndex: 'size1qty1',
                                                    key: 'size1qty1',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '2',
                                                            dataIndex: 'size1qty1',
                                                            key: 'size1qty1',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '27',
                                                                    dataIndex: 'size1qty1',
                                                                    key: 'size1qty1',
                                                                    align: 'center',
                                                                    width: 50,
                                                                    children: [
                                                                        {
                                                                            title: '23',
                                                                            dataIndex: 'size1qty1',
                                                                            key: 'size1qty1',
                                                                            width: 50,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"4/5"',
                    align: 'center',
                    dataIndex: 'size1qty2',
                    key: 'size1qty2',
                    width: 50,
                    children: [
                        {
                            title: '2XL',
                            align: 'center',
                            dataIndex: 'size1qty2',
                            key: 'size1qty2',
                            width: 55,
                            children: [
                                {
                                    title: 'S',
                                    align: 'center',
                                    dataIndex: 'size1qty2',
                                    key: 'size1qty2',
                                    width: 55,
                                    children: [
                                        {
                                            title: '0',
                                            align: 'center',
                                            dataIndex: 'size1qty2',
                                            key: 'size1qty2',
                                            width: 55,
                                            children: [
                                                {
                                                    title: '12W',
                                                    align: 'center',
                                                    dataIndex: 'size1qty2',
                                                    key: 'size1qty2',
                                                    width: 55,
                                                    children: [
                                                        {
                                                            title: '3',
                                                            align: 'center',
                                                            dataIndex: 'size1qty2',
                                                            key: 'size1qty2',
                                                            width: 50,
                                                            children: [
                                                                {
                                                                    title: '28',
                                                                    align: 'center',
                                                                    dataIndex: 'size1qty2',
                                                                    key: 'size1qty2',
                                                                    width: 50,
                                                                    children: [
                                                                        {
                                                                            title: '24',
                                                                            align: 'center',
                                                                            dataIndex: 'size1qty2',
                                                                            key: 'size1qty2',
                                                                            width: 50,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"6/6X"',
                    dataIndex: 'size1qty3',
                    key: 'size1qty3',
                    align: 'center',
                    width: 60,
                    children: [
                        {
                            title: '3XL',
                            dataIndex: 'size1qty3',
                            key: 'size1qty3',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'M',
                                    dataIndex: 'size1qty3',
                                    key: 'size1qty3',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '1',
                                            dataIndex: 'size1qty3',
                                            key: 'size1qty3',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '14W',
                                                    dataIndex: 'size1qty3',
                                                    key: 'size1qty3',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '4',
                                                            dataIndex: 'size1qty3',
                                                            key: 'size1qty3',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '29',
                                                                    dataIndex: 'size1qty3',
                                                                    key: 'size1qty3',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '25',
                                                                            dataIndex: 'size1qty3',
                                                                            key: 'size1qty3',
                                                                            width: 55,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty4',
                    key: 'size1qty4',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty4',
                            key: 'size1qty4',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'L',
                                    dataIndex: 'size1qty4',
                                    key: 'size1qty4',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '2',
                                            dataIndex: 'size1qty4',
                                            key: 'size1qty4',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '16W',
                                                    dataIndex: 'size1qty4',
                                                    key: 'size1qty4',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '5',
                                                            dataIndex: 'size1qty4',
                                                            key: 'size1qty4',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '30',
                                                                    dataIndex: 'size1qty4',
                                                                    key: 'size1qty4',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '26',
                                                                            dataIndex: 'size1qty4',
                                                                            key: 'size1qty4',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty5',
                    key: 'size1qty5',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty5',
                            key: 'size1qty5',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'XL',
                                    dataIndex: 'size1qty5',
                                    key: 'size1qty5',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '3',
                                            dataIndex: 'size1qty5',
                                            key: 'size1qty5',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '18W',
                                                    dataIndex: 'size1qty5',
                                                    key: 'size1qty5',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '6',
                                                            dataIndex: 'size1qty5',
                                                            key: 'size1qty5',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '31',
                                                                    dataIndex: 'size1qty5',
                                                                    key: 'size1qty5',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '27',
                                                                            dataIndex: 'size1qty5',
                                                                            key: 'size1qty5',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty6',
                    key: 'size1qty6',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty6',
                            key: 'size1qty6',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'XXL',
                                    dataIndex: 'size1qty6',
                                    key: 'size1qty6',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '4',
                                            dataIndex: 'size1qty6',
                                            key: 'size1qty6',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '20W',
                                                    dataIndex: 'size1qty6',
                                                    key: 'size1qty6',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '6x',
                                                            dataIndex: 'size1qty6',
                                                            key: 'size1qty6',
                                                            width: 50,
                                                            align: 'center',

                                                            children: [
                                                                {
                                                                    title: '32',
                                                                    dataIndex: 'size1qty6',
                                                                    key: 'size1qty6',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '28',
                                                                            dataIndex: 'size1qty6',
                                                                            key: 'size1qty6',
                                                                            width: 50,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty7',
                    key: 'size1qty7',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty7',
                            key: 'size1qty7',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty7',
                                    key: 'size1qty7',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '5',
                                            dataIndex: 'size1qty7',
                                            key: 'size1qty7',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '22W',
                                                    dataIndex: 'size1qty7',
                                                    key: 'size1qty7',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '7',
                                                            dataIndex: 'size1qty7',
                                                            key: 'size1qty7',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '33',
                                                                    dataIndex: 'size1qty7',
                                                                    key: 'size1qty7',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '29',
                                                                            dataIndex: 'size1qty7',
                                                                            key: 'size1qty7',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty8',
                    key: 'size1qty8',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty8',
                            key: 'size1qty8',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty8',
                                    key: 'size1qty8',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '6',
                                            dataIndex: 'size1qty8',
                                            key: 'size1qty8',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '24W',
                                                    dataIndex: 'size1qty8',
                                                    key: 'size1qty8',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '7x',
                                                            dataIndex: 'size1qty8',
                                                            key: 'size1qty8',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '34',
                                                                    dataIndex: 'size1qty8',
                                                                    key: 'size1qty8',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '30',
                                                                            dataIndex: 'size1qty8',
                                                                            key: 'size1qty8',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty9',
                    key: 'size1qty9',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty9',
                            key: 'size1qty9',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty9',
                                    key: 'size1qty9',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '7',
                                            dataIndex: 'size1qty9',
                                            key: 'size1qty9',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '26W',
                                                    dataIndex: 'size1qty9',
                                                    key: 'size1qty9',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '8',
                                                            dataIndex: 'size1qty9',
                                                            key: 'size1qty9',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '35',
                                                                    dataIndex: 'size1qty9',
                                                                    key: 'size1qty9',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '31',
                                                                            dataIndex: 'size1qty9',
                                                                            key: 'size1qty9',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty10',
                    key: 'size1qty10',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty10',
                            key: 'size1qty10',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty10',
                                    key: 'size1qty10',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '8',
                                            dataIndex: 'size1qty10',
                                            key: 'size1qty10',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '28W',
                                                    dataIndex: 'size1qty10',
                                                    key: 'size1qty10',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '10',
                                                            dataIndex: 'size1qty10',
                                                            key: 'size1qty10',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '36',
                                                                    dataIndex: 'size1qty10',
                                                                    key: 'size1qty10',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '32',
                                                                            dataIndex: 'size1qty10',
                                                                            key: 'size1qty10',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty11',
                    key: 'size1qty11',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty11',
                            key: 'size1qty11',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty11',
                                    key: 'size1qty11',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '9',
                                            dataIndex: 'size1qty11',
                                            key: 'size1qty11',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '30W',
                                                    dataIndex: 'size1qty11',
                                                    key: 'size1qty11',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '12',
                                                            dataIndex: 'size1qty11',
                                                            key: 'size1qty11',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '38',
                                                                    dataIndex: 'size1qty11',
                                                                    key: 'size1qty11',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '33',
                                                                            dataIndex: 'size1qty11',
                                                                            key: 'size1qty11',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty12',
                    key: 'size1qty12',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty12',
                            key: 'size1qty12',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty12',
                                    key: 'size1qty12',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '10',
                                            dataIndex: 'size1qty12',
                                            key: 'size1qty12',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '32W',
                                                    dataIndex: 'size1qty12',
                                                    key: 'size1qty12',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '14',
                                                            dataIndex: 'size1qty12',
                                                            key: 'size1qty12',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '40',
                                                                    dataIndex: 'size1qty12',
                                                                    key: 'size1qty12',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '34',
                                                                            dataIndex: 'size1qty12',
                                                                            key: 'size1qty12',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty13',
                    key: 'size1qty13',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty13',
                            key: 'size1qty13',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty13',
                                    key: 'size1qty13',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty13',
                                            key: 'size1qty13',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty13',
                                                    key: 'size1qty13',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '16',
                                                            dataIndex: 'size1qty13',
                                                            key: 'size1qty13',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '42',
                                                                    dataIndex: 'size1qty13',
                                                                    key: 'size1qty13',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '35',
                                                                            dataIndex: 'size1qty13',
                                                                            key: 'size1qty13',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty14',
                    key: 'size1qty14',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty14',
                            key: 'size1qty14',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty14',
                                    key: 'size1qty14',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty14',
                                            key: 'size1qty14',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty14',
                                                    key: 'size1qty14',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '18',
                                                            dataIndex: 'size1qty14',
                                                            key: 'size1qty14',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '44',
                                                                    dataIndex: 'size1qty14',
                                                                    key: 'size1qty14',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '36',
                                                                            dataIndex: 'size1qty14',
                                                                            key: 'size1qty14',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty15',
                    key: 'size1qty15',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty15',
                            key: 'size1qty15',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty15',
                                    key: 'size1qty15',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty15',
                                            key: 'size1qty15',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty15',
                                                    key: 'size1qty15',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '20',
                                                            dataIndex: 'size1qty15',
                                                            key: 'size1qty15',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '46',
                                                                    dataIndex: 'size1qty15',
                                                                    key: 'size1qty15',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '37',
                                                                            dataIndex: 'size1qty15',
                                                                            key: 'size1qty15',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty16',
                    key: 'size1qty16',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty16',
                            key: 'size1qty16',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty16',
                                    key: 'size1qty16',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty16',
                                            key: 'size1qty16',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty16',
                                                    key: 'size1qty16',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '-',
                                                            dataIndex: 'size1qty16',
                                                            key: 'size1qty16',
                                                            width: 50,
                                                            align: 'center',

                                                            children: [
                                                                {
                                                                    title: '48',
                                                                    dataIndex: 'size1qty16',
                                                                    key: 'size1qty16',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '38',
                                                                            dataIndex: 'size1qty16',
                                                                            key: 'size1qty16',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
            ],
        },
        {
            title: 'Qty',
            dataIndex: "total_qty",
            key: 'total_qty',
            align: "right",
        },
        {
            title: 'Rate',
            dataIndex: "rate",
            key: 'rate',
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.rate).format('0,0.00')}</>
            }
        },
        {
            title: 'Amount',
            dataIndex: "amount",
            key: 'amount',
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.amount).format('0,0.00')}</>
            }
        },
        {
            title: 'Discount Amount',
            dataIndex: "disc_amount",
            key: 'disc_amount',
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.disc_amount).format('0,0.00')}</>
            }
        },
        {
            title: 'Tax Amount',
            dataIndex: "tax_amount",
            key: 'tax_amount',
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.tax_amount).format('0,0.00')}</>
            }
        },
        {
            title: 'Terms',
            dataIndex: "payment_term",
            key: 'payment_term',
            ...filterHelper('payment_term')
        },
        {
            title: 'Status',
            dataIndex: "status",
            key: 'status',
            ...filterHelper('status')
        },
        {
            title: 'Gender',
            dataIndex: 'gender_name',
            key: 'gender_name',
            ...filterHelper('gender_name')
        },
        {
            title: "Brand",
            dataIndex: "brand_name",
            key: "brand_name",
            align: "center",
            ...filterHelper('brand_name')
        },
        {
            title: 'Sales Person',
            dataIndex: "salesperson_name",
            key: 'salesperson_name',
            ...filterHelper('salesperson_name')
        },
        {
            title: 'Region',
            dataIndex: "region_name",
            key: 'region_name',
            ...filterHelper('region_name')
        },
        {
            title: 'Location',
            dataIndex: "location_name",
            key: 'location_name',
            ...filterHelper('location_name')
        },
        {
            title: 'New Season',
            dataIndex: "newseason",
            key: 'newseason',
            ...filterHelper('newseason')
        },
    ];

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Order #": v.order_header_no,
                    "Customer Category": v.customer_type_name,
                    "Customer": v.customer_name,
                    "Billing Addressee": v.billing_addressee,
                    "PO #": v.reference_no,
                    "Store": v.store_id,
                    "Order Date": moment(v.order_date).format("MM-DD-YY"),
                    "Start Date": moment(v.start_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "Product Status": v.productstatus,
                    "SKU": v.parent_item_code,
                    "Inseam": v.inseam,
                    "Style": v.style_name,
                    "Wash": v.wash_name,
                    "=\"\"2/3\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"00\"\"&CHAR(10)&\"\"10W\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.size1qty1,
                    "=\"\"4/5\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"0\"\"&CHAR(10)&\"\"12W\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.size1qty2,
                    "=\"\"6/6X\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"1\"\"&CHAR(10)&\"\"14W\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.size1qty3,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"16W\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.size1qty4,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"18W\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.size1qty5,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"XXL\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"20W\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.size1qty6,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"22W\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.size1qty7,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"24W\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.size1qty8,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"26W\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.size1qty9,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"28W\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.size1qty10,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"9\"\"&CHAR(10)&\"\"30W\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.size1qty11,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"32W\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.size1qty12,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.size1qty13,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.size1qty14,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.size1qty15,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.size1qty16,
                    "Qty": v.total_qty,
                    "Currency": currencySymbol,
                    "Rate": parseFloat(v.rate).toFixed(2),
                    "Amount": parseFloat(v.amount).toFixed(2),
                    "Discount Amount": parseFloat(v.disc_amount).toFixed(2),
                    "Tax Amount": parseFloat(v.tax_amount).toFixed(2),
                    "Term": v.payment_term,
                    "Status": v.status,
                    "Gender": v.gender_name,
                    "Brand": v.brand_name,
                    "Sale person Name": v.salesperson_name,
                    "Region": v.region_name,
                    "Location": v.location_name,
                    "New Season": v.newseason,
                };
            })
        )
    }




    useEffect(() => {
        if (location?.state?.order_header_no) {
            const values = {
                cutomer_category: null,
                from_date: initialDate,
                to_date: initialLastDate,
                order_no: location?.state?.order_header_no,
                po_no: null,


                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: null,
                status: null,
                location: null,
                style: null
            }
            form.setFieldsValue({
                order_no: values.order_no,
                from_date: values.from_date,
            });
            setLoading(true)
            dispatch(getOMSDetailReport(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setBookDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
        else if (location?.state?.po_no) {
            const values = {
                cutomer_category: null,
                from_date: initialDate,
                to_date: initialLastDate,
                order_no: null,
                po_no: location?.state?.po_no,


                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: null,
                status: null,
                location: null,
                style: null
            }
            form.setFieldsValue({
                from_date: values.from_date,
                po_no: values.po_no
            });
            setLoading(true)
            dispatch(getOMSDetailReport(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setBookDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
    }, [location?.state?.order_header_no, location?.state?.po_no])

    const dateFormat = "MM-DD-YYYY";

    const initialFormValues = {
        from_date: initialDate,
        to_date: moment().endOf('year'),
        cutomer_category: null,
        order_no: null,
        po_no: null,
        sale_person: null,
        region: null,
        gender: null,
        terms: null,
        source: null,
        store: null,
        subsidary: companyID,
        status: null,
        sku: null,
        style: null,
        location: null,
    };

    const onFinish = async () => {
        await form.validateFields().then((values) => {
            setLoading(true)

            dispatch(getOMSDetailReport(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setBookDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);

            });
        })
    }

    const totalQuantity = () => {
        let sumQty = bookDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.total_qty);
        }, 0);
        return sumQty;


    }

    const totalSum = () => {
        let sumAmount = bookDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }

    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Book Detail"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks " initialValues={initialFormValues}
                            >
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={initialDate}
                                                    >
                                                        <DatePicker
                                                            format={dateFormat}
                                                            size='small'
                                                            className="styleInput w-100"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={initialLastDate}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        defaultValue={form.getFieldValue().order_no}
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                    >
                                                        <Option>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                    >
                                                        <Option>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="store"
                                                        label="Store #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item name="subsidary"
                                                    label="Subsidiary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}>
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        size="small"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        <Option
                                                            key={defaultCompany?.payload?.company_id}
                                                            value={defaultCompany?.payload?.company_id}
                                                        >
                                                            {defaultCompany?.payload?.company_name}
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        allowClear
                                                        loading={saleOrderFormData.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option >All</Option>
                                                        {saleOrderFormData?.payload?.orderStatusDetail.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.order_status_id}
                                                                        value={item.order_status_id}
                                                                    >
                                                                        {item.status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="sku"
                                                        label="Sku #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="style"
                                                    label="Style"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                    >
                                                        <Option>All</Option>
                                                        {
                                                            style?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.style_id}>{val.style_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="brand_id"
                                                    label="Brand"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            brand?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.brand_id} value={val.brand_id}>{val.brand_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>


                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (bookDetailList.length > 0 ? (
                        < Table
                            title={() =>
                                <CSVLink
                                    filename={"Booked-Detail.csv"}
                                    data={excelData}
                                    onClick={() => {

                                    }}
                                >
                                    Download Excel
                                </CSVLink>
                            }
                            ref={tableRef}
                            columns={BDCols}
                            loading={omsBookedDetailReport?.loading}
                            dataSource={omsBookedDetailReport?.payload?.data}
                            size="small"
                            footer={false}
                            bordered={false}
                            scroll={{ x: "max-content" }}
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                            }}
                            summary={(pageData) => {
                                let Size1 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty1
                                    ));
                                },
                                    0);
                                let Size2 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty2
                                    ));
                                },
                                    0);
                                let Size3 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty3
                                    ));
                                },
                                    0);
                                let Size4 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty4

                                    ));
                                },
                                    0);
                                let Size5 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty5

                                    ));
                                },
                                    0);
                                let Size6 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty6

                                    ));
                                },
                                    0);
                                let Size7 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty7

                                    ));
                                },
                                    0);
                                let Size8 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty8

                                    ));
                                },
                                    0);
                                let Size9 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty9

                                    ));
                                },
                                    0);
                                let Size10 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty10

                                    ));
                                },
                                    0);
                                let Size11 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty11

                                    ));
                                },
                                    0);
                                let Size12 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty12

                                    ));
                                },
                                    0);
                                let Size13 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty13

                                    ));
                                },
                                    0);
                                let Size14 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty14

                                    ));
                                },
                                    0);
                                let Size15 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty15

                                    ));
                                },
                                    0);
                                let Size16 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty16

                                    ));
                                },
                                    0);
                                let Qty = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.total_qty
                                    ));
                                },
                                    0);
                                let Amount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.amount
                                    ));
                                },
                                    0);
                                let DiscountAmount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.disc_amount
                                    ));
                                },
                                    0);
                                let TaxAmount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.tax_amount
                                    ));
                                },
                                    0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size1.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size2.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size3.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size4.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size5.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size6.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size7.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size8.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size9.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size10.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size11.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size12.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size13.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size14.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size15.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size16.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {Qty.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(Amount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(DiscountAmount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(TaxAmount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    ) : <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export default BookDetail;

