import {
  ADD_CUSTOMER_ADREESS_REQUEST, ADD_CUSTOMER_ADREESS_SUCCESS, ADD_CUSTOMER_ADREESS_ERROR,
  GET_ONE_CUSTOMER_ADREESS_REQUEST, GET_ONE_CUSTOMER_ADREESS_SUCCESS, GET_ONE_CUSTOMER_ADREESS_ERROR,
  UPDATE_CUSTOMER_ADREESS_SUCCESS, UPDATE_CUSTOMER_ADREESS_REQUEST, UPDATE_CUSTOMER_ADREESS_ERROR,
  DELETE_CUSTOMER_ADREESS_REQUEST, DELETE_CUSTOMER_ADREESS_SUCCESS, DELETE_CUSTOMER_ADREESS_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const addCustomerAddress = (token, data) => (dispatch) => {

  data = data.map((item) => {
    return {
      customer_addressee: item.address_addressee,
      customer_full_address: item.customer_full_address,
      address_1: item.address_address_1,
      address_2: item.address_address_2,
      address_3: item.address_address_3,
      customer_first_name: item.first_name,
      customer_last_name: item.last_name,
      country_id: item?.address_country_id,
      state_id: item?.address_state_id,
      city_id: item?.address_city_id,
      customer_email: item.address_email,
      phone: item.address_phone,
      fax: item.address_fax,
      zip_code: item.address_zip_code,
      is_active: item.is_active,
      is_default: item.default_bill_to,
      default_ship_to: item.default_ship_to,
      customer_id: item.customer_id,
    }
  })
  dispatch({ type: ADD_CUSTOMER_ADREESS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/customerAddress`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: data }
  })
    .then((response) => {
      return dispatch({ type: ADD_CUSTOMER_ADREESS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_CUSTOMER_ADREESS_ERROR,
        payload: error.response,
      });
    });
};

export const getOneCustomerAddress = (token, customerID, orderID) => (dispatch) => {
  dispatch({ type: GET_ONE_CUSTOMER_ADREESS_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/customerAddress/${customerID}&${orderID ? orderID : 0}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_ONE_CUSTOMER_ADREESS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_CUSTOMER_ADREESS_ERROR,
        payload: error.response,
      });
    });
};


export const updateAddress = (token, data, id) => dispatch => {
  dispatch({ type: UPDATE_CUSTOMER_ADREESS_REQUEST });
  data = data.map((item) => {
    return {
      customer_addressee: item?.address_addressee,
      customer_full_address: item?.customer_full_address,
      address_1: item?.address_address_1,
      address_2: item?.address_address_2,
      address_3: item?.address_address_3,
      customer_first_name: item?.first_name,
      customer_last_name: item?.last_name,
      customer_email: item?.address_email,
      country_id: item?.address_country_id,
      state_id: item?.address_state_id,
      city_id: item?.address_city_id,
      phone: item?.phone,
      fax: item?.address_fax,
      zip_code: item?.address_zip_code,
      is_active: item?.is_active,
      is_default: item?.default_bill_to,
      default_ship_to: item?.default_ship_to,
      customer_address_id: item?.customer_address_id
    }
  })
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customerAddress/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
    .then(response => {
      return dispatch({ type: UPDATE_CUSTOMER_ADREESS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_CUSTOMER_ADREESS_ERROR,
        payload: error.response,
      });
    })
}


export const deleteAddress = (token, id, data) => (dispatch) => {
  dispatch({ type: DELETE_CUSTOMER_ADREESS_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/customerAddress/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: data }
  })
    .then((response) => {

      return dispatch({
        type: DELETE_CUSTOMER_ADREESS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_CUSTOMER_ADREESS_ERROR,
        payload: error.response,
      });
    });
};
