import {
    ADD_NU_IMAGE_REQUEST,
    ADD_NU_IMAGE_SUCCESS,
    ADD_NU_IMAGE_ERROR,
} from "./types.js";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const imageUploaderNu = (token, data) => (dispatch) => {
    dispatch({ type: ADD_NU_IMAGE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/imageUploaderNu`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            data
        },
    })
        .then((response) => {
            return dispatch({
                type: ADD_NU_IMAGE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_NU_IMAGE_ERROR,
                payload: error.response,
            });
        });
};