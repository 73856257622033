import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { Row as Brow, Col as Bcol } from "reactstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./pickticket.css"
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getPtSummary } from "../../redux/actions/ptSummary.action";
import { getShipStatus } from "../../redux/actions/shipStatus.action";
import { getPickTicketStatus } from '../../redux/actions/pickTicketStatus.action';
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Table,
    InputNumber,
    Space, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { companyID, currencySymbol } from "../../utils/constants";
import { getBrands } from "../../redux/actions/brand.action";
import filterHelper from "../../utils/filterHelper";

const { Option } = Select;

const PtSummary = () => {
    TabTitle("Pick Ticket Summary")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const submitButton = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const [ptSummary, setPtSummary] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    const [excelData, setExcelData] = useState([])

    const {
        gender,
        region,
        brand,
        salePersonForm,
        defaultCompany,
        paymentterm,
        adminLocation,
        shipStatus,
        getPendingSummaryReducer,
        PickTicketSummary,
        saleOrderFormData,
        PtSummary,
        customerTypes,
        pickTicketStatus
    } = useSelector((state) => state);



    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getAllCompany(token));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getBrands(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getShipStatus(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
        dispatch(getPickTicketStatus(token, menuId))
    }, [dispatch, menuId, token]);





    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',

            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'PT #',
            dataIndex: 'ship_header_no',
            key: 'ship_header_id',

            render: (text, record) => (
                <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                    to={"/order-shipment?mode=edit&order_id=" + record.ship_header_id + "&customer_id=" + record.customer_id}
                >
                    {text}
                </Link>
            ),
            ...filterHelper('ship_header_id', 'num')
        },
        {
            title: 'PT Date',
            render: (text, record) => {
                return <>{moment(record.ship_date).format("MM-DD-YYYY")}</>;
            },
            ...filterHelper('ship_date', 'date')

        },
        {
            title: 'Start Date',
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YYYY")}</>;
            },
        },
        {
            title: 'Cancel Date',
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
            },
            ...filterHelper('cancel_date', 'date')

        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            ...filterHelper('customer_name')

        },
        {
            title: 'Billing Addressee',
            dataIndex: 'billing_addressee',
            key: 'billing_addressee',
            ...filterHelper('billing_addressee')

        },
        {
            title: 'SO #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            ...filterHelper('order_header_no','num')

        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            ...filterHelper('reference_no')

        },
        {
            title: 'PT Qty',
            render: (text, record) => {
                return <>{parseFloat(record.qty).toFixed(0)}</>;
            },
            ...filterHelper('qty','num'),
            align: "right",
        },
        {
            title: 'Amount',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.amount).format('0,0.00')}</>
            },
            ...filterHelper('amount','num'),
            align: "right",
        },
        {
            title: 'Term',
            dataIndex: 'term_name',
            key: 'term_name',
            ...filterHelper('term_name'),

        },
        {
            title: 'Sale Person',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            ...filterHelper('salesperson_name'),

        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            key: 'region_name',
            ...filterHelper('region_name'),

        },
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            ...filterHelper('brand_name'),

        },
        {
            title: 'PT Status',
            dataIndex: 'pt_status_name',
            key: 'pt_status_name',
            ...filterHelper('pt_status_name'),

        },
        {
            title: 'NS Status',
            dataIndex: 'ship_status_name',
            key: 'ship_status_name',
            ...filterHelper('ship_status_name'),

        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location_name',
            ...filterHelper('location_name'),

        },
        {
            title: 'Customer Type',
            dataIndex: 'customer_type_name',
            key: 'customer_type_name',
            ...filterHelper('customer_type_name'),

        },
        {
            title: 'SCF #',
            dataIndex: 'factor_decision',
            key: 'factor_decision',
            ...filterHelper('factor_decision','num'),

        },
        {
            title: 'Shipping Method',
            dataIndex: 'shipping_method_name',
            key: 'shipping_method_name',
            ...filterHelper('shipping_method_name'),

        },
        {
            title: 'Tracking #',
            dataIndex: 'tracking_no',
            key: 'tracking_no',
            ...filterHelper('tracking_no','num'),

        },
    ];

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "PT #": v.ship_header_no,
                    "PT Date": moment(v.ship_date).format("MM-DD-YY"),
                    "Start Date": moment(v.start_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "Customer": v.customer_name,
                    "Billing Addressee": v.billing_addressee,
                    "SO #": v.order_header_no,
                    "PO #": v.reference_no,
                    "PT Qty": parseFloat(v.qty).toFixed(0),
                    "Currency": currencySymbol,
                    "Amount": numeral(v.amount).format("0,0.00"),
                    "Term": v.term_name,
                    "Sale person": v.salesperson_name,
                    "Region": v.region_name,
                    "Brand": v.brand_name,
                    "PT Status": v.pt_status_name,
                    "NS Status": v.ship_status_name,
                    "Location": v.location_name,
                    "Customer Type": v.customer_type_name,
                    "SCF #": v.factor_decision,
                    "Shipping Method": v.shipping_method_name,
                    "Tracking #": v.tracking_no,
                };
            })
        )
    }



    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            dispatch(getPtSummary(token, values)).then((res) => {
                setPtSummary(res?.payload?.data);
                setToogle(true);
                setLoading(false);
                downloadExcelFileData(res?.payload?.data);

            });
        }).catch((error) => {
            setLoading(false);
        })
    }

    const totalQuantity = () => {
        let sumQty = ptSummary.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.qty);
        }, 0);
        return sumQty;
    }

    const totalSum = () => {
        let sumAmount = ptSummary.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }

    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Pick Ticket Summary"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_no"
                                                        label="PT #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}

                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[

                                                            {
                                                                pattern: new RegExp(/[0-9]/),
                                                                message: "Must Be a Number."
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().startOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        defaultValue={form.getFieldValue().cutomer_category}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().region}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"

                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().gender}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"

                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Pt Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        loading={pickTicketStatus.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {pickTicketStatus?.payload?.data.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.pt_status_id}
                                                                        value={item.pt_status_id}
                                                                    >
                                                                        {item.pt_status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_status"
                                                        label="Ship Status"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={"All"}
                                                    >
                                                        <Select
                                                            size='small'
                                                            showSearch
                                                            optionFilterProp="children"

                                                            filterSort={(optionA, optionB) =>
                                                                optionB?.value !== null ?
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                            }
                                                            className="styleInput w-100"
                                                            defaultValue={form.getFieldValue().terms}
                                                        >
                                                            <Option value={null}>All</Option>
                                                            {
                                                                shipStatus?.payload?.data.map((val) => {
                                                                    return (
                                                                        <Option value={val.ship_status_id}>{val.ship_status_name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="subsidary"
                                                    label="Subsidiary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={companyID}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        defaultValue={form.getFieldValue().subsidary}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="brand_id"
                                                    label="Brand"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            brand?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.brand_id} value={val.brand_id}>{val.brand_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (
                        ptSummary.length > 0 ? (
                            <Table
                                title={() =>
                                    <CSVLink
                                        filename={"PT-Summary.csv"}
                                        data={excelData}
                                        onClick={() => {

                                        }}
                                    >
                                        Download Excel
                                    </CSVLink>

















                                }
                                ref={tableRef}

                                size="small"
                                scroll={{ x: "max-content" }}

                                bordered={false}
                                type="company"




                                pagination={{
                                    defaultCurrent: 1,
                                    defaultPageSize: 100,
                                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                                }}
                                summary={(pageData) => {
                                    let sumQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.qty
                                        ));
                                    },
                                        0);
                                    let Amount = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.amount
                                        ));
                                    },
                                        0);
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {sumQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {currencySymbol} {numeral(Amount).format('0,0.00')}
                                                    </p>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                                dataSource={ptSummary}
                                columns={columns}
                                loading={PtSummary.loading}
                                paginate
                            />
                        ) : <div
                            style={{
                                backgroundColor: "#CCCCCC",
                                borderWidth: "1pt",
                                borderStyle: "Dashed",
                                fontWeight: "bold",
                                height: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            No record(s)
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default PtSummary;
