import {
    GET_DROPSHIP_SUMMARY_REPORTS_REQUEST,
    GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS,
    GET_DROPSHIP_SUMMARY_REPORTS_ERROR,
    GET_DROPSHIP_SKU_REPORTS_REQUEST,
    GET_DROPSHIP_SKU_REPORTS_SUCCESS,
    GET_DROPSHIP_SKU_REPORTS_ERROR,
    GET_DROPSHIP_TOTAL_REPORTS_REQUEST,
    GET_DROPSHIP_TOTAL_REPORTS_SUCCESS,
    GET_DROPSHIP_TOTAL_REPORTS_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getDropshipSummary = (token, values) => (dispatch) => {
    dispatch({ type: GET_DROPSHIP_SUMMARY_REPORTS_REQUEST });
    const from_date = moment(values.from_date).format("MM-DD-YYYY");
    const to_date = moment(values.to_date).format("MM-DD-YYYY");
    const newData = {
        cy_start_date: from_date,
        cy_end_date: to_date,
        ly_start_date: from_date
            ? moment(from_date).subtract(1, "year").format("MM-DD-YYYY")
            : null,
        ly_end_date: to_date
            ? moment(to_date).subtract(1, "year").format("MM-DD-YYYY")
            : null,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/DropShipCustomerSummary`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_DROPSHIP_SUMMARY_REPORTS_ERROR,
                payload: [],
            });
        });
};

export const getDropshipSKU =
    (token, values, stepschecked, report) => (dispatch) => {
        console.log({ stepschecked });
        dispatch({ type: GET_DROPSHIP_SKU_REPORTS_REQUEST });
        const newData = {
            from_date: moment(values.from_date).format("MM-DD-YYYY"),
            to_date: moment(values.to_date).format("MM-DD-YYYY"),
            customer_id: report.length > 0 ? report.toString() : null,
            gender_id:
                values.gender_id === "All" || values.gender_id === undefined
                    ? null
                    : values.gender_id,
            location_id: stepschecked.length === 0 ? null : stepschecked.toString(),
        };
        return axios({
            method: "POST",
            url: `${BASEURL}getAll/DropShipBySku`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: newData,
        })
            .then((response) => {
                return dispatch({
                    type: GET_DROPSHIP_SKU_REPORTS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_DROPSHIP_SKU_REPORTS_ERROR,
                    payload: [],
                });
            });
    };

export const getDropshipTotal = (token, values) => (dispatch) => {
    dispatch({ type: GET_DROPSHIP_TOTAL_REPORTS_REQUEST });
    const newData = {
        from_date: moment(values.from_date).format("MM-DD-YYYY"),
        to_date: moment(values.to_date).format("MM-DD-YYYY"),
        gender_id:
            values.gender_id === "All" || values.gender_id === undefined
                ? null
                : values.gender_id,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/DropShipTotal`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_DROPSHIP_TOTAL_REPORTS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_DROPSHIP_TOTAL_REPORTS_ERROR,
                payload: [],
            });
        });
};
