import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Checkbox, DatePicker, Form, Select, Spin, Table } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getDropshipSKU } from "../../redux/actions/dropshipSummaryReports.action.js";
import { getGender } from "../../redux/actions/gender.action.js";
import { getAdminLocation } from "../../redux/actions/adminLocation.action.js";
import { currencySymbol } from "../../utils/constants.js";
import { sorterHelper } from "../../utils/filterHelper.js";

const DropshipSKU = ({ token }) => {
    TabTitle("DropShip SKU")
    const userName = ReadCookie("name");
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const { gender, dropshipSKU, adminLocation } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const stepsArr = [1, 20, 28, 33, 105];
    const [steps, setSteps] = useState([]);
    const [allStatus, setAllStatus] = useState(true);
    const [allFilters, setAllFilters] = useState([
        { id: 1590056, name: "Amazon Seller Central", selected: false },
        { id: 1516580, name: "Moda Operandi", selected: false },
        { id: 70536, name: "Neiman D2C", selected: false },
        { id: 70550, name: "Nordstrom D2C", selected: false },
        { id: 85782, name: "Saks Dropship", selected: false },
        { id: 1429711, name: "Saks Marketplace", selected: false },
        { id: 42989, name: "Maisonette, Inc", selected: false },
    ]);

    const statusSelectAll = (e) => {
        let copyArr = [...allFilters];
        copyArr.map((v) => {
            v.selected = false;;
            return v;
        });
        setAllFilters(copyArr);
        setAllStatus(e.target.checked);
    };

    const { onDownload: onDownloadReport } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Dropship SKU",
        sheet: "Sheet 1",
    });

    useEffect(() => {
        if (adminLocation?.payload?.data?.length > 0) {
            setSteps(
                adminLocation?.payload?.data
                    .filter((val) => stepsArr.includes(val.location_id))
                    .map((it) => ({ ...it, selected: true }))
            );
        }
    }, [adminLocation]);

    const stepschecked =
        steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);

    const getStepData =
        adminLocation &&
        adminLocation?.payload?.data.filter((val) =>
            stepsArr.includes(val.location_id)
        );

    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            let report = []
            allFilters.filter((item) => {
                if (item.selected === true) {
                    report.push(item.id)
                }
                return item;
            });
            values.stepsChecked = stepschecked
            dispatch(getDropshipSKU(token, values, stepschecked, report)).then((res) => {
                if (res.type === "GET_DROPSHIP_SKU_REPORTS_SUCCESS") {
                    setLoading(false);
                }
            });
        }).catch((error) => {
            setLoading(false);
        })
    }

    useEffect(() => {
        dispatch(getGender(token, menuId));
        dispatch(getAdminLocation(token, menuId));
    }, [dispatch, menuId, token]);

    const handlePrint = () => {
        window.print();
    };

    const columns = [
        {
            title: "#",
            className: "b_bottom",
            render: (text, record, index) => {
                return index + 1
            },
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("sku")
        },
        {
            title: "Inseam",
            dataIndex: "inseam",
            key: "inseam",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("inseam", "num")
        },
        {
            title: "Style",
            dataIndex: "style_name",
            key: "style_name",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("style_name")
        },
        {
            title: "Wash",
            dataIndex: "wash_name",
            key: "wash_name",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("wash_name")
        },
        {
            title: "SHP U",
            dataIndex: "shipped_qty",
            key: "shipped_qty",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("shipped_qty", "num")
        },
        {
            title: "RTRN U",
            dataIndex: "returned_qty",
            key: "returned_qty",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("returned_qty", "num")
        },
        {
            title: "NET U",
            dataIndex: "net_qty",
            key: "net_qty",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("net_qty", "num")
        },
        {
            title: "NET " + currencySymbol,
            dataIndex: "net_amount",
            key: "net_amount",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {currencySymbol} {text}
                    </>
                );
            },
            ...sorterHelper("net_amount", "num")
        },
        {
            title: "AUC",
            dataIndex: "auc",
            key: "auc",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("auc", "num")
        },
        {
            title: "RET %",
            dataIndex: "ret_rate_per",
            key: "ret_rate_per",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text} %
                    </>
                );
            },
            ...sorterHelper("ret_rate_per", "num")
        },
        {
            title: "D2C",
            dataIndex: "d2c",
            key: "d2c",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("d2c")
        },
        {
            title: "Bergen",
            dataIndex: "bergen",
            key: "bergen",
            className: " b_left b_bottom",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            ...sorterHelper("bergen")
        },
    ];

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="report-wrapper-main header_class">
                            <div className="row justify-content-between pb-0">
                                <div className="col-3 d-flex align-items-start  box-space">
                                    <div className="logobrand">
                                        <img id="ImgLogo" alt="logo" src={logo} />
                                    </div>
                                </div>

                                <div className="col-3 box-space right-content-header text-right">
                                    <div className=" d-flex justify-content-end">
                                        <div className=" d-flex justify-content-end printing_class">
                                            <div className="print_icon printing_class">
                                                <i
                                                    id="Image1"
                                                    title="Print"
                                                    alt="Print"
                                                    className="fa-solid fa-print printing_class"
                                                    onClick={handlePrint}
                                                ></i>
                                            </div>
                                            <div className="print_icon printing_class">
                                                {dropshipSKU?.payload?.data ? (
                                                    <input
                                                        type="image"
                                                        name="ImgExcel"
                                                        alt="Export to excel"
                                                        id="ImgExcel"
                                                        title="Export to excel"
                                                        src={xlsx}
                                                        onClick={onDownloadReport}
                                                        style={{
                                                            borderWidth: "0px",
                                                            height: "14px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="image"
                                                        name="ImgExcel"
                                                        alt="Export to excel"
                                                        id="ImgExcel"
                                                        title="Export to excel"
                                                        src={xlsx}
                                                        style={{ borderWidth: "0px", height: "14px" }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <span style={{ fontWeight: "bolder" }}>
                                            Dropship SKU
                                            <br />
                                            Rpt # 006
                                            <br />
                                            Login as:
                                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                                {" "}
                                                {userName}
                                            </span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col">
                                    <Form
                                        form={form}
                                        name="control-hooks"
                                        onFinish={onFinish}
                                    >
                                        <div className="main_form row">
                                            <div className="col-12">
                                                <hr className="my-1" />
                                                <div>
                                                    <label>Location</label>
                                                    <div className="checkboxes">
                                                        {getStepData &&
                                                            getStepData.map((step, i) => {
                                                                return (
                                                                    <div className="checkbox_content">
                                                                        <Checkbox
                                                                            checked={steps[i]?.selected}
                                                                            value={12}
                                                                            disabled={i === 0}
                                                                            onChange={(e) => {
                                                                                setSteps(
                                                                                    steps.map((it) => {
                                                                                        if (it.location_id == step.location_id) {
                                                                                            return {
                                                                                                ...it,
                                                                                                selected: e.target.checked,
                                                                                            };
                                                                                        } else {
                                                                                            return it;
                                                                                        }
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            {step.location_name}
                                                                        </Checkbox>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                                <hr className="my-1" />
                                                <div
                                                // className="row"
                                                >
                                                    <label htmlFor="" style={{ marginRight: "25px" }}>Customer</label>
                                                    <div className="checkboxes">
                                                        <div className="checkbox_content">
                                                            <Form.Item name="customer_id" className=" mb-0">
                                                                <Checkbox
                                                                    checked={allStatus} onChange={statusSelectAll}
                                                                >
                                                                    All
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                        {allFilters &&
                                                            allFilters.map((it, index) => {
                                                                return (
                                                                    <div key={index} className="checkbox_content">
                                                                        <Form.Item name="customer_id">
                                                                            <Checkbox
                                                                                checked={it.selected}


                                                                                onChange={(e) => {
                                                                                    let copyArr = [...allFilters];
                                                                                    if (e.target.checked) {
                                                                                        copyArr[index].selected = true;
                                                                                    } else {
                                                                                        copyArr[index].selected = false;
                                                                                    }
                                                                                    setAllFilters(copyArr);
                                                                                    setAllStatus(false);
                                                                                }}
                                                                            >
                                                                                {it.name}
                                                                            </Checkbox>
                                                                        </Form.Item>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                                <hr className="my-1" />
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="gender_id" className="labels width30">
                                                                From:
                                                            </label>
                                                            <div style={{ marginBottom: "-2px" }} className=" w-100">
                                                                <Form.Item
                                                                    className="formFullWidth"
                                                                    name="from_date"
                                                                    initialValue={moment().startOf("year")}
                                                                >
                                                                    <DatePicker
                                                                        size="small"
                                                                        defaultValue={moment().format("MM-DD-YYYY")}
                                                                        className={"select_Inputs"}
                                                                        format={"MM-DD-YYYY"}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="gender_id" className="labels width30">
                                                                To:
                                                            </label>
                                                            <div style={{ marginBottom: "-2px" }} className=" w-100">
                                                                <Form.Item
                                                                    className="formFullWidth"
                                                                    name="to_date"
                                                                    initialValue={moment().endOf("year")}
                                                                >
                                                                    <DatePicker
                                                                        size="small"
                                                                        defaultValue={moment().format("MM-DD-YYYY")}
                                                                        className={"select_Inputs"}
                                                                        format={"MM-DD-YYYY"}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="" className="labels width30">Gender:</label>
                                                            <div className=" w-100">
                                                                <Form.Item name="gender_id" initialValue={"All"}>
                                                                    <Select
                                                                        className={"select_Inputs"}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        size="small"
                                                                    >
                                                                        <Option value={null}>All</Option>
                                                                        {gender &&
                                                                            gender?.payload?.data.map((it, index) => {
                                                                                return (
                                                                                    <Option key={index} value={it.gender_id}>
                                                                                        {it.gender_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-12 col-md-3">
                                                        <div className="row">
                                                            {/* <div className=" col-12"> */}
                                                            <div style={{ marginTop: "3px", display: "flex", justifyContent: "left" }} >
                                                                <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                                                                    Search
                                                                </Button>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table_content"
                style={{ width: '60%' }}
                ref={tableRef} >
                <div>
                    {loading ? (
                        <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin />
                        </div>
                    ) : dropshipSKU?.payload?.data.length ? (
                        // Loop through each state group and render a table

                        <Table
                            style={{ fontSize: '10.3px' }}
                            className="test04 mt-3 sales_target_report_table sales_report_res_table_intransit ledger_print speciality_customer_size sales_report_res_table"
                            size="small"
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000]
                            }}
                            summary={pageData => {
                                let shipQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.shipped_qty)
                                }, 0);
                                let returnQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.returned_qty)
                                }, 0);
                                let netQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_qty)
                                }, 0);
                                let netAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_amount)
                                }, 0);
                                let auc = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.auc)
                                }, 0);
                                let d2c = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.d2c)
                                }, 0);
                                let bergen = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.bergen)
                                }, 0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(shipQty.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(returnQty.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(netQty.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {currencySymbol} {numeral(netAmount.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral((netAmount / netQty).toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(((returnQty / shipQty) * 100).toLocaleString("en-US")).format("0,00")} %
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(d2c.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(bergen.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            // scroll={{ x: 'max-content' }}
                            dataSource={dropshipSKU?.payload?.data}
                            loading={dropshipSKU?.loading}
                        />
                    ) : (
                        <div
                            style={{
                                backgroundColor: '#CCCCCC',
                                borderWidth: '1pt',
                                borderStyle: 'Dashed',
                                fontWeight: 'bold',
                                height: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No record(s)
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DropshipSKU;

