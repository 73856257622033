import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { getControlSheet } from "../../redux/actions/controlSheet.action"
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getAllCompany } from "../../redux/actions/companyAccess";
import PageHeader from "../../components/PageHeader/PageHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { sendEmail, sendOrderConfirmationAutoApprovedPTEmail, sendOrderConfirmationEmail } from "../../redux/actions/email.action";
import { TabTitle } from "../../utils/GeneralFunction";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import notification from "../../utils/notification";
import { ReadCookie } from "../../utils/readCookie";
import { MailOutlined } from '@ant-design/icons';
import { Button, Table } from "antd";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import numeral from "numeral";
import moment from "moment";
import "./pickticket.css";
import { currencySymbol } from "../../utils/constants";

const ControlSheet = () => {
    const [minimize, setMinimize] = useState(false);
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const dispatch = useDispatch();
    TabTitle("Control-Sheet")

    const [selectedRows, setSelectedRows] = useState([]);
    const [controlSheet, setControlSheet] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState([]);
    const tableRef = useRef(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys, selectedRows) => {

        setSelectedRows(selectedRows)
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(() => {
        dispatch(getAllCompany(token));
        dispatch(getDefaultCompany(token));
        dispatch(getControlSheet(token)).then((res) => {
            setLoading(false);
            setSpinner(false);
            setControlSheet(res?.payload?.data);
        });
    }, [dispatch, menuId, token]);


    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'PT #',
            dataIndex: 'ship_header_no',
            key: 'ship_header_no',
            width: 70,
            render: (text, record) => (
                <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                    to={"/order-shipment?mode=edit&order_id=" + record.ship_header_id + "&customer_id/" + record.customer_id + "?edit=yes"}
                >
                    {text}
                </Link>
            ),
        },
        {
            title: 'PT Date',
            render: (text, record) => {
                return <>{moment(record.ship_date).format("MM-DD-YYYY")}</>;
            },
            width: 90
        },
        {
            title: 'Start Date',
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YYYY")}</>;
            },
            width: 90
        },
        {
            title: 'Cancel Date',
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
            },
            width: 90
        },
        {
            title: 'Customer',
            dataIndex: 'addressee',
            key: 'addressee',
            width: 200
        },
        {
            title: 'SO #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            width: 80
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            width: 120
        },
        {
            title: 'Qty',
            render: (text, record) => {
                return <>{parseFloat(record.quantity).toFixed(0)}</>;
            },
            width: 60,
            align: "right",
        },
        {
            title: 'Amount',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.gross_amount).format("0,00")}</>
            },
            width: 80,
            align: "right",
        },
        {
            title: 'Discount Amount',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.discount_amount).format("0,00")}</>
            },
            width: 120,
            align: "right",
        },
        {
            title: 'Freight',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.shipping_cost).format("0,00")}</>
            },
            width: 80,
            align: "right",
        },
        {
            title: 'Surcharge Amount',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.surcharge_amount).format("0,00")}</>
            },
            width: 120,
            align: "right",
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.net_amount).format("0,00")}</>
            },
            width: 100,
            align: "right",
        },
        {
            title: 'Term',
            dataIndex: 'term_name',
            key: 'term_name',
            width: 100
        },
        {
            title: 'Sale Person',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            width: 200
        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            key: 'region_name',
            width: 200
        },
        {
            title: 'PT Status',
            dataIndex: 'pt_status_name',
            key: 'pt_status_name',
            width: 160
        },
        {
            title: 'Approved',
            dataIndex: 'is_approved',
            key: 'is_approved',
            render: (text, record) => {
                return <>{record.is_approved ? "Yes" : "No"}</>
            },
            width: 80
        },
        {
            title: 'Customer Email',
            dataIndex: 'email',
            key: 'email',
            width: 200
        },
        {
            title: 'Customer Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 120
        },
        {
            title: 'Comment',
            dataIndex: 'cs_comment',
            key: 'cs_comment',
            width: 220
        },
        {
            title: '# of Emails Sent',
            dataIndex: 'pt_count',
            key: 'pt_count',
            render: (text, record, index) => {
                return text ? text : 0
            },
        },
        {
            title: 'Send Email',
            render: (text, record, index) => {
                return (
                    <Button icon={<MailOutlined />} loading={loadings[index]}
                        onClick={() => sendingEmail(record, index)} >Send Email</Button>
                )
            },
            width: 140
        },
    ];

    const sendToAll = (() => {
        setSpinner(true)
        selectedRows?.map((res) => {
            if (res.email) {
                if (res?.is_approved) {
                    // Approved
                    dispatch(sendOrderConfirmationAutoApprovedPTEmail(token, res)).then((res) => {
                        if (res.type === "SEND_EMAIL_SUCCESS") {
                            setSelectedRowKeys([]);
                            setSelectedRows([]);
                            setSpinner(false)
                            notification("success", res?.payload);
                        }
                    });
                } else {
                    dispatch(sendOrderConfirmationEmail(token, res)).then((res) => {
                        if (res.type === "SEND_EMAIL_SUCCESS") {
                            setSelectedRowKeys([]);
                            setSelectedRows([]);
                            setSpinner(false)
                            notification("success", res?.payload);
                        }
                    });
                    // Not Approved
                }

            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Customer Email is required!"
                });
            }
        })
    })

    function sendingEmail(e, index) {


        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        const data = {
            ship_date: e.ship_date,
            cancel_date: e.cancel_date,
            addressee: e.addressee,
            order_header_no: e.order_header_no,
            reference_no: e.reference_no,
            quantity: e.quantity,
            gross_amount: e.gross_amount,
            net_amount: e.net_amount,
            surcharge_amount: e.surcharge_amount,
            term_name: e.term_name,
            salesperson_name: e.salesperson_name,
            salesperson_email: e.salesperson_email,
            region_name: e.region_name,
            pt_status_name: e.pt_status_name,
            is_approved: e.is_approved,
            phone: e.phone,
            is_email: true,
            customer_id: e.customer_id,
            email: e.email,
            cs_comment: e.cs_comment,
            ship_header_id: e.ship_header_id,
            ship_header_no: e.ship_header_no,
            shipping_cost: e.shipping_cost,
            secondary_email: e.secondary_email,
        }
        if (e.email) {
            if (e?.is_approved) {
                // Approved
                dispatch(sendOrderConfirmationAutoApprovedPTEmail(token, data)).then((res) => {
                    if (res.type === "SEND_EMAIL_SUCCESS") {
                        setLoadings((prevLoadings) => {
                            const newLoadings = [...prevLoadings];
                            newLoadings[index] = false;
                            return newLoadings;
                        });
                        notification("success", res?.payload);
                    }
                });
            } else {
                dispatch(sendOrderConfirmationEmail(token, data)).then((res) => {
                    if (res.type === "SEND_EMAIL_SUCCESS") {
                        setLoadings((prevLoadings) => {
                            const newLoadings = [...prevLoadings];
                            newLoadings[index] = false;
                            return newLoadings;
                        });
                        notification("success", res?.payload);
                    }
                });
                // Not Approved
            }

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Customer Email is required!"
            });
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }
    }

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Control Sheet"}
                    minimize={setMinimize}
                    check={minimize}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <Table
                    title={() =>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <DownloadTableExcel
                                filename={`Control-Sheet-${moment().format("MM-DD-YYYY")}`}
                                sheet="Control Sheet"
                                currentTableRef={tableRef.current}
                            >
                                <span
                                    className="print_icon printing_class">
                                    <input
                                        style={{ height: "30px", width: "30px" }}
                                        size={"large"}
                                        type="image"
                                        name="ImgExcel"
                                        alt="Export to excel"
                                        id="ImgExcel"
                                        title="Export to excel"
                                        src={xlsx}
                                    />
                                </span>
                            </DownloadTableExcel>
                            {selectedRows.length ?
                                <Button onClick={() => sendToAll()}>
                                    Selected {selectedRows.length} Items
                                </Button> : ""
                            }
                        </div>
                    }
                    rowSelection={{ ...rowSelection }}
                    rowKey={(record) => record.ship_header_id}
                    ref={tableRef}
                    size="small"
                    bordered={false}
                    type="company"
                    scroll={{ x: 'calc(1200px + 90%)', y: 700 }}
                    pagination={{ pageSize: 1000 }}
                    dataSource={controlSheet}
                    columns={columns}
                    loading={spinner}
                    paginate
                    rowClassName={(record) => record.is_email ? "odd" : ''}


                />
            </div>
        </div>
    )
}

export default ControlSheet;