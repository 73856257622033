import React from 'react'
import { Card } from 'antd';

const SalesReturnReportFooter = () => {
  return (
    <div className='order_report_footer'

    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Card style={{ width: "475px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center", textDecoration: "underline" }}>Return Instructions</p>
          <p className='footer_order_p'>1) No return will be accepted without RA#.</p>
          <p className='footer_order_p'>2) All Return must include a copy of this packing list.</p>
          <p className='footer_order_p'>3) Only approved styles and Quantities are returnable against this RA.</p>
          <p className='footer_order_p'>4) Returns will not be accepted if merchandise has a sensor on it.</p>
          <p className='footer_order_p'>5) Return will not be accepted after expire date.</p>
          <p className='footer_order_p'>6) Only Credit Memo will be issued for returned goods - no refund.</p>
          <p className='footer_order_p'>7) There will be a deduction of 5% if the returned unit is missing waist tag.</p>
          <p className='footer_order_p'>8) Returns without an advance shipping notice (including tracking#) and an approved RA# will not be processed.</p>
        </Card>

        <Card style={{ width: "310px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center", textDecoration: "underline" }}>Return Address</p>
          <p className='footer_order_p'>
            Bergen Shippers DLPD Canada LTD c/o Bergen Logistics<br />
            3925 Steeles Ave East UNIT # 3 Brampton, ON L6T 5W5<br />
            Contact Person : Mary Williams<br />
            Tel: 905 792 8585 Ext.25  </p>
        </Card>
      </div>

    </div>
  )
}

export default SalesReturnReportFooter;