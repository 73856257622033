import {
	ADD_ITEM_TRANSFER_HEADER_ERROR,
	ADD_ITEM_TRANSFER_HEADER_REQUEST,
	ADD_ITEM_TRANSFER_HEADER_SUCCESS,
	DELETE_ITEM_TRANSFER_HEADER_ERROR,
	DELETE_ITEM_TRANSFER_HEADER_REQUEST,
	DELETE_ITEM_TRANSFER_HEADER_SUCCESS,
	GET_ALL_ITEM_TRANSFER_ERROR,
	GET_ALL_ITEM_TRANSFER_REQUEST,
	GET_ALL_ITEM_TRANSFER_SUCCESS,
	GET_ITEM_TRANSFER_FORM_DATA_ERROR,
	GET_ITEM_TRANSFER_FORM_DATA_REQUEST,
	GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS,
	GET_ONE_ITEM_TRANSFER_HEADER_ERROR,
	GET_ONE_ITEM_TRANSFER_HEADER_REQUEST,
	GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS,
	UPDATE_ITEM_TRANSFER_HEADER_ERROR,
	UPDATE_ITEM_TRANSFER_HEADER_REQUEST,
	UPDATE_ITEM_TRANSFER_HEADER_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const addItemTransferHeader = (token, formData, companyId) => (dispatch) => {
	
	dispatch({ type: ADD_ITEM_TRANSFER_HEADER_REQUEST });
	let newData = {
		asn_no: formData.asnNo,
		transfer_date: moment(formData.transferDate).format("MM-DD-YYYY"),
		gl_period_id: formData.postingPeriod,
		from_location_id: formData.fromLocation,
		ship_via: formData.shipVia,
		to_location_id: formData.toLocation,
		company_id: companyId,
		brand_id: formData.brand_id,
		memo: formData.memo,
		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
		bill_no: formData.billNo
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/iNVItemTransferHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_ITEM_TRANSFER_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_ITEM_TRANSFER_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateItemTransferHeader = (token, formData, itemTransferId) => (dispatch) => {
	let newData = {
		asn_no: formData.asnNo,
		transfer_date: moment(formData.transferDate).format("MM-DD-YYYY"),
		gl_period_id: formData.postingPeriod,
		from_location_id: formData.fromLocation,
		ship_via: formData.shipVia,
		to_location_id: formData.toLocation,
		memo: formData.memo,
		brand_id: formData.brand_id,
		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
		bill_no: formData.billNo
	};
	dispatch({ type: UPDATE_ITEM_TRANSFER_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/iNVItemTransferHeader/${itemTransferId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_ITEM_TRANSFER_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_ITEM_TRANSFER_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllItemTransferHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_ITEM_TRANSFER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/iNVItemTransferHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			transfer_header_no: data.transferNo === "" ? null : data.transferNo,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			from_location_name: data.fromLocation === "" ? null : data.fromLocation,
			to_location_name: data.toLocation === "" ? null : data.toLocation,
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			ship_via: null,
			asn_no: data.asn === "" ? null : data.asn,
			memo: data.memo === "" ? null : data.memo,
			transfer_header_id: null,
			brand_id: data.brand_id === "" ? null : data.brand_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_ITEM_TRANSFER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_ITEM_TRANSFER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneItemTransferHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ITEM_TRANSFER_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/iNVItemTransferHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneItemTransferHeaderDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/iNVItemTransferHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteItemTransferHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_ITEM_TRANSFER_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/iNVItemTransferHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_ITEM_TRANSFER_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_ITEM_TRANSFER_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getItemTransferFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_ITEM_TRANSFER_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
			payload: {
				location: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/iNVItemTransferFormData`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ITEM_TRANSFER_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};
