import {
    GET_ROUTINE_LOG_REQUEST,
    GET_ROUTINE_LOG_SUCCESS,
    GET_ROUTINE_LOG_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getRoutineLog = (token, data) => (dispatch) => {
    dispatch({ type: GET_ROUTINE_LOG_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetRoutineLogs`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            sku: (data.sku === "All" || data.sku === undefined) ? null : data.sku,
            routine_name: (data.routine === "All" || data.routine === undefined) ? null : data.routine,
            from_date: moment(data?.from_date).format("MM-DD-YYYY"),
            to_date: moment(data?.to_date).format("MM-DD-YYYY")
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ROUTINE_LOG_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ROUTINE_LOG_ERROR,
                payload: [],
            });
        });
};