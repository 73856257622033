import React, { useState } from 'react'
import "../OMSBooking/styleSheet.css"
import { Table, Spin, Input } from 'antd';
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react'
import { getRegion } from '../../redux/actions/region.action'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getGender } from '../../redux/actions/gender.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { getOmsShipReportByCustomer } from '../../redux/actions/omsShipReportByCustomer';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { getCustomerType } from '../../redux/actions/customerType.action';
import { currencySymbol } from '../../utils/constants';
import moment from 'moment';
const OmsReportByCustomer = ({ token, id }) => {
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const dispatch = useDispatch();
    const { gender, region, omsShipReportByCustomer, customerTypes } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)
    const history = useHistory();
    const [data, setData] = useState([])

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let company_id = query.get("company_id")
    let gender_id = query.get("gender_id")
    let customer_type_id = query.get("customer_type_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let select = query.get("select")
    let state_id = query.get("state_id")
    let customer_id = query.get("customer_id")
    let customer_name = query.get("customer_name")
    let state_name = query.get("state_name")

    useLayoutEffect(() => {
        setValues(history?.location?.values)
        setLoading(true)
        dispatch(getOmsShipReportByCustomer(token, { id, company_id, gender_id, from_date, from_date, to_date, customer_type_id, region_id, selected_Region, select, customer_id, state_id })).then((res) => {
            setData(res?.payload?.data)
            setSubmit(true)
            setLoading(false)
        });
    }, []);

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId))
        dispatch(getAllCompany(token));
        dispatch(getCustomerType(token));
    }, [dispatch, menuId, token])

    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            key: 'serial_no',
            align: 'left',
            width: 50,
            render: (text, object, index) => index + 1,

        },

        {
            title: 'Invoice #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            align: 'left',
            width: 90,
            render: (record, text) => {
                return <Link
                    
                    
                    
                    rel="noopener noreferrer"
                
                
                
                
                > {record}</ Link >
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.order_header_no !== null) {
                    return record.order_header_no.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return a.order_header_no - b.order_header_no;
            },

        },
        {
            title: 'Store',
            dataIndex: '',
            key: '',
            align: 'left',
            width: 90,
        },
        {
            title: 'Ref #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            align: 'left',
            width: 110,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.reference_no !== null) {
                    return record.reference_no.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.reference_no !== null & b.reference_no !== null) {
                    return a.reference_no.localeCompare(b.reference_no)
                }
            },
        },
        {
            title: 'Invoice Date',
            dataIndex: 'order_date',
            key: 'order_date',
            align: 'left',
            width: 110,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.order_date !== null) {
                    return record.order_date.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.order_date !== null & b.order_date !== null) {
                    return a.order_date.localeCompare(b.order_date)
                }
            },
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            width: 90,
            render: text => <span style={{ textAlign: "right" }}> {numeral(text).format("")} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.quantity !== null) {
                    return record.quantity.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Inv Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            align: 'right',
            width: 120,
            render: (text,record) => {
           return <> <span style={{ textAlign: "right" }}> {currencySymbol}{parseFloat(record.net_amount).toFixed(2)} </span ></>
            },
            
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.net_amount !== null) {
                    return record.net_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.net_amount.replaceAll(',', '') - b.net_amount.replaceAll(',', '')
                )
            },

        },
        {
            title: 'Creadit Note',
            dataIndex: 'return_amount',
            key: 'return_amount',
            align: 'right',
            width: 110,
            render: text => <span style={{ textAlign: "right" }}> {currencySymbol}{text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.return_amount !== null) {
                    return record.return_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.return_amount.replaceAll(',', '') - b.return_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Payment',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            width: 90,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {currencySymbol}{text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.payment_amount !== null) {
                    return record.payment_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.payment_amount.replaceAll(',', '') - b.payment_amount.replaceAll(',', '')
                )
            },

        },
        {
            title: 'Balance',
            dataIndex: 'running_balance',
            key: 'running_balance',
            width: 110,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> 0</span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.running_balance !== null) {
                    return record.running_balance.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.running_balance.replaceAll(',', '') - b.running_balance.replaceAll(',', '')
                )
            },
        },
        {
            title: "AUC",
            dataIndex: "auc",
            key: "auc",
            width: 120,
            align: "right",
            render: (text) => <span style={{ textAlign: "right" }}>{currencySymbol}{parseFloat(text).toFixed(2)}</span>,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              return (
                <>
                  <Input
                    allowClear
                    autoFocus
                    className="pop-search-gen pop-220"
                    
                    value={selectedKeys[0]}
                    onChange={(e) => {
                      setSelectedKeys(e.target.value ? [e.target.value] : []);
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={() => {
                      confirm();
                    }}
                    onBlur={() => {
                      confirm();
                    }}
                  />
                </>
              );
            },
            filterIcon: (filtered) =>
              filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
              if (record.auc !== null) {
                return record.auc
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }
            },
            sorter: (a, b) => {
              return (
                a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
              )
            },
          },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            align: 'right',
            width: 110,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.due_date !== null) {
                    return record.due_date.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.due_date !== null & b.due_date !== null) {
                    return a.due_date.localeCompare(b.due_date)
                }
            },
        },
        {
            title: 'Status',
            dataIndex: '',
            key: '',
            align: 'left',
            width: 80,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.status !== null) {
                    return record.status.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return a.status - b.status;
            },
        },

        {
            title: 'Sold By',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            align: 'left',
            width: 160,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.salesperson_name !== null) {
                    return record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.salesperson_name !== null & b.salesperson_name !== null) {
                    return a.salesperson_name.localeCompare(b.salesperson_name)
                }
            },
        },
        {
            title: 'Tracking',
            dataIndex: '',
            key: '',
            align: 'left',
            width: 120,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.tracking !== null) {
                    return record.tracking.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return a.tracking - b.tracking;
            },
        },

    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id)
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id)
    })

    const filterCustomerType = customerTypes.payload?.data.find((item) => {
        return item.customer_type_id === Number(customer_type_id);
    })

    const totalSum = () => {
        let sumAmount = data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.net_amount.replace(/\$|,/g, ''));
        }, 0);
        return sumAmount;
    }

    const totalQuantity = () => {
        
        
        
        
        let sumQty = data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity.replace(/\$|,/g, ''));
        }, 0);
        return sumQty;
    }

    return (
        <>
            <div className='row row report-wrapper-main p-0'>
                <div className='col p-0'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                                <td width="103">
                                    State:
                                </td>
                                <td>
                                    <b>
                                        {select === "true" ? "All" : state_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                Customer Type:
                                </td>
                                <td>
                                    <b>
                                    {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {moment(from_date).format("MM-DD-YYYY")}
                                    </b>
                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {moment(to_date).format("MM-DD-YYYY")}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                </td>
                                <td>
                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                    {company_id === "All"? "All" : company_id === "1"? "DL1961" : company_id === "2" ? "Warp + Weft" : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >

                    <table className="tablestyleControls" style={{ marginTop: "5px" }}>
                        <tbody>
                            <tr >
                                <td style={{ width: "82.5px" }}>
                                    Selected Customer:
                                </td>
                                <td style={{ fontWeight: "bolder" }} rowSpan={"15"}>
                                    {customer_name}
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {
                loading ?
                    <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                        <Spin size='large' />
                    </div> :
                    <>{(submit) ?
                        <div className='w-100 mt-3'>
                            <Table
                        showSorterTooltip = {false}
                                scroll={{ x: 100, y: 420 }}
                                className=' test04 ship_region_report_4'
                                
                                size='small'
                                pagination={{ pageSize: 3000 }}
                                summary={pageData => {
                                    let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                        return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                                    }, 0);
                                    let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                        return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                                    }, 0);
                                    let sumCN = pageData.reduce(function (previousValue, currentValue) {
                                        return previousValue += parseFloat(currentValue.return_amount.replace(/\$|,/g, ''))
                                    }, 0);
                                    let sumPayment = pageData.reduce(function (previousValue, currentValue) {
                                        return previousValue += parseFloat(currentValue.payment_amount.replace(/\$|,/g, ''))
                                    }, 0);
                                    let sumBalance = pageData.reduce(function (previousValue, currentValue) {
                                        return previousValue += parseFloat(currentValue.running_balance.replace(/\$|,/g, ''))
                                    }, 0);
                                    return (
                                        <>
                                            <Table.Summary.Row >
                                            <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ fontWeight: "bolder" }}>Total</p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{
                                                        fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                        borderBottom: "1px solid black"
                                                    }}>{sumQty.toLocaleString("en-US")}</p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumAmount.toLocaleString("en-US")}</p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumCN.toLocaleString("en-US")}</p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumPayment.toLocaleString("en-US")}</p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumBalance.toLocaleString("en-US")}</p>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                                </Table.Summary.Cell>

                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                                bordered
                                columns={columns}
                                dataSource={omsShipReportByCustomer?.payload?.data}
                                sortDirections={["ascend", "descend", "ascend"]}
                            />
                        </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                            fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                        }} >
                            <tbody>
                                <tr style={{
                                    backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                                }} >
                                    <td colSpan="5">
                                        No record(s)</td>
                                </tr>
                            </tbody>
                        </table>}
                    </>
            }
        </>
    )
}
export default OmsReportByCustomer;
