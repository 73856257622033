import {
  GET_ALL_CUSTOMERS_ERROR,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_REQUEST,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  GET_ONE_CUSTOMER_REQUEST,
  GET_ONE_CUSTOMER_SUCCESS,
  GET_ONE_CUSTOMER_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
export const loadCustomers = (token, customerCode, regionID, customerTypeID, salePersonID, paymentTerm, customerName, customerid, shippingMethodID, freightTerm, shippingAccount, resalecertificate, orderStatusID, email, billAddress, apfirstname, aplastname, active, ishold) => (dispatch) => {
  dispatch({ type: GET_ALL_CUSTOMERS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/customer`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {

      p_resale_certificate_no: resalecertificate ? resalecertificate : null,
      p_customer_code: customerCode ? customerCode : null,
      p_region_id: regionID === "All" ? null : regionID,
      p_customer_name: customerName ? customerName : null,
      p_saleperson_id: salePersonID === "All" ? null : salePersonID,
      p_customer_type_id: customerTypeID === "All" ? null : customerTypeID,
      payment_term_id: paymentTerm === "All" ? null : paymentTerm,
      p_shipping_method: shippingMethodID === "All" ? null : shippingMethodID,
      p_freight_term_id: freightTerm === "All" ? null : freightTerm,
      p_shipping_account_no: shippingAccount ? shippingAccount : null,

      p_order_status_id: orderStatusID === "All" ? null : orderStatusID,
      p_email_address: email ? email : null,
      p_default_bill_address: billAddress ? billAddress : null,
      p_first_name: apfirstname ? apfirstname : null,
      p_last_name: aplastname ? aplastname : null,
      p_active: active ? active : null,
      p_is_hold: ishold ? ishold : null,
      p_customer_id: customerid ? customerid : null








    }
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CUSTOMERS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CUSTOMERS_ERROR,
        payload: [],
      });
    });
};

export const getOneCustomer = (token, id) => dispatch => {
  dispatch({ type: GET_ONE_CUSTOMER_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/customer/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    }
  })
    .then(response => {
      return dispatch({ type: GET_ONE_CUSTOMER_SUCCESS, payload: response.data.data[0] });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_CUSTOMER_ERROR,
        payload: error.response,
      });
    })
}

export const addCustomer = (token, data, address) => (dispatch) => {
  dispatch({ type: ADD_CUSTOMER_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/customer`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      is_active: true,
      company_id: data.company,
      tax_duties: data.taxDuties,
      order_type_id: data.orderType,
      ship_to_non_us: data?.shipToNonUs,
      bill_to_non_us: data?.billToNonUs,
      customer_name: data.customerName,
      customer_type_id: data.customerType,
      customer_category_id: data.customerType,
      website: data.webAddress,
      remarks: data.comments,
      credit_limit: !!data.credit_limit ? Number(Number(data.credit_limit).toFixed(2)) : null,
      brand_id: data.brand_id,
      first_name: data.firstName,
      last_name: data.lastName,
      email_address: data.email,
      secondary_email: data.secondary_email,
      alternate_email_address: data.otherEmails,
      phone_no: data.phone,
      fax_no: data.fax,
      order_status_id: data.orderStatus,
      old_customer_code: data.oldID,
      resale_certificate_no: data.rcn,
      resale_certificate_date: moment(data.expiryDate).format("MM-DD-YYYY"),
      shipping_method_id: data.shippingMethod,
      freight_term_id: data.freightTerm,
      bergan_billing_id: data.bergenBilling,
      bergan_ship_via_id: data.shipVia,
      shipping_account_no: data.accountNo,
      alcohol_recipient_type: data.alcoholType,
      is_hold: data.isApproved,
      is_ship_complete: data.isComplete,
      is_approved: data.isApproved,
      federal_tax_id: data.fTaxID,
      term_id: data.paymentTerm,
      address
    },
  })
    .then((response) => {
      return dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: response.data })
    })
    .catch((error) => {
      return dispatch({
        type: ADD_CUSTOMER_ERROR,
        payload: error.response,
      });
    });
};

export const deleteCustomer = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_CUSTOMER_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/customer/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({
        type: DELETE_CUSTOMER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_CUSTOMER_ERROR,
        payload: error.response,
      });
    });
};

export const updateCustomer = (token, data, customerAllIdd) => (dispatch) => {

  dispatch({ type: UPDATE_CUSTOMER_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customer/${customerAllIdd}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      is_deleted: 0,
      is_active: data.is_active,
      company_id: data.company,
      customer_name: data.customerName,
      customer_type_id: data.customerType,
      website: data.webAddress,
      remarks: data.comments,
      credit_limit: !!data.credit_limit ? Number(Number(data.credit_limit).toFixed(2)) : null,
      brand_id: data.brand_id,
      first_name: data.firstName,
      last_name: data.lastName,
      email_address: data.email,
      secondary_email: data.secondary_email,
      alternate_email_address: data.otherEmails,
      phone_no: data.phone,
      fax_no: data.fax,
      order_status_id: data.orderStatus ? data.orderStatus : 0,
      old_customer_code: data.oldID,
      resale_certificate_no: data.rcn,
      resale_certificate_date: data.expiryDate,
      shipping_method_id: data.shippingMethod,
      freight_term_id: data.freightTerm,
      bergan_billing_id: data.bergenBilling,
      bergan_ship_via_id: data.shipVia,
      shipping_account_no: data.accountNo,
      alcohol_recipient_type: data.alcoholType,
      is_hold: data.isApproved,
      is_ship_complete: data.isComplete,
      is_approved: data.isApproved,
      term_id: data.paymentTerm,
      federal_tax_id: data.fTaxID,
      tax_duties: data.taxDuties,
      order_type_id: data.orderType,
      ship_to_non_us: data?.shipToNonUs,
      bill_to_non_us: data?.billToNonUs,



    },
  })
    .then((response) => {

      return dispatch({
        type: UPDATE_CUSTOMER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_CUSTOMER_ERROR,
        payload: error.response,
      });
    });
};
