import React, { useEffect, useMemo, useRef } from "react";
import "./OrderSalesReport.css";
import OrderReportHeader from "./OrderReportHeader/OrderReportHeader";
import OrderReportFooter from "./OrderReportFooter/OrderReportFooter";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import {
  getPickTicketPrint,
  getPickTicketColumn,
  getPickTicketColumnValue,
  getPickTicketItem,
  getNewPTReport
} from "../../redux/actions/pickTicketReport.action"
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { currencySymbol } from "../../utils/constants";

const NewOrderReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {
    PickTicketPrint,
    PickTicketColumnValue,
    PickTicketColumn,
    PickTicketItem,
    newPTReport
  } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let ship_header_id = query.get("ship_header_id")

  useEffect(() => {
    dispatch(getPickTicketColumn(token, values));
    dispatch(getNewPTReport(token, values));
  }, []);

  const footerData =
    newPTReport && newPTReport?.payload?.data[0];
  let groupValues =
    newPTReport &&
    newPTReport?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));
  const groupedColumnValue = groupValues && Object.values(groupValues);

  const values = {
    ship_header_id: ship_header_id,
  };

  const sum = PickTicketColumnValue?.payload?.data.reduce((acc, item, index) => acc + parseInt(item.quantity), 0)


  const myData = useMemo(() => {
    return PickTicketColumn?.payload?.data.map((it, i) => {
      return {
        column: it,
        detail: groupedColumnValue && groupedColumnValue[i],
      };
    });
  }, [groupedColumnValue, PickTicketColumn]);



  return (
    <div>
      {
        myData ? (
          <div
            className="order_report_view"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <table>
              <thead>
                <tr>
                  <td className="footer">
                    <div>
                      <OrderReportHeader />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="content-block">
                      <div className="sales-report-Table-data">
                        {myData?.map((size, index) => {
                          return (
                            <table className="sales-report-table">
                              <tbody>
                                <tr>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "36px",
                                    }}
                                  >
                                    S.No
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{

                                      width: "148px",
                                    }}
                                  >
                                    SKU #
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{

                                      width: "80px",
                                    }}
                                  >
                                    Brand
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{

                                      width: "340px",
                                    }}
                                  >
                                    Style/Desc
                                    <span style={{ marginLeft: "5px" }}>
                                      {size?.column?.gender_category_name}
                                    </span>
                                  </td>
                                  <td
                                    key={index}
                                    className="sales_table_col font_weight text-center pl-0"
                                  >
                                    {size.column.hq23 && size.column.hq23}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq24 && size.column.hq24}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq25 && size.column.hq25}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq26 && size.column.hq26}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq27 && size.column.hq27}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq28 && size.column.hq28}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq29 && size.column.hq29}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq30 && size.column.hq30}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq31 && size.column.hq31}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq32 && size.column.hq32}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq33 && size.column.hq33}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq34 && size.column.hq34}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq35 && size.column.hq35}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq36 && size.column.hq36}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq37 && size.column.hq37}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq38 && size.column.hq38}
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Qty
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{

                                      width: "90px",
                                    }}
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Amount
                                  </td>
                                </tr>
                                {/* dummy data */}

                                {size.detail?.map((it, index) => {

                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td
                                        style={{
                                          marginLeft: "0",
                                        }}
                                      >
                                        {it.item_code}
                                      </td>
                                      <td>
                                        {it?.sku_brand_name}
                                      </td>
                                      <td>
                                        {it.description}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_1 && Number(it.qty_1).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_2 && Number(it.qty_2).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_3 && Number(it.qty_3).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_4 && Number(it.qty_4).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_5 && Number(it.qty_5).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_6 && Number(it.qty_6).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_7 && Number(it.qty_7).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_8 && Number(it.qty_8).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_9 && Number(it.qty_9).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_10 && Number(it.qty_10).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_11 && Number(it.qty_11).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_12 && Number(it.qty_12).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_13 && Number(it.qty_13).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_14 && Number(it.qty_14).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_15 && Number(it.qty_15).toFixed(0)}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.qty_16 && Number(it.qty_16).toFixed(0)}
                                      </td>
                                      <td>
                                        {it.quantity && Number(it.quantity).toFixed(0)}
                                      </td>

                                      <td>{currencySymbol} {it.rate && Number(it.rate).toFixed(2)}</td>
                                      <td>{currencySymbol} {it.amount && Number(it.amount).toFixed(2)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          );
                        })}

                        {/* <div className="total_qty">Total Quantity = {sum}</div> */}
                      </div>
                    </div>
                    <table className="sales-report-table-total">
                      <tfoot>
                        <tr className="font_weight ">
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight total_cell">Total Quantity</td>
                          <td className="sum_cell"
                            style={{
                              width: "48px",
                            }}
                          >
                            {/* {sum} */}
                            {footerData && footerData.gross_quantity}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    {/* {footerData && (footerData.term === "CREDIT CARD" || footerData.term === "PRE-PAYMENT" || footerData.term === "WIRE TRANSFER") ? */}
                    <table className="sales-report-table-total">
                      <tbody>
                        <tr style={{
                          width: "100px",
                          textAlign: "right",
                          paddingRight: "10px",
                        }} className="font_weight total_cell">{footerData && footerData.term} ORDER</tr>
                        <tr className="font_weight">
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight total_cell"
                          >
                            Amount
                          </td>
                          <td
                            className="sum_cell"
                            style={{
                              width: "48px",
                            }}
                          >
                            {footerData && Number(footerData.sum_gross_amount).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight"
                          >
                            Discount Amount
                          </td>
                          <td>{currencySymbol} {footerData && Number(footerData.discount).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight"
                          >
                            Surcharge Amount
                          </td>
                          <td>{currencySymbol} {footerData && Number(footerData.surcharge_amount || 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight"
                          >
                            Tax Amount
                          </td>
                          <td>{currencySymbol} {footerData && Number(footerData.tax_amount || 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td
                            className="font_weight"
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                          >
                            Freight Amount
                          </td>
                          <td>{currencySymbol} {footerData && Number(footerData.freight_amount).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td
                            className="font_weight"
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                          >
                            Net Amount
                          </td>
                          <td>{currencySymbol} {footerData && Number(footerData.net_amount).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/*: <></>}*/}
                    {/* <div>
                  <div className="signature">
                    Authorized Signature
                  </div>
                </div> */}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="footer2">

                    <div className="sales-report-footer">
                      <OrderReportFooter />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            {/* <div className="sales-report-footer">
              <OrderReportFooter />
            </div> */}
          </div>

        ) : (
          <div className="spin">
            <Spin size="large" />
          </div>
        )
      }

      {/* <div className="sales-report-footer">
        <OrderReportFooter />
      </div> */}
    </div>
  );
};

export default NewOrderReport;
