import {
    GET_SPECIALITY_BY_STYLE_REQUEST,
    GET_SPECIALITY_BY_STYLE_SUCCESS,
    GET_SPECIALITY_BY_STYLE_ERROR,
    GET_CUSTOMER_BY_PO_REQUEST,
    GET_CUSTOMER_BY_PO_SUCCESS,
    GET_CUSTOMER_BY_PO_ERROR
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const getSpecialityByStyle = (token, data) => (dispatch) => {
    dispatch({ type: GET_SPECIALITY_BY_STYLE_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetSpecialityReportByStyleWise`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            customer_id: (data.customer === "All" || data.customer === undefined) ? null : data.customer,
            status_name: (data.status === "All" || data.status === undefined) ? null : data.status,
            region_id: (data.region === "All" || data.region === undefined) ? null : data.region,
            from_date: moment(data?.from_date).format("MM-DD-YYYY"),
            to_date: moment(data?.to_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({ type: GET_SPECIALITY_BY_STYLE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SPECIALITY_BY_STYLE_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SPECIALITY_BY_STYLE_ERROR,
                payload: error.response,
            });
        });
};


export const getCustomerByPO = (token, data) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_BY_PO_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetCustomerByPONO`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            customer_id: (data.customer === "All" || data.customer === undefined) ? null : data.customer,
            status_name: (data.status === "All" || data.status === undefined) ? null : data.status,
            from_date: moment(data?.from_date).format("MM-DD-YYYY"),
            to_date: moment(data?.to_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({ type: GET_CUSTOMER_BY_PO_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_CUSTOMER_BY_PO_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_CUSTOMER_BY_PO_ERROR,
                payload: error.response,
            });
        });
};
