






















export function fetchIPAddress() {
  const hardcodedIP = "123.45.67.89"; 
  
  return Promise.resolve(hardcodedIP);
}

export async function fetchIpWithoutApi() {
  const peerConnection = new RTCPeerConnection({
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  });

  
  peerConnection.createDataChannel("");

  
  const offer = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offer);

  
  return peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      const candidate = event.candidate.candidate;
      const ipMatch = candidate.match(
        /(\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b)/
      );
      if (ipMatch) {
        
        return ipMatch[1]
      }
    }
  };
}
