import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addSaleReturnHeader,
  updateSaleReturnHeader,
  deleteSaleReturnLine,
} from "../../../../redux/actions/salesReturn.action";
import { DeleteOutlined } from "@ant-design/icons";

import { getSingleBilling } from "../../../../redux/actions/billing";
import {
  getOneSalesLine,
  getOneSalesLineDetail,
  getOnesaleReturnRelatedRecord,
} from "../../../../redux/actions/salesReturnLine.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { relatedTransactionColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import { asnToBergen } from "../../../../redux/actions/asnToBergen.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Card,
  Row,
  Col,
  Table,
  InputNumber,
  Tooltip,
  Popconfirm,
  Skeleton,
  Checkbox
} from "antd";
import { getOneCustomerAddress } from "../../../../redux/actions/customerAddress.action";
import { getSaleOrderFormData } from "../../../../redux/actions/salesOrder.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import "../../../../screens/SaleOrder/salesOrder.css";
import EditableTable from "../../EditableTable";
import Swal from "sweetalert2";
import { getReturnType } from "../../../../redux/actions/ReturnType.action";
import { getReturnResaon } from "../../../../redux/actions/returnreason.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import numeral from "numeral";
import { onMouseDownSelectableHack } from "../../../../components/DropdownSelected";
import { CSVLink } from "react-csv";
import { getBrands } from "../../../../redux/actions/brand.action";
import { companyID, currencySymbol } from "../../../../utils/constants";

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

export default function SaleReturnForm({
  userRights,
  formData,
  val,
  changeState,
  token,
  formState,
  submit,
  companyId,
  mode,
  setReturnAuthID,
  return_id,
  setDisable
}) {
  const submitButton = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    systemLog,
    glImpact,
    saleOrderFormData,
    ProductSale,
    customerAddress,
    returnType,
    returnreason,
    companyAll,
    brand
  } = useSelector((state) => state);
  const [gender1, setGender1] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [gender, setGender] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const focusInput = useRef(null);
  const [discountBasis] = useState("");
  const [saleReturnRelatedTransac, setReturnRelatedTransac] = useState([]);
  const [menuId] = useState(56);
  const [formDataValues, setFormDataValues] = useState({});

  useEffect(() => {
    dispatch(getReturnType(token, menuId));
    dispatch(getReturnResaon(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllCompany(token));
    dispatch(getBrands(token));
  }, []);

  useEffect(() => {
    if (mode === "return") {
      changeState("return");
    }
  }, [mode, formState]);

  useEffect(() => {
    if (formData) {
      dispatch(
        getSaleOrderFormData(
          token,
          companyId,
          menuId,
          "",
          formData?.customer_id
        )
      );
      dispatch(
        getOneCustomerAddress(
          token,
          formData?.customer_id,
          formData?.order_header_id
        )
      );
      setCustomer(formData?.customer_id);
    }
    if (formData && mode === "edit") {
      dispatch(getOneSalesLine(token, return_id)).then(
        (res) => {
          if (res.type === "GET_ONE_SALES_RETURN_LINE_SUCCESS") {
            const orderData = res.payload.data;
            setVisibleItems(res.payload.data);
            setOrderItems(res.payload.data);
            setVisibleItems(orderData);
          }
        }
      );
    }
    if (formData && mode === "return") {
      dispatch(getOneSalesLineDetail(token, formData?.invoice_header_id)).then((res) => {
        if (res.type === "GET_ONE_SALES_RETURN_LINE_DETAIL_SUCCESS") {
          const orderData = res.payload.data;
          setVisibleItems(res.payload.data);
          setOrderItems(res.payload.data);
          setVisibleItems(orderData);
        }
      });
    }
    if (formData) {
      const initialValues = {
        returnAuthorizationNumber: formData && formData?.return_header_no,
        customer: formData && formData?.customer_id,
        invoice_header_no: formData && formData?.invoice_header_no,
        company: formData && formData?.company_id,
        category: formData && formData.sp_region_category_id,
        gender: formData && formData.gender_id,
        location: formData && formData.location_id,
        salesPerson: formData && formData.sales_person_id,
        region: formData && formData.region_id,
        memo: formData && formData.memo,
        remarks: formData && formData.remarks,
        reference_no: formData && formData.reference_no,
        return_date: formData && moment(formData.return_date),
        ship_header_no: formData && formData.ship_header_no,
        tracking_no: formData && formData.tracking_no,
        order_header_no: formData && formData.order_header_no,
        paymentTermId: formData && formData.payment_term_id,
        brand_id: formData && formData.brand_id,
        returnType: formData && formData.return_type_id,
        returnReason: formData && formData.reason_id,
        lotNumber: formData && formData.lot_no,
        year: formData && formData.year,
        RaApproval: formData && formData.ra_no,
        season: formData && formData.season ? formData.season : "Spring",
        // billing
        billingCustomer: formData.billing_address_id,
        billing_firstname: formData.bill_to_first_name,
        billing_lastname: formData.bill_to_last_name,
        addressOneBilling: formData.bill_to_address_1,
        addressTwoBilling: formData.bill_to_address_2,
        cityBilling: formData.bill_to_city_name,
        stateBilling: formData.bill_to_state_name,
        zipBilling: formData.bill_to_zip_code,
        countryBilling: formData.bill_to_country_name,
        telBilling: formData.bill_to_phone,
        billingEmail: formData.bill_to_email,
        // shipping
        shippingCustomer: formData.shipping_address_id,
        shipping_firstname: formData.ship_to_first_name,
        shipping_lastname: formData.ship_to_last_name,
        addressOneShipping: formData.ship_to_address_1,
        addressTwoShipping: formData.ship_to_address_2,
        shippingEmail: formData.ship_to_email,
        cityShipping: formData.ship_to_city_name,
        telShipping: formData.ship_to_phone,
        stateShipping: formData.ship_to_state_name,
        zipShipping: formData.ship_to_zip_code,
        countryShipping: formData.ship_to_country_name,
        ASNToBergen: formData.asn_to_bergen,
      }
      form.setFieldsValue(initialValues);
      setFormDataValues(initialValues)
    }
  }, [formData]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  useEffect(() => {
    if (formState === "add") {
      focusInput.current.focus();
      setOrderItems([]);
      setVisibleItems([]);
      const initialValues = {
        customer: null,
        lotNumber: null,
        returnType: null,
        returnReason: null,
        RaApproval: null,
        invoceNumber: null,
        orderNumber: null,
        order_header_no: null,
        category: null,
        location: null,
        salesPerson: null,
        region: null,
        memo: null,
        season: null,
        year: null,
        gender: null,
        paymentTermId: null,
        reference_no: null,
        return_date: moment(invoiceDate),
        billingEmail: null,
        addressOneBilling: null,
        cityBilling: null,
        remarks: null,
        telBilling: null,
        stateBilling: null,
        zipBilling: null,
        countryBilling: null,
        shippingEmail: null,
        addressOneShipping: null,
        cityShipping: null,
        telShipping: null,
        stateShipping: null,
        zipShipping: null,
        countryShipping: null,
        billingCustomer: null,
        shippingCustomer: null,
        ship_header_no: null,
        tracking_no: null,
        paymentTermId: null,
        brand_id: null,
        invoice_header_no: null,
        ASNToBergen: null
      }
      form.setFieldsValue(initialValues);
      setFormDataValues(initialValues)
      dispatch(
        getSaleOrderFormData(token, companyId, menuId, "", "test", true)
      );
    }
  }, [formState]);

  const glImpcatCols = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_header_id",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "created_date",
      dataIndex: "created_date",
    },
  ];

  const handleDeleteOrder = (record) => {
    if (record.receipt_qty > 0) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Item Reciept Already Created For this ${record.item_code}.`,
      });
    } else if (orderItems.length <= 1) {
      return Swal.fire({
        icon: "error",
        title: "There is only 1 Line.",
        text: "Last line of the document cannot be deleted",
      });
    } else {
      const dataSource = [...orderItems];
      const deletedDataSource = [...deletedItems];
      deletedDataSource.push(record);
      setDeletedItems(deletedDataSource);
      setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));

      notification("success", "Item Deleted");
    }
  };

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }

  const columns = [
    {
      title: "SKU",
      dataIndex: "item_code",
      width: 100,
      fixed: 'left',

    },
    {
      title: "Style",
      dataIndex: "style_name",
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
    },

    {
      title: "Balance Quantity",
      render: (_, record) => parseFloat(record.balance_quantity).toFixed(0),
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      editable: true,
      render: (text, record) => {
        return (
          <InputNumber
            size="small"
            min="0"
            disabled={formState === "formView"}
            onStep={(value, info) =>
              updateCostPrice(info.type, record.item_code, value)
            }
            value={record.rate}
            onChange={(value) => updateCostPrice("up", record.item_code, value)}
          />
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      width: 100,
      render: (text, record) => {
        return (
          <InputNumber
            size="small"
            min="0"
            disabled={formState === "formView"}
            onStep={(value, info) =>
              updateQuantity(info.type, record.item_code, value)
            }
            value={record.quantity}
            onChange={(value) => updateQuantity("up", record.item_code, value)}
          />
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
      render: (_, record) =>
        parseFloat(record.quantity * record.rate).toFixed(2),
    },
    {
      title: "Discount Percent",
      dataIndex: "amount",
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber
            size="small"
            min="0"
            disabled={formState === "formView"}
            onStep={(value, info) =>
              updateDiscountPercent(info.type, record.item_code, value, visibleItems.findIndex((v) => v.item_code === record.item_code))
            }
            value={parseFloat(record.discount_percent || 0).toFixed(2)}
            onChange={(value) => updateDiscountPercent("up", record.item_code, value, index)}
          />
        );
      },

    },
    {
      title: "Discount Amount",
      dataIndex: "discount",
      editable: true,
      render: (_, record) => parseFloat(record.discount || 0).toFixed(2),
    },
    {
      title: "Tax Percent",
      dataIndex: "tax_percent",
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber
            size="small"
            min="0"
            disabled={formState === "formView"}
            onStep={(value, info) =>
              updateTaxPercent(info.type, record.item_code, value, visibleItems.findIndex((v) => v.item_code === record.item_code))
            }
            value={parseFloat(record.tax_percent || 0).toFixed(2)}
            onChange={(value) => updateTaxPercent("up", record.item_code, value, index)}
          />
        );
      },
      width: 70,

    },
    {
      title: "Tax Amount",
      dataIndex: "tax_amount",
      editable: true,
      width: 70,

      render: (_, record) => parseFloat(record.tax_amount || 0).toFixed(2),
    },
    {
      title: "Net Amount",
      editable: true,
      render: (_, record) => {
        return (
          parseFloat(record.net_amount || 0).toFixed(2)
        )
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record, index) => (
        formState === "edit" || formState === "add" || formState === "return" ? (
          <Tooltip title="Delete" color={"white"}>


            <Popconfirm
              title={`Are you sure to delete the item ${record.item_code}?`}
              onConfirm={() => handleDeleteOrder(record)}
            >
              <DeleteOutlined className={`delete-Button`} />
            </Popconfirm>

          </Tooltip>

        ) : ""
      ),
    },
  ];

  const updateQuantity = (type, id, value) => {
    let arr = [...visibleItems];
    value = parseInt(value);
    if (formState === "add") {
      const filter = arr.map((item) => {
        if (item.item_code === id) {
          let balance = parseInt(item.balance_quantity);
          if (value > balance) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Value Should be Less Than Balance Qty.",
            });
          }
          if (type === "up") {
            item.quantity = value > balance ? balance : value;
            item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
            item.discount = parseFloat(((item.quantity * item.rate) * item.discount_percent / 100)).toFixed(2);
            item.tax_amount = parseFloat(((item.quantity * item.rate) * item.tax_percent / 100)).toFixed(2);

            item.net_amount = item.quantity * item.rate - item.discount + item.tax_amount;
            return item;
          }

          if (type === "down") {
            item.quantity = value > balance ? balance : value;
            item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
            item.discount = parseFloat(((item.quantity * item.rate) * item.discount_percent / 100)).toFixed(2);
            item.tax_amount = parseFloat(((item.quantity * item.rate) * item.tax_percent / 100)).toFixed(2);

            item.net_amount = item.quantity * item.rate - item.discount + item.tax_amount;
            return item;
          }
        } else {
          return item;
        }
      });
      setVisibleItems(filter);
    } else if (formState === "edit" || formState === "return") {
      const filter = arr.map((item) => {
        if (item.item_code === id) {
          let receipt_qty = parseInt(item.receipt_qty);
          if (value < receipt_qty) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Value Should be Less Than Reciept Qty.",
            });
          } else {
            item.quantity = value;
            item.discount = parseFloat(((item.quantity * item.rate) * item.discount_percent / 100)).toFixed(2);
            item.tax_amount = parseFloat(((item.quantity * item.rate) * item.tax_percent / 100)).toFixed(2);
            item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
          }
          return item;
        } else {
          return item;
        }
      });
      setVisibleItems(filter);
    }

  };

  const updateCostPrice = (type, id, value) => {
    let arr = [...visibleItems];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        item.rate = value;
        item.discount = ((value * item.quantity) * item.discount_percent) / 100;
        item.tax_amount = ((value * item.quantity) * item.tax_percent) / 100;
        item.net_amount = value * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
        return item;
      } else {
        return item;
      }
    });
    setVisibleItems(filter);
  }

  const updateDiscountPercent = (type, id, value, index) => {
    let arr = [...visibleItems];
    value = parseInt(value);
    let rate_and_qty = arr[index].rate * arr[index].quantity
    arr[index].discount_percent = value;
    arr[index].discount = (rate_and_qty * value) / 100;
    arr[index].net_amount = rate_and_qty - arr[index].discount || 0 + arr[index].tax_amount || 0;
    setVisibleItems(arr);
  }

  const updateTaxPercent = (type, id, value, index) => {
    let arr = [...visibleItems];
    value = parseInt(value);
    let rate_and_qty = arr[index].rate * arr[index].quantity
    arr[index].tax_percent = value;
    arr[index].tax_amount = (rate_and_qty * value) / 100;
    arr[index].net_amount = rate_and_qty - arr[index].discount + arr[index].tax_amount;
    setVisibleItems(arr);
  }

  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({
        company: value,
      });
    }

    if (type === "factor") {
      form.setFieldsValue({
        factor: value,
      });
    }

    if (type === "gender") {
      form.setFieldsValue({
        category: null,
        region: null,
        salesPerson: null,
      });
      if (customer) {
        setGender1(value);
        dispatch(getSaleOrderGenderHeader(token, value, customer)).then(
          (res) => {
            if (
              res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
              res?.payload.length > 0
            ) {
              form.setFieldsValue({
                category: res.payload[0].sp_region_category_id,
                region: res.payload[0].region_id,
                salesPerson: res.payload[0].salesperson_id,
              });
            }
          }
        );
      } else {
        setGender1(null);
      }
    }
  }

  const onFinish = async (e) => {
    submit(false);
    setDisable(true);
    if (orderItems.length <= 0) {
      Swal.fire({
        icon: "error",
        title: "Add Line Item/s",
        text: "The line item shouldn't be empty.",
      });
      setDisable(false);
    } else {
      await form.validateFields().then((values) => {
        setLoading(true);
        // Add Values to form Values
        if (mode === "return" || mode === "edit") {
          values.order_header_id = formData?.order_header_id;
          values.order_header_no = formData?.order_header_no;
          values.ship_header_id = formData?.ship_header_id;
          values.ship_header_no = formData?.ship_header_no;
          values.invoice_header_id = formData?.invoice_header_id;
          // On Edit Mode
          values.return_header_id = formData?.return_header_id;
          values.return_header_no = formData?.return_header_no;
          values.brand_id = formData?.brand_id;
        }
        // On Edit Mode

        if (formState === "add" || formState === "return") {
          dispatch(
            addSaleReturnHeader(
              token,
              orderItems.map((item) => {
                item.gross_amount = item.amount;
                delete item.commit_status;
                delete item.cut_name;
                delete item.fabric_name;
                delete item.gender_category_name;
                delete item.brand_name;
                delete item.brand_id;
                delete item.gender_name;
                delete item.inseam;
                delete item.isSelected;
                delete item.quantity_available;
                delete item.quantity_backorder;
                delete item.quantity_committed;
                delete item.quantity_billed;
                delete item.quantity_packed;
                delete item.quantity_on_hand;
                delete item.size;
                delete item.style_id;
                delete item.wash_id;
                delete item.wash_name;
                delete item.amount;
                delete item.item_name;

                delete item.quantity_shipped;
                delete item.key;

                delete item.item_code;
                item.discount_id = null;
                item.tax_id = null;

                return item;
              }),
              values
            )
          ).then((res) => {
            if (res.type === "ADD_SALE_RETURN_HEADER_SUCCESS") {
              setLoading(false);
              setDisable(false);
              setReturnAuthID(res?.payload?.return_header_id);
              notification("success", res?.payload?.data);
              history.push(
                `/return-authorization?mode=edit&return_id=${res?.payload?.return_header_id}&customer_id=${customer}`
              );
              changeState("formView");
            }
          });
        } else if (formState === "edit") {
          formData.asn_to_bergen = form.getFieldValue().ASNToBergen;
          if (deletedItems.length > 0) {
            // Delete Api
            dispatch(deleteSaleReturnLine(token, deletedItems, 123));
          }
          dispatch(
            updateSaleReturnHeader(
              token,
              orderItems.map((item) => {
                delete item.commit_status;
                delete item.cut_name;
                delete item.fabric_name;
                delete item.gender_category_name;
                delete item.gender_name;
                delete item.brand_name;
                delete item.brand_id;
                delete item.inseam;
                delete item.isSelected;
                delete item.quantity_available;
                delete item.quantity_backorder;
                delete item.quantity_committed;
                delete item.quantity_billed;
                delete item.quantity_packed;
                delete item.quantity_on_hand;
                delete item.style_name;
                delete item.size;
                delete item.style_id;
                delete item.wash_id;
                delete item.wash_name;
                delete item.amount;
                delete item.item_name;
                delete item.receipt_qty;
                delete item.deleted_by;
                delete item.deleted_date;
                delete item.quantity_shipped;
                delete item.key;
                delete item.return_header_id;

                delete item.item_code;
                return item;
              }),
              values,
              formData?.return_header_id
            )
          ).then((res) => {
            if (res?.type === "UPDATE_SALE_RETURN_HEADER_SUCCESS") {
              dispatch(getOneSalesLine(token, return_id)).then(
                (res) => {
                  if (res.type === "GET_ONE_SALES_RETURN_LINE_SUCCESS") {
                    const orderData = res.payload.data;
                    setVisibleItems(res.payload.data);
                    setOrderItems(res.payload.data);
                    setVisibleItems(orderData);
                    if (formData.asn_to_bergen === true) {

                      sendToBergenMethod();
                    }
                  }
                }
              );
              notification("success", res?.payload?.data);
              setLoading(false);
              setDisable(false);
              changeState("formView");
            }
          });
        }
      });
    }
  };

  // ********** SEND TO BERGEN METHOD FUNCTION **********
  const sendToBergenMethod = () => {
    setLoading(true);

    dispatch(asnToBergen(token, return_id)).then((res) => {
      if (res.type === "ASN_TO_BERGEN_SUCCESS") {
        setLoading(false);
        if (!res?.payload?.data?.error) {
          notification("success", "ASN Send To Bergen");
        } else {
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: res?.payload?.data?.error,
          });
        }
      }
    });
  };

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "scm_sale_order_header", record_id: formData?.return_header_id }));
    } else if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "scm_sale_order_lines", record_id: formData?.return_header_id }));
    } else if (key === "relatedTransaction" && formState === "formView" || formState === "edit") {
      dispatch(getOnesaleReturnRelatedRecord(token, return_id)).then((res) => {
        if (res?.type === "GET_ONE_SALE_RETURN_RELATED_RECORD_SUCCESS") {
          setReturnRelatedTransac(res?.payload);
        }
      });
    }
  };

  const onCustomerSelect = (e, valid) => {
    if (e) {
      setCustomer(e);
      dispatch(getOneCustomerAddress(token, e)).then((res) => {
        if (res?.type === "GET_ONE_CUSTOMER_ADREESS_SUCCESS") {
          let filterCustomer = res?.payload.data.filter(
            (it) => it.default_bill_to
          );
          let filterDefaultShipping = res?.payload.data.filter(
            (it) => it.default_ship_to
          );
          if (filterCustomer.length) {
            form.setFieldsValue({
              addressOneBilling: filterCustomer[0]?.address_address_1,
              addressTwoBilling: filterCustomer[0]?.address_address_2,
              billingEmail: filterCustomer[0]?.address_email,
              zipBilling: filterCustomer[0]?.address_zip_code,
              stateBilling: filterCustomer[0]?.address_state_name,
              telBilling: filterCustomer[0]?.address_phone,
              cityBilling: filterCustomer[0]?.address_city_name,
              countryBilling: filterCustomer[0]?.address_country_name,
              billingCustomer: filterCustomer[0]?.customer_address_id,
              billing_firstname: filterCustomer[0]?.first_name,
              billing_lastname: filterCustomer[0]?.last_name,
              berganBillingId: filterCustomer[0]?.bergan_billing_id,
              freightTermId: filterCustomer[0]?.freight_term_id,
              isShipComplete: filterCustomer[0]?.is_ship_complete,
              paymentTermId: filterCustomer[0]?.term_id,
              shippingAccountNo: filterCustomer[0]?.shipping_account_no,
              shippingMethodId: filterCustomer[0]?.shipping_method_id,
              shipViaId: filterCustomer[0]?.courier_service_id,
              factor: filterCustomer[0]?.factor_id,
              customer: filterCustomer[0]?.customer_id,
            });
          } else {
            form.setFieldsValue({
              addressOneBilling:
                res?.payload?.data[0]?.address_address_1 === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_address_1,
              addressTwoBilling:
                res?.payload?.data[0]?.address_address_2 === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_address_2,
              billingEmail:
                res?.payload?.data[0]?.address_email === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_email,
              zipBilling:
                res?.payload?.data[0]?.address_zip_code === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_zip_code,
              stateBilling:
                res?.payload?.data[0]?.address_state_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_state_name,
              telBilling:
                res?.payload?.data[0]?.address_phone === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_phone,
              cityBilling:
                res?.payload?.data[0]?.address_city_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_city_name,
              countryBilling:
                res?.payload?.data[0]?.address_country_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_country_name,
              billing_firstname:
                res?.payload?.data[0]?.first_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.first_name,
              billing_lastname:
                res?.payload?.data[0]?.last_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.last_name,
              berganBillingId: res?.payload?.data[0]?.bergan_billing_id,
              freightTermId: res?.payload?.data[0]?.freight_term_id,
              isShipComplete: res?.payload?.data[0]?.is_ship_complete,
              paymentTermId: res?.payload?.data[0]?.term_id,
              shippingAccountNo:
                res?.payload?.data[0]?.shipping_account_no === "NULL"
                  ? ""
                  : res?.payload?.data[0]?.shipping_account_no,
              shippingMethodId: res?.payload?.data[0]?.shipping_method_id,
              shipViaId: res?.payload?.data[0]?.courier_service_id,
              factor: res?.payload?.data[0]?.factorId,
              customer: res?.payload?.data[0]?.customer_id,
            });
          }
          if (filterDefaultShipping.length) {
            form.setFieldsValue({
              addressOneShipping:
                filterDefaultShipping[0]?.address_address_1 === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_address_1,
              addressTwoShipping:
                filterDefaultShipping[0]?.address_address_2 === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_address_2,
              shippingEmail:
                filterDefaultShipping[0]?.address_email === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_email,
              zipShipping:
                filterDefaultShipping[0]?.address_zip_code === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_zip_code,
              stateShipping:
                filterDefaultShipping[0]?.address_state_name === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_state_name,
              telShipping:
                filterDefaultShipping[0]?.address_phone === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_phone,
              cityShipping:
                filterDefaultShipping[0]?.address_city_name === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_city_name,
              countryShipping:
                filterDefaultShipping[0]?.address_country_name === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.address_country_name,
              shippingCustomer:
                filterDefaultShipping[0]?.customer_address_id === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.customer_address_id,
              shipping_firstname:
                filterDefaultShipping[0]?.first_name === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.first_name,
              shipping_lastname:
                filterDefaultShipping[0]?.last_name === null
                  ? "N/A"
                  : filterDefaultShipping[0]?.last_name,
            });
          } else {
            form.setFieldsValue({
              addressOneShipping:
                res?.payload?.data[0]?.address_address_1 === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_address_1,
              addressTwoShipping:
                res?.payload?.data[0]?.address_address_2 === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_address_2,
              shippingEmail:
                res?.payload?.data[0]?.address_email === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_email,
              zipShipping:
                res?.payload?.data[0]?.address_zip_code === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_zip_code,
              stateShipping:
                res?.payload?.data[0]?.address_state_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_state_name,
              telShipping:
                res?.payload?.data[0]?.address_phone === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_phone,
              cityShipping:
                res?.payload?.data[0]?.address_city_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_city_name,
              countryShipping:
                res?.payload?.data[0]?.address_country_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.address_country_name,
              shippingCustomer:
                res?.payload?.data[0]?.customer_address_id === null
                  ? "N/A"
                  : res?.payload?.data[0]?.customer_address_id,
              shipping_firstname:
                res?.payload?.data[0]?.first_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.first_name,
              shipping_lastname:
                res?.payload?.data[0]?.last_name === null
                  ? "N/A"
                  : res?.payload?.data[0]?.last_name,
            });
          }
        }
      });
    }
  };

  const onBillingNameSelect = (e) => {
    dispatch(getSingleBilling(token, formData?.order_header_id, formData?.customer_id || customer, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
        form.setFieldsValue({
          countryBilling: res.payload?.data[0].country_name,
          billingCustomer: res.payload?.data[0].customer_address_id,
          cityBilling: res.payload?.data[0].city_name,
          billingEmail: res.payload?.data[0].customer_email,
          stateBilling: res.payload?.data[0].state_name,
          billing_firstname: res.payload?.data[0]?.customer_first_name,
          billing_lastname: res.payload?.data[0]?.customer_last_name,
          zipBilling: res.payload?.data[0].zip_code,
          telBilling: res.payload?.data[0]?.phone,
          addressOneBilling: `${res.payload?.data[0].address_1},${res.payload?.data[0].address_2
            }, ${res.payload?.data[0].address_3 === null
              ? ""
              : res.payload?.data[0].address_3
            } `,
        });
      }
    });
  };

  const onShippingNameSelect = (e) => {
    dispatch(getSingleBilling(token, formData?.order_header_id, formData?.customer_id || customer, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
        form.setFieldsValue({
          countryShipping: res.payload?.data[0].country_name,
          shippingCustomer: res.payload?.data[0].customer_address_id,
          cityShipping: res.payload?.data[0].city_name,
          shippingEmail: res.payload?.data[0].email,
          stateShipping: res.payload?.data[0].state_name,
          shipping_firstname: res.payload?.data[0]?.customer_first_name,
          shipping_lastname: res.payload?.data[0]?.customer_last_name,
          zipShipping: res.payload?.data[0].zip_code,
          addressOneShipping: `${res.payload?.data[0].address_1 ? res.payload?.data[0].address_1 : ""
            }, ${res.payload?.data[0].address_2 ? res.payload?.data[0].address_2 : ""
            }, ${res.payload?.data[0].address_3 ? res.payload?.data[0].address_3 : ""
            } `,
        });
      }
    });



  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  const summary = () => {
    let orderSummary = orderItems;
    let amount = 0;
    let quantity = 0;
    let discount = 0;
    let tax = 0;
    let shippingCost = form.getFieldValue().shippingCost
      ? form.getFieldValue().shippingCost
      : 0;

    if (orderSummary.length > 0) {
      orderSummary?.forEach(function (item) {
        if (item.hasOwnProperty("rate")) {
          amount += parseFloat(item.rate) * parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("quantity")) {
          quantity += parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("discount")) {
          discount += parseFloat(item.discount);
        }
        if (item.hasOwnProperty("tax_amount")) {
          tax += parseFloat(item.tax_amount);
        }
      });
    }
    return {
      quantity: quantity,
      amount: numeral(amount).format("0,0.00"),
      discount: numeral(discount || 0).format("0,0.00"),
      tax: numeral(tax).format("0.00"),
      shippingCost: numeral(shippingCost).format("0,0.00"),
      net: numeral(
        numeral(amount).value() -
        numeral(discount).value() + numeral(tax).value()

      ).format("0,0.00"),
    };
  };

  const showModal = () => {
    if (!formDataValues.location) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Location",
      });
    } else if (!formDataValues.brand_id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Brand.",
      });
    } else {
      setIsModalVisible(true);
    }
  };

  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const discount = form?.getFieldValue()?.discount
      ? form.getFieldValue().discount
      : 0;
    const discountPercentage = form?.getFieldValue()?.discountPercent
      ? form.getFieldValue().discountPercent
      : 0;
    const onlyKey = [...value].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => {
      return onlyKey.find((i) => i === f.item_code);
    });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...value];
      //remove Duplicates
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
        }
      }
    } else {
      //remove Duplicates
      let uniqueOrderItems = [...visibleItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);

      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
        }
      }
    }
    notification("success", "Item Added Successfully");
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    setCustomer(val);
    dispatch(getSaleOrderFormData(token, companyId, menuId, val, ""));
  };

  function handleGenderChange(value) {
    if (customer) {
      setGender(value);
      dispatch(getSaleOrderGenderHeader(token, value, customer)).then((res) => {
        if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload.length > 0
        ) {
          form.setFieldsValue({
            category: res.payload[0].sp_region_category_id,
            region: res.payload[0].region_id,
            salesPerson: res.payload[0].salesperson_id,
          });
        } else if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload?.length === 0
        ) {
          form.setFieldsValue({
            category: null,
            region: null,
            salesPerson: null,
          });
        }
      });
    }
  }

  const optimizedFn = useCallback(debounce(handleChanges), []);

  return (
    <>
      <EditableTable
        token={token}
        companyId={companyId}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        formState={formState}
        setVisibleItems={setVisibleItems}
        visibleItems={visibleItems}
        location_id={formDataValues.location}
        brand_id={formDataValues.brand_id}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        onValuesChange={(values, fields) => {
          if (values?.brand_id && formDataValues?.brand_id !== values?.brand_id) {
            setOrderItems([])
          }
          setFormDataValues({ ...fields })
        }}
        name="basic"
      >
        {saleOrderFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-15">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  {formState === "formView" || formState === "edit" ? (
                    <Bcol Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          name="returnAuthorizationNumber"
                          label="Return #"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            tabIndex={1}
                            className="customInputs"
                            disabled={formState === "formView"}
                          />
                        </Form.Item>
                      </div>
                    </Bcol>
                  ) : null}
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="company"
                        label="Subsidary"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        defaultValue={companyID}
                        initialValue={companyID}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          loading={companyAll?.loading}
                          disabled={formState === "formView"}
                          onMouseDownCapture={onMouseDownSelectableHack}
                        >
                          {companyAll?.payload?.data?.map((it) => {
                            return (
                              <Option key={it.company_id} value={it.company_id}>
                                {it.company_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="returnType"
                        label="Return Type"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          loading={returnType?.loading}
                          disabled={formState === "formView"}
                          onMouseDownCapture={onMouseDownSelectableHack}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {returnType?.payload?.data?.map((it) => {
                            return (
                              <Option
                                key={it.return_type_id}
                                value={it.return_type_id}
                              >
                                {it.return_type_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="returnReason"
                        label="Return Reason"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          loading={returnreason?.loading}
                          disabled={formState === "formView"}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {returnreason?.payload?.data?.map((it) => {
                            return (
                              <Option
                                key={it.return_reason_id}
                                value={it.return_reason_id}
                              >
                                {it.description}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Return Date"
                        name="return_date"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <DatePicker
                          size="small"
                          format="MM-DD-YYYY"
                          allowClear
                          defaultValue={moment(invoiceDate)}
                          className="dateInput customInputs"
                          onChange={(e) => setInvoiceDate(moment(e))}
                          disabled={formState === "formView"}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="reference_no"
                        label="P.O #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          size="small"
                          tabIndex={1}
                          className="customInputs"
                          disabled={formState === "formView"}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="customer"
                        label="Customer"
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 52 },
                          lg: { span: 52 },
                          xxl: { span: 4 },
                        }}
                        rules={[
                          { required: true, message: "Please Select Customer" },
                        ]}
                      >
                        <Select
                          notFoundContent={
                            saleOrderFormData.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          loading={saleOrderFormData?.loading}
                          onSelect={(data) => onCustomerSelect(data, true)}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          onMouseDownCapture={onMouseDownSelectableHack}
                          onSearch={(value) => {
                            optimizedFn(value);
                          }}
                          ref={focusInput}
                        >
                          {saleOrderFormData?.payload?.customerDetail.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} - ${item.customer_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>


                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">

                      <Form.Item
                        name="invoice_header_no"
                        label="Invoice #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}

                      >
                        <Input
                          size="small"
                          tabIndex={1}
                          className="customInputs"
                          disabled={formState === "formView"}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{
                          lg: { span: 52 },
                          xxl: { span: 10 },
                        }}
                      >
                        <Input
                          size="small"
                          tabIndex={1}
                          disabled={formState === "formView"}
                          className="customInputs"
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Return Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Quantity:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {summary().quantity}
                        </p>
                      </Bcol>
                    </Brow>
                    {/* <Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Shipping:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().shippingCost}</p>
											</Bcol>
										</Brow> */}

                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Sub Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{summary().amount}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Less Discount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{summary().discount}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Tax:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">{currencySymbol}{summary().tax}</p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Net:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">{currencySymbol}{summary().net}</p>
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Brow className="secondSectio">
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Billing Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? (
                      <Skeleton paragraph={{ rows: 6 }} />
                    ) : (
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="billingCustomer"
                            label="Name"
                            className="name-field-form selectNamee"
                            labelAlign="left"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Billing Customer is required",
                              },
                            ]}
                          >
                            <Select
                              optionFilterProp="children"
                              size="small"
                              tabIndex={4}
                              autoComplete={"nope"}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}
                              onSelect={onBillingNameSelect}
                              onMouseDownCapture={onMouseDownSelectableHack}
                            >
                              {customerAddress.payload?.data?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.customer_address_id}>
                                      {" "}
                                      {`${item.address_addressee}(${item.address_zip_code})`}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_firstname"
                            label={`First Name`}
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              readOnly
                              disabled={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              readOnly
                              disabled={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneBilling"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoBilling"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityBilling"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateBilling"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipBilling"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryBilling"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telBilling"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card>
              </Bcol>
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Shipping Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? (
                      <Skeleton paragraph={{ rows: 6 }} />
                    ) : (
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="shippingCustomer"
                            label="Name"
                            labelAlign="left"
                            className="name-field-form"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Shipping Customer is required",
                              },
                            ]}
                          >
                            <Select
                              optionFilterProp="children"
                              size="small"
                              tabIndex={5}
                              autoComplete={"nope"}
                              showSearch={true}
                              className="customInputs fw-400 "
                              loading={customerAddress?.loading}
                              onSelect={onShippingNameSelect}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            >
                              {customerAddress?.payload?.data?.map((item) => {
                                return (
                                  <Option value={item.customer_address_id}>
                                    {" "}
                                    {`${item.address_addressee} (${item.address_zip_code})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_firstname"
                            label="First Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              className={`customInputs without_bg fw-400 `}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              className={`customInputs without_bg fw-400 `}
                              disabled={formState === "formView"}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneShipping"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoShipping"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityShipping"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateShipping"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipShipping"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryShipping"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telShipping"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shippingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{
                display: "none",
              }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>
          <SubTitles name={"Sales Information"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Gender is required" }]}
                  >
                    <Select
                      size="small"
                      tabIndex={9}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().gender
                          : null
                      }
                      disabled={(formData?.ship_header_no || formState !== 'add') || formState === 'formView'}
                      onChange={(value) => handleGenderChange(value)}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.gender.map((item) => {
                        return (
                          <Option
                            key={item.gender_id}
                            disabled={!item.is_active}
                            value={item.gender_id}
                          >
                            {item.gender_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="category"
                    label="Category"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      readOnly
                      className={`customInputs fw-400  without_border`}
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="region"
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Region is required" }]}
                  >
                    <Select
                      size="small"
                      tabIndex={10}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      defaultValue={form.getFieldValue().region}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      disabled={(formData?.ship_header_no || formState !== 'add') || formState === 'formView'}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.adminRegionDetail.map(
                        (item) => {
                          return (
                            <Option key={item.region_id} value={item.region_id}>
                              {item.region_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="salesPerson"
                    label="Sales Person"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[
                      { required: true, message: "Sales Person is required" },
                    ]}
                  >
                    <Select
                      size="small"
                      tabIndex={12}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={(formData?.ship_header_no || formState !== 'add') || formState === 'formView'}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.salesPersonDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.sales_person_id}
                              value={item.sales_person_id}
                            >
                              {item.salesperson_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[
                      { required: true, message: "Location is required" },
                    ]}
                  >
                    <Select
                      tabIndex={13}
                      size="small"
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      defaultValue={form.getFieldValue().location}
                      onChange={(value) => handleChange(value)}
                      disabled={(formData?.ship_header_no || formState !== 'add') || formState === 'formView'}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.locationDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.location_id}
                              value={item.location_id}
                            >
                              {item.location_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="paymentTermId"
                    label="Payment Term"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select
                      size="small"
                      tabIndex={24}
                      autoComplete={"nope"}
                      allowClear
                      loading={saleOrderFormData.loading}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      defaultValue={form.getFieldValue().paymentTermId}
                      onChange={(value) => handleChange(value, "paymentTermId")}
                      disabled={(formData?.ship_header_no || formState !== 'add') || formState === 'formView'}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.customerTerm.map((item) => {
                        return (
                          <Option
                            key={item.term_id}
                            disabled={!item.is_active}
                            value={item.term_id}
                          >
                            {item.term_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              {/* Brand Field */}
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="brand_id"
                    label="Brand"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select
                      size="small"
                      tabIndex={24}
                      autoComplete={"nope"}
                      allowClear
                      loading={brand.loading}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      disabled={formState === 'formView' || formState === 'edit'}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {brand?.payload?.data.map((item) => {
                        return (
                          <Option
                            key={item.brand_id}
                            disabled={!item.is_active}
                            value={item.brand_id}
                          >
                            {item.brand_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <SubTitles name={"Others"} />
          <div className="container-fluid  pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="year"
                    label="Year"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="lotNumber"
                    label="Lot #"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="RaApproval"
                    label="RA Approval"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="order_header_no"
                    label="Order Number"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="season"
                    label="Season"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select
                      size="small"
                      className="customInputs"
                      defaultValue="Spring"
                      onChange={handleChange}
                      disabled={formState === "formView"}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      options={[

                        {
                          value: "Fall",
                          label: "fall",
                        },
                        {
                          value: "Spring",
                          label: "spring",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="ship_header_no"
                    label="PT No"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="tracking_no"
                    label="Tracking #"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      tabIndex={14}
                      className="customInputs"
                      disabled={formState === "formView"}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div
                  className="firstSectionRo"

                >
                  <Form.Item
                    name="ASNToBergen"
                    valuePropName="checked"
                    label="ASN to Bergen"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Checkbox
                      disabled={formState === "formView"}
                      name="ASNToBergen"
                    ></Checkbox>
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="1">
              <div className="pbpx-20">
                <div style={{ display: "flex", justifyContent: "space-between" }}>

                  <Button
                    type="primary"
                    className="modalButton"
                    disabled={((formState === "add" || formState === "view")) || userRights.can_delete === true ? false : true}
                    onClick={showModal}
                  >
                    Add Item
                  </Button>
                  {
                    orderItems.length > 0 && (
                      <CSVLink
                        filename={"Return-Authorization-Lines.csv"}
                        data={orderItems.length > 0 ? (
                          orderItems.map((v) => {
                            return {
                              "Item Code": v.item_code,
                              "Style Name": v.style_name,
                              "Wash Name": v.wash_name,
                              "Gender Category Name": v.gender_category_name,
                              "Size": v.size,
                              "Inseam": v.inseam,
                              "Balance Quantity": v.balance_quantity,
                              "Cost Price": v.rate,
                              "Quantity": v.quantity,
                              "Amount": v.amount,
                              "Discount Percent": v.discount_percent,
                              "Discount": v.discount,
                              "Tax Percent": v.tax_percent,
                              "Tax Amount": v.tax_amount,
                              "Net Amount": v.net_amount,
                            }
                          })
                        ) : []}
                      // data={[]}

                      >
                        Download Excel
                      </CSVLink>
                    )
                  }
                </div>
              </div>
              <Table
                type={"saleOrderLine"}
                loading={ProductSale.loading}
                scroll={{ x: "calc(600px + 90%)", y: 400 }}
                size="small"
                rowKey="item_id"
                bordered
                perPage={500}
                dataSource={orderItems}
                columns={columns}
                pagination={{ pageSize: 500 }}
              />
            </TabPane>
            <TabPane tab="GL Impact" key="gl_impact">
              <div className="tabs">
                <Tables
                  type="company"
                  scrollX={"50rem"}
                  scroll={360}
                  perPage={15}
                  source={glImpact?.payload?.data}
                  col={glImpcatCols}
                  load={glImpact.loading}
                  paginate
                />
              </div>
            </TabPane>
            <TabPane tab="System Information" key="logs">
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
                onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Header" key="header">
                  <div className="tabs">
                    {systemLog?.payload?.data?.length > 0 && <Tables
                      type="company"
                      scrollX={"50rem"}
                      scroll={360}
                      perPage={15}
                      source={systemLog?.payload?.data}
                      dataSource={[]}
                      col={renderColumns()}
                      load={systemLog.loading}
                      paginate
                    />}
                  </div>
                </TabPane>
                <TabPane tab="Line" key="line">
                  <div className="tabs">
                    {systemLog?.payload?.data?.length > 0 && <Tables
                      type="company"
                      scrollX={"50rem"}
                      scroll={360}
                      perPage={15}
                      source={systemLog?.payload?.data}
                      dataSource={[]}
                      col={renderColumns()}
                      load={systemLog.loading}
                      paginate
                    />}
                  </div>
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Related Transaction" key="relatedTransaction">
              <div className="tabs">
                <Tables
                  type="company"
                  scrollX={"50rem"}
                  scroll={360}
                  perPage={15}
                  source={
                    saleReturnRelatedTransac
                  }
                  col={relatedTransactionColumns}
                  load={relatedTransactionColumns.loading}
                  paginate
                />
              </div>
            </TabPane>
          </Tabs>


        </div>
      </Form>
    </>
  );
}