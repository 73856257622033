import React, { useState, useLayoutEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { Table, Spin, Input } from 'antd';
import moment from 'moment';

import { getOmsShipRegionStateReport } from '../../redux/actions/omsShippRegionReport.action'
import "../OMSBooking/styleSheet.css"
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { currencySymbol } from '../../utils/constants';
const OmsRegionReport = ({ token, history, id, }) => {
    const dispatch = useDispatch();
    const { gender, region, companyAll, omsShipRegionReport, customerTypes } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)
    const [regionID, setRegionID] = useState(null)
    const [customerTypeID, setCustomerTypeID] = useState(null)
    const [data, setData] = useState([])

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let company_id = query.get("company_id")
    let gender_id = query.get("gender_id")
    let customer_type_id = query.get("customer_type_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_id = query.get("selected_id")
    let select = query.get("select")
    let state_id = query.get("state_id")
    let state_name = query.get("state_name")


    useLayoutEffect(() => {
        setValues(history?.location?.values)
        setRegionID(history?.location?.state?.region_id)
        setCustomerTypeID(history?.location?.customerTypes?.customer_type_id)
        setLoading(true)
        dispatch(getOmsShipRegionStateReport(token, { id, company_id, gender_id, from_date, from_date, to_date, customer_type_id, region_id, state_id, select, selected_id })).then((res) => {
            setData(res?.payload?.data)
            setSubmit(true)
            setLoading(false)
        });

    }, []);

    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            align: 'left',
            key: 'serial_no',
            width: 50,
            render: (text, object, index) => index + 1
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_id',
            width: 280,
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }}
                    to={{
                        pathname: `/shipped-region-wise`,
                        search: `?submited=${true}&report=${4}&company_id=${company_id}&gender_id=${gender_id}&region_id=${region_id}&customer_type_id=${customer_type_id}&from_date=${from_date}&to_date=${to_date}&select=${select === "true" ? true : false}&selected_id=${selected_id}&customer_id=${text.customer_id}&customer_name=${text.customer_name}&state_name=${text.state_name}&state_id=${text.state_id}`,
                        state: { ...text, region_id: regionID }, values,
                    }}
                > {record}</Link >
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.customer_name !== null) {
                    return record.customer_name.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.customer_name !== null & b.customer_name !== null) {
                    return a.customer_name.localeCompare(b.customer_name)
                }
            },
        },
        {
            title: "State",
            dataIndex: "state_name",
            align: 'left',
            key: "state_id",
            width: 100,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.state_name !== null) {
                    return record.state_name.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.state_name !== null & b.state_name !== null) {
                    return a.state_name.localeCompare(b.state_name)
                }
            },
        },
        {
            title: 'Shipped QTY',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.quantity !== null) {
                    return record.quantity.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Shipped Amount',
            dataIndex: 'ship_amount',
            key: 'ship_amount',
            width: 120,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {currencySymbol}{text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.ship_amount !== null) {
                    return record.ship_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.ship_amount.replaceAll(',', '') - b.ship_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Return Qty',
            dataIndex: 'return_quantity',
            key: 'return_quantity',
            width: 100,
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.return_quantity !== null) {
                    return record.return_quantity.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.return_quantity.replaceAll(',', '') - b.return_quantity.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Return Amount',
            dataIndex: 'return_amount',
            key: 'return_amount',
            width: 140,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {currencySymbol}{text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.return_amount !== null) {
                    return record.return_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.return_amount.replaceAll(',', '') - b.return_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Net Qty',
            dataIndex: 'net_quantity',
            key: 'net_quantity',
            width: 150,
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.net_quantity !== null) {
                    return record.net_quantity.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.net_quantity.replaceAll(',', '') - b.net_quantity.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Net Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            width: 150,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {currencySymbol}{text}</span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.net_amount !== null) {
                    return record.net_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.net_amount.replaceAll(',', '') - b.net_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: "AUC",
            dataIndex: "auc",
            key: "auc",
            width: 120,
            align: "right",
            render: (text) => <span style={{ textAlign: "right" }}>{currencySymbol}{parseFloat(text).toFixed(2)}</span>,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              return (
                <>
                  <Input
                    allowClear
                    autoFocus
                    className="pop-search-gen pop-220"
                    
                    value={selectedKeys[0]}
                    onChange={(e) => {
                      setSelectedKeys(e.target.value ? [e.target.value] : []);
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={() => {
                      confirm();
                    }}
                    onBlur={() => {
                      confirm();
                    }}
                  />
                </>
              );
            },
            filterIcon: (filtered) =>
              filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
              if (record.auc !== null) {
                return record.auc
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }
            },
            sorter: (a, b) => {
              return (
                a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
              )
            },
          },
        {
            title: '%',
            align: 'right',
            dataIndex: 'percentage',
            key: 'percentage',
            width: 70,
            render: text => <span style={{ textAlign: "right" }}> {text}% </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.percentage !== null) {
                    return record.percentage.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.percentage.replaceAll(',', '') - b.percentage.replaceAll(',', '')
                )
            },
        },
    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id)
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id)
    })

    const filterCustomerType = customerTypes.payload?.data.find((item) => {
        return item.customer_type_id === Number(customer_type_id);
    })

    const totalSum = () => {
        let sumAmount = data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.ship_amount.replace(/\$|,/g, ''));
        }, 0);
        return sumAmount;
    }

    const totalQuantity = () => {
        let sumQty = data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity.replace(/\$|,/g, ''));
        }, 0);
        return sumQty;
    }

    return (
        <>

            <div className='row report-wrapper-main p-0'>
                <div className='col p-0'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                                <td width="103">
                                    State:
                                </td>
                                <td>
                                    <b>
                                        {select === "true" ? "All" : state_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Customer Type:
                                </td>
                                <td>
                                    <b>
                                    {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {moment(from_date).format("MM-DD-YYYY")}
                                    </b>
                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {moment(to_date).format("MM-DD-YYYY")}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                </td>
                                <td>

                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                    {company_id === "All"? "All" : company_id === "1"? "DL1961" : company_id === "2" ? "Warp + Weft" : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin size='large' />
                </div> :
                <>{(submit) ?
                    <div className='w-100 mt-3'>
                        <Table
                            showSorterTooltip={false}
                            
                            className='w-100 test04'
                            size='small'
                            pagination={{ pageSize: 1000 }}
                            summary={pageData => {
                                let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                                }, 0);
                                let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.ship_amount.replace(/\$|,/g, ''))
                                }, 0);
                                
                                
                                
                                
                                
                                
                                let sumReturnQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.return_quantity.replace(/\$|,/g, ''))
                                }, 0);
                                let sumReturnAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.return_amount.replace(/\$|,/g, ''))
                                }, 0);
                                let sumNetQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_quantity.replace(/\$|,/g, ''))
                                }, 0);
                                let sumNetAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                                }, 0);



                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{
                                                    fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                    borderBottom: "1px solid black"
                                                }}>{sumQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{sumReturnQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumReturnAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}> {sumNetQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumNetAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                            </Table.Summary.Cell>

                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            dataSource={omsShipRegionReport?.payload?.data}
                        />
                    </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                        fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                    }} >
                        <tbody>
                            <tr style={{
                                backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                            }} >
                                <td colSpan="5">
                                    No record(s)</td>
                            </tr>
                        </tbody>
                    </table>}
                </>
            }
        </>
    )
}
export default OmsRegionReport;
