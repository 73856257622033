import { combineReducers } from "redux";
import auth from "./auth";
import menus from "./menus.reducer";
import style from "./style.reducer";
import companyAccess from "./companyAccess.reducer";
import companyAll from "./companyGetAll.reducer";
import log from "./log.reducer";
import defaultCompany from "./defaultCompany.reducer";
import productForm from "./productForm.reducer";
import item from "./item.reducer";
import wash from "./wash.reducer";
import inseam from "./inseam.reducer";
import size from "./size.reducer";
import generateItem from "./generateItem.reducer";
import cut from "./cut.reducer";
import fabric from "./fabric.reducer";
import fitCategory from "./fitCategory.reducer";
import gender from "./gender.reducer";
import genderCategory from "./genderCategory.reducer";
import rise from "./rise.reducer";
import productClass from "./productClass.reducer";
import inseamLabel from "./inseamLabel.reducer";
import productStatus from "./productStatus.reducer";
import riseLabel from "./riseLabel.reducer";
import productType from "./productType.reducer";
import season from "./season.reducer";
import getDemoDefaultCompany from "./getOneDefaultCompany";
import customerSections from "./customerSection.reducer";
import customerForms from "./customerForm.reducer";
import getcustomer from "./customerFormById.reducer";
import Billing from "./billing.reducer";
import Shipping from "./shipping.reducer";
import country from "./adminCountry.reducer";
import City from "./adminCity.reducer";
import State from "./adminState.reducer";

import salePerson from "./salePerson.reducer";
import salePersonId from "./salePerson/salePersonById.reducer";
import jobTitle from "./salePerson/jobTitle.reducer";
import supervisor from "./salePerson/supervisor.reducer";
import reportCurrency from "./salePerson/reportCurrency.reducer";
import salePersonForm from "./salePerson/salePersonForm.reducer";
import regionCategory from "./regionCategory.reducer";
import getRegionCategoryId from "./regionCategoryById.reducer";
import Region from "./region.reducer";
import Category from "./customerCategory.reducer";
import ShipVia from "./shipVia.reducer";
import ShippingMethod from "./shippingMethod.reducer";
import FreightTerm from "./freightTerm.reducer";
import BergenBillingOption from "./bergenBillingOption.reducer";
import userRights from "./userRights.reducer";
import styleCombination from "./styleCombination.reducer";
import combinationForm from "./combinationForm.reducer";
import saleOrderFormData from "./saleOrderFormData.reducer";
import orderShipmentFormData from "./orderShipmentFormData.reducer";
import orderShipmentList from "./orderShipmentList.reducer";
import salesOrder from "./salesOrder.reducer";
import salesOrderList from "./salesOrderList.reducer";
import getSaleOrderGenderHeader from "./saleOrderGenderHeader.reducer";
import getBillingShippingDetails from "./billingShipping.reducer";
import ProductSale from "./productSale.reducer";
import region from "./adminRegion.reducer";
import adminstate from "./adminState.reducer";
import courierService from "./courierService.reducer";
import users from "./adminUser.reducer";
import module from "./adminModule.reducer";
import menuRights from "./menuRights.reducer";
import adminMenuForm from "./AdminMenuForm.reducer";
import adminMenu from "./adminmenu.reducer";
import parentMenu from "./parentMenu.reducer";
import paymentterm from "./paymentterm.reducer";
import taxsetup from "./adminTax.reducer";
import orderShipment from "./orderShipment.reducer";
import orderShipmentLine from "./orderShipmentLine.reducer";
import chartofaccount from "./chartofaccount.reducer";
import currency from "./adminCurrency.reducer";
import invoiceHeader from "./invoiceHeader.reducer";
import invoiceHeaderList from "./invoiceHeaderList.reducer";
import invoiceHeaderFormDataReducer from "./invoiceHeaderFormData.reducer";
import invoiceGlPeriod from "./invoiceGlPeriod.reducer";
import adminCompany from "./adminCompany.reducer";
import accounttype from "./accounttype.reducer";
import returnreason from "./returnreason.reducer";
import adminLocation from "./adminLocation.reducer";
import salesTarget from "./salesTarget.reducer";
import scmFigure from "./scmFigure.reducer";
import scmMonth from "./scmTargetMonth.reducer";
import scmYear from "./scmTargetYear.reducer";
import orderStatus from "./orderStatus.reducer";
import pickTicketStatus from "./pickTicketStatus.reducer";
import shipStatus from "./shipStatus.reducer";
import salesReturn from "./salesReturn.reducer";
import salesReturnList from "./salesReturnList.reducer";
import salesReturnFormDataReducer from "./salesReturnFormDataReducer.reducer";
import fiscalYear from "./fiscalYear.reducer";
import receipt from "./receipt.reducer";
import receiptList from "./receiptList.reducer";
import receiptLine from "./receiptLine.reducer";
import vendor from "./vendor.reducer";
import vendorBill from "./vendor.bill.reducer";
import creditNote from "./credit.reducer";
import creditNoteList from "./creditList.reducer";
import itemTransfer from "./itemTransfer.reducer";
import itemTransferLine from "./itemTransferLine.reducer";
import itemTransferFormData from "./itemTransferFormData.reducer";
import itemTransferList from "./itemTransferList.reducer";
import customerTerm from "./customerTerm.reducer";
import vendorCategory from "./vendorCategory.reducer";
import bill from "./bill.reducer";
import billList from "./billList.reducer";
import billFormData from "./billFormData.reducer";
import billLine from "./billLine.reducer";
import expenseBill from "./expenseBill.reducer";
import expenseLine from "./expenseLine.reducer";
import washType from "./washType.reducer";
import returnType from "./returnType.reducer";
import ReportIP from "./reportIP.reducer";
import saleOrderAdjustment from "./salesOrderAdjustment.reducer";
import saleOrderAdjustmentFormData from "./saleOrderAdjustmentFormData.reducer";
import saleOrderAdjustmentList from "./saleOrderAdjustmentList.reducer";
import fabricComposition from "./fabricComposition.reducer";
import itemType from "./itemType.reducer";
import fabricClass from "./fabricClass.reducer";
import fabricClass2 from "./fabricClass2.reducer";
import fabricType from "./fabricType.reducer";
import fabricUsable from "./fabricUsable.reducer";
import customerUploads from "./customer.upload.reducer";
import commitCriteria from "./commitCriteria.reducer";
import chargeBack from "./chargeBackType.reducer";
import arAccount from "./AR_Account.reducer";
import adminPaymentType from "./adminPaymentTypes.reducer";
import payCustomerPaymentHeaderList from "./paymentHeaderList.reducer";
import payCustomerPayment from "./payCustomerPaymentHeader.reducer";
import paymentType from "./paymentType.reducer";
import adminModule from "./adminModule.reducer";
import customerTypes from "./customerType.reducer";
import saleOrderLine from "./saleOrderLine.reducer";
import saleOrderRelatedRecord from "./saleOrderRelatedRecord.reducer";
import termForPrint from "./termForPrint.reducer";
import OmsReprot from "./omsReport.reducer";
import omsRegionStateReport from "./omsRegionStateReport.reducer";
import regionStateReport from "./omsRegionReport.reducer";
import omsReportByCustomer from "./omsReportByCustomer";
import adminJob from "./adminJob.reducer";
import adminDiscount from "./adminDiscount.reducer";
import factor from "./factor.reducer";
import adminMenuType from "./adminMenuType.reducer";
import paymentMethodType from "./paymentMethodType.reducer";
import adminCurrencyTest from "./adminCurrency.reducer";
import scmBrand from "./scmBrand.reducer";
import barganPaymentTerm from "./barganPaymentTerm.reducer";
import billpayment from "./billPayments.reducer";
import payVendorBill from "./payVendorBill.reducer";
import billpaymentLine from "./payBillPaymentLine.reducer";
import omsPendingReport from "./omsPendingReport.reducer";
import omsPendingStateReport from "./omsPendingStateReport.reducer";
import omsRegionPendingReport from "./omsRegionPendingReport.reducer";
import omsPendingReportByCustomer from "./omsPendingReportByCustomer.reducer";
import customerRefundHeaderList from "./customerRefundHeaderList.reducer";
import customerRefundHeader from "./customerRefundHeader.reducer";
import customerRefundFormData from "./customerRefundFormData.reducer";
import { getBussinessDashboardReducer } from "./bussinessDashboard.reducer";
import { salesReportOrderItem } from "./salesReportOrderItem.reducer";
import { salesReportOrderPrint } from "./salesReportOrderPrint.reducer";
import { salesReportColumnValue } from "./salesReportOrderColoumValue.reducer";
import { newSalesReport } from "./newSalesReport.reducer";
import { salesReportOrderColumn } from "./salesReportOrderColumn.reducer";
import userType from "./userType.reducer";
import omsShipReport from "./omsShipReport.reducer";
import omsShipRegionStateReport from "./omsShipRegionStateReport.reducer";
import omsShipRegionReport from "./omsShipRegionReport.reducer";
import omsShipReportByCustomer from "./omsShipReportByCustomer";
import OmsPendingPODReport from "./omsPedingPODReport.reducer";
import getPendinPODStateReport from "./omsPendingPODRegionStateReport.reducer";
import omsPendingPODRegionReport from "./omsPendingPODRegionReport.reducer";
import omsPODReportByCustomer from "./omsPendingPODReportByCustomer";
import omsReturnReport from "./omsReturnReport.reducer";
import omsReturnRegionStateReport from "./omsReturnRegionStateReport.reducer";
import omsReturnRegionReport from "./omsReturnRegionReport.reducer";
import { getReturnRegionStateCustomer } from "./omsReturnByCustomerRegionState.reducer";
import { getSaleOmsReturnCustomer } from "./getSaleReturnOmsCustomerbyReport.reducer";
import { getWiseStockReport } from "./wiseStockReport.reducer";
import omsStockSummary from "./omsStockSummaryReport.reducer";
import journalVoucherFormData from "./journalVoucherFormData.reducer";
import journalVoucherHeader from "./journalVoucherHeader.reducer";
import journalVoucherHeaderList from "./journalVoucherHeaderList.reducer";
import journalVoucherLine from "./journalVoucherLine.reducer";
import { getNewSeasonReducer } from "./newSeason.reducer";
import billDirectPayment from "./billDirectPayments.reducer";
import billDirectPaymentline from "./billDirectPaymentLine.reducer";
import billDirectPaymentItem from "./billDirectPaymentItem.reducer";
import payment from "./payment.reducer";
import paymentStateReport from "./paymentStateReport.reducer";
import { updateInvoiceLine } from "./invoiceLine.reducer";
import { getOneInvoice } from "./getOneInvoiceItemLine.reducer";
import invoiceEmail from "./invoiceEmail.reducer";
import fundTransfer from "./fundTransfer.reducer";
import { saleTargetReport } from "./saleTargetReport.reducer";
import creaditNoteInvoice from "./fundTransfer.reducer";
import depositHeader from "./depositHeader.reducer";
import depositHeaderList from "./depositHeaderList.reducer";
import customerPayment from "./customerPayment.reducer";
import quickPoLookup from "./quickPoLookup.reducer";
import inventoryAdjustment from "./inventoryAdjustment.reducer";
import inventoryAdjustmentAdd from "./inventoryAdjustmentAdd.reducer";
import inventoryAdjustmentFormData from "./inventoryAdjustmentFormData.reducer";
import name from "./name.reducer";
import inventoryAdjustmentNewAdd from "./inventoryAdjustmentNewAdd.reducer";
import InvAdjHeader from "./inventoryAdjustmentNew.reducer";
import InvAdjLineNewAdd from "./inventoryAdjustmentNewLine.reducer";
import paymentMethod from "./paymentMethod.reducer";
import omsStockStyle from "./omsStockStyle.reducer";
import glImpact from "./gl_Impact.reducer";
import customerCategory from "./customerCategory.reducer";
import { salesInvoiceReport } from "./saleInvoiceReport.reducer";
import { salesReturnReport } from "./saleReturnReport.reducer";
import { getBookedStyleWise } from "./bookedStyleWise.reducer";
import { pendingStyleWise } from "./pendingStyleWise.reducer";
import getAllQuery from "./querytool.reducer";
import orderShipToDetail from "./orderShiptoDetail.reducer";
import orderBillToDetail from "./orderBilltoDetail.reducer";
import { getBookSummaryReducer } from "./bookSummary.reducer";
import { getPendingSummaryReducer } from "./pendingSummary.reducer";
import bGradeStock from "./bGradeStock.reducer";
import productBalance from "./productBalance.reducer";
import productReport from "./productReport.reducer";
import { getShippedSummaryReducer } from "./shippedSummary.reducer";
import { getReturnSummaryReducer } from "./returnSummary.reducer";
import omsBookedDetailReport from "./omsBookDetailReport.reducer";
import pendingdDetailReport from "./pendingDetailReport.reducer";
import shippedDetailReport from "./shippedDetailReport.reducer";
import product from "./product.reducer";
import relocateItem from "./relocateItems.reducer";
import customerAddress from "./customerAddress.reducer";
import PickTicketSummary from "./pickTicketSummary.reducer";
import onecustomerCategory from "./onecustomerCategory.reducer";
import invStockAdjustmentList from "./invStockAdjustmentList.reducer";
import tableschema from "./tableSchema.reducer";
import columnschema from "./columnSchema.reducer";
import updateTable from "./updateTable.reducer";
import pendingPodSummary from "./pendingPodSummary.reducer";
import PtSummary from "./ptSummary.reducer";
import OrderAdjSummary from "./orderAdjSummary.reducer";
import PtDetail from "./ptDetail.reducer";
import OrderAdjDetail from "./orderAdjDetail.reducer";
import availableProductSale from "./availableProductSale.reducer";
import globalSearch from "./globalSearch.reducer";
import PickTicketPrint from "./pickTicketReportPrint.reducer";
import PickTicketColumn from "./pickTicketReportColumn.reducer";
import PickTicketColumnValue from "./pickTicketReportColumnValue.reducer";
import PickTicketItem from "./pickTicketReportItem.reducer";
import creditOpenInvoice from "./creditOpenInvoiceDetailReport.reducer copy";
import salesAdjustmentPrint from "./salesAdjustmentReportPrint.reducer";
import saleAdjustmentItem from "./salesAdjustmentReportItem.reducer";
import saleAdjustmentColumn from "./salesAdjustmentReportColumn.reducer";
import saleAdjustmentColumnValue from "./salesAdjustmentReportColoumValue.reducer";
import newAdjustmentAccount from "./newAdjustmentAccount.reducer";
import RAPrint from "./raReportPrint.reducer";
import RAItem from "./raReportItem.reducer";
import RAColumn from "./raReportColumn.reducer";
import RAColumnValue from "./raReportColoumValue.reducer";
import ControlSheet from "./controlSheet.reducer";
import InvoiceReport from "./invoiceReport.reducer";
import invoicePrint from "./invoicePrint.reducer";
import invoiceColumns from "./invoiceColumns.reducer";
import invoiceColumnsValues from "./invoiceColumnsValues.reducer";
import shippedStyleWise from "./shippedStyleWiseReport.reducer";
import tracking from "./tracking.reducer";
import genderCategoryChange from "./genderCategoryChange.reducer";
import styleChange from "./styleChange.reducer";
import Sku from "./sku.reducer";
import customerInfoReport from "./customerInfoReport.reducer";
import productInfoReport from "./productInfoReport.reducer";
import podStyleWise from "./podStyleWise.reducer";
import omsAvailableBalance from "./omsAvailableBalance.reducer";
import returnStyleWise from "./returnStyleWiseReport.reducer";
import { getReturnDetailReducer } from "./returnDetail.reducer";
import customrPayment from "./customrPayment.reducer";
import billCredit from "./billCredit.reducer";
import billCreditFormData from "./billCreditFormData.reducer";
import billCreditList from "./billCreditList.reducer";
import billCreditItemLine from "./billCreditItemLine.reducer";
import billCreditExpenseLine from "./billCreditExpenseLine.reducer";
import payVendorBillCredit from "./payVendorBillCredit.reducer";
import billCreditApplyLine from "./billCreditApplyLine.reducer";
import { creditMemoReportPrint } from "./creditMemoReport.reducer";
import { saleComparisionReport } from "./saleComparisionReport.reducer";
import { saleComparisionCustomerWiseReport } from "./saleComparisionCustomerWiseReport.reducer";

import creditMemoLine from "./creditMemoLine.reducer";
import creditMemo from "./creditMemo.reducer";
import creditMemoList from "./creditMemoList.reducer";
import creditMemoInvoice from "./creditMemoInvoice.reducer";
import { intransit } from "./intransitReport.reducer";
import systemLog from "./systemLog.reducer";
import { getSKULookUpReport } from "./skuLookUp";
import { getSKULookUpReportNu } from "./skuLookUpNu";
import { getPendingSKULookUpReport } from "./pendingSkuLookUp.reducer.js";
import { production } from "./productionReport.reducer";
import accountPayable from "./accountPayable.reducer";
import chartOfAccount from "./chart_All_Account.reducer";
import customerVendor from "./customerVendor.reducer";
import otsDashboard from "./otsDashboard.reducer";
import billNumber from "./billNo.reducer";
import customerPaymentImpact from "./customerPaymentImpact.reducer";
import customerRefundImpact from "./customerRefundImpact.reducer.js";
import { sendToBergen } from "./sendToBergen.reducer";
import pendingPodSummaryAr from "./pendingPodSummaryAr.reducer";
import customerBalances from "./customerBalances.reducer";
import customerList from "./customerList.reducer";
import { internationalOrder } from "./internationalOrder.reducer";
import { canadaOrder } from "./canadaOrder.reducer";
import ProductSaleTransfer from "./productSaleTransfer.reducer";
import { cit } from "./cit.reducer";
import { creditPrintReport } from "./creditPrintReport.reducer";
import { dlShowroom } from "./dlShowroom.reducer";
import { asnToBergen } from "./asnToBergen.reducer";
import { inventory } from "./inventoryReport.reducer";
import { dscoInvoice } from "./dscoInvoiceReport.reducer";
import poddetail from "./podDetail.reducer";
import seasonYear from "./seasonYear.reducer";
import updateItems from "./updateItems.reducer";
import creaditApply from "./creaditApply.reducer";
import expenseAccount from "./expenseAccount.reducer";
import omsStockSummary2 from "./omsStockSummaryReport2.reducer.js";
import { locationWise } from "./locationWiseReport.reducer";
import { commissionReport } from "./commissionReport.reducer";
import productInfoBySize from "./productInfoBySize.reducer";
import goodRequired from "./goodRequired.reducer";
import getTransactionNo from "./getTransactionNo.reducer";
import { arAgingSummary } from "./arAgingSummary.reducer";
import interLog from "./interlog.reducer";
import totalOTSbySize from "./totalOTSbySize.reducer";
import invoicePending from "./invoicePending.reducer";
import incomeStatement from "./incomeStatement.reducer";
import getAllSelectQuery from "./getAllSelectQuery.reducer";
import skuLookUpData from "./skuLookUpData.reducer";
import incomeDetailStatement from "./incomeDetail.reducer"
import vendorLedger from "./vendorLedger.reducer"
import balanceSheet from "./balanceSheet.reducer"
import queryBuilder from "./queryBuilder.reducer";
import { upcReport } from "./upcReport.reducer";
import balaceDetail from "./balanceDetail.reducer";
import { newPTReport } from "./newPTReport.reducer";
import controlLog from "./controlSheetLog.reducer";
import searchProduct from "./searchProduct.reducer";
import searchRA from "./searchRA.reducer";
import searchPTUK from "./searchPTUK.reducer";
import sendProduct from "./sendProduct.reducer";
import sendRA from "./sendRA.reducer";
import vendorLedger2 from "./vendorLedger2.reducer"
import paymentSummary from "./paymentLedgerSummaryReport.reducer"
import omsStyle from "./omsStyleReport.reducer"
import omsWash from "./omsWashReport.reducer"
import searchPackingList from "./searchPackingList.reducer";
import sendBergenEdi from "./sendBergenEdi.reducer";
import sendStitchFixEdi from "./sendStitchFixEdi.reducer";
import sendPackingList from "./sendPackingList.reducer";
import sendPtUKToBergen from "./sendPtUKToBergen.reducer";
import customerPaymentDetail from "./customerPaymentDetail.reducer.js";
import { ChartOfAccount } from "./chartOfAccountReport.reducer.js"
import { sendBergenEmail } from "./sendBergenEmail.reducer.js"
import styleShot from "./styleShot.reducer.js"
import styledistro from "./styleDistro.reducer.js"
import vendorSummary from "./vendorSummary.reducer.js"
import expense from "./expense.reducer.js"
import arInventory from "./arInventory.reducer.js"
import inventoryValuation from "./inventoryValuation.reducer.js"
import salePersonInfo from "./salePersonInfo.reducer";
import journalLedger from "./journalLedger.reducer.js"
import PickTicketDetail from "./pickTicketDetail.reducer.js"
import { styleWiseStockReport } from "./styleWiseStockReport.reducer.js"
import apAgingDetail from "./apAgingDetail.reducer.js"
import avalaraInvoices from "./avalaraInvoices.reducer.js";
import salePersonNew from "./salePersonNew.reducer.js"
import customerTypeNew from "./customerTypeNew.reducer.js"
import RegionNew from "./RegionNew.reducer.js"
import CrmSkuWise from "./crmSkuWise.reducer.js"
import { transactionLock } from "./transactionLock.reducer.js";
import { saleTargetReportSalePerson } from "./saleTargetReportSalePerson.reducer.js"
import chargeBackDetail from "./chargeBackDetail.reducer.js"
import { upcToItemCode } from "./upcToItemcode.reducer.js";
import { netShipStyleWise } from "./netShipStyleWise.reducer.js"
import missingUpc from "./missingUpc.reducer.js"
import customerPaymentDateWise from "./customerPaymentDateWise.reducer.js"
import PendingOrderAvailability from "./pendingOrderAvailibilityBySize.reducer.js"
import {NegativeOtsOrderWise} from "./negativeOtsOrderWise.reducer.js"
import brand from "./brand.reducer.js"

export default combineReducers({
  accounttype,
  adminCompany,
  adminLocation,
  adminMenu,
  adminMenuForm,
  adminstate,
  auth,
  BergenBillingOption,
  Billing,
  bill,
  billList,
  billLine,
  billFormData,
  Category,
  chartofaccount,
  
  City,
  combinationForm,
  companyAccess,
  companyAll,
  country,
  courierService,
  creditNote,
  creditNoteList,
  currency,
  customerForms,
  customerSections,
  customerTerm,
  cut,
  defaultCompany,
  expenseBill,
  expenseLine,
  fabric,
  fiscalYear,
  fitCategory,
  FreightTerm,
  gender,
  genderCategory,
  generateItem,
  getBillingShippingDetails,
  getcustomer,
  getDemoDefaultCompany,
  getRegionCategoryId,
  getSaleOrderGenderHeader,
  inseam,
  inseamLabel,
  invoiceGlPeriod,
  invoiceHeader,
  invoiceHeaderList,
  invoiceHeaderFormDataReducer,
  item,
  itemTransfer,
  itemTransferList,
  itemTransferFormData,
  itemTransferLine,
  jobTitle,
  log,
  menuRights,
  menus,
  module,
  orderShipment,
  orderShipmentFormData,
  orderShipmentLine,
  orderShipmentList,
  orderStatus,
  parentMenu,
  paymentterm,
  pickTicketStatus,
  productClass,
  productForm,
  ProductSale,
  productStatus,
  productType,
  receipt,
  receiptLine,
  region,
  Region,
  regionCategory,
  returnreason,
  rise,
  riseLabel,
  reportCurrency,
  receiptList,
  saleOrderLine,
  saleOrderFormData,
  salePerson,
  salePersonForm,
  salePersonId,
  salesOrder,
  salesOrderList,
  salesReturn,
  salesReturnList,
  salesReturnFormDataReducer,
  salesTarget,
  scmBrand,
  scmFigure,
  scmMonth,
  scmYear,
  season,
  Shipping,
  ShippingMethod,
  shipStatus,
  ShipVia,
  size,
  State,
  style,
  styleCombination,
  supervisor,
  taxsetup,
  userRights,
  users,
  vendor,
  vendorBill,
  vendorCategory,
  wash,
  washType,
  returnType,
  ReportIP,
  saleOrderAdjustment,
  saleOrderAdjustmentList,
  fabricComposition,
  itemType,
  fabricClass,
  fabricClass2,
  fabricType,
  fabricUsable,
  customerUploads,
  commitCriteria,
  chargeBack,
  arAccount,
  adminPaymentType,
  payCustomerPaymentHeaderList,
  payCustomerPayment,
  paymentType,
  adminModule,
  customerTypes,
  
  saleOrderRelatedRecord,
  termForPrint,
  OmsReprot,
  omsRegionStateReport,
  regionStateReport,
  omsReportByCustomer,
  adminJob,
  adminDiscount,
  factor,
  adminMenuType,
  paymentMethodType,
  adminCurrencyTest,
  barganPaymentTerm,
  omsPendingReport,
  omsPendingStateReport,
  omsRegionPendingReport,
  omsPendingReportByCustomer,
  userType,
  getBussinessDashboardReducer,
  salesReportOrderItem,
  salesReportOrderPrint,
  salesReportColumnValue,
  salesReportOrderColumn,
  billpayment,
  payVendorBill,
  billpaymentLine,
  customerRefundHeaderList,
  customerRefundHeader,
  omsShipReport,
  omsShipRegionStateReport,
  omsShipRegionReport,
  omsShipReportByCustomer,
  OmsPendingPODReport,
  getPendinPODStateReport,
  omsPendingPODRegionReport,
  omsPODReportByCustomer,
  omsReturnReport,
  omsReturnRegionStateReport,
  omsReturnRegionReport,
  getWiseStockReport,
  getReturnRegionStateCustomer,
  getSaleOmsReturnCustomer,
  omsStockSummary,
  journalVoucherFormData,
  journalVoucherHeader,
  journalVoucherHeaderList,
  customerRefundFormData,
  journalVoucherLine,
  getNewSeasonReducer,
  billDirectPayment,
  billDirectPaymentline,
  billDirectPaymentItem,
  payment,
  paymentStateReport,
  updateInvoiceLine,
  getOneInvoice,
  fundTransfer,
  saleTargetReport,
  creaditNoteInvoice,
  depositHeader,
  depositHeaderList,
  customerPayment,
  customerPaymentDetail,
  quickPoLookup,
  inventoryAdjustment,
  inventoryAdjustmentAdd,
  inventoryAdjustmentFormData,
  name,
  paymentMethod,
  inventoryAdjustmentNewAdd,
  InvAdjHeader,
  InvAdjLineNewAdd,
  omsStockStyle,
  glImpact,
  customerCategory,
  salesReturnReport,
  salesInvoiceReport,
  getBookedStyleWise,
  pendingStyleWise,
  getAllQuery,
  orderShipToDetail,
  orderBillToDetail,
  getBookSummaryReducer,
  bGradeStock,
  productReport,
  getPendingSummaryReducer,
  getShippedSummaryReducer,
  getReturnSummaryReducer,
  omsBookedDetailReport,
  getPendingSummaryReducer,
  pendingdDetailReport,
  shippedDetailReport,
  customerAddress,
  productBalance,
  PickTicketSummary,
  product,
  relocateItem,
  customerAddress,
  saleOrderAdjustmentFormData,
  onecustomerCategory,
  invStockAdjustmentList,
  tableschema,
  columnschema,
  updateTable,
  pendingPodSummary,
  PtSummary,
  OrderAdjSummary,
  PtDetail,
  OrderAdjDetail,
  availableProductSale,
  globalSearch,
  PickTicketPrint,
  PickTicketColumn,
  PickTicketColumnValue,
  PickTicketItem,
  creditOpenInvoice,
  salesAdjustmentPrint,
  saleAdjustmentItem,
  saleAdjustmentColumn,
  saleAdjustmentColumnValue,
  newAdjustmentAccount,
  RAPrint,
  RAItem,
  RAColumn,
  RAColumnValue,
  ControlSheet,
  InvoiceReport,
  invoicePrint,
  invoiceColumns,
  invoiceColumnsValues,
  shippedStyleWise,
  tracking,
  genderCategoryChange,
  styleChange,
  Sku,
  customerInfoReport,
  productInfoReport,
  podStyleWise,
  omsAvailableBalance,
  returnStyleWise,
  getReturnDetailReducer,
  customrPayment,
  billCredit,
  billCreditFormData,
  billCreditList,
  billCreditItemLine,
  billCreditExpenseLine,
  payVendorBillCredit,
  billCreditApplyLine,
  creditMemoReportPrint,
  saleComparisionReport,
  saleComparisionCustomerWiseReport,
  creditMemoLine,
  creditMemo,
  creditMemoList,
  creditMemoInvoice,
  intransit,
  systemLog,
  production,
  getSKULookUpReport,
  accountPayable,
  production,
  chartOfAccount,
  customerVendor,
  otsDashboard,
  billNumber,
  customerPaymentImpact,
  sendToBergen,
  pendingPodSummaryAr,
  customerBalances,
  customerList,
  internationalOrder,
  canadaOrder,
  ProductSaleTransfer,
  cit,
  creditPrintReport,
  dlShowroom,
  asnToBergen,
  inventory,
  dscoInvoice,
  poddetail,
  newSalesReport,
  seasonYear,
  invoiceEmail,
  updateItems,
  creaditApply,
  expenseAccount,
  omsStockSummary2,
  customerRefundImpact,
  locationWise,
  commissionReport,
  productInfoBySize,
  goodRequired,
  getTransactionNo,
  arAgingSummary,
  getSKULookUpReportNu,
  interLog,
  totalOTSbySize,
  getPendingSKULookUpReport,
  invoicePending,
  incomeStatement,
  getAllSelectQuery,
  skuLookUpData,
  incomeDetailStatement,
  vendorLedger,
  balanceSheet,
  queryBuilder,
  upcReport,
  balaceDetail,
  newPTReport,
  controlLog,
  searchProduct,
  sendProduct,
  vendorLedger2,
  paymentSummary,
  omsStyle,
  omsWash,
  searchPackingList,
  sendBergenEdi,
  sendStitchFixEdi,
  sendPackingList,
  searchPTUK,
  sendPtUKToBergen,
  ChartOfAccount,
  sendBergenEmail,
  styleShot,
  styledistro,
  vendorSummary,
  expense,
  searchRA,
  sendRA,
  arInventory,
  inventoryValuation,
  salePersonInfo,
  journalLedger,
  PickTicketDetail,
  styleWiseStockReport,
  apAgingDetail,
  avalaraInvoices,
  salePersonNew,
  customerTypeNew,
  RegionNew,
  CrmSkuWise,
  transactionLock,
  saleTargetReportSalePerson,
  chargeBackDetail,
  upcToItemCode,
  netShipStyleWise,
  missingUpc,
  customerPaymentDateWise,
  PendingOrderAvailability,
  NegativeOtsOrderWise,
  brand
});
