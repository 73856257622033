import {
	ADD_ITEM_TRANSFER_LINE_ERROR, ADD_ITEM_TRANSFER_LINE_REQUEST, ADD_ITEM_TRANSFER_LINE_SUCCESS, DELETE_ITEM_TRANSFER_LINE_ERROR, DELETE_ITEM_TRANSFER_LINE_REQUEST, DELETE_ITEM_TRANSFER_LINE_SUCCESS, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS, GET_ONE_ITEM_TRANSFER_LINE_ERROR, UPDATE_ITEM_TRANSFER_LINE_SUCCESS, UPDATE_ITEM_TRANSFER_LINE_REQUEST,
	GET_ONE_ITEM_TRANSFER_LINE_REQUEST, GET_ONE_ITEM_TRANSFER_LINE_SUCCESS, UPDATE_ITEM_TRANSFER_LINE_ERROR,

} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addItemTransferLine = (token, object) => (dispatch) => {
	dispatch({ type: ADD_ITEM_TRANSFER_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/iNVItemTransferLine`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: ADD_ITEM_TRANSFER_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_ITEM_TRANSFER_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateItemTransferLine = (token, object, itemTransferId) => (dispatch) => {
	object.map((item) => {
		delete item.style_name;
		delete item.cut_name;
		delete item.fabric_name;
		delete item.gender_category_name;
		delete item.gender_name;
		delete item.inseam;
		delete item.isSelected;
		delete item.quantity_available;
		delete item.quantity_backorder;
		delete item.quantity_committed;
		delete item.quantity_on_hand;
		delete item.rate;
		delete item.size;
		delete item.style_id;
		delete item.brand_id;
		delete item.brand_name;
		delete item.wash_id;
		delete item.wash_name;
		delete item.amount;
		delete item.item_code;
		delete item.commit_status;
		delete item.transfer_header_id;
		return item;
	});
	dispatch({ type: UPDATE_ITEM_TRANSFER_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/iNVItemTransferLine/${itemTransferId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			data: object,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_ITEM_TRANSFER_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_ITEM_TRANSFER_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneItemTransferLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/iNVItemTransferLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const getOneItemTransferLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ITEM_TRANSFER_LINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/saleReturnLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {

			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ITEM_TRANSFER_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteItemTransferLine = (token, data, id) => (dispatch) => {
	dispatch({ type: DELETE_ITEM_TRANSFER_LINE_REQUEST });

	data.map((val) => {
		delete val.item_code;
		delete val.item_id;
		delete val.quantity;
		delete val.transfer_header_id;
	})

	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/iNVItemTransferLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_ITEM_TRANSFER_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_ITEM_TRANSFER_LINE_ERROR,
				payload: error.response,
			});
		});
};
