import React from 'react'
import { Card } from 'antd';

const OrderReportFooter = () => {
  return (
    <div className='order_report_footer' 
    
    >
      <div>
        Note: This estimates does Not include Sales Taxes. You will be invoiced with sales tax if you have not provided a valid resale Certificate
      </div>
      <div style={{display :"flex", justifyContent : "space-between"}}>
        <Card style={{ width: "420px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center" }}>Return Policy</p>
          <p className='footer_order_p'>1) No return of goods after 45 days of shipment.</p>
          <p className='footer_order_p'>2) Damage goods must be returned within 10 days of shipment.</p>
          <p className='footer_order_p'>3) No returns would be accepted without RA.</p>
          <p className='footer_order_p'>4) All Return must include a copy of this packing list.</p>
          <p className='footer_order_p'>5) Returns without an advance shipping notice (including tracking#) and an approved RA# will not be processed.</p>
        </Card>
    

        <Card style={{ width: "310px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center"}}>Return Address</p>
          <p className='footer_order_p'>
            Bergen Shippers DLPD Canada LTD c/o Bergen Logistics<br/>
            3925 Steeles Ave East UNIT # 3 Brampton, ON L6T 5W5<br/>
            Contact Person : Mary Williams<br/>
            Tel: 905 792 8585 Ext.25  </p>
        </Card>
      </div>

    </div>

  )
}

export default OrderReportFooter;