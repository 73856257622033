import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Form, Input, Tabs, Divider, Select, Checkbox } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { addChartofAccount, getChartofAccount, deleteChartofAccount, updateChartofAccount } from "../../redux/actions/chartofaccount.action";
import Tables from "../../components/Table/Tables";
import { chartofaccountColumns } from "../../utils/cols";
import "../Style/style.css";
import "../User/user.css";
import notification from "../../utils/notification";
import { getAdminCurrency } from "../../redux/actions/adminCurrency.action";
import { getAccountType } from "../../redux/actions/accounttype.action";

import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";

const { TabPane } = Tabs;
const { Option } = Select;
const rateList = ["AVERAGE", "CURRENT", "HISTORICAL"];
const ChartOfAccount = ({ token }) => {
	TabTitle("Chart Of Account")
	const { chartofaccount, userRights, currency, log, accounttype } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [full_name, setFullName] = useState("");
	const [generalrate, setGeneralRate] = useState("");
	const [reconcile_with_matching, setReconcile] = useState(false);
	const [parent_account_id, setParentAccountId] = useState(null);
	const [includechildren, setIncludeChildren] = useState(false);
	const [accountNumber, setAccountNumber] = useState("");
	const [is_inventory, setInventory] = useState(false);
	const [is_eliminate, setEliminate] = useState(false);
	const [description, setDescription] = useState("");
	const [accttype, setAccountType] = useState(null);
	const [account_id, setAccountId] = useState(null);
	const [is_summary, setSummary] = useState(false);
	const [cashflowrate, setFlowRate] = useState("");
	const [is_active, setActive] = useState(true);
	const [list, setList] = useState([]);
	const [is_revalue, setRevalue] = useState(false);
	const [currency_id, setCurrency] = useState(null);
	const [openBalance, setOpenBalance] = useState(null);
	const [form] = Form.useForm();
	const fullNameInput = useRef(null);
	const fullAccountInput = useRef(null);
	const [disable, setDisable] = useState(false);
	const [chartObj, setChartObj] = useState(null);
	const [idd, setID] = useState(null);
	const dispatch = useDispatch();
	const id = ReadCookie("menuId");

	useEffect(() => {
		dispatch(getChartofAccount(token)).then((res) => setList(res?.payload?.data));
	}, [token]);

	useEffect(() => {
		dispatch(getAccountType(token));
	}, [token]);

	
	const handleDelete = () => {
		if (chartObj) {
			dispatch(deleteChartofAccount(token, chartObj.account_id)).then((res) => {
				if (res.type === "DELETE_CHART_OF_ACCOUNT_SUCCESS") {
					notification("success", "Chart Of Account Deleted", "Record deleted successfully!");
				}
				if (res.type === "DELETE_CHART_OF_ACCOUNT_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				changingState("delete");
			});
		}
		setFormState("view");
		dispatch(getChartofAccount(token));
	};

	useEffect(() => {
		if (chartObj) {
			setFormState("view");
			changingState("view");
		}
	}, [chartObj]);

	useEffect(() => {
		if (description === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [description]);

	useEffect(() => {
		dispatch(getAdminCurrency(token));
	}, [token]);

	useLayoutEffect(() => {
		dispatch(getRights(token, id)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				if (!res?.payload.can_add) {
					setFormState("table");
				}
			}
		});
	}, []);

	
	
	

	

	const changingState = (state) => {
		if (state === "view") {
			form.setFieldsValue({
				rate: chartObj.generalrate,
				cash: chartObj.cashflowrate,
				id: chartObj.account_id,
				name: chartObj.full_name,
				pid: chartObj.parent_account_id,
				desc: chartObj.full_name,
				inc: chartObj.includechildren,
				accttype: chartObj.accttype,
				reconc: chartObj.reconcile_with_matching,
				active: chartObj.is_active,
				sum: chartObj.is_summary,
				eli: chartObj.is_eliminate,
				inv: chartObj.is_inventory,
				rev: chartObj.is_revalue,
				curr: chartObj.currency_id,
				account: chartObj.account_number,
				opening_balance: chartObj.opening_balance,
			});
			setDescription(chartObj.full_name);
			setActive(chartObj.is_active);
			setEliminate(chartObj.is_eliminate);
			setSummary(chartObj.is_summary);
			setReconcile(chartObj.reconcile_with_matching);
			setRevalue(chartObj.is_revalue);
			setInventory(chartObj.is_inventory);
			setIncludeChildren(chartObj.includechildren);
			setGeneralRate(chartObj.generalrate);
			setFlowRate(chartObj.cashflowrate);
			setParentAccountId(chartObj.parent_account_id);
			setAccountType(chartObj.accttype);
			setAccountId(chartObj.account_id);
			setFullName(chartObj.full_name);
			setAccountNumber(chartObj.account_number);
		}
		if (state === "delete") {
			form.setFieldsValue({
				id: null,
				rate: null,
				cash: null,
				name: "",
				pid: null,
				desc: "",
				inc: "",
				accttype: null,
				reconc: "",
				sum: "",
				eli: "",
				inv: "",
				rev: "",
				curr: null,
				account: "",
			});
			setDescription("");
			setActive(true);
			setEliminate(false);
			setSummary(false);
			setReconcile(false);
			setRevalue(false);
			setInventory(false);
			setIncludeChildren(false);
			setGeneralRate("");
			setFlowRate("");
			setParentAccountId(null);
			setReconcile(false);
			setAccountType("");
			setAccountId(null);
			setFullName("");
			setChartObj(null);
			setAccountNumber("");
		}
	};

	const formSave = async () => {
		await form
			.validateFields()
			.then((values) => {
				if (formState === "add") {
					dispatch(
						addChartofAccount(
							token,
							{
								full_name: description,
								generalrate,
								cashflowrate,
								parent_account_id,
								description,
								account_number: accountNumber,
								accttype,
								includechildren,
								is_active,
								is_eliminate,
								is_inventory,
								is_revalue,
								is_summary,
								currency_id,
								reconcile_with_matching,
								account_search_display_name: accountNumber + ' ' + description,
								account_search_display_name_copy: accountNumber + ' ' + description,
								display_name_with_hierarchy: accountNumber + ' ' + description,
								opening_balance: openBalance
							},
							id
						)
					).then((res) => {
						if (res.type === "ADD_CHART_OF_ACCOUNT_ERROR") {

							notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
						}
						if (res.type === "ADD_CHART_OF_ACCOUNT_SUCCESS") {
							setID(res?.payload?.account_id)
							
							notification("success", "Chart Of Account Created", "Account created successfully!");
							changingState("delete");
							setFormState("view");
						}
						dispatch(
							updateChartofAccount(
								token,
								{
									account_id:res?.payload?.account_id
								},
								res?.payload?.account_id
							),
							)
						dispatch(getChartofAccount(token, id));
						
					});
					
				}
				if (formState === "edit") {
					
					dispatch(
						updateChartofAccount(
							token,
							{
								full_name: description,
								generalrate,
								cashflowrate,
								parent_account_id,
								description,
								accttype,
								includechildren,
								is_active,
								is_eliminate,
								is_inventory,
								is_revalue,
								is_summary,
								currency_id,
								reconcile_with_matching,
								account_number: accountNumber,
								account_search_display_name: accountNumber + ' ' + description,
								account_search_display_name_copy: accountNumber + ' ' + description,
								display_name_with_hierarchy: accountNumber + ' ' + description,
								opening_balance: openBalance
							},
							account_id
						)
					).then((res) => {
						if (res.type === "UPDATE_CHART_OF_ACCOUNT_ERROR") {
							notification("error", "Not updated", "Something went wrong");
						}
						if (res.type === "UPDATE_CHART_OF_ACCOUNT_SUCCESS") {
							notification("success", "Chart Of Account Updated", "Chart Of Account updated successfully");
						}
						dispatch(getChartofAccount(token, id));
					});
				}
			})
			.catch((err) => {

			});
	};

	useEffect(() => {
		if (formState === "add") {
			fullNameInput.current.focus();
			fullAccountInput.current.focus();
			changingState("delete");
		}
	}, [formState]);

	const filterReset = () => {
		setFormState("view");
		changingState("delete");
	};
	

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Chart of Account"} minimize={setMinimize} check={minimize} />
				{!minimize && (
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								formType={formState}
								rights={userRights?.payload}
								formState={setFormState}
								disable={disable}
								onDelete={handleDelete}
								save={formSave}
								reset={filterReset}
							/>
						</div>
						<div className="styleContent">
							<Row className="tables-main-row-new">
								<Col xs={24} sm={24} md={10} lg={8} xl={7} xxl={6}>
									<div className="styleTable">
										<CSVLink
											filename={"chart-of-account.csv"}
											data={list.length > 0 ? list : []}
										>
											Download Excel
										</CSVLink>

										<Tables
											type="chartofaccount"
											formState={formState}
											source={chartofaccount?.payload?.data}
											col={chartofaccountColumns}
											paginate
											scrollX={"28rem"}
											scroll={602}
											
											perPage={1000}
											load={chartofaccount.loading}
											chartObj={setChartObj}
											rowKey="account_id"
										/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={14} lg={16} xl={17} xxl={18}>
									<div className="styleForm w-100">
										<div className="testStyle">
											<Form form={form} name="basic" labelWrap labelAlign="left">
												<div className="customRow styleFormUpper "></div>
												<div className="container-fluid label-mob-space">
													<Brow>
														{chartObj !== null && !(formState === "add") && (
															<Bcol lg={6} xxl={4}>
																<Form.Item
																	label=" Acount Id"
																	name="id"
																	initialValue={form.getFieldValue().id}
																	labelCol={{ xs: 11, xxl: 10 }}
																	wrapperCol={{ span: 14 }}
																>
																	<Input bordered={false} className="styleInput id-field-style" readOnly />
																</Form.Item>
															</Bcol>
														)}
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Account Number"
																name="account"
																initialValue={form.getFieldValue().account}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
															>
																<Input
																	size="small"
																	ref={fullAccountInput}
																	disabled={!(formState === "add" || formState === "edit")}
																	className="styleInput"
																	onChange={(e) => setAccountNumber(e.target.value)}
																/>
															</Form.Item>
														</Bcol>

														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Description"
																name="desc"
																initialValue={form.getFieldValue().desc}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
															>
																<Input
																	size="small"
																	ref={fullNameInput}
																	disabled={!(formState === "add" || formState === "edit")}
																	className="styleInput"
																	onChange={(e) => setDescription(e.target.value)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="General Rate"
																name="rate"
																initialValue={form.getFieldValue().desc}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 14 }}
																rules={formState === "add" ? [{ required: true, message: "General Rate is required" }] : null}
															>
																<Select
																	size="small"
																	className="styleInput"
																	disabled={!(formState === "add" || formState === "edit")}
																	onChange={(e) => setGeneralRate(e)}
																	showSearch
																	optionFilterProp="children"
																	filterSort={(optionA, optionB) =>
																		  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
																	}
																>
																	{rateList.map((list, i) => {
																		return <Option key={i} value={list}>{list}</Option>;
																	})}
																</Select>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Cash Flow Rate"
																name="cash"
																initialValue={form.getFieldValue().desc}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
																rules={
																	formState === "add"
																		? [
																			{
																				required: true,
																				message: "Cash Flow Rate is required",
																			},
																		]
																		: null
																}
															>
																<Select
																	size="small"
																	className="styleInput"
																	disabled={!(formState === "add" || formState === "edit")}
																	
																	showSearch
																	optionFilterProp="children"
																	onChange={(e) => setFlowRate(e)}
																	filterSort={(optionA, optionB) =>
																		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
																  }
																>
																	{rateList.map((list, i) => {
																		return <Option key={i} value={list}>{list}</Option>;
																	})}
																</Select>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Parent Account"
																name="pid"
																initialValue={form.getFieldValue().id}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 14 }}
															>
																<Select
																	showSearch
																	allowClear
																	size="small"
																	className="styleInput"
																	
																	disabled={!(formState === "add" || formState === "edit")}
																	optionFilterProp="children"
																	onChange={(e) => setParentAccountId(e)}
																	filterSort={(optionA, optionB) =>
																		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
																  }
																>
																	{chartofaccount?.payload?.data?.map((acc) => {
																		return <Option key={acc.account_id} value={acc.account_id}>{acc.full_name}</Option>;
																	})}
																</Select>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Account Type"
																name="accttype"
																initialValue={form.getFieldValue().desc}
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 14 }}
																rules={
																	formState === "add"
																		? [
																			{
																				required: true,
																				message: "Account type is required",
																			},
																		]
																		: null
																}
															>
																<Select
																	size="small"
																	showSearch
																	className="styleInput"
																	
																	disabled={!(formState === "add" || formState === "edit")}
																	optionFilterProp="children"
																	onSelect={(e) => setAccountType(e)}
																	allowClear
																	filterSort={(optionA, optionB) =>
																		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
																  }
																>
																	{accounttype?.payload?.data.map((acc) => {
																		return <Option key={acc.account_type_code} value={acc.account_type_code}>{acc.account_type_code}</Option>;
																	})}
																</Select>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Currency"
																name="curr"
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
																rules={
																	formState === "add"
																		? [
																			{
																				required: true,
																				message: "Currency is required",
																			},
																		]
																		: null
																}
															>
																<Select
																	size="small"
																	showSearch
																	className="styleInput"
																	
																	disabled={!(formState === "add" || formState === "edit")}
																	optionFilterProp="children"
																	onSelect={(e) => setCurrency(e)}
																	allowClear
																	filterSort={(optionA, optionB) =>
																		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
																  }
																>
																	{currency?.payload?.data.map((curr) => {
																		return <Option key={curr.currency_id} value={curr.currency_id}>{curr.currency_name}</Option>;
																	})}
																</Select>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Opening Balance"
																name="opening_balance"
																
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
															>
																<Input
																	size="small"
																	
																	disabled={!(formState === "add" || formState === "edit")}
																	className="styleInput"
																	onChange={(e) => setOpenBalance(e.target.value)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Include Children" name="inc" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={includechildren}
																	onChange={(e) => setIncludeChildren(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Active" name="active" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={is_active}
																	onChange={(e) => setActive(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item
																label="Recnocile With Matching"
																name="reconc"
																labelCol={{ xs: 11, xxl: 10 }}
																wrapperCol={{ span: 16 }}
															>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={reconcile_with_matching}
																	onChange={(e) => setReconcile(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Summary" name="sum" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={is_summary}
																	onChange={(e) => setSummary(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Eliminate" name="eli" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={is_eliminate}
																	onChange={(e) => setEliminate(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Inventory" name="inv" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={is_inventory}
																	onChange={(e) => setInventory(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Bcol lg={6} xxl={4}>
															<Form.Item label="Revalue" name="rev" labelCol={{ xs: 11, xxl: 10 }} wrapperCol={{ span: 16 }}>
																<Checkbox
																	disabled={!(formState === "add" || formState === "edit")}
																	checked={is_revalue}
																	onChange={(e) => setRevalue(e.target.checked)}
																/>
															</Form.Item>
														</Bcol>
														<Divider />
														{/* <div className="styleTab">
															<Tabs type="card" tabBarGutter={2} defaultActiveKey="1" centered>
																<TabPane tab="Log Info" key="logs">
																	<div className="tabs">
																		<Tables
																			type="company"
																			scrollX={"50rem"}
																			scroll={800}
																			perPage={10}
																			source={log?.payload?.data}
																			col={logColumns}
																			load={log.loading}
																			paginate
																		/>
																	</div>
																</TabPane>
															</Tabs>
														</div> */}
													</Brow>
												</div>
											</Form>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default ChartOfAccount;