import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DatePicker, Input, Tabs, Button, Form, Table, Select, InputNumber, Tooltip, Popconfirm } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Loader from "react-loader-spinner";
import moment from "moment";
import Swal from "sweetalert2";
import { addReceiptHeader, updateReceiptHeader, getOneReceiptHeaderDetail } from "../../../../redux/actions/receipt.action";
import { getOneReceiptLine, getOneReceiptLineDetail, addReceiptLine, updateReceiptLine, } from "../../../../redux/actions/receiptLine.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { addCreditHeader } from "../../../../redux/actions/credit.action";
import { addCreditLine } from "../../../../redux/actions/creditLine.action";
import numeral from "numeral";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { DeleteOutlined } from "@ant-design/icons";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { getBrands } from "../../../../redux/actions/brand.action";
import { currencySymbol } from "../../../../utils/constants";
const { TabPane } = Tabs;
const { Option } = Select;

export default function ItemReceiptForm({ formData, setFormData, val, token, submit, checkFlag, setFlag, edit, saleReturnId, loading: deleteLoading, formState, mode, return_id, changeState, setDisable }) {
	const submitButton = useRef(null);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { systemLog, invoiceGlPeriod, adminLocation, glImpact, brand } = useSelector((state) => state);
	const [invoiceDate, setInvoiceDate] = useState(moment());
	const [visibleItems, setVisibleItems] = useState([]);
	const [deletedItems, setDeletedItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));

	useEffect(() => {
		setLoading(true)
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			if (res?.type === "GET_ONE_INVOICE_GL_PERIOD_SUCCESS") {
				let result = res?.payload?.find((item) => {
					return item.period_name === currentDate;
				});
				setLoading(false);
				setCurrentDate(result?.period_id);
			}
		});
		dispatch(getAdminLocation(token));
	}, [token, dispatch]);

	useEffect(() => {
		if (formState === "formView") {
			dispatch(getGLImpact(token, { "transaction_header_id": return_id, "transaction_type": "ITEMRECEIPT" }));
		}
	}, [formState]);

	useEffect(() => {
		setLoading(deleteLoading);
	}, [deleteLoading]);

	useEffect(() => {
		setLoading(true);
		if (mode === "return") {
			dispatch(getOneReceiptLine(token, return_id)).then((res) => {
				if (res.type === "GET_ONE_RECEIPT_LINE_SUCCESS") {
					setLoading(false);
					setVisibleItems(res.payload);
				} else if (res.type === "GET_ONE_RECEIPT_LINE_ERROR") {
					setLoading(false);
					notification("warning", "No Items Found", res?.payload?.data.data);
				}
			});
		} else {
			dispatch(getOneReceiptLineDetail(token, return_id)).then((res) => {
				if (res.type === "GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS") {
					setLoading(false);
					setVisibleItems(res.payload);
				} else if (res.type === "GET_ONE_RECEIPT_LINE_DETAIL_ERROR") {
					setLoading(false);
					notification("warning", "No Items Found", res?.payload?.data.data);
				}
			});
		}
		dispatch(getBrands(token))
	}, []);

	useEffect(() => {
		if (formData) {
			form.setFieldsValue({
				receiptNo: formData.receipt_header_no ? formData.receipt_header_no : formData.receipt_header_no,
				postingPeriod: formData.gl_period_id ? formData.gl_period_id : currentDate,
				receiptDate: moment(formData.receipt_date),
				customerName: formData.customer_name,
				creaditMemo: formData.note_header_no,
				asnNo: mode === "return" ? formData.lot_no : formData?.asn_no,
				memo: formData.memo,
				receiptHeaderId: formData.receipt_header_id,
				invoiceNo: formData.invoice_header_no,
				RaNumber: formData.return_header_no,
				RaApprovalNumber: formData.ra_no,
				location: formData.location_id,
				brand_id: formData.brand_id
			});
		}
	}, [formData]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	useEffect(() => {
		if (checkFlag === true) {
			form.setFieldsValue({
				receiptNo: null,
				customerName: null,
				postingPeriod: currentDate,
				returnDate: null,
				memo: null,
				asnNo: null,
				location: null,
				brand_id: null,
			});
			setVisibleItems([]);
			setFlag(false);
		}
	}, [checkFlag]);

	const handleDeleteOrder = (record) => {
		const dataSource = [...visibleItems];
		const deletedDataSource = [...deletedItems];
		if (visibleItems.length > 1) {
			deletedDataSource.push(record);
			setDeletedItems(deletedDataSource);
			setVisibleItems(dataSource.filter((item) => item.item_id !== record.item_id));
			notification("success", "Item Deleted");
		} else {
			return Swal.fire({
				icon: "error",
				title: "There is only 1 Line.",
				text: "Last line of the document cannot be deleted",
			});
		}
	};

	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style",
			dataIndex: "style_name",
		},
		{
			title: "Wash",
			dataIndex: "wash_name",
		},
		{
			title: "Gender Category",
			dataIndex: "gender_category_name",
		},
		{
			title: "Size",
			dataIndex: "size",
		},
		{
			title: "Inseam",
			dataIndex: "inseam",
		},
		{
			title: "Cost Price",
			dataIndex: "rate",
			editable: true,
			render: (text, record, index) => {
				return (
					<InputNumber
						disabled={!(formState === "add" || formState === "edit")}
						size="small"
						min="0"
						onStep={(value, info) =>
							updateRate(info.type, record.item_code, value, index)
						}
						value={numeral(record.rate).format("0.00")}
						onChange={(value) =>
							updateRate("up", record.item_code, value, index)
						}
						defaultValue={0}
					/>

				);
			},
		},
		{
			title: "Balance Quantity",
			dataIndex: "balance_quantity",
			editable: true,
			render: (text, record) => {
				return (
					numeral(record.balance_quantity).format("0")
				);
			},
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						min="0"

						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						value={record.quantity}
						disabled={!(formState === "add" || formState === "Return")}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={0}
					/>
				);
			},
		},
		{
			title: "Amount",
			render: (_, record) => parseFloat(record.quantity * record.rate).toFixed(2),
		},
		{
			title: "Discount Percentage",
			render: (_, record, index) => {
				return (
					<>
						<InputNumber
							disabled={!(formState === "add" || formState === "edit")}
							size="small"
							min="0"
							onStep={(value, info) =>
								updateDiscountPercent("up", record.item_code, value, index)
							}
							value={parseFloat(record.discount_percent || 0).toFixed(2)}
							onChange={(value) =>
								updateDiscountPercent("up", record.item_code, value, index)
							}

						/>
						{/* {parseFloat(record.discount_percent || 0).toFixed(2)} % */}
					</>
				)
			},
		},
		{
			title: "Discount Amount",
			render: (_, record) => parseFloat(record.discount || 0).toFixed(2),
		},
		{
			title: "Tax Percentage",
			render: (_, record, index) => {
				return (
					<>
						<InputNumber
							disabled={!(formState === "add" || formState === "edit")}
							size="small"
							min="0"
							onStep={(value, info) =>
								updateTaxPercent("up", record.item_code, value, index)
							}
							value={parseFloat(
								record.tax_percent === null ? 0 : `${record.tax_percent}`
							).toFixed(2)}
							onChange={(value) =>
								updateTaxPercent("up", record.item_code, value, index)
							}

						/>
						{/* {parseFloat(0).toFixed(2)} % */}
					</>
				)
			},
		},
		{
			title: "Tax Amount",
			render: (_, record) => parseFloat(record.tax_amount || 0).toFixed(2),
		},

		{
			title: "Net Amount",
			render: (_, record) => parseFloat(record.net_amount || 0).toFixed(2) || 0,
		},
		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record, index) => (
				<Tooltip title="Delete" color={"white"}>
					{/* {formState === "edit" ? ( */}
					{
						formState !== "formView" && formState !== "edit" ? (
							<Popconfirm
								title={`Are you sure to delete the item ${record.item_id}?`}
								onConfirm={() => handleDeleteOrder(record)}
							>
								<DeleteOutlined className={`delete-Button`} />
							</Popconfirm>
						) : ""
					}


				</Tooltip>
			),
		},
	];

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}

	const glImpcatCols = [
		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						{currencySymbol} {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const updateQuantity = (type, id, value) => {
		let arr = [...visibleItems];
		value = parseInt(value);
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				let balance = parseInt(item.balance_quantity);
				if (value === 0) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Qty cannot be 0, Delete line item",
					});
					item.quantity = item.quantity;
					return item;
				}
				if (value > balance) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Maxiumum Quantity Exceed",
					});
				}
				if (type === "up") {
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.discount =
						(item.quantity * item.rate * item.discount_percent) / 100;
					item.tax_amount =
						(item.quantity * item.rate * item.tax_percent) / 100;
					item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
					return item;
				}

				if (type === "down") {
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.discount =
						(item.quantity * item.rate * item.discount_percent) / 100;
					item.tax_amount =
						(item.quantity * item.rate * item.tax_percent) / 100;
					item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
					return item;
				}
			} else {
				return item;
			}
		});
		setVisibleItems(filter);
	};

	const updateRate = (type, id, value, index) => {
		let arr = [...visibleItems];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				item.rate = value;
				item.discount = ((value * item.quantity) * item.discount_percent) / 100;
				item.tax_amount = ((value * item.quantity) * item.tax_percent) / 100;
				item.net_amount = item.rate * item.quantity - parseFloat(item.discount || 0) + parseFloat(item.tax_amount || 0);
				return item;
			} else {
				return item;
			}
		});
		setVisibleItems(filter);
	}

	const updateDiscountPercent = (type, id, value, index) => {
		let arr = [...visibleItems];
		value = parseInt(value);

		let rate_and_qty = arr[index].rate * arr[index].quantity
		arr[index].discount_percent = value;
		arr[index].discount = (rate_and_qty * value) / 100;
		arr[index].net_amount = rate_and_qty - arr[index].discount || 0 + arr[index].tax_amount || 0;
		setVisibleItems(arr);
	}

	const updateTaxPercent = (type, id, value, index) => {
		let arr = [...visibleItems];
		value = parseInt(value);
		let rate_and_qty = arr[index].rate * arr[index].quantity
		arr[index].tax_percent = value;
		arr[index].tax_amount = (rate_and_qty * value) / 100;
		arr[index].net_amount = rate_and_qty - arr[index].discount + arr[index].tax_amount;
		setVisibleItems(arr);
	}

	const onFinish = async (e) => {
		if (visibleItems.length === 0) {
			return Swal.fire({
				icon: "error",
				title: "Add Line Items",
				text: "The line item shouldn't be empty.",
			});
		}
		setLoading(true);
		setDisable(true);
		submit(false);
		await form.validateFields().then((values) => {
			if (formState === "edit") {
				dispatch(updateReceiptHeader(token, values, formData)).then((res) => {
					dispatch(getOneReceiptLineDetail(token, return_id)).then((res) => {
						if (res.type === "GET_ONE_RECEIPT_LINE_SUCCESS") {
							setVisibleItems(res.payload);
						} else if (res.type === "GET_ONE_RECEIPT_LINE_ERROR") {
							notification("warning", "No Items Found", res?.payload?.data.data);
						}
					});
					if (res.type === "UPDATE_RECEIPT_HEADER_SUCCESS") {
						let items = visibleItems;
						dispatch(
							updateReceiptLine(
								token,
								formData?.receipt_header_id,
								items.map((item) => {
									delete item.balance_quantity;
									delete item.amount;
									delete item.item_code;
									delete item.parent_item_id;
									delete item.style_name;
									delete item.wash_name;
									delete item.gender_category_name;
									delete item.size;
									delete item.inseam;
									delete item.deleted_by;
									delete item.deleted_date;
									return item;
								})
							)
						).then((res) => {
							if (res.type === "UPDATE_RECEIPT_LINE_SUCCESS") {
								setLoading(false);
								setDisable(false);
								changeState("formView")
								notification("success", "Receipt Updated Successfully", res?.payload?.data);
							} else if (res.type === "UPDATE_RECEIPT_LINE_ERROR") {
								setLoading(false);
								setDisable(false);
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					}
				});
			} else {
				dispatch(addReceiptHeader(token, values, formData)).then((res) => {
					let headerId = res.payload.receipt_header_id;
					if (res.type === "ADD_RECEIPT_HEADER_SUCCESS") {
						let items = visibleItems.map((items) => {
							return {
								...items,
								receipt_header_id: res.payload.receipt_header_id,
							};
						});
						dispatch(
							addReceiptLine(
								token,
								items.map((item) => {
									delete item.balance_quantity;
									delete item.amount;
									delete item.return_header_id;
									delete item.parent_item_id;
									delete item.created_by;
									delete item.created_date;

									delete item.brand_name;
									delete item.gender_category_name;
									delete item.size;
									delete item.inseam;

									return item;
								})
							)
						).then((res) => {
							let receiptLinesData = [];
							if (res.type === "ADD_RECEIPT_LINE_SUCCESS") {
								formData.receipt_header_id = headerId;
								dispatch(getOneReceiptLineDetail(token, headerId)).then((res) => {
									if (res.type === "GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS") {
										setLoading(false);
										receiptLinesData.push(res.payload);
										dispatch(addCreditHeader(token, values, formData)).then((res) => {
											if (res.type === "ADD_CREDIT_HEADER_SUCCESS") {
												dispatch(getOneReceiptHeaderDetail(token, headerId)).then((v) => {
													if (v.type === "GET_ONE_RECEIPT_HEADER_SUCCESS") {
														setFormData(v.payload);
													}
												})
												form.setFieldsValue({ creaditMemo: res?.payload.note_header_no })
												let items = receiptLinesData[0].map((items) => {
													return {
														...items,
														note_header_id: res.payload.note_header_id,
													};
												});
												dispatch(
													addCreditLine(
														token,
														items.map((item) => {
															delete item.balance_quantity;
															delete item.amount;
															delete item.return_header_id;
															delete item.parent_item_id;
															delete item.created_by;
															delete item.created_date;
															delete item.receipt_header_id;
															delete item.order_line_id;
															delete item.style_name;
															delete item.wash_name;
															delete item.gender_category_name;
															delete item.size;
															delete item.inseam;
															item.net_amount = item.gross_amount;
															return item;
														})
													)
												).then((res) => {
													if (res.type === "ADD_CREDIT_LINE_SUCCESS") {
														notification("success", "Receipt Created", res?.payload?.data);
														setLoading(false);
														setDisable(false);
													} else if (res.type === "ADD_CREDIT_HEADER_ERROR") {
														setLoading(false);
														setDisable(false);
														Swal.fire({
															icon: "error",
															title: "Oops...",
															text: res?.payload?.data?.data,
														});
													}
												});
											} else if (res.type === "UPDATE_CREDIT_HEADER_ERROR") {
												setLoading(false);
												setDisable(false);
												Swal.fire({
													icon: "error",
													title: "Oops...",
													text: res?.payload?.data?.data,
												});
											}
										});
									} else if (res.type === "GET_ONE_RECEIPT_LINE_DETAIL_ERROR") {
										setLoading(false);
										setDisable(false);
										notification("warning", "No Items Found", res?.payload?.data.data);
									}
								});
								notification("success", "Receipt Created", res?.payload?.data);
								setLoading(false);
								setDisable(false);
								changeState("formView")
								history.push(`/item-receipt?mode=edit&return_id=${headerId}`);
							} else if (res.type === "ADD_RECEIPT_HEADER_ERROR") {
								setLoading(false);
								setDisable(false);
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "UPDATE_RECEIPT_HEADER_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};



	const handleTab = (key) => {
		if (key === "logs") {
			dispatch(getSystemLog(token, { table_name: "scm_item_receipt_header", record_id: return_id }));
		}
	};


	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const summary = () => {
		let orderSummary = visibleItems;
		let amount = 0;
		let quantity = 0;
		let discount = 0;
		let tax = 0;
		let shippingCost = form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0;
		if (orderSummary.length > 0) {
			orderSummary?.forEach(function (item) {
				if (item.hasOwnProperty("rate")) {
					amount += parseFloat(item.rate) * parseFloat(item.quantity);
				}
				if (item.hasOwnProperty("quantity")) {
					quantity += parseFloat(item.quantity);
				}
				tax += parseFloat(item.tax_amount);
				discount += parseFloat(item.discount);
			});
		}
		return {
			quantity: quantity,
			amount: numeral(amount).format("0,0.00"),
			discount: numeral(discount).format("0,0.00"),
			tax: numeral(tax).format("0.00"),
			shippingCost: numeral(shippingCost).format("0,0.00"),
			net: numeral(amount - (isNaN(discount) ? 0 : discount) + tax).format("0.00") || 0,
		};
	};

	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			setLoading(false)
			if (r.payload.data.length > 0) {
				setInvoiceDate(moment())
				form.setFieldsValue({
					receiptDate: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setInvoiceDate(moment(e))
				form.setFieldsValue({
					receiptDate: e,
				});
			}
		});
	}

	const addcreaditHandler = () => {
		dispatch(addCreditHeader(token, formData, formData)).then((res) => {
			if (res.type === "ADD_CREDIT_HEADER_SUCCESS") {
				dispatch(getOneReceiptHeaderDetail(token, formData.receipt_header_id)).then((v) => {
					if (v.type === "GET_ONE_RECEIPT_HEADER_SUCCESS") {
						setFormData(v.payload);
					}
				})
				form.setFieldsValue({ creaditMemo: res?.payload.note_header_no })
				let items = visibleItems.map((items) => {
					return {
						...items,
						note_header_id: res.payload.note_header_id,
					};
				});
				dispatch(
					addCreditLine(
						token,
						items.map((item) => {
							delete item.balance_quantity;
							delete item.amount;
							delete item.return_header_id;
							delete item.parent_item_id;
							delete item.created_by;
							delete item.created_date;
							delete item.receipt_header_id;
							delete item.order_line_id;
							delete item.style_name;
							delete item.wash_name;
							delete item.gender_category_name;
							delete item.size;
							delete item.inseam;
							item.net_amount = item.gross_amount;
							return item;
						})
					)
				).then((res) => {
					if (res.type === "ADD_CREDIT_LINE_SUCCESS") {
						notification("success", "Receipt Created", res?.payload?.data);
						setLoading(false);
						setDisable(false);
					} else if (res.type === "ADD_CREDIT_HEADER_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			} else if (res.type === "UPDATE_CREDIT_HEADER_ERROR") {
				setLoading(false);
				setDisable(false);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: res?.payload?.data?.data,
				});
			}
		});
	}

	return (
		<>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{loading && (
					<div className="loaderDiv">
						<Loader type="Grid" color="#212121" height={40} width={40} />
					</div>
				)}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-15">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<div className="firstSectionRo d-none">
									<Form.Item name="receiptHeaderId" label="receiptHeaderId" labelAlign="left" labelCol={{ span: 6 }}>
										<Input size="small" disabled className="customInputs" />
									</Form.Item>
								</div>
								<Brow>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="receiptNo" label="Item Receipt #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" disabled className="customInputs" />
											</Form.Item>
										</div>
									</Bcol>
									{ }
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
													showSearch
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													disabled={!(formState === "add" || formState === "edit" || formState === "Return")}
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Receipt Date" name="receiptDate" labelAlign="left" labelCol={{ span: 6 }}>
												<DatePicker
													size="small"
													defaultValue={moment(invoiceDate)}
													format="MM-DD-YYYY"

													allowClear
													disabled={!(formState === "add" || formState === "edit" || formState === "Return")}
													className="dateInput customInputs"
													onChange={(e) => {
														form.setFieldsValue({
															receiptDate: e,
														});
														setInvoiceDate(moment(e));
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="customerName" label="Customer" labelAlign="left" labelCol={{ span: 6 }}>
												<Input disabled size="small" tabIndex={1} className="customInputs"

												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs"
													disabled={!(formState === "add" || formState === "edit" || formState === "Return")}
												/>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="asnNo" label="Lot #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs"
													disabled={!(formState === "add" || formState === "edit" || formState === "Return")}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="RaNumber" label="RA #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input disabled size="small" tabIndex={1} className="customInputs"

												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="RaApprovalNumber" label="RA Approval#" labelAlign="left" labelCol={{ span: 6 }}>
												<Input disabled size="small" tabIndex={1} className="customInputs"

												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="invoiceNo" label="Invoice #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input disabled size="small" tabIndex={1} className="customInputs"
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="creaditMemo" label="Credit Note #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input disabled size="small" tabIndex={1} className="customInputs"
												/>
											</Form.Item>
											{
												!formData?.note_header_no ?
													<Button onClick={addcreaditHandler}>+</Button>
													: null
											}
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Location" name="location" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().location}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
													showSearch
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().location}
													disabled={!(formState === "add" || formState === "edit" || formState === "Return")}
													onChange={(value) => {
														form.setFieldsValue({
															location: value,
														});
													}}
												>
													{adminLocation?.payload?.data.map((item) => {
														return (
															<Option key={item.location_id} value={item.location_id}>
																{item.location_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Brand" name="brand_id" labelAlign="left" labelCol={{ span: 6 }}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
													showSearch
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
													loading={brand.loading}
													disabled
													onChange={(value) => {
														form.setFieldsValue({
															brand: value,
														});
													}}
												>
													{brand?.payload?.data.map((item) => {
														return (
															<Option key={item.brand_id} value={item.brand_id}>
																{item.brand_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Reciept Summary</div>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Quantity:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{summary().quantity}</p>
											</Bcol>
										</Brow>

										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Sub Total:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{currencySymbol}{summary().amount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Less Discount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{currencySymbol}{summary().discount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Add Tax:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{currencySymbol}{summary().tax}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Net Amount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{currencySymbol}{summary().net}</p>
											</Bcol>
										</Brow>
									</div>
								</div>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Product Information" key="1">
							{visibleItems.length > 0 && (
								<Table
									type={"company"}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									dataSource={visibleItems}
									columns={columns}
									pagination={false}
								/>
							)}
						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact?.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<div className="tabs">
									{systemLog?.payload?.data?.length > 0 && <Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={systemLog?.payload?.data}
										dataSource={[]}
										col={renderColumns()}
										load={systemLog?.loading}
										paginate
									/>}
								</div>
							</TabPane>}
					</Tabs>
				</div>
			</Form>
		</>
	);
}