import {
    ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR, GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
    GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR, GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST,
    DELETE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR, DELETE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, DELETE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST,
    UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
    CUSTOMER_SELECTED_INVOICE_REQUEST,
    CUSTOMER_SELECTED_INVOICE_SUCCESS,
    CUSTOMER_SELECTED_INVOICE_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
import numeral from "numeral";

const axios = require("axios");

export const addCustomerPaymentHeader = (token, formData, customerId, companyId) => (dispatch) => {

    dispatch({ type: ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST });
    let newData = {
        company_id: companyId,
        payment_date: moment(formData.payment_date).format("MM-DD-YYYY"),
        customer_id: customerId,
        gl_period_id: formData.postingPeriod,
        payment_term_id: formData.payment_term_id,
        memo: formData.memo,
        payment_type_id: formData.payment_type_id,
        credit_account_id: formData.credit_account_id,
        instrument_no: formData.instrument_no,
        payment_amount: numeral(formData.payment_amount).format("0.00"),
        applied: formData.applied,
        to_apply: formData.to_apply,
        un_applied: formData.un_applied,
        currency_id: formData.currency_id,
        gender_id: formData.gender_id,
        debit_account_id: formData.debit_account_id,
        remarks: formData.remarks,
        brand_id: formData.brand_id,
    }
    return axios({
        method: "POST",
        url: `${BASEURL}create/payCustomerPaymentHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getAllCustomerPaymentHeaders = (token, data) => (dispatch) => {
    dispatch({ type: GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/payCustomerPaymentHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            payment_header_no: data.paymentNo === "" ? null : data.paymentNo,
            from_date: moment(data.from_date).format("MM-DD-YYYY"),
            to_date: moment(data.to_date).format("MM-DD-YYYY"),
            credit_account_name: data.arAccount === undefined || data.arAccount === "" ? null : data.arAccount,
            debit_account_name: data.debitAccount === undefined || data.debitAccount === "" ? null : data.debitAccount,
            gl_period_name: data.postingPeriod === undefined || data.postingPeriod === "" ? null : data.postingPeriod,
            ar_account_id: data.arAccount === undefined || data.arAccount === "" ? null : data.arAccount,
            payment_term_id: data.paymentTerm === undefined || data.paymentTerm === "" ? null : data.paymentTerm,
            payment_type_id: data.paymentType === undefined || data.paymentType === "" ? null : data.paymentType,
            payment_mode_id: data.paymentMethod === undefined || data.paymentMethod === "" ? null : data.paymentMethod,
            gender_id: data.genderCategory === undefined || data.genderCategory === "" ? null : data.genderCategory,
            customer_name: data.customer === undefined || data.customer === "" ? null : data.customer,
            brand_id: data.brand_id === undefined || data.brand_id === "" ? null : data.brand_id,
        },
    })
        .then((response) => {
            return dispatch({
                type: GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCustomerPaymentHeader = (token, id) => (dispatch) => {

    dispatch({ type: GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/payCustomerPaymentHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const updateCustomerPaymentHeader = (token, formData, itemTransferId, companyId) => (dispatch) => {

    let newData = {
        company_id: companyId,
        payment_header_no: formData.payment_header_no,
        payment_date: moment(formData.payment_date).format("MM-DD-YYYY"),
        customer_id: formData.customer_id,
        gl_period_id: formData.postingPeriod,
        payment_term_id: formData.payment_term_id,
        memo: formData.memo,
        payment_type_id: formData.payment_type_id,
        credit_account_id: formData.credit_account_id,
        debit_account_id: formData.debit_account_id,
        brand_id: formData.brand_id,
        instrument_no: formData.instrument_no,
        currency_id: formData.currency_id,
        gender_id: formData.gender_id,
        remarks: formData.remarks,
        payment_amount: numeral(formData.payment_amount).format("0.00")
    }
    dispatch({ type: UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/payCustomerPaymentHeader/${itemTransferId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
        
    })
        .then((response) => {

            return dispatch({
                type: UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
                payloadUpdate: response?.data?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
                payloadUpdate: error.response,
            });
        });
};

export const deleteCustomerPaymentHeader = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/payCustomerPaymentHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: DELETE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
                payload: response?.data.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCustomerSelectedinvoice = (token, data) => (dispatch) => {
    dispatch({ type: CUSTOMER_SELECTED_INVOICE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerselectedinvoice`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: data
    })
        .then((response) => {
            return dispatch({
                type: CUSTOMER_SELECTED_INVOICE_SUCCESS,
                payload: response?.data.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: CUSTOMER_SELECTED_INVOICE_ERROR,
                payload: error.response,
            });
        });
};
