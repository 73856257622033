import {
    GET_INSEAM_ERROR,
    GET_INSEAM_REQUEST,
    GET_INSEAM_SUCCESS,
    ADD_INSEAM_ERROR,
    ADD_INSEAM_REQUEST,
    ADD_INSEAM_SUCCESS,
    DELETE_INSEAM_REQUEST,
    DELETE_INSEAM_SUCCESS,
    DELETE_INSEAM_ERROR,
    UPDATE_INSEAM_ERROR,
    UPDATE_INSEAM_REQUEST,
    UPDATE_INSEAM_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getInseam = (token, menu_id) => (dispatch, getState) => {
    const { inseam } = getState();
    // if data is present in store return promise resolved
    if (inseam?.payload?.data?.length) {
        return Promise.resolve();
    }
    // if data is not present in store then fetch from server
    dispatch({ type: GET_INSEAM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/inseam`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_INSEAM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_INSEAM_ERROR,
                payload: error.response,
            });
        });
};

export const addInseam = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_INSEAM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/inseam`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { inseam_name: data.inseamName, is_active: data.check, seq: data.seq, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_INSEAM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_INSEAM_ERROR,
                payload: error.response,
            });
        });
};

export const updateInseam = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_INSEAM_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/inseam/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { inseam_name: data.inseamName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_INSEAM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_INSEAM_ERROR,
                payload: error.response,
            });
        });
};

export const deleteInseam = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_INSEAM_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/inseam/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_INSEAM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_INSEAM_ERROR,
                payload: error.response,
            });
        });
};
