import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, Select, Button, Table } from "antd";
import { Row as Brow, Col as Bcol, TabPane } from "reactstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import moment from "moment";
import Swal from "sweetalert2";
import { ReadCookie } from "../../utils/readCookie";
import { addPurchaseBill, addCreateBill } from "../../redux/actions/purchaseBill.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { TabTitle } from "../../utils/GeneralFunction";
import notification from "../../utils/notification";
import { currencySymbol } from "../../utils/constants";
import { CSVLink } from "react-csv";
import { getBrands } from "../../redux/actions/brand.action";

export default function PurchaseBill({ token }) {
  TabTitle("PurchaseBill")
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { adminLocation, brand } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [LocationName, setLocationName] = useState('');

  useEffect(() => {
    dispatch(getAdminLocation(token, menuId));
    dispatch(getBrands(token));
  }, [dispatch, menuId, token]);

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({ icon: "error", title: "Oops...", text: errors[0]?.errors[0] });
  };

  const moveToErp = async (e) => {
    setLoading(true)
    const newArr = data.map(v => ({ ...v, location_id: form.getFieldValue().location, delivery_date: moment(form.getFieldValue().deliveryDate).format("MM-DD-YYYY") }))
    await dispatch(addCreateBill(token, newArr, form.getFieldValue().brand_id)).then((res) => {
      if (res?.payload?.data?.data === "Bill Already Exist") {
        Swal.fire({ icon: "error", title: "Oops...", text: "Bill Already Exist" })
      } else if (res?.type === "ADD_CREATE_BILL_ERROR") {
        Swal.fire({ icon: "error", title: "Oops...", text: "Unexpected Server Error Occurred." })
      }
      if (res?.payload?.data === "Bill Created Successfully") {
        notification("success", "Bill", res?.payload?.data);
      }
      setLoading(false)
    })
  }

  const renderStateColumn = (str) => {
    return <span>{str}</span>;
  };

  const LinesColumns = [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text, object, index) => index + 1
    },
    {
      title: "Invoice ID",
      dataIndex: "Invoice_ID",
    },
    {
      title: "Invoice Date",
      dataIndex: "Invoice_Date",
      render: (text) => moment(text).format("MM-DD-YYYY")
    },
    {
      title: "Item Code",
      dataIndex: "Product2",
    },

    {
      title: "Size",
      dataIndex: "ActualSize",
    },
    {
      title: "Inseam",
      dataIndex: "Inseam",
    },
    {
      title: "Quantity",
      dataIndex: "ActualSizeQty",
    },
    {
      title: "Rate",
      dataIndex: "Rate",
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
    },
    {
      title: "Memo",
      dataIndex: "Memo",
    },
    {
      title: "Location",
      dataIndex: 'LocationValue',
      key: 'LocationValue',
      render: () => renderStateColumn(LocationName),
    },
    {
      title: "VIA",
      dataIndex: "VIA",
    },
  ];

  function clearData(value, type, name) {
    if (data.length > 0) setData([])
    if (name === 'location') {
      setLocationName(type.children)
    }
  }

  const onFinish = async (e) => {
    form.validateFields().then(async values => {
      await dispatch(
        addPurchaseBill(token, values.billNo, values.deliveryDate, values.rate, values.via, values.location, values.brand_id)
      ).then((res) => {
        if (res?.payload?.data?.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No Data",
          });
        } else {
          setData(res?.payload?.data);
        }
      });
    })

  };

  const downloadExcelFileData = (data) => {
    if (data) {
      return data?.map((v) => {
        return {
          "Invoice ID": v.Invoice_ID,
          "Invoice Date": moment(v.Invoice_Date).format("MM-DD-YY"),
          "Product2": v.Product2,
          "Actual Size": v.actualsize,
          "Inseam": v.Inseam,
          "Actual Size Qty": v.ActualSizeQty,
          "Rate": v.Rate,
          "Amount": v.AMOUNT,
          "Memo": v.Memo,
          "Location Value": LocationName,
          "VIA": v.VIA,
        };
      })
    }
  }

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"Purchase Bill"} />
        <div className="bodyStyle">
          <Form onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}>
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <Form.Item
                      name="billNo"
                      label="Bill No"
                      labelCol={{ span: 7 }}
                      rules={[{ required: true, message: "Bill No is required" }]}
                    >
                      <Input
                        tabIndex={18}
                        size="small"
                        className="customInputs"
                        onChange={(value, type) => clearData(value, type)}
                      />
                    </Form.Item>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="slc">
                      <Form.Item
                        label="Delivery Date"
                        name="deliveryDate"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Delivery Date is required" }]}
                      >
                        <DatePicker
                          size="small"
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                          onChange={(value, type) => clearData(value, type)}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="brand_id"
                        label="Brand"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Brand ID is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          autoComplete={"nope"}
                          loading={brand?.loading}
                          onChange={(value, type) => clearData(value, type)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {brand?.payload?.data.map((val, index) => {
                            return (
                              <Option key={index} value={val.brand_id}>
                                {val.brand_name}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
                <Brow>
                  <Bcol lg={6} xl={4}>
                    <div className="slc">
                      <Form.Item
                        name="via"
                        label="VIA"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "VIA is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="styleInput w-100"
                          onChange={(value, type) => clearData(value, type)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option value={"AIR"}>AIR</Option>
                          <Option value={"SEA"}>SEA</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4}>
                    <div className="slc">
                      <Form.Item
                        name="rate"
                        labelAlign="left"
                        label="Rate"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Rate is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="styleInput w-100"
                          onChange={(value, type) => clearData(value, type)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option value={"FOB"}>FOB</Option>
                          <Option value={"LDP"}>LDP</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="location"
                        label="Location"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Location is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          autoComplete={"nope"}
                          loading={adminLocation?.loading}
                          onChange={(value, type) => clearData(value, type, 'location')}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {adminLocation?.payload?.data.map((val, index) => {
                            return (
                              <Option key={index} value={val.location_id}>
                                {val.location_name}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
                <Bcol lg={6} xxl={1}>
                  <Button
                    htmlType="submit"
                    className="btn btn-success"
                    style={{ marginLeft: "13px" }}
                  >
                    Submit
                  </Button>
                </Bcol>
              </Bcol>
            </Brow>
          </Form>
          {data && data.length > 0 ? (
            <>
              <div style={{ display: "flex", justifyContent: "right", alignItems: 'center', paddingRight: 12, marginBottom: 10 }}>
                <Button
                  style={{ border: "1px solid black" }}
                  htmlType="submit"
                  onClick={moveToErp}
                >
                  Move To ERP
                </Button>
                <CSVLink
                  style={{ border: "1px solid black", padding: 4, marginLeft: 5 }}
                  filename={"Purchase-Bill.csv"}
                  data={downloadExcelFileData(data) || []}
                  onClick={() => {
                  }}
                >
                  Download Excel
                </CSVLink>
              </div>
              <TabPane tab="Data">
                <div className="overflow"
                  style={{
                    overflowY: "scroll",
                    height: "57vh",
                    paddingLeft: 12
                  }}>
                  <Table
                    type={"company"}
                    loading={loading}
                    scroll={{ x: "max-content" }}
                    size="small"
                    rowKey={'index'}
                    style={{ paddingTop: 10 }}
                    dataSource={data}
                    bordered
                    columns={LinesColumns}
                    pagination={false}
                    summary={pageData => {
                      let sumQty = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += currentValue.ActualSizeQty
                      }, 0);
                      let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += currentValue.AMOUNT
                      }, 0);
                      return (
                        <>
                          <Table.Summary.Row >
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p>{sumQty.toLocaleString("en-US")}</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p>{currencySymbol} {sumAmount.toLocaleString("en-US")}</p>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                        </>
                      );

                    }}

                  />
                </div>
              </TabPane>
            </>
          ) :
            null}
        </div>
      </div>
    </div>
  );
}
