import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';

const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}) => {
    return (
        <>
            <Input
                autoFocus
                allowClear
                className='pop-search-gen pop-170'
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                    confirm();
                }}
                onBlur={() => {
                    confirm();
                }}
            />
        </>
    );
}

const filterIcon = (filtered) =>
    filtered ? <FilterFilled /> : <SearchOutlined />

const filterHelper = (key, type = 'str') => {
    return {
        filterDropdown,
        filterIcon,
        onFilter: (value, record) =>
            (record[key] || '').toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            if (type === 'num') {
                return Number(a[key]) - Number(b[key]);
            }
            if (type === 'date') {
                return moment(a[key]).unix() - moment(b[key]).unix()
            }
            if (type === 'str') {
                if ((a[key] !== null) & (b[key] !== null)) {
                    return (a[key] || '').localeCompare(b[key]);
                }
            }
        },
    }
}

export const sorterHelper = (key, type = 'str') => {
    return {
        sorter: (a, b) => {
            if (type === 'num') {
                return Number(a[key]) - Number(b[key]);
            }
            if (type === 'date') {
                return moment(a[key]).unix() - moment(b[key]).unix()
            }
            if (type === 'str') {
                if ((a[key] !== null) & (b[key] !== null)) {
                    return (a[key] || '').localeCompare(b[key]);
                }
            }
        },
    }
}

export default filterHelper