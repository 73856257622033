import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import "./pickticket.css"
import {
    Button,
    Form,
    Input,
    Select,
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
import Swal from "sweetalert2";
import notification from '../../utils/notification';
import { updateAdminUser } from "../../redux/actions/adminUser.actions";
var CryptoJS = require("crypto-js");
const { Option } = Select;

const ForgetPassword = () => {
    TabTitle("Change Password")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const dispatch = useDispatch();
    const token = ReadCookie("token");
    const Id = ReadCookie("id");

    const onFinish = async () => {
        await form.validateFields().then((values) => {
            let user_password = CryptoJS.AES.encrypt(values.changePassword, process.env.REACT_APP_DECRYPT_KEY).toString()
            if (values.changePassword === values.confChangePassword) {
                dispatch(updateAdminUser(token, { first_name: null, last_name: null, email: null, user_password: user_password, user_type: null, mobile_no: null, login_id: null, is_first_login: false }, Id)).then((res) => {
                    
                    if (res.type === "UPDATE_ADMIN_USER_SUCCESS") {
                        notification("success", "Updated", "Password updated successfully")
                        form.resetFields(['changePassword', 'confChangePassword']);
                    } else {
                        notification("error", "Not updated", "Something went wrong")
                    }
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "OOPS",
                    text: `The Password should be same!`,
                });
            }
        })
    }


    return (
        <>
            <div className="styleContainer">
                <div className="styleBody h-100" >
                    <PageHeader
                        title={"Change Password"}
                        minimize={setMinimize}
                        check={minimize}
                    />
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "40%", minWidth: "650px", padding: 40 }}>
                            <div className="formPassword login-form-main" style={{ width: "100%", margin: 0 }} >
                                <div className="heading login-title-area">
                                    <h3 className="login-hd">Change Password</h3>
                                </div>
                                <div className="form-inputs ">
                                    <Form className="ant-login-form" form={form} name="control-hooks">
                                        <div>
                                            <Form.Item
                                                name="changePassword"
                                                label="Change Password"
                                                labelAlign="left"
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your new password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="input"
                                                    size="small"
                                                    type={"password"}
                                                    placeholder="Password"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div >
                                            <Form.Item
                                                name="confChangePassword"
                                                label="Confirm Change Password"
                                                labelAlign="left"
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please confirm your new password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="input"
                                                    size="small"
                                                    type={"password"}
                                                    placeholder="Password"
                                                />
                                            </Form.Item>
                                        </div>
                                        <Button
                                            className="loginButton" htmlType="submit" type="primary" style={{ left: "86px" }}
                                            onClick={onFinish}
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword;

