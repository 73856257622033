import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useSelector, useDispatch } from 'react-redux';
import Tables from "../../components/Table/Tables"
import PageHeader from '../../components/PageHeader/PageHeader';
import { logColumns, brandColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";
import "../Style/style.css";
import notification from '../../utils/notification'
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
import { getBrands, addBrand, deleteBrand, updateBrand } from '../../redux/actions/brand.action';

const { TabPane } = Tabs;

const Brand = ({ token }) => {
    TabTitle("Brand")
    const dispatch = useDispatch()
    const { brand, userRights, log } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [brandName, setBrandName] = useState('');
    const [formState, setFormState] = useState('view');
    const [form] = Form.useForm();
    const brandNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [brandObj, setBrandObj] = useState(null);
    const [check, setCheck] = useState(true);
    const id = ReadCookie('menuId')

    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {
        dispatch(getBrands(token))
    }, [token, id, dispatch])

    useEffect(() => {
        dispatch(getLog(token, { scm_brand: "scm_brand" }))
    }, [dispatch, token])

    useEffect(() => {
        if (formState === "add") {
            brandNameInput.current.focus();
            form.setFieldsValue({
                brand: null,
            })
            setCheck(true)
            setBrandName("")
            setBrandObj(null)
        }
    }, [formState]);

    useEffect(() => {
        if (brandObj) {
            setFormState('view')
            form.setFieldsValue({
                id: brandObj.brand_id,
                brand: brandObj.brand_name,
                active: brandObj.is_active,
            });
            setBrandName(brandObj.brand_name)
            setCheck(brandObj.is_active)
        }
    }, [brandObj]);

    useEffect(() => {
        if (brandName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [brandName])

    const handleDelete = () => {
        if (brandObj) {
            dispatch(deleteBrand(token, brandObj.brand_id)).then((res) => {
                if (res.type === "DELETE_BRAND_SUCCESS") {
                    notification("success", "Country Deleted", "Country Deleted Successfully!");
                }
                if (res.type === "DELETE_BRAND_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    brand: ""
                })
                setBrandName("")
                setBrandObj(null)
                setFormState('view')
                dispatch(getBrands(token))
            })
        }
        setFormState("view")
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addBrand(token, { brand_name: brandName, is_active: check }, id)).then((res) => {
                if (res.type === "ADD_BRAND_ERROR") {
                    notification("error", "Not Created", res?.payload?.data?.data)
                }
                if (res.type === "ADD_BRAND_SUCCESS") {
                    notification("success", "Brand Created", "Brand created successfully!");
                    form.setFieldsValue({
                        country: ""
                    })
                    setFormState("")
                    setBrandName("")
                    setBrandObj(null)
                }
                form.setFieldsValue({
                    country: ""
                })
                setFormState("")
                setBrandName("")
                setBrandObj(null)
                dispatch(getBrands(token))
            })
        }
        if (formState === "edit") {
            dispatch(updateBrand(token, { brand_name: brandName, is_active: check }, brandObj.brand_id)).then((res) => {
                if (res.type === "UPDATE_BRAND_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_BRAND_SUCCESS") {
                    notification("success", "Brand Updated", "Record updated successfully")
                }
                dispatch(getBrands(token))
            })
        }
    }   

    const filterReset = () => {
        setFormState("view")
        setBrandObj(null)
        setCheck(true)
        form.setFieldsValue({
            brand: null,
        })
        setBrandName(null)
    }

    return <div className='styleContainer'>
        <div className='styleBody h-100' >
            <PageHeader title={"Brand"} minimize={setMinimize} check={minimize} />
            {
                !minimize &&
                <div className='bodyStyle' >
                    <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} reset={filterReset} disable={disable} save={formSave} onDelete={handleDelete} /></div>
                    <div className='styleContent' >
                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                <div className='styleTable' >
                                    <Tables type="brand" formState={formState} scrollX={"20rem"} brandObj={setBrandObj} source={brand?.payload?.data} col={brandColumns} paginate scroll={602} perPage={25} load={brand.loading} rowKey="country_id" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                <div className='styleForm'  >
                                    <div className='testStyle' >
                                        <Form
                                            form={form}
                                            name="basic"
                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {brandObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            initialValue={form.getFieldValue().id}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />
                                                        </Form.Item>
                                                    </div>
                                                }
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Brand"
                                                        name="brand"
                                                        initialValue={form.getFieldValue().brand}
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={brandNameInput} onChange={(e) => setBrandName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='modalStyle' >
                                    </div>
                                    <Divider />
                                    <div className='styleTab' >
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered   >
                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </div>
    </div>
};

export default Brand