import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRegionCategory } from "../../redux/actions/regionCategoryById";
import { loadRegionCategory } from "../../redux/actions/regionCategory";
import { loadSalesPersons } from "../../redux/actions/salesPerson";
import { getRights } from "../../redux/actions/userRights.action";
import { getGender } from "../../redux/actions/gender.action";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Row, Col, Checkbox, Tooltip, Popconfirm } from "antd";
import { loadRegion } from "../../redux/actions/region";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import Tables from "../../components/Table/Tables";
import { Row as Brow, Col as Bcol } from "reactstrap";

const { Option } = Select;

export default function CategorySection({ customer_id, token, formType, setCategoryData, categoryData, deletedCategoryLine,
  setDeletedCategoryLine }) {
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const [genderForm, setGenderForm] = useState({});
  const [regionCatForm, setRegionCatForm] = useState({});
  const [salesPerson, setSalesperson] = useState({});
  const [visible, setVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [EmployeeID, setEmployeeid] = useState(null);
  const [RegionID, setRegionsid] = useState(null);
  const [check, setCheck] = useState(true);
  const [editableRowIndex, setEditableRowIndex] = useState(null);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {

        }
      }
    });
  }, []);

  const categoryColumns = [
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_id",
      width: 25
    },
    {
      title: "Category Id",
      dataIndex: "sp_region_category_id",
      key: "sp_region_category_id",
      width: 25

    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_id",
      width: 20

    },

    {
      title: "Sales person",
      dataIndex: "salesperson_name",
      key: "sales_person_id",
      width: 25

    },

    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 25,
      render: (text, record) => {
        return <p>{record.is_active ? "True" : "False"}</p>
      }

    },
    {
      title: "Action",
      key: "action",
      width: 25,
      render: (text, record, index) => {
        return (
          <>
            {['add', 'edit'].includes(formType) &&
              <>
                <Tooltip title="Edit" color={"white"}>
                  <EditOutlined
                    className={`edit-Button`}
                    onClick={() => hanldeSubItems(record, index)}
                  />
                </Tooltip>
                <Tooltip title="Delete" color={"white"}>
                  <Popconfirm
                    title={`Are you sure to delete the Gender ${record.gender_name}?`}
                    onConfirm={() => hanldeDeleteItems(record, index)}
                  >
                    <DeleteOutlined className={`delete-Button`} />
                  </Popconfirm>
                </Tooltip>
              </>
            }

          </>
        )
      },
    },
  ];


  useEffect(() => {
    if (formType === "add") {
      return setCategoryData([])
    }
  }, [customer_id]);

  useEffect(() => {
    dispatch(loadRegionCategory(token));
  }, [token]);

  useEffect(() => {
    dispatch(getGender(token, menuId));
  }, [token]);

  const hanldeSubItems = (record, index) => {
    setShowForm(true);
    setVisible(true);
    onChangeRegionCategory(record?.sp_region_category_id)
    setEditableRowIndex(index);
    form.setFieldsValue({
      regionCategory: record?.sp_region_category_id,
      gender: record?.gender_name,
      region: record?.region_name,
    });
    setGenderForm({ children: record.gender_name, value: record.gender_id });
  };

  const hanldeDeleteItems = (record, index) => {
    let copyArr = [...categoryData];
    setCategoryData(copyArr.filter((v, i) => i !== index));
    const deleteDataSource = [...deletedCategoryLine];
    deleteDataSource.push(record);
    setDeletedCategoryLine(deleteDataSource);
    notification("success", `Item Deleted Gender (${record?.gender_name})`);
  };

  const onChangeGender = (value, obj) => {
    setGenderForm(obj);
  };

  const dispatch = useDispatch();
  const {
    gender,
    salePerson,
    Category,
    regionCategory,
    Region,
  } = useSelector((state) => state);

  useEffect(() => {
    if (EmployeeID !== null) {
      dispatch(loadSalesPersons(token, EmployeeID));
    }
  }, [EmployeeID]);

  useEffect(() => {
    if (RegionID === null) {
    } else {
      dispatch(loadRegion(token, RegionID));
      setShowForm(true);
    }
  }, [RegionID]);

  const onChangeRegionCategory = (value, obj) => {
    let emp = "";
    let reg = "";

    dispatch(getRegionCategory(token, `${value}`)).then((regionCatg) => {
      if (regionCatg.type === "GET_REGIONCATG_BYID_SUCCESS") {
        emp = regionCatg.payload.data[0].sales_person_id;
        reg = regionCatg.payload.data[0].region_id;
        setEmployeeid(emp);
        setRegionsid(reg);
        dispatch(loadSalesPersons(token, emp)).then((res) => {
          setSalesperson(res.payload.data[0])
        });
        dispatch(loadRegion(token, reg)).then((region) => {
          if (region.type === "GET_REGION_SUCCESS") {
            setRegionCatForm(region.payload.data[0])
            setShowForm(true);
          }
        });
      }
      if (regionCatg.type === "GET_REGIONCATG_BYID_ERROR") {
        notification("error", "Category", regionCatg?.payload?.data);
      }
    });
  };

  const onSearch = (val) => { }

  const handleOk = async (e, record) => {
    await form.validateFields().then((values) => {
      values.active = check;
      values.employee = EmployeeID;
      values.region = RegionID;
      if (editableRowIndex === null) {
        setCategoryData([...categoryData, {
          gender_name: genderForm.children, gender_id: genderForm.value, region_name: regionCatForm.region_name, region_id: regionCatForm.region_id,
          salesperson_name: salesPerson.salesperson_name, sales_person_id: salesPerson.sales_person_id, is_active: check, sp_region_category_id: values.regionCategory,
        }]);
      } else {
        console.log("🚀 ~ awaitform.validateFields ~ genderForm:", genderForm)
        console.log("🚀 ~ awaitform.validateFields ~ regionCatForm:", regionCatForm)
        console.log("🚀 ~ awaitform.validateFields ~ salesPerson:", salesPerson)
        let copyArr = [...categoryData];
        copyArr[editableRowIndex].gender_name = genderForm.children;
        copyArr[editableRowIndex].gender_id = genderForm.value;
        copyArr[editableRowIndex].region_name = regionCatForm.region_name;
        copyArr[editableRowIndex].region_id = regionCatForm.region_id;
        copyArr[editableRowIndex].salesperson_name = salesPerson.salesperson_name;
        copyArr[editableRowIndex].sales_person_id = salesPerson.sales_person_id;
        if (formType === "edit") {
          copyArr[editableRowIndex].sp_region_category_id = values.regionCategory;
        }
        copyArr[editableRowIndex].is_active = check;
        setCategoryData(copyArr);
      }
      setVisible(false);
      setEditableRowIndex(null);
    });

  };

  const handleCancel = () => {
    setVisible(false);
    setShowForm(false);
    setEmployeeid(null);
    setRegionsid(null);
    setEditableRowIndex(null);
    form.setFieldsValue({
      regionCategory: null,
      employee_id: null,
      gender: null,
      region: null,
    });
  };

  return (
    <>
      <div>
        <Modal
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          title="Category Form"
          okText="Submit"
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={12} xl={12} xxl={12}>
                  <Form.Item name="gender" label="Gender">
                    <Select
                      size="small"
                      showSearch
                      placeholder="Select Gender"
                      optionFilterProp="children"
                      value={genderForm}
                      onChange={onChangeGender}
                      onSearch={onSearch}
                      filterOption={(input, options) =>
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {gender?.payload?.data.map((gender) => (
                        <Option key={gender.gender_id} value={gender.gender_id}>{gender.gender_name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="regionCategory" label="Region Category" className="label-height-adjust">
                    <Select
                      size="small"
                      className="w-100"

                      showSearch
                      placeholder="Select Region Category"
                      optionFilterProp="children"
                      value={regionCatForm}
                      onChange={onChangeRegionCategory}
                      onSearch={onSearch}
                      find={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {regionCategory?.payload?.data.map((regioncatg, i) => (
                        <Option key={i} value={regioncatg.sp_region_category_id}>
                          {regioncatg.sp_region_category_id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="active" label="Active"
                  >
                    <Checkbox size="small" className="styleInput" checked={check} onChange={(e) => setCheck(e.target.checked)} />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>
            {showForm &&
              <>
                <Row style={{ width: "100%", display: "flex", gap: "10%" }} >
                  <Col xs={24} sm={12} md={8} lg={18} xl={6}>
                    <label>Employe</label>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={18} xl={10}>
                    <Form.Item
                      name="employee"
                      initialValue={form.getFieldValue().employee}
                    >
                      {salePerson?.payload?.data &&
                        salePerson?.payload?.data.map((emp, i) => (
                          <Input
                            size="small"
                            style={{ borderRadius: "10px", width: 300 }}
                            disabled
                            value={emp.salesperson_name}
                          />
                        ))}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ width: "100%", display: "flex", gap: "10%" }} >
                  <Col xs={24} sm={12} md={8} lg={18} xl={6}>
                    <label>Region</label>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={18} xl={10}>
                    <Form.Item name="region" initialValue={form.getFieldValue().region}>
                      {Region?.payload?.data &&
                        Region?.payload?.data.map((reg) => (
                          <Input
                            size="small"
                            style={{ borderRadius: "10px", width: 300 }}
                            disabled
                            value={reg.region_name}
                          />
                        ))}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }
          </Form>
        </Modal>
      </div>
      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => {
          setVisible(true)

        }}
      />

      <Tables
        type="categoryData"
        categoryObj={() => { }}
        scroll={500}
        scrollX={"90rem"}
        source={JSON.parse(JSON.stringify(categoryData))}
        col={categoryColumns}
        load={Category.loading}
      />
    </>
  );
}
