import {
    GET_PRODUCTTYPE_ERROR,
    GET_PRODUCTTYPE_REQUEST,
    GET_PRODUCTTYPE_SUCCESS,
    ADD_PRODUCTTYPE_ERROR,
    ADD_PRODUCTTYPE_REQUEST,
    ADD_PRODUCTTYPE_SUCCESS,
    DELETE_PRODUCTTYPE_REQUEST,
    DELETE_PRODUCTTYPE_SUCCESS,
    DELETE_PRODUCTTYPE_ERROR,
    UPDATE_PRODUCTTYPE_ERROR,
    UPDATE_PRODUCTTYPE_REQUEST,
    UPDATE_PRODUCTTYPE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getProductType = (token, menu_id) => (dispatch, getState) => {
    const { productType } = getState();
    // if data is present in store return promise resolved
    if (productType?.payload?.data?.length) {
        return Promise.resolve();
    }
    // if data is not present in store then fetch from server
    dispatch({ type: GET_PRODUCTTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_PRODUCTTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PRODUCTTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addProductType = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_PRODUCTTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/productType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { product_type_name: data.productTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_PRODUCTTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PRODUCTTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updateProductType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_PRODUCTTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/productType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { product_type_name: data.productTypeName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_PRODUCTTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PRODUCTTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteProductType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PRODUCTTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/productType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_PRODUCTTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PRODUCTTYPE_ERROR,
                payload: error.response,
            });
        });
};
