import {
    GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_REQUEST,
    GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_SUCCESS,
    GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_ERROR,

} from "./types.js";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");
export const getOmsStockStyleWiseSummary = (token, data, seasonID, statusID, PCID, BgradeCheck) => (dispatch) => {
    dispatch({ type: GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getOtsSummaryStyleWiseReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            as_on_date: moment(data.as_on_date).format("YYYY-MM-DD"),
            style_id: data.style_id === "All" ? null : data.style_id,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            product_status_id: statusID.length > 0 ? statusID.toString() : null,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_OMS_STOCK_STYLE_WISE_SUMMARY_REPORT_ERROR,
                payload: error.response,
            });
        });
};