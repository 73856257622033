import React, { useEffect, useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Table, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getCustomerPaymentReport, getCustomerPaymentReportDateWise } from "../../redux/actions/customerPayment.action";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];

const CustomerPaymentLedgerDateWise = ({ token }) => {
  TabTitle("Customer Ledger")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const tableRef = useRef(null);
  const [select, setSelect] = useState("All");
  const [loader, setLoader] = useState(false);
  const [customerList, setCutomerList] = useState([]);
  const [customerId, setCutomerId] = useState([]);
  const [excelData, setExcelData] = useState([])
  const {
    gender,
    region,
    companyAll,
    scmBrand,
    customerForms,
    getBussinessDashboardReducer,
    customerPaymentDateWise,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  let rbalance = 0;

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let customer_id = query.get("customer_id");
  let customer_name = query.get("customer_name");

  useEffect(() => {
    if (customer_id) {
      let values = {
        customer_id: customer_id,
        from_date: "01-01-2010",
        to_date: "12-31-2024",
        status: "U",
        customer_name: customer_name
      };

      form.setFieldsValue({
        customer_id: values.customer_name,
        from_date: moment(values.from_date),
        to_date: moment(values.to_date),
        status: "U"
      });
      setLoader(true)
      dispatch(getCustomerPaymentReport(token, values)).then((res) => {

        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })

        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })


        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoader(false);
        downloadExcelFileData(filterData);
      })
    }
  }, [customer_id])

  const onFinish = async (e) => {
    setLoader(true)
    await form.validateFields().then((values) => {
      dispatch(getCustomerPaymentReportDateWise(token, values)).then((res) => {

        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })

        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })


        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoader(false);
        downloadExcelFileData(filterData);
      })
    });
  };

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Invoice#": v.invoice_header_no,
          "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
          "PO Number": v.po_number,
          "Term": v.term_name,
          "Invoice Qty": v.inv_qty,
          "Currency": currencySymbol,
          "Invoice Amount": v.invoice_amount,
          "Credit Note": v.note_amount_us,
          "Payment": v.payment_us,
          "Balance": v.balance,
          "R.Balance": v.running_balance,
          "Status": v.status,
          "Days": v.daysdiff,
          "Due Date": moment(v.due_date).format("MM-DD-YYYY"),
          "Gender": v.gender_name,
        };
      })
    )
  }


  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'Invoice#', key: 'invoice_header_no', width: 15 },
      { header: 'Invoice Date', key: 'invoice_date', width: 15 },
      { header: 'PO Number', key: 'po_number', width: 15 },
      { header: 'Term', key: 'term_name', width: 20 },
      { header: 'Invoice Qty', key: 'inv_qty', width: 15 },
      { header: 'Currency', key: 'currency', width: 30 },
      { header: 'Invoice Amount', key: 'invoice_amount', width: 15 },
      { header: 'Credit Note', key: 'note_amount_us', width: 15 },
      { header: 'Payment', key: 'payment_us', width: 15 },
      { header: 'Balance', key: 'balance', width: 15 },
      { header: 'R.Balance', key: 'r_balance', width: 15 },
      { header: 'Status', key: 'status', width: 15 },
      { header: 'Days', key: 'daysdiff', width: 15 },
      { header: 'Due Date', key: 'due_date', width: 15 },
      { header: 'Gender', key: 'gender_name', width: 15 },
    ];

    let filterData = customerPaymentDateWise?.payload?.data && customerPaymentDateWise?.payload?.data.filter((elem) => {
      return elem.invoice_header_id != "0";
    })

    let totalInvoiceQty = 0;
    let totalInvoiceAmount = 0;
    let totalCreditNote = 0;
    let totalPayment = 0;
    let totalBalance = 0;

    filterData.forEach((item) => {
      worksheet.addRow({ invoice_header_no: item.invoice_header_no, invoice_date: moment(item.invoice_date).format("MM-DD-YYYY"), po_number: item.po_number, term_name: item.term_name, inv_qty: item.inv_qty, currency: currencySymbol, invoice_amount: numeral(item.invoice_amount).format("0,00.00"), note_amount_us: numeral(item.note_amount_us).format("0,00.00"), payment_us: numeral(item.payment_us).format("0,00.00"), balance: numeral(item.balance).format("0,00.00"), r_balance: numeral(item.r_balance).format("0,00.00"), status: item.status, daysdiff: item.daysdiff, due_date: moment(item.due_date).format("MM-DD-YYYY"), gender_name: item.gender_name });
      totalInvoiceQty += Number(item.inv_qty)
      totalInvoiceAmount += Number(item.invoice_amount);
      totalCreditNote += Number(item.note_amount_us);
      totalPayment += Number(item.payment_us);
      totalBalance += Number(item.balance);
    });

    worksheet.addRow({ inv_qty: totalInvoiceQty, invoice_amount: totalInvoiceAmount, note_amount_us: totalCreditNote, payment_us: totalPayment, balance: totalBalance });

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


      const filename = 'Customer-Pyament-ledger.xlsx';


      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();


    } catch (error) {
      console.error(error);
    }
  };


  function generatePDF() {
    var doc = new jsPDF();


    var columns = ["Invoice#", "Invoice Date", "PO Number", "Term", "Invoice Qty", "Invoice Amount", "Credit Note", "Payment", "Balance", "R.Balance", "Status	", "Days", "Due Date", "Gender"];
    var rows = [];

    var customerName = customerPaymentDateWise?.payload?.data[0]?.customer_name || "Unknown Customer";

    doc.setFontSize(10);
    doc.text(`Customer Name: ${customerName}`, 15, 10);

    var yOffset = 7;

    doc.autoTable({
      startY: yOffset,
      body: rows,
      styles: { fontSize: 6 },
    });

    let filteredData = customerPaymentDateWise?.payload?.data.filter((elem) => {
      return elem.invoice_header_id != "0";
    });


    filteredData.forEach(item => {
      var row = [item.invoice_header_no, moment(item.invoice_date).format("MM-DD-YYYY"), item.po_number, item.term_name, item.inv_qty, currencySymbol + numeral(item.invoice_amount).format("0,00.00"), currencySymbol + numeral(item.note_amount_us).format("0,00.00"), currencySymbol + numeral(item.payment_us).format("0,00.00"), currencySymbol + numeral(item.balance).format("0,00.00"), currencySymbol + numeral(item.r_balance).format("0,00.00"), item.status, item.daysdiff,moment(item.due_date).format("MM-DD-YYYY"), item.gender_name];
      rows.push(row);
    });

    const total_invoice_Qty = filteredData.reduce((acc, item, index) => acc + parseFloat(item.inv_qty), 0)
    const total_invoice_amount = filteredData.reduce((acc, item, index) => acc + parseFloat(item.invoice_amount), 0)
    const total_note_amount = filteredData.reduce((acc, item, index) => acc + parseFloat(item.note_amount_us), 0)
    const total_amount_paid = filteredData.reduce((acc, item, index) => acc + parseFloat(item.payment_us), 0)
    const total_balance = filteredData.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)

    rows.push([" ", " ", " ", "Total: ", total_invoice_Qty, currencySymbol + total_invoice_amount.toLocaleString("en-US"), currencySymbol + total_note_amount.toLocaleString("en-US"), currencySymbol + total_amount_paid.toLocaleString("en-US"), currencySymbol + total_balance.toLocaleString("en-US")])

    doc.autoTable({
      head: [columns],
      body: rows,
      styles: { fontSize: 6 },
    })

    doc.save("Customer-Pyament-ledger.pdf");
  }


  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Invoice#",
      dataIndex: "invoice_header_no",
      key: "invoice_header_no",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text, record) => {
        return moment(record.invoice_date).format("MM-DD-YYYY")
      }
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      key: "po_number",
      width: 150
    },
    {
      title: "Term",
      dataIndex: "term_name",
      key: "term_name",
    },
    {
      title: "Invoice Qty",
      dataIndex: "inv_qty",
      key: "inv_qty",
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
      render: (text, record) => {
        return currencySymbol + numeral(record.invoice_amount).format("0,00.00")
      }
    },
    {
      title: "Credit Note",
      dataIndex: "note_amount_us",
      key: "note_amount_us",
      render: (text, record) => {
        return currencySymbol + numeral(record.note_amount_us).format("0,00.00")
      }
    },
    {
      title: "Payment",
      dataIndex: "payment_us",
      key: "payment_us",
      render: (text, record) => {
        return currencySymbol + numeral(record.payment_us).format("0,00.00")
      }
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        return currencySymbol + numeral(record.balance).format("0,00.00")
      }
    },
    {
      title: "R.Balance",
      dataIndex: "r_balance",
      key: "r_balance",
      render: (text, record) => {
        return currencySymbol + numeral(record.r_balance).format("0,00.00")
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render(text, record) {
        return {
          props: {
            style: { color: text === "Paid" || text === "Excess Paid" ? "green" : "red" }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Days",
      dataIndex: "daysdiff",
      key: "daysdiff",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text, record) => {
        return moment(record.due_date).format("MM-DD-YYYY")
      }
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },





  ];

  const total_invoice_Qty = customerList.reduce((acc, item, index) => acc + parseFloat(item.inv_qty), 0)
  const total_invoice_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.invoice_amount), 0)
  const total_note_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.note_amount_us), 0)
  const total_amount_paid = customerList.reduce((acc, item, index) => acc + parseFloat(item.payment_us), 0)
  const total_balance = customerList.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)






  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class" >
                      <CSVLink
                        filename={"Customer-Pyament-ledger.csv"}
                        data={excelData}
                        onClick={() => {
                        }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}


                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                    </div>
                    <div className="print_icon printing_class" >
                      <input
                        type="image"
                        name="ImgExcel"
                        alt="Export to Excel"
                        id="ImgExcel"
                        title="Export to Excel"
                        src={xlsx}
                        onClick={exportToExcel}

                        style={{ borderWidth: "0px", height: "14px" }}
                      />
                    </div>
                    <div className="print_icon printing_class" >
                      <input
                        type="image"
                        name="ImgPDF"
                        alt="Export to PDF"
                        id="ImgPDF"
                        title="Export to PDF"
                        src={pdf}
                        onClick={generatePDF}

                        style={{ borderWidth: "0px", height: "18px" }}
                      />
                      {/* <button onClick={() => generatePDF()}>Generate PDF</button> */}
                    </div>
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Customer Payment Ledger
                    <br />

                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* Filters Table */}
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}

                            />
                          </Form.Item>
                        </div>
                      </div>

                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}

                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Customer:</label>
                        <div className=" w-100">
                          <Form.Item
                            name="customer_id"
                            rules={[
                              {
                                required: true,
                                message: "Customer is Required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              className={"select_Inputs"}
                              loading={customerForms.loading}

                              size="small"
                              onSearch={(value) => {
                                optimizedFn(value);
                              }}
                              ref={focusInput}
                              value={customer}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {customerForms?.payload?.data.map((item) => {
                                return (
                                  <Option
                                    key={item.customer_id}
                                  >
                                    {`${item.customer_name}`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Status:</label>
                        <div className=" w-100">
                          <Form.Item
                            className=" w-100"
                            name="status"
                            initialValue={"All"}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"

                              className="select_Inputs"

                              defaultValue={
                                submited ? form.getFieldValue().status : "All"
                              }
                              size="small"

                            >
                              <Option key={"All"} value={"All"}>
                                All
                              </Option>
                              <Option key={"P"} value={"P"}>
                                Paid
                              </Option>
                              <Option key={"U"} value={"U"}>
                                Unpaid
                              </Option>
                              {/* <Option key={"E"} value={"E"}>
                                Excess Paid
                              </Option> */}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Render Data Table */}
          </div>
          <div className="table_content customer_ledger_table_content ledger_print"

            style={{ maxWidth: '1260px' }}
          >
            <div className="">
              {customerPaymentDateWise ? (
                customerPaymentDateWise?.payload?.data.length > 0 ? (
                  (
                    <div className="data_Table">
                      <div >
                        <div

                          style={{ margin: "0 auto", paddingTop: "10px" }}
                        >
                          <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls" style={{ fontSize: "10px" }}>
                            <tbody>
                              <tr>
                                <td className="font_weight">
                                  Name :
                                </td>
                                <td style={{ width: "106px" }} colSpan={2}>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].customer_name}
                                  </b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className="font_weight">
                                  Address :
                                </td>
                                <td colSpan={2}>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].address_1}
                                  </b>
                                </td>
                                <td></td>
                                <td className="font_weight">
                                  Balance :
                                </td>
                                <td colSpan={2}>
                                  <b className="font_weight">
                                    {currencySymbol}{numeral(total_balance).format("0,00,0")}
                                  </b>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="no-border-btm font_weight" style={{ width: "70px" }}>
                                  City :
                                </td>
                                <td style={{ width: "263px" }}>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].city_name}
                                  </b>
                                </td>
                                <td style={{ width: "126px" }} className="font_weight">
                                  State :
                                </td>
                                <td style={{ width: "119.6px" }} >
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].state_name}
                                  </b>
                                </td>
                              </tr> */}
                              <tr>
                                <td className="font_weight">
                                  City :
                                </td>
                                <td>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].city_name}
                                  </b>
                                </td>
                                <td className="no-border-btm font_weight">
                                  State :
                                </td>
                                <td>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].state_name}
                                  </b>
                                </td>
                                <td className="no-border-btm font_weight">
                                  Other Credit :
                                </td>
                                <td>
                                  <b className="font_weight">
                                    {currencySymbol}{numeral(customerId && customerId[0]?.credit_balance).format("0,00,0")}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="font_weight">
                                  Country :
                                </td>
                                <td>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].country_name}
                                  </b>
                                </td>
                                <td className="no-border-btm font_weight">
                                  Zip Code :
                                </td>
                                <td>
                                  <b>
                                    {customerPaymentDateWise?.payload?.data[0].zip_code}
                                  </b>
                                </td>
                                <td className="no-border-btm font_weight">
                                  Unapplied Amount :
                                </td>
                                <td >
                                  <b className="font_weight">
                                    {currencySymbol}{numeral(customerId && customerId[0]?.unapplied_amount).format("0,00,0")}
                                  </b>
                                </td>
                              </tr>
                            </tbody >
                          </table >
                        </div>
                      </div>
                    </div>
                  )

                ) : <></>) : <></>}
              <Table
                className="
                
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName='abc'
                dataSource={customerList && customerList}
                columns={column}
                loading={customerPaymentDateWise.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>Other Credit : {currencySymbol}{numeral(customerId && customerId[0]?.credit_balance).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2}>Unapplied Amount : {currencySymbol}{numeral(customerId && customerId[0]?.unapplied_amount).format("0,00,0")}</Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_invoice_Qty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>

                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {currencySymbol}{total_invoice_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {currencySymbol}{total_note_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_memo_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell> */}
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {currencySymbol}{total_amount_paid.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_refund_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_credit_note_apply.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_memo_apply.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell> */}
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {currencySymbol}{total_balance.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell>
                          <p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
                            {total_running_balance.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell> */}
                        <Table.Summary.Cell></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPaymentLedgerDateWise;
