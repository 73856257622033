import axios from "axios";
import Routes from "./routes";
import { useEffect } from "react";

function App() {
  axios.interceptors.response.use(
    function (response) {
      return response;
    }, 
    function (error) {
      if (error.response.status === 401) {
        document.cookie.split(";").forEach(function (c) {
          document.cookie =
            c.trim().split("=")[0] +
            "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        });
        localStorage.clear()
        window.location.href="/login"
      }
      return Promise.reject(error);
    }
  );
  return <Routes />;
}
export default App;
