import {
  GET_ALL_RECEIPT_REQUEST,
  GET_ALL_RECEIPT_SUCCESS,
  GET_ALL_RECEIPT_ERROR,
  GET_ONE_RECEIPT_HEADER_REQUEST,
  GET_ONE_RECEIPT_HEADER_SUCCESS,
  GET_ONE_RECEIPT_HEADER_ERROR,
  GET_ONE_RECEIPT_HEADER_DETAIL_REQUEST,
  GET_ONE_RECEIPT_HEADER_DETAIL_SUCCESS,
  GET_ONE_RECEIPT_HEADER_DETAIL_ERROR,
  DELETE_RECEIPT_HEADER_REQUEST,
  DELETE_RECEIPT_HEADER_SUCCESS,
  DELETE_RECEIPT_HEADER_ERROR,
  ADD_RECEIPT_HEADER_REQUEST,
  ADD_RECEIPT_HEADER_SUCCESS,
  ADD_RECEIPT_HEADER_ERROR,
  UPDATE_RECEIPT_HEADER_REQUEST,
  UPDATE_RECEIPT_HEADER_SUCCESS,
  UPDATE_RECEIPT_HEADER_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const addReceiptHeader = (token, formData, dbData) => (dispatch) => {
  dispatch({ type: ADD_RECEIPT_HEADER_REQUEST });
  let newData = {
    receipt_date: moment(formData.receiptDate).format("MM-DD-YYYY"),
    return_header_id: dbData.return_header_id,
    invoice_header_id: dbData.invoice_header_id,
    ship_header_id: dbData.ship_header_id,
    order_header_id: dbData.order_header_id,
    gl_period_id: dbData.gl_period_id,
    customer_id: dbData.customer_id,
    company_id: dbData.company_id,
    gl_period_id: formData.postingPeriod,
    asn_no: formData.asnNo,
    memo: formData.memo,
    location_id: formData?.location,
    return_header_no: formData?.RaNumber,
    receipt_remarks: formData.receipt_remarks  === undefined ? null : formData.receipt_remarks,
    receiving_status: formData.receiving_status === undefined ? null : formData.receiving_status,
    brand_id: formData?.brand_id,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}create/receiptHeader`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: ADD_RECEIPT_HEADER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_RECEIPT_HEADER_ERROR,
        payload: error.response,
      });
    });
};

export const updateReceiptHeader = (token, formData, dbData) => (dispatch) => {
  let newData = {
    receipt_date: moment(formData?.receiptDate).format("MM-DD-YYYY"),
    return_header_id: dbData?.return_header_id,
    invoice_header_id: dbData?.invoice_header_id,
    ship_header_id: dbData?.ship_header_id,
    order_header_id: dbData?.order_header_id,
    gl_period_id: dbData?.gl_period_id,
    customer_id: dbData?.customer_id,
    company_id: dbData?.company_id,
    gl_period_id: formData?.postingPeriod,
    asn_no: formData?.asnNo,
    memo: formData?.memo,
    location_id: formData?.location,
    receipt_remarks: formData.receipt_remarks,
    receiving_status: formData.receiving_status,
    brand_id: formData?.brand_id,
  };
  dispatch({ type: UPDATE_RECEIPT_HEADER_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/receiptHeader/${formData?.receiptHeaderId}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {

      return dispatch({
        type: UPDATE_RECEIPT_HEADER_SUCCESS,
        payloadUpdate: response?.data?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_RECEIPT_HEADER_ERROR,
        payloadUpdate: error.response,
      });
    });
};

export const getAllReceiptHeader = (token, data) => (dispatch) => {
  dispatch({ type: GET_ALL_RECEIPT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/receiptHeader`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      from_date: moment(data.from_date).format("MM-DD-YYYY"),
      to_date: moment(data.to_date).format("MM-DD-YYYY"),
      receipt_header_no: data.receiptNo === "" ? null : data.receiptNo,
      return_header_no: data.returnNo === "" ? null : data.returnNo,
      note_header_no: data.CNno === "" ? null : data.CNno,
      gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
      customer_name: data.customerName === "" ? null : data.customerName,
      invoice_header_no: data.InvoiceNo === "" ? null : data.InvoiceNo,
      brand_id: data.brand_id === "" ? null : data.brand_id,
      ra_no: data.raNo === "" ? null : data.raNo,
      lot_no: data.lotNo === "" ? null : data.lotNo,
      receiving_status: data.receiving_status,
    },

  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_RECEIPT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_RECEIPT_ERROR,
        payload: error.response,
      });
    });
};

export const getOneReceiptHeaderDetail = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_RECEIPT_HEADER_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/receiptHeaderDetail/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_RECEIPT_HEADER_SUCCESS,
        payload: response?.data[0],
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_RECEIPT_HEADER_ERROR,
        payload: error.response,
      });
    });
};

export const getOneReceiptHeader = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_RECEIPT_HEADER_DETAIL_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/receiptHeader/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_RECEIPT_HEADER_DETAIL_SUCCESS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_RECEIPT_HEADER_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const deleteReceiptHeader = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_RECEIPT_HEADER_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/receiptHeader/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_RECEIPT_HEADER_SUCCESS,
        payload: response?.data.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_RECEIPT_HEADER_ERROR,
        payload: error.response,
      });
    });
};
