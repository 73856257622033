import { GET_USER_LOG_REQUEST, GET_USER_LOG_SUCCESS, GET_USER_LOG_ERROR } from "../actions/types"
export default function (state = null, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_LOG_REQUEST:
            return {
                loading: true,
            };
        case GET_USER_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_USER_LOG_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}