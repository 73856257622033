import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Spin,
  Select,
  Checkbox,
  Form,
  Button,
  Table,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action";
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import { getStyle } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { imediateOtsBySize } from "../../redux/actions/omsStockSummary2.action";
import { getskuloopUpData } from "../../redux/actions/skuLookupData.action";
import { sorterHelper } from "../../utils/filterHelper";
import { getBrands } from "../../redux/actions/brand.action";

const ImediateOtsBySize3 = ({ token }) => {
  TabTitle("Immediate OTS By Size");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    gender,
    genderCategory,
    season,
    productStatus,
    wash,
    skuLookUpData,
    genderCategoryChange,
    styleChange,
    brand,
    Sku,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [isChecked2, setIsChecked2] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [filters, setFilters] = useState([]);
  const location = useLocation();
  const tableRef = useRef(null);
  const [isGenerate, setIsGenerate] = useState("All");
  const [isSKU, setIsSKU] = useState("All");

  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus &&
      productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [
        ...current,
        filterCheckBox.product_status_id,
      ]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  useEffect(() => {
    if (location?.state && location?.state?.values?.new_season_id) {
      const values = {
        as_on_date: moment("12-31-2023"),
        sku: "",
        season_id: location?.state?.values?.new_season_id,
        gender_id: location?.state?.values?.gender_id,
        gender_category_id: null,
        fit_category_id: null,
        style_id: null,
        product_status_id: null,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: null,
      };
      form.setFieldsValue({
        season_id: values.season_id,
        gender_id: values.gender_id,
      });
      setLoading(true);
      dispatch(
        imediateOtsBySize(
          token,
          values,
          [],
          isChecked2,
          [],
          false
        )
      ).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [location?.state && location?.state?.values?.new_season_id]);

  const onFinish = async (e) => {
    form.setFieldsValue({
      gender_id: isGenerate,
      sku: isSKU
    });
    await form.validateFields().then((values) => {
      const distributorParam = {
        location_id: 1,
        season_id: values.season_id === "All" ? null : values.season_id,
        sku: isSKU === "All" ? null : isSKU,
        gender_category_id: values.type === "All" ? null : values.type,
        style_id: values.style_id === "All" ? null : values.style_id,
        product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
        pending_date: moment(values.as_on_date).format("YYYY-MM-DD"),
        as_on_date: moment(values.as_on_date).format("YYYY-MM-DD"),
        category_id:
          values.gender_category_id === "All"
            ? null
            : values.gender_category_id,
        fit_category_id: null,
        gender_id: isGenerate === "All" ? null : isGenerate,
        gender_category_id: null,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: values.wash_id === "All" ? null : values.wash_id,
        brand_id: values.brand_id === "All" ? null : values.brand_id,
        new_sesaon_year_id:
          values.new_season_year_id === "All"
            ? null
            : values.new_season_year_id,
      };
      setLoading(true);
      dispatch(getskuloopUpData(token, distributorParam)).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
      });
    });
  };

  const downloadExcelFileData = () => {
    setExcelData(
      data && data.map((v) => ({
        Sku: v.sku,
        Inseam: v.inseam,
        Style: v.style_name,
        Wash: v.wash_name,
        "Current Season": v.current_season,
        Gender: v.gender_name,
        "Fit Category": v.fit_category_name,
        "Currency": currencySymbol,
        WS: v.ws,
        RTL: v.rtl,
        BERGEN: v.all_locations.bergen,
        TRNS: v.all_locations.transit,
        "Bulk DL": v.all_locations.dl_bulk,
        "Bulk WW": v.all_locations.ww_bulk,
        PEND: v.all_locations.pending,
        INT: v.all_locations.international,
        OTS: v.all_locations.final_ots,
        '=""2/3""&CHAR(10)&""00""&CHAR(10)&""XS""&CHAR(10)&""2""&CHAR(10)&""27""&CHAR(10)&""23""':
          ((v.gender_name === "Men" &&
            v.all_locations.size_1 === "28") ||
            (v.gender_name === "Kids" &&
              (v.all_locations.size_1 === "7" ||
                v.all_locations.size_1 === "8")) ||
            (v.gender_name === "Kids" &&
              v.all_locations.size_1 === "S")
            ? 0
            : Number(v.all_locations.qty_1) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_1
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_1
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_1
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_1
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_1
              )) +
            (filters.includes("24S D2C") &&
              Number(
                v.all_locations.tfs_qty_1
              ))),
        '=""4/5""&CHAR(10)&""0""&CHAR(10)&""S""&CHAR(10)&""3""&CHAR(10)&""28""&CHAR(10)&""24""':
          (v.gender_name === "Kids" &&
            (v.all_locations.size_1 === "7" ||
              v.all_locations.size_1 === "8")
            ? 0
            : (v.gender_name === "Men" &&
              v.all_locations.size_1 === "28") ||
              (v.gender_name === "Kids" &&
                v.all_locations.size_1 === "S")
              ? Number(v.all_locations.qty_1) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_1
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_1
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_1
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_1
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_1))
              : Number(v.all_locations.qty_2) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_2
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_2
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_2
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_2
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_2
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_2
                ))),
        '=""6/6X""&CHAR(10)&""1""&CHAR(10)&""M""&CHAR(10)&""4""&CHAR(10)&""29""&CHAR(10)&""25""':
          (v.gender_name === "Kids" &&
            (v.all_locations.size_1 === "7" ||
              v.all_locations.size_1 === "8")
            ? 0
            : (v.gender_name === "Men" &&
              v.all_locations.size_1 === "28") ||
              (v.gender_name === "Kids" &&
                v.all_locations.size_1 === "S")
              ? Number(v.all_locations.qty_2) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_2
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_2
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_2
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_2
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_2))
              : Number(v.all_locations.qty_3) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_3
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_3
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_3
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_3
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_3
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_3
                ))),
        '=""-""&CHAR(10)&""2""&CHAR(10)&""L""&CHAR(10)&""5""&CHAR(10)&""30""&CHAR(10)&""26""':
          (v.gender_name === "Kids" &&
            (v.all_locations.size_1 === "7" ||
              v.all_locations.size_1 === "8")
            ? 0
            : (v.gender_name === "Men" &&
              v.all_locations.size_1 === "28") ||
              (v.gender_name === "Kids" &&
                v.all_locations.size_1 === "S")
              ? Number(v.all_locations.qty_3) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_3
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_3
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_3
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_3
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_3))
              : Number(v.all_locations.qty_4) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_4
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_4
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_4
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_4
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_4
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_4
                ))),
        '=""-""&CHAR(10)&""3""&CHAR(10)&""XL""&CHAR(10)&""6""&CHAR(10)&""31""&CHAR(10)&""27""':
          (v.gender_name === "Kids" &&
            (v.all_locations.size_1 === "7" ||
              v.all_locations.size_1 === "8")
            ? 0
            : v.gender_name === "Men" &&
              v.all_locations.size_1 === "28"
              ? Number(v.all_locations.qty_4) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_4
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_4
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_4
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_4
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_4))
              : Number(v.all_locations.qty_5) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_5
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_5
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_5
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_5
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_5
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_5
                ))),
        '=""-""&CHAR(10)&""4""&CHAR(10)&""1XL""&CHAR(10)&""6x""&CHAR(10)&""32""&CHAR(10)&""28""':
          (v.gender_name === "Kids" &&
            (v.all_locations.size_1 === "7" ||
              v.all_locations.size_1 === "8")
            ? 0
            : v.gender_name === "Men" &&
              v.all_locations.size_1 === "28"
              ? Number(v.all_locations.qty_5) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_5
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_5
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_5
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_5
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_5))
              : Number(v.all_locations.qty_6) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_6
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_6
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_6
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_6
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_6
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_6
                ))),
        '=""-""&CHAR(10)&""5""&CHAR(10)&""2XL""&CHAR(10)&""7""&CHAR(10)&""33""&CHAR(10)&""29""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? 0
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_1) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_1
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_1
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_1
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_1
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_1))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_6) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_6
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_6
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_6
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_6
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(v.all_locations.saks_qty_6))
                : Number(v.all_locations.qty_7) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_7
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_7
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_7
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_7
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_7
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_7
                  ))),
        '=""-""&CHAR(10)&""6""&CHAR(10)&""3XL""&CHAR(10)&""8""&CHAR(10)&""34""&CHAR(10)&""30""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_1) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_1
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_1
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_1
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_1
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_1))
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_2) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_2
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_2
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_2
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_2
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_2))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_7) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_7
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_7
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_7
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_7
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(v.all_locations.saks_qty_7))
                : Number(v.all_locations.qty_8) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_8
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_8
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_8
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_8
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_8
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_8
                  ))),
        '=""-""&CHAR(10)&""7""&CHAR(10)&""-""&CHAR(10)&""10""&CHAR(10)&""35""&CHAR(10)&""31""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_2) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_2
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_2
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_2
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_2
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_2))
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_3) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_3
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_3
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_3
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_3
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_3))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_8) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_8
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_8
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_8
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_8
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(v.all_locations.saks_qty_8))
                : Number(v.all_locations.qty_9) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_9
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_9
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_9
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_9
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_9
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_9
                  ))),
        '=""-""&CHAR(10)&""8""&CHAR(10)&""-""&CHAR(10)&""12""&CHAR(10)&""36""&CHAR(10)&""32""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_3) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_3
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_3
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_3
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_3
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_3))
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_4) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_4
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_4
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_4
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_4
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_4))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_9) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_9
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_9
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_9
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_9
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(v.all_locations.saks_qty_9))
                : Number(v.all_locations.qty_10) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_10
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_10
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_10
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_10
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_10
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_10
                  ))),
        '=""-""&CHAR(10)&""9""&CHAR(10)&""-""&CHAR(10)&""14""&CHAR(10)&""38""&CHAR(10)&""33""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_4) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_4
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_4
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_4
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_4
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_4))
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_5) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_5
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_5
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_5
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_5
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_5))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_10) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_10
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_10
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_10
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_10
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_10
                  ))
                : Number(v.all_locations.qty_11) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_11
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_11
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_11
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_11
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_11
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_11
                  ))),
        '=""-""&CHAR(10)&""10""&CHAR(10)&""-""&CHAR(10)&""16""&CHAR(10)&""40""&CHAR(10)&""34""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_5) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_5
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_5
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_5
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_5
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_5))
            : v.gender_name === "Kids" &&
              v.all_locations.size_1 === "7"
              ? Number(v.all_locations.qty_6) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_6
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_6
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_6
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_6
                )) +
              (filters.includes("Saks D2C") &&
                Number(v.all_locations.saks_qty_6))
              : v.gender_name === "Men" &&
                v.all_locations.size_1 === "28"
                ? Number(v.all_locations.qty_11) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_11
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_11
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_11
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_11
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_11
                  ))
                : Number(v.all_locations.qty_12) +
                (filters.includes("BERGEN ECOM") &&
                  Number(
                    v.all_locations.ecommerce_qty_12
                  )) +
                (filters.includes("NORD D2C") &&
                  Number(
                    v.all_locations.nord_qty_12
                  )) +
                (filters.includes("VERISHOP D2C") &&
                  Number(
                    v.all_locations.verishop_qty_12
                  )) +
                (filters.includes("Neiman D2C") &&
                  Number(
                    v.all_locations.neiman_qty_12
                  )) +
                (filters.includes("Saks D2C") &&
                  Number(
                    v.all_locations.saks_qty_12
                  )) +
                (filters.includes("24S D2C") &&
                  Number(
                    v.all_locations.tfs_qty_12
                  ))),
        '=""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""18""&CHAR(10)&""42""&CHAR(10)&""35""':
          (v.gender_name === "Kids" &&
            v.all_locations.size_1 === "8"
            ? Number(v.all_locations.qty_6) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_6
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_6
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_6
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_6
              )) +
            (filters.includes("Saks D2C") &&
              Number(v.all_locations.saks_qty_6))
            : v.gender_name === "Men" &&
              v.all_locations.size_1 === "28"
              ? Number(v.all_locations.qty_12) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_12
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_12
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_12
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_12
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_12
                ))
              : Number(v.all_locations.qty_13) +
              (filters.includes("BERGEN ECOM") &&
                Number(
                  v.all_locations.ecommerce_qty_13
                )) +
              (filters.includes("NORD D2C") &&
                Number(
                  v.all_locations.nord_qty_13
                )) +
              (filters.includes("VERISHOP D2C") &&
                Number(
                  v.all_locations.verishop_qty_13
                )) +
              (filters.includes("Neiman D2C") &&
                Number(
                  v.all_locations.neiman_qty_13
                )) +
              (filters.includes("Saks D2C") &&
                Number(
                  v.all_locations.saks_qty_13
                )) +
              (filters.includes("24S D2C") &&
                Number(
                  v.all_locations.tfs_qty_13
                ))),
        '=""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""20""&CHAR(10)&""44""&CHAR(10)&""36""':
          (v.gender_name === "Men" &&
            v.all_locations.size_1 === "28"
            ? Number(v.all_locations.qty_13) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_13
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_13
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_13
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_13
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_13
              ))
            : Number(v.all_locations.qty_14) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_14
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_14
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_14
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_14
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_14
              )) +
            (filters.includes("24S D2C") &&
              Number(
                v.all_locations.tfs_qty_14
              ))),
        '=""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""46""&CHAR(10)&""37""':
          (v.gender_name === "Men" &&
            v.all_locations.size_1 === "28"
            ? Number(v.all_locations.qty_14) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_14
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_14
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_14
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_14
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_14
              ))
            : Number(v.all_locations.qty_15) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_15
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_15
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_15
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_15
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_15
              )) +
            (filters.includes("24S D2C") &&
              Number(
                v.all_locations.tfs_qty_15
              ))),
        '=""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""48""&CHAR(10)&""38""':
          (v.gender_name === "Men" &&
            v.all_locations.size_1 === "28"
            ? Number(v.all_locations.qty_15) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_15
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_15
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_15
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_15
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_15
              ))
            : Number(v.all_locations.qty_16) +
            (filters.includes("BERGEN ECOM") &&
              Number(
                v.all_locations.ecommerce_qty_16
              )) +
            (filters.includes("NORD D2C") &&
              Number(
                v.all_locations.nord_qty_16
              )) +
            (filters.includes("VERISHOP D2C") &&
              Number(
                v.all_locations.verishop_qty_16
              )) +
            (filters.includes("Neiman D2C") &&
              Number(
                v.all_locations.neiman_qty_16
              )) +
            (filters.includes("Saks D2C") &&
              Number(
                v.all_locations.saks_qty_16
              )) +
            (filters.includes("24S D2C") &&
              Number(
                v.all_locations.tfs_qty_16
              ))),
        Total:
          Number(v.all_locations.ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(v.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") && Number(v.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(v.all_locations.verishop)) +
          (filters.includes("Neiman D2C") && Number(v.all_locations.neiman)) +
          (filters.includes("Saks D2C") && Number(v.all_locations.saks)) +
          (filters.includes("24S D2C") &&
            Number(
              v.all_locations.tfs
            )),
      }))
    );
  }

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, values));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getFitCategory(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getSeasonYear(token, menuId));
    dispatch(getBrands(token));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  const handlePrint = () => {
    window.print();
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  };

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  let sizeChartRendering = form.getFieldValue("gender_id")
  let sizeChartRenderingSKU = form.getFieldValue("sku")

  const colums = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text, record) => {
        return (
          <Link
            style={{
              color: "blue",
              textDecoration: "underline",
            }}
            to={{
              pathname: `/sku-lookup?sku=${record.sku}`,
              state: data,
            }}
            target="_blank"
          >
            {record.sku}
          </Link>
        );
      },
      ...sorterHelper("sku", "num"),
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      ...sorterHelper("inseam", "num"),
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      ...sorterHelper("style_name"),
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      ...sorterHelper("wash_name"),
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
      ...sorterHelper("current_season"),
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      ...sorterHelper("gender_name"),
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category_name",
      key: "fit_category_name",
      ...sorterHelper("fit_category_name"),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      ...sorterHelper("brand_name"),
    },
    {
      title: "WS",
      dataIndex: "ws",
      key: "ws",
      align: "right",
      ...sorterHelper("ws", "num"),
    },
    {
      title: "RTL",
      dataIndex: "rtl",
      key: "rtl",
      align: "right",
      ...sorterHelper("rtl", "num"),
    },
    //for Kids
    sizeChartRendering === "Kids" || (sizeChartRenderingSKU !== "All" && skuLookUpData?.payload?.data[0]?.gender_name === "Kids") ? (
      {
        children: [
          {
            title: "T-Jacket",
            dataIndex: "building",
            key: "building",
            width: 75,
            children: [
              {
                title: "Shirt/RTW-P",
                dataIndex: "building",
                key: "building",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-R",
                    dataIndex: "building",
                    key: "building",
                    width: 75,
                    children: [
                      {
                        title: "Y-Adult",
                        dataIndex: "building",
                        key: "building",
                        width: 75,
                        children: [
                          {
                            title: "Kids",
                            dataIndex: "building",
                            key: "building",
                            width: 75,
                          }
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"2/3"',
            align: "right",
            dataIndex: "qty_1",
            key: "qty_1",
            width: 40,
            children: [
              {
                title: "1XL",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                align: "right",
                children: [
                  {
                    title: "XS",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "00",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                        children: [
                          {
                            title: "2",
                            dataIndex: "qty_1",
                            key: "qty_1",
                            width: 40,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {
                                    (data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28") ||
                                      (data.gender_name === "Kids" &&
                                        (data.all_locations.size_1 === "7" ||
                                          data.all_locations.size_1 === "8")) ||
                                      (data.gender_name === "Kids" &&
                                        data.all_locations.size_1 === "S")
                                      ? 0
                                      : Number(data.all_locations.qty_1) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_1
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_1
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_1
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_1
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_1
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_1
                                        ))
                                  }
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"4/5"',
            align: "right",
            dataIndex: "qty_2",
            key: "qty_2",
            width: 50,
            children: [
              {
                title: "2XL",
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 55,
                children: [
                  {
                    title: "S",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "0",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                        children: [
                          {
                            title: "3",
                            align: "right",
                            dataIndex: "qty_2",
                            key: "qty_2",
                            width: 50,
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")
                                    ? 0
                                    : (data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28") ||
                                      (data.gender_name === "Kids" &&
                                        data.all_locations.size_1 === "S")
                                      ? Number(data.all_locations.qty_1) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_1
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_1
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_1
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_1
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_1))
                                      : Number(data.all_locations.qty_2) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_2
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_2
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_2
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_2
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_2
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_2
                                        ))
                                  }
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"6/6X"',
            dataIndex: "qty_3",
            key: "qty_3",
            align: "right",
            width: 60,
            children: [
              {
                title: "3XL",
                dataIndex: "qty_3",
                key: "qty_3",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "M",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "1",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "4",
                            dataIndex: "qty_3",
                            key: "qty_3",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")
                                    ? 0
                                    : (data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28") ||
                                      (data.gender_name === "Kids" &&
                                        data.all_locations.size_1 === "S")
                                      ? Number(data.all_locations.qty_2) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_2
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_2
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_2
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_2
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_2))
                                      : Number(data.all_locations.qty_3) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_3
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_3
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_3
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_3
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_3
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_3
                                        ))
                                  }
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_4",
            key: "qty_4",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "L",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "2",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "5",
                            dataIndex: "qty_4",
                            key: "qty_4",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")
                                    ? 0
                                    : (data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28") ||
                                      (data.gender_name === "Kids" &&
                                        data.all_locations.size_1 === "S")
                                      ? Number(data.all_locations.qty_3) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_3
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_3
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_3
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_3
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_3))
                                      : Number(data.all_locations.qty_4) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_4
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_4
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_4
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_4
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_4
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_4
                                        ))
                                  }
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_5",
            key: "qty_5",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XL",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "3",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6",
                            dataIndex: "qty_5",
                            key: "qty_5",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")
                                    ? 0
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_4) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_4
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_4
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_4
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_4
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_4))
                                      : Number(data.all_locations.qty_5) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_5
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_5
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_5
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_5
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_5
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_5
                                        ))
                                  }
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_6",
            key: "qty_6",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XXL",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "4",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6x",
                            dataIndex: "qty_6",
                            key: "qty_6",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")
                                    ? 0
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_5) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_5
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_5
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_5
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_5
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_5))
                                      : Number(data.all_locations.qty_6) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_6
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_6
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_6
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_6
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_6
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_6
                                        ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_7",
            key: "qty_7",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "5",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "7",
                            dataIndex: "qty_7",
                            key: "qty_7",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? 0
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_1) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_1
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_1
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_1
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_1
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_1))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_6) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_6
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_6
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_6
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_6
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(data.all_locations.saks_qty_6))
                                        : Number(data.all_locations.qty_7) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_7
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_7
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_7
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_7
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_7
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_7
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_8",
            key: "qty_8",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "6",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "8",
                            dataIndex: "qty_8",
                            key: "qty_8",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_1
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_1
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_1
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_1
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_1))
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_2) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_2
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_2
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_2
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_2
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_2))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_7) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_7
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_7
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_7
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_7
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(data.all_locations.saks_qty_7))
                                        : Number(data.all_locations.qty_8) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_8
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_8
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_8
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_8
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_8
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_8
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_9",
            key: "qty_9",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "7",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "10",
                            dataIndex: "qty_9",
                            key: "qty_9",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_2) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_2
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_2
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_2
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_2
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_2))
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_3) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_3
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_3
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_3
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_3
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_3))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_8) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_8
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_8
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_8
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_8
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(data.all_locations.saks_qty_8))
                                        : Number(data.all_locations.qty_9) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_9
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_9
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_9
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_9
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_9
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_9
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_10",
            key: "qty_10",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "8",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "12",
                            dataIndex: "qty_10",
                            key: "qty_10",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_3
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_3
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_3
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_3
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_3))
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_4) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_4
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_4
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_4
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_4
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_4))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_9) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_9
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_9
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_9
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_9
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(data.all_locations.saks_qty_9))
                                        : Number(data.all_locations.qty_10) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_10
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_10
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_10
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_10
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_10
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_10
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_11",
            key: "qty_11",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "9",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "14",
                            dataIndex: "qty_11",
                            key: "qty_11",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_4
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_4
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_4
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_4
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_4))
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_5) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_5
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_5
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_5
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_5
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_5))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_10) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_10
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_10
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_10
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_10
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_10
                                          ))
                                        : Number(data.all_locations.qty_11) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_11
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_11
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_11
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_11
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_11
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_11
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_12",
            key: "qty_12",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "10",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "16",
                            dataIndex: "qty_12",
                            key: "qty_12",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_5
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_5
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_5
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_5
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_5))
                                    : data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "7"
                                      ? Number(data.all_locations.qty_6) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_6
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_6
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_6
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_6
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_6))
                                      : data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_11) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_11
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_11
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_11
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_11
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_11
                                          ))
                                        : Number(data.all_locations.qty_12) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_12
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_12
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_12
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_12
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_12
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_12
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_13",
            key: "qty_13",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "18",
                            dataIndex: "qty_13",
                            key: "qty_13",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" || data.all_locations.size_1 === "8")
                                    ? Number(data.all_locations.qty_7) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_7
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_7
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_7
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_7
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_7))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_12) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_12
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_12
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_12
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_12
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_12
                                        ))
                                      : Number(data.all_locations.qty_13) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_13
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_13
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_13
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_13
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_13
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_13
                                        ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_14",
            key: "qty_14",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "20",
                            dataIndex: "qty_14",
                            key: "qty_14",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {
                                    data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "8"
                                      ? Number(data.all_locations.qty_6) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_6
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_6
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_6
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_6
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_6))
                                      :
                                      data.gender_name === "Men" &&
                                        data.all_locations.size_1 === "28"
                                        ? Number(data.all_locations.qty_13) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_13
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_13
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_13
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_13
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_13
                                          ))
                                        : Number(data.all_locations.qty_14) +
                                        (filters.includes("BERGEN ECOM") &&
                                          Number(
                                            data.all_locations.ecommerce_qty_14
                                          )) +
                                        (filters.includes("NORD D2C") &&
                                          Number(
                                            data.all_locations.nord_qty_14
                                          )) +
                                        (filters.includes("VERISHOP D2C") &&
                                          Number(
                                            data.all_locations.verishop_qty_14
                                          )) +
                                        (filters.includes("Neiman D2C") &&
                                          Number(
                                            data.all_locations.neiman_qty_14
                                          )) +
                                        (filters.includes("Saks D2C") &&
                                          Number(
                                            data.all_locations.saks_qty_14
                                          )) +
                                        (filters.includes("24S D2C") &&
                                          Number(
                                            data.all_locations.tfs_qty_14
                                          ))}
                                </>
                              );
                            },
                          },
                        ],
                      }
                    ]
                  },]
              }
            ],
          },
          {
            title: "-",
            dataIndex: "qty_15",
            key: "qty_15",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_15",
                            key: "qty_15",
                            width: 50,
                            align: "right",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_14) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_14
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_14
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_14
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_14
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_14
                                      ))
                                    : Number(data.all_locations.qty_15) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_15
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_15
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_15
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_15
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_15
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_16
                                      ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_16",
            key: "qty_16",
            width: 50,
            align: "right",
            className: "Border-Right-table border-z-index-1",
            children: [
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_16",
                            key: "qty_16",
                            width: 50,
                            align: "right",
                            className: "Border-Right-table border-z-index-1",
                            render: (text, data) => {
                              return (
                                <>
                                  {data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_15) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_15
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_15
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_15
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_15
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_15
                                      ))
                                    : Number(data.all_locations.qty_16) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_16
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_16
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_16
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_16
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_16
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_16
                                      ))}
                                </>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
        ],
      }
    )
      : sizeChartRendering === "Women" || (sizeChartRenderingSKU !== "All" && skuLookUpData?.payload?.data[0]?.gender_name === "Women") ? (
        // // for Women
        {
          children: [
            {
              title: 'Shirt/RTW-P',
              dataIndex: "building",
              key: "building",
              width: 75,
              children: [
                {
                  title: 'Shirt/RTW-R',
                  dataIndex: "building",
                  key: "building",
                  width: 75,
                  children: [
                    {
                      title: "W-Plus",
                      dataIndex: "building",
                      key: "building",
                      width: 75,
                      children: [
                        {
                          title: "Women",
                          dataIndex: "building",
                          key: "building",
                          width: 75,
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '1XL',
              align: "right",
              dataIndex: "qty_1",
              key: "qty_1",
              width: 40,
              children: [
                {
                  title: "XS",
                  dataIndex: "qty_1",
                  key: "qty_1",
                  width: 40,
                  align: "right",
                  children: [
                    {
                      title: "10W",
                      dataIndex: "qty_1",
                      key: "qty_1",
                      width: 40,
                      align: "right",
                      children: [
                        {
                          title: "23",
                          dataIndex: "qty_1",
                          key: "qty_1",
                          width: 40,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {
                                  (data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28") ||
                                    (data.gender_name === "Kids" &&
                                      (data.all_locations.size_1 === "7" ||
                                        data.all_locations.size_1 === "8")) ||
                                    (data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "S")
                                    ? 0
                                    : Number(data.all_locations.qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_1
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_1
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_1
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_1
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_1
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_1
                                      ))
                                }
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '2XL',
              align: "right",
              dataIndex: "qty_2",
              key: "qty_2",
              width: 50,
              children: [
                {
                  title: "S",
                  align: "right",
                  dataIndex: "qty_2",
                  key: "qty_2",
                  width: 55,
                  children: [
                    {
                      title: "12W",
                      align: "right",
                      dataIndex: "qty_2",
                      key: "qty_2",
                      width: 55,
                      children: [
                        {
                          title: "24",
                          align: "right",
                          dataIndex: "qty_2",
                          key: "qty_2",
                          width: 55,
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" ||
                                    data.all_locations.size_1 === "8")
                                  ? 0
                                  : (data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28") ||
                                    (data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "S")
                                    ? Number(data.all_locations.qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_1
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_1
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_1
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_1
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_1))
                                    : Number(data.all_locations.qty_2) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_2
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_2
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_2
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_2
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_2
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_2
                                      ))
                                }
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '3XL',
              dataIndex: "qty_3",
              key: "qty_3",
              align: "right",
              width: 60,
              children: [
                {
                  title: "M",
                  dataIndex: "qty_3",
                  key: "qty_3",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "14W",
                      dataIndex: "qty_3",
                      key: "qty_3",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "25",
                          dataIndex: "qty_3",
                          key: "qty_3",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" ||
                                    data.all_locations.size_1 === "8")
                                  ? 0
                                  : (data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28") ||
                                    (data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "S")
                                    ? Number(data.all_locations.qty_2) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_2
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_2
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_2
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_2
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_2))
                                    : Number(data.all_locations.qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_3
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_3
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_3
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_3
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_3
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_3
                                      ))
                                }
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_4",
              key: "qty_4",
              width: 50,
              align: "right",
              children: [
                {
                  title: "L",
                  dataIndex: "qty_4",
                  key: "qty_4",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "16W",
                      dataIndex: "qty_4",
                      key: "qty_4",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "26",
                          dataIndex: "qty_4",
                          key: "qty_4",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" ||
                                    data.all_locations.size_1 === "8")
                                  ? 0
                                  : (data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28") ||
                                    (data.gender_name === "Kids" &&
                                      data.all_locations.size_1 === "S")
                                    ? Number(data.all_locations.qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_3
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_3
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_3
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_3
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_3))
                                    : Number(data.all_locations.qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_4
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_4
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_4
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_4
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_4
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_4
                                      ))
                                }
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_5",
              key: "qty_5",
              width: 50,
              align: "right",
              children: [
                {
                  title: "XL",
                  dataIndex: "qty_5",
                  key: "qty_5",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "18W",
                      dataIndex: "qty_5",
                      key: "qty_5",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "27",
                          dataIndex: "qty_5",
                          key: "qty_5",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" ||
                                    data.all_locations.size_1 === "8")
                                  ? 0
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_4
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_4
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_4
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_4
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_4))
                                    : Number(data.all_locations.qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_5
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_5
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_5
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_5
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_5
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_5
                                      ))
                                }
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_6",
              key: "qty_6",
              width: 50,
              align: "right",
              children: [
                {
                  title: "XXL",
                  dataIndex: "qty_6",
                  key: "qty_6",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "28W",
                      dataIndex: "qty_6",
                      key: "qty_6",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "28",
                          dataIndex: "qty_6",
                          key: "qty_6",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" ||
                                    data.all_locations.size_1 === "8")
                                  ? 0
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_5
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_5
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_5
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_5
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_5))
                                    : Number(data.all_locations.qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_6
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_6
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_6
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_6
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_6
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_6
                                      ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_7",
              key: "qty_7",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_7",
                  key: "qty_7",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "22W",
                      dataIndex: "qty_7",
                      key: "qty_7",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "29",
                          dataIndex: "qty_7",
                          key: "qty_7",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? 0
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_1) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_1
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_1
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_1
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_1
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_1))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_6) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_6
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_6
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_6
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_6
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_6))
                                      : Number(data.all_locations.qty_7) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_7
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_7
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_7
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_7
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_7
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_7
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_8",
              key: "qty_8",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_8",
                  key: "qty_8",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "24W",
                      dataIndex: "qty_8",
                      key: "qty_8",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "30",
                          dataIndex: "qty_8",
                          key: "qty_8",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_1) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_1
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_1
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_1
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_1
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_1))
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_2) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_2
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_2
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_2
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_2
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_2))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_7) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_7
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_7
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_7
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_7
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_7))
                                      : Number(data.all_locations.qty_8) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_8
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_8
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_8
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_8
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_8
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_8
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_9",
              key: "qty_9",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_9",
                  key: "qty_9",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "26W",
                      dataIndex: "qty_9",
                      key: "qty_9",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "31",
                          dataIndex: "qty_9",
                          key: "qty_9",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_2) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_2
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_2
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_2
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_2
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_2))
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_3) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_3
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_3
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_3
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_3
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_3))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_8) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_8
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_8
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_8
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_8
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_8))
                                      : Number(data.all_locations.qty_9) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_9
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_9
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_9
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_9
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_9
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_9
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_10",
              key: "qty_10",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_10",
                  key: "qty_10",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "28W",
                      dataIndex: "qty_10",
                      key: "qty_10",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "32",
                          dataIndex: "qty_10",
                          key: "qty_10",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_3) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_3
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_3
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_3
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_3
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_3))
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_4) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_4
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_4
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_4
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_4
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_4))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_9) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_9
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_9
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_9
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_9
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(data.all_locations.saks_qty_9))
                                      : Number(data.all_locations.qty_10) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_10
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_10
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_10
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_10
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_10
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_10
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_11",
              key: "qty_11",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_11",
                  key: "qty_11",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "30W",
                      dataIndex: "qty_11",
                      key: "qty_11",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "33",
                          dataIndex: "qty_11",
                          key: "qty_11",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_4) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_4
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_4
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_4
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_4
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_4))
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_5) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_5
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_5
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_5
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_5
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_5))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_10) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_10
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_10
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_10
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_10
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_10
                                        ))
                                      : Number(data.all_locations.qty_11) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_11
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_11
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_11
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_11
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_11
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_11
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_12",
              key: "qty_12",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_12",
                  key: "qty_12",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "32W",
                      dataIndex: "qty_12",
                      key: "qty_12",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "34",
                          dataIndex: "qty_12",
                          key: "qty_12",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_5) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_5
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_5
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_5
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_5
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_5))
                                  : data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "7"
                                    ? Number(data.all_locations.qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_6
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_6
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_6
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_6
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_6))
                                    : data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_11) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_11
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_11
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_11
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_11
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_11
                                        ))
                                      : Number(data.all_locations.qty_12) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_12
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_12
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_12
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_12
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_12
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_12
                                        ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_13",
              key: "qty_13",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_13",
                  key: "qty_13",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_13",
                      key: "qty_13",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "35",
                          dataIndex: "qty_13",
                          key: "qty_13",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Kids" &&
                                  (data.all_locations.size_1 === "7" || data.all_locations.size_1 === "8")
                                  ? Number(data.all_locations.qty_7) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_7
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_7
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_7
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_7
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_7))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_12) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_12
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_12
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_12
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_12
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_12
                                      ))
                                    : Number(data.all_locations.qty_13) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_13
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_13
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_13
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_13
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_13
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_13
                                      ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_14",
              key: "qty_14",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_14",
                  key: "qty_14",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_14",
                      key: "qty_14",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "36",
                          dataIndex: "qty_14",
                          key: "qty_14",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {
                                  data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "8"
                                    ? Number(data.all_locations.qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_6
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_6
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_6
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_6
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_6))
                                    :
                                    data.gender_name === "Men" &&
                                      data.all_locations.size_1 === "28"
                                      ? Number(data.all_locations.qty_13) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_13
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_13
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_13
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_13
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_13
                                        ))
                                      : Number(data.all_locations.qty_14) +
                                      (filters.includes("BERGEN ECOM") &&
                                        Number(
                                          data.all_locations.ecommerce_qty_14
                                        )) +
                                      (filters.includes("NORD D2C") &&
                                        Number(
                                          data.all_locations.nord_qty_14
                                        )) +
                                      (filters.includes("VERISHOP D2C") &&
                                        Number(
                                          data.all_locations.verishop_qty_14
                                        )) +
                                      (filters.includes("Neiman D2C") &&
                                        Number(
                                          data.all_locations.neiman_qty_14
                                        )) +
                                      (filters.includes("Saks D2C") &&
                                        Number(
                                          data.all_locations.saks_qty_14
                                        )) +
                                      (filters.includes("24S D2C") &&
                                        Number(
                                          data.all_locations.tfs_qty_14
                                        ))}
                              </>
                            );
                          },
                        }
                      ]
                    }
                  ]
                },
              ],
            },
            {
              title: "-",
              dataIndex: "qty_15",
              key: "qty_15",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_15",
                  key: "qty_15",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_15",
                      key: "qty_15",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "37",
                          dataIndex: "qty_15",
                          key: "qty_15",
                          width: 50,
                          align: "right",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28"
                                  ? Number(data.all_locations.qty_14) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_14
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_14
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_14
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_14
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_14
                                    ))
                                  : Number(data.all_locations.qty_15) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_15
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_15
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_15
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_15
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_15
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_16
                                    ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_16",
              key: "qty_16",
              width: 50,
              align: "right",
              className: "Border-Right-table border-z-index-1",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_16",
                  key: "qty_16",
                  width: 50,
                  align: "right",
                  className: "Border-Right-table border-z-index-1",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_16",
                      key: "qty_16",
                      width: 50,
                      align: "right",
                      className: "Border-Right-table border-z-index-1",
                      children: [
                        {
                          title: "38",
                          dataIndex: "qty_16",
                          key: "qty_16",
                          width: 50,
                          align: "right",
                          className: "Border-Right-table border-z-index-1",
                          render: (text, data) => {
                            return (
                              <>
                                {data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28"
                                  ? Number(data.all_locations.qty_15) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_15
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_15
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_15
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_15
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_15
                                    ))
                                  : Number(data.all_locations.qty_16) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_16
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_16
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_16
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_16
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_16
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_16
                                    ))}
                              </>
                            );
                          },
                        },
                      ],
                    }
                  ]
                }
              ]
            },
          ],
        }
      )
        : sizeChartRendering === "Men" || (sizeChartRenderingSKU !== "All" && skuLookUpData?.payload?.data[0]?.gender_name === "Men") ? (

          // for Men
          {
            children: [
              {
                title: "Shirt/RTW-P",
                dataIndex: "building",
                key: "building",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-R",
                    dataIndex: "building",
                    key: "building",
                    width: 75,
                    children: [
                      {
                        title: "Men",
                        dataIndex: "building",
                        key: "building",
                        width: 75,
                      }
                    ]
                  }
                ]
              },
              {
                title: '1XL',
                align: "right",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                children: [
                  {
                    title: "XS",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "27",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {
                                (data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28") ||
                                  (data.gender_name === "Kids" &&
                                    (data.all_locations.size_1 === "7" ||
                                      data.all_locations.size_1 === "8")) ||
                                  (data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "S")
                                  ? 0
                                  : Number(data.all_locations.qty_1) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_1
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_1
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_1
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_1
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_1
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_1
                                    ))
                              }
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: '2XL',
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 50,
                children: [
                  {
                    title: "S",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "28",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" ||
                                  data.all_locations.size_1 === "8")
                                ? 0
                                : (data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28") ||
                                  (data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "S")
                                  ? Number(data.all_locations.qty_1) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_1
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_1
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_1
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_1
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_1))
                                  : Number(data.all_locations.qty_2) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_2
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_2
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_2
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_2
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_2
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_2
                                    ))
                              }
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: '3XL',
                dataIndex: "qty_3",
                key: "qty_3",
                align: "right",
                width: 60,
                children: [
                  {
                    title: "M",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "29",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" ||
                                  data.all_locations.size_1 === "8")
                                ? 0
                                : (data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28") ||
                                  (data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "S")
                                  ? Number(data.all_locations.qty_2) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_2
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_2
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_2
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_2
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_2))
                                  : Number(data.all_locations.qty_3) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_3
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_3
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_3
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_3
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_3
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_3
                                    ))
                              }
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "L",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "30",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" ||
                                  data.all_locations.size_1 === "8")
                                ? 0
                                : (data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28") ||
                                  (data.gender_name === "Kids" &&
                                    data.all_locations.size_1 === "S")
                                  ? Number(data.all_locations.qty_3) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_3
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_3
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_3
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_3
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_3))
                                  : Number(data.all_locations.qty_4) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_4
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_4
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_4
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_4
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_4
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_4
                                    ))
                              }
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XL",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "31",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" ||
                                  data.all_locations.size_1 === "8")
                                ? 0
                                : data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28"
                                  ? Number(data.all_locations.qty_4) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_4
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_4
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_4
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_4
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_4))
                                  : Number(data.all_locations.qty_5) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_5
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_5
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_5
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_5
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_5
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_5
                                    ))
                              }
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XXL",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "32",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" ||
                                  data.all_locations.size_1 === "8")
                                ? 0
                                : data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28"
                                  ? Number(data.all_locations.qty_5) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_5
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_5
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_5
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_5
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_5))
                                  : Number(data.all_locations.qty_6) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_6
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_6
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_6
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_6
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_6
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_6
                                    ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "33",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? 0
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_1) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_1
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_1
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_1
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_1
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_1))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_6) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_6
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_6
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_6
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_6
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_6))
                                    : Number(data.all_locations.qty_7) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_7
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_7
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_7
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_7
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_7
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_7
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "34",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? Number(data.all_locations.qty_1) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_1
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_1
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_1
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_1
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_1))
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_2) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_2
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_2
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_2
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_2
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_2))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_7) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_7
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_7
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_7
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_7
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_7))
                                    : Number(data.all_locations.qty_8) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_8
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_8
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_8
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_8
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_8
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_8
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "35",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? Number(data.all_locations.qty_2) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_2
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_2
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_2
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_2
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_2))
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_3) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_3
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_3
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_3
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_3
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_3))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_8) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_8
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_8
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_8
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_8
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_8))
                                    : Number(data.all_locations.qty_9) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_9
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_9
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_9
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_9
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_9
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_9
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "36",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? Number(data.all_locations.qty_3) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_3
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_3
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_3
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_3
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_3))
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_4) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_4
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_4
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_4
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_4
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_4))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_9) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_9
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_9
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_9
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_9
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(data.all_locations.saks_qty_9))
                                    : Number(data.all_locations.qty_10) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_10
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_10
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_10
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_10
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_10
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_10
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "38",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? Number(data.all_locations.qty_4) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_4
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_4
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_4
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_4
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_4))
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_5) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_5
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_5
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_5
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_5
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_5))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_10) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_10
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_10
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_10
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_10
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_10
                                      ))
                                    : Number(data.all_locations.qty_11) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_11
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_11
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_11
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_11
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_11
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_11
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "40",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                data.all_locations.size_1 === "8"
                                ? Number(data.all_locations.qty_5) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_5
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_5
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_5
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_5
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_5))
                                : data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "7"
                                  ? Number(data.all_locations.qty_6) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_6
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_6
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_6
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_6
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_6))
                                  : data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_11) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_11
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_11
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_11
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_11
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_11
                                      ))
                                    : Number(data.all_locations.qty_12) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_12
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_12
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_12
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_12
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_12
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_12
                                      ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "42",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Kids" &&
                                (data.all_locations.size_1 === "7" || data.all_locations.size_1 === "8")
                                ? Number(data.all_locations.qty_7) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_7
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_7
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_7
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_7
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(data.all_locations.saks_qty_7))
                                : data.gender_name === "Men" &&
                                  data.all_locations.size_1 === "28"
                                  ? Number(data.all_locations.qty_12) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_12
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_12
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_12
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_12
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_12
                                    ))
                                  : Number(data.all_locations.qty_13) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_13
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_13
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_13
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_13
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(
                                      data.all_locations.saks_qty_13
                                    )) +
                                  (filters.includes("24S D2C") &&
                                    Number(
                                      data.all_locations.tfs_qty_13
                                    ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "44",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {
                                data.gender_name === "Kids" &&
                                  data.all_locations.size_1 === "8"
                                  ? Number(data.all_locations.qty_6) +
                                  (filters.includes("BERGEN ECOM") &&
                                    Number(
                                      data.all_locations.ecommerce_qty_6
                                    )) +
                                  (filters.includes("NORD D2C") &&
                                    Number(
                                      data.all_locations.nord_qty_6
                                    )) +
                                  (filters.includes("VERISHOP D2C") &&
                                    Number(
                                      data.all_locations.verishop_qty_6
                                    )) +
                                  (filters.includes("Neiman D2C") &&
                                    Number(
                                      data.all_locations.neiman_qty_6
                                    )) +
                                  (filters.includes("Saks D2C") &&
                                    Number(data.all_locations.saks_qty_6))
                                  :
                                  data.gender_name === "Men" &&
                                    data.all_locations.size_1 === "28"
                                    ? Number(data.all_locations.qty_13) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_13
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_13
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_13
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_13
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_13
                                      ))
                                    : Number(data.all_locations.qty_14) +
                                    (filters.includes("BERGEN ECOM") &&
                                      Number(
                                        data.all_locations.ecommerce_qty_14
                                      )) +
                                    (filters.includes("NORD D2C") &&
                                      Number(
                                        data.all_locations.nord_qty_14
                                      )) +
                                    (filters.includes("VERISHOP D2C") &&
                                      Number(
                                        data.all_locations.verishop_qty_14
                                      )) +
                                    (filters.includes("Neiman D2C") &&
                                      Number(
                                        data.all_locations.neiman_qty_14
                                      )) +
                                    (filters.includes("Saks D2C") &&
                                      Number(
                                        data.all_locations.saks_qty_14
                                      )) +
                                    (filters.includes("24S D2C") &&
                                      Number(
                                        data.all_locations.tfs_qty_14
                                      ))}
                            </>
                          );
                        },
                      },
                    ],
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "46",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Men" &&
                                data.all_locations.size_1 === "28"
                                ? Number(data.all_locations.qty_14) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_14
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_14
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_14
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_14
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(
                                    data.all_locations.saks_qty_14
                                  ))
                                : Number(data.all_locations.qty_15) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_15
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_15
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_15
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_15
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(
                                    data.all_locations.saks_qty_15
                                  )) +
                                (filters.includes("24S D2C") &&
                                  Number(
                                    data.all_locations.tfs_qty_16
                                  ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "48",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                        render: (text, data) => {
                          return (
                            <>
                              {data.gender_name === "Men" &&
                                data.all_locations.size_1 === "28"
                                ? Number(data.all_locations.qty_15) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_15
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_15
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_15
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_15
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(
                                    data.all_locations.saks_qty_15
                                  ))
                                : Number(data.all_locations.qty_16) +
                                (filters.includes("BERGEN ECOM") &&
                                  Number(
                                    data.all_locations.ecommerce_qty_16
                                  )) +
                                (filters.includes("NORD D2C") &&
                                  Number(
                                    data.all_locations.nord_qty_16
                                  )) +
                                (filters.includes("VERISHOP D2C") &&
                                  Number(
                                    data.all_locations.verishop_qty_16
                                  )) +
                                (filters.includes("Neiman D2C") &&
                                  Number(
                                    data.all_locations.neiman_qty_16
                                  )) +
                                (filters.includes("Saks D2C") &&
                                  Number(
                                    data.all_locations.saks_qty_16
                                  )) +
                                (filters.includes("24S D2C") &&
                                  Number(
                                    data.all_locations.tfs_qty_16
                                  ))}
                            </>
                          );
                        },
                      }
                    ]
                  }]
              },
            ],
          }) :

          ///for All
          {
            children: [
              {
                title: "T-Jacket",
                dataIndex: "building",
                key: "building",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-P",
                    dataIndex: "building",
                    key: "building",
                    width: 75,
                    children: [
                      {
                        title: "Shirt/RTW-R",
                        dataIndex: "building",
                        key: "building",
                        width: 75,
                        children: [
                          {
                            title: "Y-Adult",
                            dataIndex: "building",
                            key: "building",
                            width: 75,
                            children: [
                              {
                                title: "W-Plus",
                                dataIndex: "building",
                                key: "building",
                                width: 75,
                                children: [
                                  {
                                    title: "Kids",
                                    dataIndex: "building",
                                    key: "building",
                                    width: 75,
                                    children: [
                                      {
                                        title: "Men",
                                        dataIndex: "building",
                                        key: "building",
                                        width: 75,
                                        children: [
                                          {
                                            title: "Women",
                                            dataIndex: "building",
                                            key: "building",
                                            width: 75,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"2/3"',
                align: "right",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                children: [
                  {
                    title: "1XL",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "XS",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                        children: [
                          {
                            title: "00",
                            dataIndex: "qty_1",
                            key: "qty_1",
                            width: 40,
                            align: "right",
                            children: [
                              {
                                title: "10W",
                                dataIndex: "qty_1",
                                key: "qty_1",
                                width: 40,
                                align: "right",
                                children: [
                                  {
                                    title: "2",
                                    dataIndex: "qty_1",
                                    key: "qty_1",
                                    width: 40,
                                    align: "right",
                                    children: [
                                      {
                                        title: "27",
                                        dataIndex: "qty_1",
                                        key: "qty_1",
                                        align: "right",
                                        width: 40,
                                        children: [
                                          {
                                            title: "23",
                                            dataIndex: "qty_1",
                                            key: "qty_1",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {
                                                    (data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28") ||
                                                      (data.gender_name === "Kids" &&
                                                        (data.all_locations.size_1 === "7" ||
                                                          data.all_locations.size_1 === "8")) ||
                                                      (data.gender_name === "Kids" &&
                                                        data.all_locations.size_1 === "S")
                                                      ? 0
                                                      : Number(data.all_locations.qty_1) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_1
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_1
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_1
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_1
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_1
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_1
                                                        ))
                                                  }
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"4/5"',
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 50,
                children: [
                  {
                    title: "2XL",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "S",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                        children: [
                          {
                            title: "0",
                            align: "right",
                            dataIndex: "qty_2",
                            key: "qty_2",
                            width: 55,
                            children: [
                              {
                                title: "12W",
                                align: "right",
                                dataIndex: "qty_2",
                                key: "qty_2",
                                width: 55,
                                children: [
                                  {
                                    title: "3",
                                    align: "right",
                                    dataIndex: "qty_2",
                                    key: "qty_2",
                                    width: 50,
                                    children: [
                                      {
                                        title: "28",
                                        align: "right",
                                        dataIndex: "qty_2",
                                        key: "qty_2",
                                        width: 50,
                                        children: [
                                          {
                                            title: "24",
                                            align: "right",
                                            dataIndex: "qty_2",
                                            key: "qty_2",
                                            width: 40,
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" ||
                                                      data.all_locations.size_1 === "8")
                                                    ? 0
                                                    : (data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28") ||
                                                      (data.gender_name === "Kids" &&
                                                        data.all_locations.size_1 === "S")
                                                      ? Number(data.all_locations.qty_1) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_1
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_1
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_1
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_1
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_1))
                                                      : Number(data.all_locations.qty_2) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_2
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_2
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_2
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_2
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_2
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_2
                                                        ))
                                                  }
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"6/6X"',
                dataIndex: "qty_3",
                key: "qty_3",
                align: "right",
                width: 60,
                children: [
                  {
                    title: "3XL",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "M",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "1",
                            dataIndex: "qty_3",
                            key: "qty_3",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "14W",
                                dataIndex: "qty_3",
                                key: "qty_3",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "4",
                                    dataIndex: "qty_3",
                                    key: "qty_3",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "29",
                                        dataIndex: "qty_3",
                                        key: "qty_3",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "25",
                                            dataIndex: "qty_3",
                                            key: "qty_3",
                                            width: 45,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" ||
                                                      data.all_locations.size_1 === "8")
                                                    ? 0
                                                    : (data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28") ||
                                                      (data.gender_name === "Kids" &&
                                                        data.all_locations.size_1 === "S")
                                                      ? Number(data.all_locations.qty_2) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_2
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_2
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_2
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_2
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_2))
                                                      : Number(data.all_locations.qty_3) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_3
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_3
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_3
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_3
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_3
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_3
                                                        ))
                                                  }
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "L",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "2",
                            dataIndex: "qty_4",
                            key: "qty_4",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "16W",
                                dataIndex: "qty_4",
                                key: "qty_4",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "5",
                                    dataIndex: "qty_4",
                                    key: "qty_4",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "30",
                                        dataIndex: "qty_4",
                                        key: "qty_4",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "26",
                                            dataIndex: "qty_4",
                                            key: "qty_4",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" ||
                                                      data.all_locations.size_1 === "8")
                                                    ? 0
                                                    : (data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28") ||
                                                      (data.gender_name === "Kids" &&
                                                        data.all_locations.size_1 === "S")
                                                      ? Number(data.all_locations.qty_3) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_3
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_3
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_3
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_3
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_3))
                                                      : Number(data.all_locations.qty_4) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_4
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_4
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_4
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_4
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_4
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_4
                                                        ))
                                                  }
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "XL",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "3",
                            dataIndex: "qty_5",
                            key: "qty_5",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "18W",
                                dataIndex: "qty_5",
                                key: "qty_5",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "6",
                                    dataIndex: "qty_5",
                                    key: "qty_5",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "31",
                                        dataIndex: "qty_5",
                                        key: "qty_5",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "27",
                                            dataIndex: "qty_5",
                                            key: "qty_5",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" ||
                                                      data.all_locations.size_1 === "8")
                                                    ? 0
                                                    : data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28"
                                                      ? Number(data.all_locations.qty_4) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_4
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_4
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_4
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_4
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_4))
                                                      : Number(data.all_locations.qty_5) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_5
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_5
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_5
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_5
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_5
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_5
                                                        ))
                                                  }
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "XXL",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "4",
                            dataIndex: "qty_6",
                            key: "qty_6",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "20W",
                                dataIndex: "qty_6",
                                key: "qty_6",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "6x",
                                    dataIndex: "qty_6",
                                    key: "qty_6",
                                    width: 50,
                                    align: "right",

                                    children: [
                                      {
                                        title: "32",
                                        dataIndex: "qty_6",
                                        key: "qty_6",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "28",
                                            dataIndex: "qty_6",
                                            key: "qty_6",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" ||
                                                      data.all_locations.size_1 === "8")
                                                    ? 0
                                                    : data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28"
                                                      ? Number(data.all_locations.qty_5) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_5
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_5
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_5
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_5
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_5))
                                                      : Number(data.all_locations.qty_6) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_6
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_6
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_6
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_6
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_6
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_6
                                                        ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "5",
                            dataIndex: "qty_7",
                            key: "qty_7",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "22W",
                                dataIndex: "qty_7",
                                key: "qty_7",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "7",
                                    dataIndex: "qty_7",
                                    key: "qty_7",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "33",
                                        dataIndex: "qty_7",
                                        key: "qty_7",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "29",
                                            dataIndex: "qty_7",
                                            key: "qty_7",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? 0
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_1) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_1
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_1
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_1
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_1
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_1))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_6) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_6
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_6
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_6
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_6
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(data.all_locations.saks_qty_6))
                                                        : Number(data.all_locations.qty_7) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_7
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_7
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_7
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_7
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_7
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_7
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6",
                            dataIndex: "qty_8",
                            key: "qty_8",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "24W",
                                dataIndex: "qty_8",
                                key: "qty_8",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "8",
                                    dataIndex: "qty_8",
                                    key: "qty_8",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "34",
                                        dataIndex: "qty_8",
                                        key: "qty_8",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "30",
                                            dataIndex: "qty_8",
                                            key: "qty_8",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? Number(data.all_locations.qty_1) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_1
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_1
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_1
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_1
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_1))
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_2) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_2
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_2
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_2
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_2
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_2))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_7) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_7
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_7
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_7
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_7
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(data.all_locations.saks_qty_7))
                                                        : Number(data.all_locations.qty_8) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_8
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_8
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_8
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_8
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_8
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_8
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "7",
                            dataIndex: "qty_9",
                            key: "qty_9",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "26W",
                                dataIndex: "qty_9",
                                key: "qty_9",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "10",
                                    dataIndex: "qty_9",
                                    key: "qty_9",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "35",
                                        dataIndex: "qty_9",
                                        key: "qty_9",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "31",
                                            dataIndex: "qty_9",
                                            key: "qty_9",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? Number(data.all_locations.qty_2) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_2
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_2
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_2
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_2
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_2))
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_3) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_3
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_3
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_3
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_3
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_3))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_8) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_8
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_8
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_8
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_8
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(data.all_locations.saks_qty_8))
                                                        : Number(data.all_locations.qty_9) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_9
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_9
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_9
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_9
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_9
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_9
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "8",
                            dataIndex: "qty_10",
                            key: "qty_10",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "28W",
                                dataIndex: "qty_10",
                                key: "qty_10",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "12",
                                    dataIndex: "qty_10",
                                    key: "qty_10",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "36",
                                        dataIndex: "qty_10",
                                        key: "qty_10",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "32",
                                            dataIndex: "qty_10",
                                            key: "qty_10",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? Number(data.all_locations.qty_3) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_3
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_3
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_3
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_3
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_3))
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_4) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_4
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_4
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_4
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_4
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_4))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_9) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_9
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_9
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_9
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_9
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(data.all_locations.saks_qty_9))
                                                        : Number(data.all_locations.qty_10) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_10
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_10
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_10
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_10
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_10
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_10
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "9",
                            dataIndex: "qty_11",
                            key: "qty_11",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "30W",
                                dataIndex: "qty_11",
                                key: "qty_11",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "14",
                                    dataIndex: "qty_11",
                                    key: "qty_11",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "38",
                                        dataIndex: "qty_11",
                                        key: "qty_11",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "33",
                                            dataIndex: "qty_11",
                                            key: "qty_11",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? Number(data.all_locations.qty_4) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_4
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_4
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_4
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_4
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_4))
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_5) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_5
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_5
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_5
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_5
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_5))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_10) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_10
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_10
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_10
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_10
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_10
                                                          ))
                                                        : Number(data.all_locations.qty_11) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_11
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_11
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_11
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_11
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_11
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_11
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "10",
                            dataIndex: "qty_12",
                            key: "qty_12",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "32W",
                                dataIndex: "qty_12",
                                key: "qty_12",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "16",
                                    dataIndex: "qty_12",
                                    key: "qty_12",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "40",
                                        dataIndex: "qty_12",
                                        key: "qty_12",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "34",
                                            dataIndex: "qty_12",
                                            key: "qty_12",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    data.all_locations.size_1 === "8"
                                                    ? Number(data.all_locations.qty_5) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_5
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_5
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_5
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_5
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_5))
                                                    : data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "7"
                                                      ? Number(data.all_locations.qty_6) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_6
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_6
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_6
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_6
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_6))
                                                      : data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_11) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_11
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_11
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_11
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_11
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_11
                                                          ))
                                                        : Number(data.all_locations.qty_12) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_12
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_12
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_12
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_12
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_12
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_12
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_13",
                            key: "qty_13",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_13",
                                key: "qty_13",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "18",
                                    dataIndex: "qty_13",
                                    key: "qty_13",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "42",
                                        dataIndex: "qty_13",
                                        key: "qty_13",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "35",
                                            dataIndex: "qty_13",
                                            key: "qty_13",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Kids" &&
                                                    (data.all_locations.size_1 === "7" || data.all_locations.size_1 === "8")
                                                    ? Number(data.all_locations.qty_7) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_7
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_7
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_7
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_7
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(data.all_locations.saks_qty_7))
                                                    : data.gender_name === "Men" &&
                                                      data.all_locations.size_1 === "28"
                                                      ? Number(data.all_locations.qty_12) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_12
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_12
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_12
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_12
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_12
                                                        ))
                                                      : Number(data.all_locations.qty_13) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_13
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_13
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_13
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_13
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(
                                                          data.all_locations.saks_qty_13
                                                        )) +
                                                      (filters.includes("24S D2C") &&
                                                        Number(
                                                          data.all_locations.tfs_qty_13
                                                        ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",

                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_14",
                            key: "qty_14",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_14",
                                key: "qty_14",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "20",
                                    dataIndex: "qty_14",
                                    key: "qty_14",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "44",
                                        dataIndex: "qty_14",
                                        key: "qty_14",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "36",
                                            dataIndex: "qty_14",
                                            key: "qty_14",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {
                                                    data.gender_name === "Kids" &&
                                                      data.all_locations.size_1 === "8"
                                                      ? Number(data.all_locations.qty_6) +
                                                      (filters.includes("BERGEN ECOM") &&
                                                        Number(
                                                          data.all_locations.ecommerce_qty_6
                                                        )) +
                                                      (filters.includes("NORD D2C") &&
                                                        Number(
                                                          data.all_locations.nord_qty_6
                                                        )) +
                                                      (filters.includes("VERISHOP D2C") &&
                                                        Number(
                                                          data.all_locations.verishop_qty_6
                                                        )) +
                                                      (filters.includes("Neiman D2C") &&
                                                        Number(
                                                          data.all_locations.neiman_qty_6
                                                        )) +
                                                      (filters.includes("Saks D2C") &&
                                                        Number(data.all_locations.saks_qty_6))
                                                      :
                                                      data.gender_name === "Men" &&
                                                        data.all_locations.size_1 === "28"
                                                        ? Number(data.all_locations.qty_13) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_13
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_13
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_13
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_13
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_13
                                                          ))
                                                        : Number(data.all_locations.qty_14) +
                                                        (filters.includes("BERGEN ECOM") &&
                                                          Number(
                                                            data.all_locations.ecommerce_qty_14
                                                          )) +
                                                        (filters.includes("NORD D2C") &&
                                                          Number(
                                                            data.all_locations.nord_qty_14
                                                          )) +
                                                        (filters.includes("VERISHOP D2C") &&
                                                          Number(
                                                            data.all_locations.verishop_qty_14
                                                          )) +
                                                        (filters.includes("Neiman D2C") &&
                                                          Number(
                                                            data.all_locations.neiman_qty_14
                                                          )) +
                                                        (filters.includes("Saks D2C") &&
                                                          Number(
                                                            data.all_locations.saks_qty_14
                                                          )) +
                                                        (filters.includes("24S D2C") &&
                                                          Number(
                                                            data.all_locations.tfs_qty_14
                                                          ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          }
                        ]
                      }]
                  },
                ],
              },
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_15",
                            key: "qty_15",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_15",
                                key: "qty_15",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "-",
                                    dataIndex: "qty_15",
                                    key: "qty_15",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "46",
                                        dataIndex: "qty_15",
                                        key: "qty_15",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "37",
                                            dataIndex: "qty_15",
                                            key: "qty_15",
                                            width: 40,
                                            align: "right",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Men" &&
                                                    data.all_locations.size_1 === "28"
                                                    ? Number(data.all_locations.qty_14) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_14
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_14
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_14
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_14
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(
                                                        data.all_locations.saks_qty_14
                                                      ))
                                                    : Number(data.all_locations.qty_15) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_15
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_15
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_15
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_15
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(
                                                        data.all_locations.saks_qty_15
                                                      )) +
                                                    (filters.includes("24S D2C") &&
                                                      Number(
                                                        data.all_locations.tfs_qty_16
                                                      ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_16",
                            key: "qty_16",
                            width: 50,
                            align: "right",
                            className: "Border-Right-table border-z-index-1",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_16",
                                key: "qty_16",
                                width: 50,
                                align: "right",
                                className: "Border-Right-table border-z-index-1",
                                children: [
                                  {
                                    title: "-",
                                    dataIndex: "qty_16",
                                    key: "qty_16",
                                    width: 50,
                                    align: "right",
                                    className: "Border-Right-table border-z-index-1",
                                    children: [
                                      {
                                        title: "48",
                                        dataIndex: "qty_16",
                                        key: "qty_16",
                                        width: 50,
                                        align: "right",
                                        className: "Border-Right-table border-z-index-1",
                                        children: [
                                          {
                                            title: "38",
                                            dataIndex: "qty_16",
                                            key: "qty_16",
                                            width: 40,
                                            align: "right",
                                            className: "Border-Right-table border-z-index-1",
                                            render: (text, data) => {
                                              return (
                                                <>
                                                  {data.gender_name === "Men" &&
                                                    data.all_locations.size_1 === "28"
                                                    ? Number(data.all_locations.qty_15) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_15
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_15
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_15
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_15
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(
                                                        data.all_locations.saks_qty_15
                                                      ))
                                                    : Number(data.all_locations.qty_16) +
                                                    (filters.includes("BERGEN ECOM") &&
                                                      Number(
                                                        data.all_locations.ecommerce_qty_16
                                                      )) +
                                                    (filters.includes("NORD D2C") &&
                                                      Number(
                                                        data.all_locations.nord_qty_16
                                                      )) +
                                                    (filters.includes("VERISHOP D2C") &&
                                                      Number(
                                                        data.all_locations.verishop_qty_16
                                                      )) +
                                                    (filters.includes("Neiman D2C") &&
                                                      Number(
                                                        data.all_locations.neiman_qty_16
                                                      )) +
                                                    (filters.includes("Saks D2C") &&
                                                      Number(
                                                        data.all_locations.saks_qty_16
                                                      )) +
                                                    (filters.includes("24S D2C") &&
                                                      Number(
                                                        data.all_locations.tfs_qty_16
                                                      ))}
                                                </>
                                              );
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
            ],
          },

    {
      title: "Total",
      align: "right",
      className: "border-z-index-1",
      render: (text, record) => {
        return (
          <>
            {Number(record.all_locations.ots) +
              (filters.includes("BERGEN ECOM") &&
                Number(record.all_locations.ecommerce)) +
              (filters.includes("NORD D2C") &&
                Number(record.all_locations.nord)) +
              (filters.includes("VERISHOP D2C") &&
                Number(record.all_locations.verishop)) +
              (filters.includes("Neiman D2C") &&
                Number(record.all_locations.neiman)) +
              (filters.includes("Saks D2C") &&
                Number(record.all_locations.saks)) +
              (filters.includes("24S D2C") &&
                Number(record.all_locations.tfs))}
          </>
        );
      },
      sorter: (a, b) => {
        const sumA =
          Number(a.all_locations.ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(a.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") && Number(a.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(a.all_locations.verishop)) +
          (filters.includes("Neiman D2C") && Number(a.all_locations.neiman)) +
          (filters.includes("Saks D2C") && Number(a.all_locations.saks)) +
          (filters.includes("24S D2C") &&
            Number(a.all_locations.tfs));

        const sumB =
          Number(b.all_locations.ots) +
          (filters.includes("BERGEN ECOM") &&
            Number(b.all_locations.ecommerce)) +
          (filters.includes("NORD D2C") && Number(b.all_locations.nord)) +
          (filters.includes("VERISHOP D2C") &&
            Number(b.all_locations.verishop)) +
          (filters.includes("Neiman D2C") && Number(b.all_locations.neiman)) +
          (filters.includes("Saks D2C") && Number(b.all_locations.saks)) +
          (filters.includes("24S D2C") &&
            Number(a.all_locations.tfs));
        return sumA - sumB;
      },
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      width: 30,
      render: (text, record) => (
        <div className="image-nu">
          <Image src={text} />
        </div>
      ),
    }
  ];

  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Imediate-OTS-By-Size.csv"}
                  data={excelData}
                  onClick={downloadExcelFileData}
                  style={{ display: "flex" }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    height={14}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Immediate OTS By Size
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">

              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={
                                allStatus
                                  ? false
                                  : isChecked2.includes(it.product_status_id)
                              }
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div>
                <label htmlFor="">Locations</label>
                <div className="checkboxes">
                  <Checkbox checked={true} disabled>
                    BERGEN LOGISTICS CAN
                  </Checkbox>
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Gender
                    </label>
                    <div className="w-100">
                      <Select
                        className={"select_Inputs"}
                        showSearch
                        optionFilterProp="children"
                        size="small"
                        defaultValue={"All"}
                        onChange={(e) => {
                          setIsGenerate(e);
                        }}
                      >
                        <Option value={null}>All</Option>
                        {gender &&
                          gender?.payload?.data.map((it, index) => {
                            return (
                              <Option key={index} value={it.gender_name}>
                                {it.gender_name}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Season
                    </label>
                    <div className=" w-100">
                      <Form.Item name="season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().season_name}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.season_name}>
                                  {status.season_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Wash
                    </label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_name}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.wash_name}>
                                  {status.wash_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Category
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender_category_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {genderCategoryChange &&
                            genderCategoryChange?.payload?.data.length >= 0
                            ? genderCategoryChange &&
                            genderCategoryChange?.payload?.data.map(
                              (it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_name}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              }
                            )
                            : genderCategory &&
                            genderCategory?.payload?.data.map((it, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={it.gender_category_name}
                                >
                                  {it.gender_category_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row form-margin">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Sku
                    </label>
                    <div className="w-100">
                      <Select
                        notFoundContent={
                          Sku.loading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Loader
                                type="Oval"
                                color="#3c4b58"
                                height={20}
                                width={20}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </div>
                          )
                        }
                        className={"select_Inputs"}
                        showSearch
                        onSearch={(value) => {
                          optimizedFn(value);
                        }}
                        onChange={(e) => {
                          console.log("eeeeeeeeeee", e);
                          setIsSKU(e);
                        }}
                        defaultValue={"All"}
                        optionFilterProp="children"
                        size="small"
                      >
                        <Option value="All">All</Option>
                        {Sku &&
                          Sku?.payload?.data.map((it, index) => {
                            return (
                              <Option key={index} value={it.sku}>
                                {it.sku}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Style
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch

                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {styleChange && styleChange?.payload?.data.length >= 0
                            ? styleChange &&
                            styleChange?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_name}>
                                  {style.style_name}
                                </Option>
                              );
                            })
                            : style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_name}>
                                  {style.style_name}
                                </Option>
                              );
                            })}

                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Brand
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="brand_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={"All"}>All</Option>
                          {brand && brand?.payload?.data.map((item, index) => {
                            return (
                              <Option key={index} value={item.brand_id}>
                                {item.brand_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "3px",
                      float: "right",
                    }}
                  >
                    <Button
                      style={{ width: "fit-content" }}
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div >
        <div
          className="overflow"
          style={{
            overflowY: "scroll",
          }}
        >
          {skuLookUpData?.payload?.data !== undefined ? (
            skuLookUpData?.payload?.data.length > 0 ? (
              <Table
                className="fix-scroll-table  table-header-sticky  border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border"
                style={{ fontSize: "9px" }}
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName="abc"
                dataSource={data}
                columns={colums}
                loading={skuLookUpData.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 50,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
                }}
                scroll={{ x: "max-content" }}
              />
            ) : (
              <div className="no-Data">
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ImediateOtsBySize3;
