import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Input, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";

import { getGender } from "../../redux/actions/gender.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import "../Distributor/Distributor.css";
import { useHistory } from "react-router-dom";

import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { getOmsStockStyleWiseSummary } from "../../redux/actions/omsStockStyleWiseSummary.action";
import numeral from "numeral";
const dateFormatList = ["MM-DD-YYYY"];

const OMSStockStyleWiseSummary = ({ token }) => {
    TabTitle("Stock Style Wise Summary")
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {
        style,
        getWiseStockReport,
        productType,
        gender,
        genderCategory,
        season,
        productStatus,
        productClass,
        size,
        wash,
        fitCategory,
        omsStockSummary,
        omsStockStyleWiseSummary,
        genderCategoryChange,
        styleChange,
        Sku,
        seasonYear
    } = useSelector((state) => state);
    const history = useHistory();
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const tableRef = useRef(null);

    const [isChecked, setIsChecked] = useState([]);
    const [isChecked2, setIsChecked2] = useState([]);
    const [allSesion, setAllSesion] = useState(true);
    const [allStatus, setAllStatus] = useState(true);
    const [isPCChecked, setIsPCChecked] = useState([]);
    const [allPC, setAllPC] = useState(true);
    const [loading, setLoading] = useState(false);
    const [BgradeCheck, setBgradeCheck] = useState(false);
    const [data, setData] = useState([])
    const [excelData, setExcelData] = useState([])


    const [genderName, setGenderName] = useState(null);
    const [styleName, setStyleName] = useState(null);

    const initialDate = moment('2026-12-31')






    let result =
        getWiseStockReport &&
        getWiseStockReport?.payload?.data?.reduce(function (r, a) {
            r[a.item_code] = r[a.item_code] || [];
            r[a.item_code].push(a);
            return r;
        }, Object.create(null));

    const wiseStockReport = result && Object.values(result);

    const values = {
        item_id: null,
        location_id: null,
    };

    const { Option } = Select;
    let pendingDate = "";

    const onChangeDate = (date, dateString) => {
        pendingDate = dateString;
    };

    const sesionSelectAll = (e) => {
        setAllSesion(e.target.checked);
    };
    const statusSelectAll = (e) => {
        setAllStatus(e.target.checked);
    };



    const isCheckboxChecked2 = (id, value) => {
        const filterCheckBox =
            productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
        const checkId = isChecked2.includes(id);
        if (!checkId) {
            setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
        }
        if (!value) {
            const removeUnCheck = isChecked2.filter(
                (item) => item !== filterCheckBox.product_status_id
            );
            setIsChecked2(removeUnCheck);
        }
        setAllStatus(false);
    };



    const onFinish = async (e) => {
        setLoading(true)
        await form.validateFields().then((values) => {

            dispatch(
                getOmsStockStyleWiseSummary(token, values, isChecked, isChecked2, isPCChecked, BgradeCheck)).then((res => {
                    setLoading(false)
                    setData(res?.payload?.data)
                    downloadExcelFileData(res?.payload?.data);
                }));
        });
    };


    const itemCodeTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.no_item_code), 0)
    const berganTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.bergan), 0)
    const transitTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.transit), 0)
    const pkTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pakistan), 0)
    const productionTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.production), 0)
    const pendingTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pending), 0)
    const internationalTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.international), 0)
    const otsTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ots), 0)
    const nordTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.nord), 0)
    const ltD2c = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.lt_d2c), 0)
    const podTotal = omsStockStyleWiseSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pod), 0)


    function downloadExcelFileData(data) {
        if (data.length > 0) {
            setExcelData(
                data.map((v) => {
                    return {
                        "Gender": v.gender_name,
                        "Style": v.style_name,
                        "No of SKU": v.no_item_code,
                        "Bergen": v.bergan,
                        "PK": v.pakistan,
                        "Production": v.production,
                        "P. Order": v.pending,
                        "International": v.international,
                        "OTS": v.ots,
                        "Nord D2C": v.nord,
                        "L&T D2C": v.lt_d2c,
                        "POD": v.pod,
                    };
                })
            )
        }
    }



    // function generatePDF() {
    //   var doc = new jsPDF();

    //   // Define columns and rows
    //   var columns = ["S#", "Gender", "Style", "No of SKU", "Bergen", "PK", "Production", "P. Order", "International", "OTS", "Nord D2C", "L&T D2C", "POD"];
    //   var rows = [];

    //   // Populate rows with data
    //   omsStockStyleWiseSummary?.payload?.data.forEach((item, idx) => {
    //     var row = [idx + 1, item.gender_name, item.style_name, item.no_item_code, item.bergan, item.pakistan, item.production, item.pending, item.international, item.ots, item.nord, item.lt_d2c, item.pod]
    //     rows.push(row);

    //   });

    //   rows.push(["", "", "Total", itemCodeTotal, berganTotal, transitTotal, pkTotal, productionTotal, pendingTotal, internationalTotal, otsTotal, nordTotal, ltD2c, podTotal])

    //   // Add table to the PDF
    //   doc.autoTable({
    //     head: [columns],
    //     body: rows,
    //     styles: { fontSize: 7 },
    //     headStyles: { 
    //       fillColor: "#3c4b58", // Header background color (RGB)
    //       textColor: "#fff", // Text color for header (white)
    //       // fontSize: 7, // Font size for header
    //       fontStyle: 'bold' // Font style for header
    //     }
    //   })

    //   doc.save("Stock-Style-Wise-Report-Summary.pdf");
    // }
    function generatePDF() {
        const input = document.getElementById("card"); // The ID of the element you want to capture

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 295; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save("Stock-Style-Wise-Report-Summary.pdf");
        });
    }



    useEffect(() => {
        dispatch(getStyle(token, menuId));
        dispatch(getProductStatus(token, menuId));
        dispatch(getGender(token, menuId));
    }, [dispatch, menuId, token]);

    useEffect(() => {
        if (allSesion) {
            setIsChecked([]);
        }
    }, [allSesion])

    useEffect(() => {
        if (allStatus) {
            setIsChecked2([]);
        }
    }, [allStatus])

    useEffect(() => {
        if (allPC) {
            setIsPCChecked([]);
        }
    }, [allPC])



    const handlePrint = () => {
        window.print({ saveAsPDF: true });
    };


    const columns = [
        {
            title: "S#",
            align: "center",
            width: "60px",
            render: (text, record, index) => {
                return index + 1
            },
        },
        {
            title: "Gender",
            dataIndex: "gender_name",
            key: "gender_name",
            // render: (text, record) => {
            //   return <>{numeral(text).format('0,0')}</>
            // },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.gender_name !== null) {
                    return record.gender_name
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.gender_name !== null) & (b.gender_name !== null)) {
                    return a.gender_name - b.gender_name;
                }
            },
        },
        {
            title: "Style",
            dataIndex: "style_name",
            key: "style_name",
            // render: (text, record) => {
            //   return <>{numeral(text).format('0,0')}</>
            // },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.style_name !== null) {
                    return record.style_name
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.style_name !== null) & (b.style_name !== null)) {
                    return a.style_name - b.style_name;
                }
            },
        },
        {
            title: "No of SKU",
            dataIndex: "no_item_code",
            key: "no_item_code",
            align: "right",
            // onHeaderCell: () => ({ className: "print-align-right-no-sku", }),
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.no_item_code !== null) {
                    return record.no_item_code
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.no_item_code !== null) & (b.no_item_code !== null)) {
                    return a.no_item_code - b.no_item_code;
                }
            },
        },

        {
            title: "Bergen",
            dataIndex: "bergan",
            key: "bergan",
            align: "right",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.bergan !== null) {
                    return record.bergan
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.bergan !== null) & (b.bergan !== null)) {
                    return a.bergan.localeCompare(b.bergan);
                }
            },
        },
        {
            title: "Transit",
            dataIndex: "transit",
            key: "transit",
            align: "right",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.transit !== null) {
                    return record.transit
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.transit !== null) & (b.transit !== null)) {
                    return a.transit.localeCompare(b.transit);
                }
            },
        },
        {
            title: "PK",
            dataIndex: "pakistan",
            key: "pakistan",
            align: "right",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.pakistan !== null) {
                    return record.pakistan
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.pakistan !== null) & (b.pakistan !== null)) {
                    return a.pakistan.localeCompare(b.pakistan);
                }
            },
        },
        {
            title: "Production",
            dataIndex: "production",
            key: "production",
            align: "right",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.production !== null) {
                    return record.production
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.production !== null) & (b.production !== null)) {
                    return a.production.localeCompare(b.production);
                }
            },
        },
        {
            title: "P. Order",
            dataIndex: "pending",
            key: "pending",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.pending !== null) {
                    return record.pending
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.pending !== null) & (b.pending !== null)) {
                    return a.pending - b.pending;
                }
            },
        },
        {
            title: "International",
            dataIndex: "international",
            key: "international",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.international !== null) {
                    return record.international
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.international !== null) & (b.international !== null)) {
                    return a.international - b.international;
                }
            },
        },
        {
            title: <span className="txtbold">OTS</span>,
            dataIndex: "ots",
            key: "ots",

            render: (text, record) => {
                return <span className="txtbold">{numeral(text).format('0,0')}</span>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.ots !== null) {
                    return record.ots
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.ots !== null) & (b.ots !== null)) {
                    return a.ots - b.ots;
                }
            },
        },
        {
            title: "Nord D2C",
            dataIndex: "nord",
            key: "nord",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.nord !== null) {
                    return record.nord
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.nord !== null) & (b.nord !== null)) {
                    return a.nord - b.nord;
                }
            },
        },
        {
            title: "L&T d2C",
            dataIndex: "lt_d2c",
            key: "lt_d2c",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.lt_d2c !== null) {
                    return record.lt_d2c
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.lt_d2c !== null) & (b.lt_d2c !== null)) {
                    return a.lt_d2c - b.lt_d2c;
                }
            },
        },
        {
            title: "POD",
            dataIndex: "pod",
            key: "pod",
            render: (text, record) => {
                return <>{numeral(text).format('0,0')}</>
            },
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220 w-100"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.pod !== null) {
                    return record.pod
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.pod !== null) & (b.pod !== null)) {
                    return a.pod - b.pod;
                }
            },
        }
    ];


    const handleGender = (gender_id) => {
        dispatch(getGenderCategoryChange(token, gender_id));
        dispatch(getStyleChange(token, gender_id, null, null));
        form.setFieldsValue({
            gender_category_id: "All",
            style_id: "All",
            sku: "All"
        })
    }


    function debounce(cb, delay = 1000) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }


    const handleSkuSearch = () => {
        dispatch(getSku(token, null, null, null));
    }

    const optimizedFn = useCallback(debounce(handleSkuSearch), []);


    const filterProductStatusName = productStatus?.payload?.data?.filter(item => isChecked2.includes(item.product_status_id));



    return (
        <div className="header_class business_header_oms" id="card-pdf">
            <div className="row justify-content-between business_class_oms distributor">
                <div className="col-3 d-flex align-items-start box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                {/* <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-514-9736</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div> */}
                <div className="col-4 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                        <div className=" d-flex justify-content-end mr-8 printing_class">
                            <div className="print_icon printing_class">
                                <i
                                    onClick={handlePrint}
                                    id="Image1"
                                    title="Print"
                                    alt="Print"
                                    src={btnPrint}
                                    className="fa-solid fa-print"
                                ></i>
                            </div>
                            <div className="print_icon printing_class">
                                <CSVLink
                                    filename={"Oms-Stock-Style-Wise-Summary.csv"}
                                    data={excelData}
                                    style={{ display: "flex" }}
                                    onClick={() => {

                                    }}
                                >
                                    <input
                                        type="image"
                                        name="ImgExcel"
                                        alt="Export to excel"
                                        id="ImgExcel"
                                        title="Export to excel"
                                        src={xlsx}
                                        style={{ borderWidth: "0px", height: "14px" }}
                                    // onClick={handleExcelExport}
                                    />
                                </CSVLink>
                            </div>
                            <div className="print_icon printing_class" >
                                <input
                                    type="image"
                                    name="ImgPDF"
                                    alt="Export to PDF"
                                    id="ImgPDF"
                                    title="Export to PDF"
                                    src={pdf}
                                    onClick={generatePDF}
                                    style={{ borderWidth: "0px", height: "18px" }}
                                />
                            </div>

                        </div>
                        <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                            Stock Report Style Wise Summary
                            <br />
                            Rpt # 489
                            <br />
                            <p className=" mb-0">Rpt # 001_V4</p>
                            Login as:{" "}
                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                {userName}
                            </span>
                            <br />
                        </span>
                    </div>
                    {/* Last Updated By:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {moment(routineLog[0]?.r_date +"T"+ routineLog[0]?.r_time).format('MM-DD-YYYY, hh:mm a')}
              </span> */}
                </div>
            </div>


            <div className="business_class_oms mb-2 distributor">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className="main_form row">
                        <div className="col-12">
                            <hr className="my-1" />
                            <div>
                                <label htmlFor="">Product Status</label>
                                <div className="checkboxes">
                                    <div className="checkbox_content">
                                        <Form.Item name="current_status_id" className=" mb-0">
                                            <Checkbox checked={allStatus} onChange={statusSelectAll}>
                                                All
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                    {productStatus &&
                                        productStatus?.payload?.data.map((it, index) => {
                                            return (
                                                <div key={index} className="checkbox_content">
                                                    <Form.Item name="current_status_id">
                                                        <Checkbox
                                                            checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                                                            onChange={(e) =>
                                                                isCheckboxChecked2(
                                                                    it.product_status_id,
                                                                    e.target.checked
                                                                )
                                                            }
                                                        >
                                                            {it.product_status_name}
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="main_form row">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Gender</label>
                                        <div className="w-100">
                                            <Form.Item name="gender_id" initialValue={"All"}>
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    // placeholder="Select Gender"
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                    onChange={(e, obj) => setGenderName(obj.children)}
                                                >
                                                    <Option value={null}>All</Option>
                                                    {gender &&
                                                        gender?.payload?.data.map((it, index) => {
                                                            return (
                                                                <Option key={index} value={it.gender_id}>
                                                                    {it.gender_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Style</label>
                                        <div className="w-100">
                                            <Form.Item initialValue={"All"} name="style_id">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    // placeholder="Select a person"
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                    size="small"
                                                    onChange={(e, obj) => setStyleName(obj.children)}
                                                // onChange={(e) => handleSku(e)}
                                                // filterOption={(input, option) =>
                                                //   option.children
                                                //     .toLowerCase()
                                                //     .includes(input.toLowerCase())
                                                // }
                                                >
                                                    <Option value={null}>All</Option>
                                                    {
                                                        styleChange && styleChange?.payload?.data.length >= 0 ? (
                                                            styleChange && styleChange?.payload?.data.map((style, index) => {
                                                                return (
                                                                    <Option key={index} value={style.style_id}>
                                                                        {style.style_name}
                                                                    </Option>
                                                                );
                                                            })
                                                        ) : (
                                                            style && style?.payload?.data.map((style, index) => {
                                                                return (
                                                                    <Option key={index} value={style.style_id}>
                                                                        {style.style_name}
                                                                    </Option>
                                                                );
                                                            })
                                                        )
                                                    }
                                                    {/* {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })} */}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Pending Till</label>
                                        <div className="w-100">
                                            <Form.Item
                                                name="as_on_date"
                                                className="full_date"
                                                initialValue={initialDate}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    // onChange={onChangeDate}
                                                    format={dateFormatList}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: "3px", float: "right" }}>
                                <Button
                                    style={{ width: "fit-content" }}
                                    type="primary"
                                    htmlType="submit"
                                    className="submit-btn-btm m-0"
                                >
                                    Generate
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div>
                <div
                // className="overflow"
                //   style={{
                //     overflowY: "scroll",
                //     height: "57vh",
                //     // borderBottom: "1px solid black"
                //   }}
                >

                    {omsStockStyleWiseSummary?.payload?.data !== undefined ?
                        (
                            data.length > 0 ? (
                                <div id="card">
                                    <div style={{ display: "flex", gap: 20 }}>
                                        {genderName && <div>Gender: {genderName}</div>}
                                        {styleName && <div>Style: {styleName}</div>}
                                        {
                                            isChecked2.length > 0 && <div>
                                                Product Status : {filterProductStatusName && filterProductStatusName.map((val, key) => {
                                                    return <span key={key}>{val.product_status_name} </span>
                                                })}
                                            </div>
                                        }

                                        <div>Pending Till: {moment(form.getFieldValue().as_on_date).format("MM-DD-YYYY")}</div>
                                    </div>
                                    <Table
                                        className=" border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border prod-table"
                                        ref={tableRef}
                                        size="small"
                                        bordered={false}
                                        type="company"
                                        // headerClassName='abc'
                                        dataSource={data && data}
                                        columns={columns}
                                        scroll={{ x: 'max-content' }}
                                        loading={omsStockStyleWiseSummary.loading}
                                        pagination={{
                                            defaultCurrent: 1,
                                            defaultPageSize: 500,
                                            pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                                        }}
                                        summary={(pageData) => {
                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                        <Table.Summary.Cell><p style={{ textAlign: "center", margin: "0" }}>
                                                            Total &gt; &gt; &gt;
                                                        </p></Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "center", margin: "0" }} className="totalquantity">
                                                                {numeral(itemCodeTotal).format('0,0')}
                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(berganTotal).format('0,0')}
                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(transitTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(pkTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(productionTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(pendingTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(internationalTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(otsTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(nordTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>

                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(ltD2c).format('0,0')}


                                                            </p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                                                                {numeral(podTotal).format('0,0')}

                                                            </p>
                                                        </Table.Summary.Cell>

                                                    </Table.Summary.Row>
                                                </>
                                            );
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="no-Data">
                                    {/* {console.log(omsStockSummary?.payload?.data.length, 'omsStockSummary?.payload?.data.length')} */}
                                    <h1>No Data</h1>
                                </div>

                            )
                        ) : (
                            loading && (
                                <div className="loader" style={{ marginTop: "20px" }}>
                                    <Spin size="medium" />
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default OMSStockStyleWiseSummary;
