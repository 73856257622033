import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';
import axios from 'axios';
import Swal from "sweetalert2";
import notification from '../../utils/notification';
import Tables from '../../components/Table/Tables';
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
import Loader from "react-loader-spinner";
import { BASEURL, MIGRATION_URL } from '../../constant/config';

const dlBulk = 'dlBulk'
const wwBulk = 'wwBulk'
const trackingNumber = 'trackingNumber'

const routineType = {
    dlBulk: {
        title: 'Bulk DL',
        key: dlBulk,
        url: MIGRATION_URL + 'mssql/bulk-dl',
        message: ['Bulk WW', 'Migration Successful'],
        error: 'Migration Failed',
    },
    wwBulk: {
        title: 'Bulk WW',
        key: wwBulk,
        url: MIGRATION_URL + 'mssql/bulk-ww',
        message: ['Neiman Order', 'Migration Successful'],
        error: 'Migration Failed',
    },
    trackingNumber: {
        title: 'Order Tracking Numbers',
        key: trackingNumber,
        url: BASEURL.replace('v1/', '') + 'bergenTracking',
        message: ['Tracking Numbers', 'Tracking Numbers Fetched Successfully'],
        error: 'Operation Failed',
    },
}

const routineButton = [
    {
        title: 'Bulk DL',
        key: dlBulk
    },
    {
        title: 'Bulk WW',
        key: wwBulk
    },
    {
        title: 'Order Tracking Numbers',
        key: trackingNumber
    }
]

const Routine = () => {
    TabTitle("Routine")
    const token = ReadCookie("token");
    const [isLoading, setIsLoading] = useState('');
    const [active, setActive] = useState('');
    const [routineLog, setRoutineLog] = useState([]);

    useEffect(() => {
        getRoutineLog()
    }, []);

    const getRoutineLog = () => {
        axios({
            method: "POST",
            url: `${BASEURL}getAll/Routine_stats`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(function (response) {
            setRoutineLog(response?.data?.data)
        })
    }

    const column = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Routine Name",
            dataIndex: "routine_name",
            key: "routine_name",
        },
        {
            title: "Routine Date",
            dataIndex: "r_date",
            key: "r_date",

        },
        {
            title: "Routine Time",
            dataIndex: "r_time",
            key: "r_time",

        },
        {
            title: "Total SKU",
            dataIndex: "total_skus",
            key: "total_skus",
        },
        {
            title: "Total Qty",
            dataIndex: "total_quantity",
            key: "total_quantity",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text === true ? "True" : "False"

            }
        },
        {
            title: "Status",
            dataIndex: "user_name",
            key: "user_name",

        },
    ];

    const getRoutineByType = (type) => {
        const { key, url, message, error: _err } = routineType[type]
        setIsLoading(key)
        axios({
            method: "GET",
            url,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(function (response) {
            if (response.status === 200) {
                setActive(key)
                notification('success', message[0], message[1])
                getRoutineLog()
            }
        })
            .catch(function (error) {
                const status = error?.response?.status || 500
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: status === 404 && [wwBulk, dlBulk].includes(key) ? "No Data to Migrate" : _err,
                });
            })
            .finally(function () {
                setIsLoading('')
            });
    }

    return (
        <>
            <Row gutter={[18, 12]}>
                {
                    isLoading ? (
                        <div>
                            <div className="loaderDiv">
                                <Loader type="Grid" color="#212121" height={40} width={40} />
                                <div>
                                    <p style={{ fontSize: 26 }}>Please Wait Until the current Routine is completed.</p>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {routineButton.map(x => (
                    <Col className="gutter-row" key={x.key} span={6}>
                        <TabButton active={active === x.key} isLoading={isLoading === x.key} title={x.title} callBack={() => getRoutineByType(x.key)} />
                    </Col>
                ))}
            </Row >
            <div style={{ paddingTop: "200px" }}>
                <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={routineLog}
                    col={column}
                    paginate
                />
            </div>
        </>
    )
};
export default Routine;

const TabButton = ({ active, isLoading, callBack, title }) => {
    return (
        <div>
            <Button className={`tab_button ${active ? 'active' : ''}`} icon={active && <CheckOutlined />} size='large' loading={isLoading} onClick={callBack}>
                {title}
            </Button>
        </div>
    )
}