import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { Table, Spin, Input } from 'antd';

import { getOmsReportByCustomer } from '../../redux/actions/omsReportByCustomer'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { getRegion } from '../../redux/actions/region.action'
import { getGender } from '../../redux/actions/gender.action'
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import numeral from "numeral";

import "./styleSheet.css"
import { getCustomerType } from '../../redux/actions/customerType.action';
import { currencySymbol } from '../../utils/constants';
import moment from 'moment';

const OmsReportByCustomer = ({ token, id, reportTittle, reportName }) => {
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const { gender, region, companyAll, omsReportByCustomer, customerTypes } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(null)
  const history = useHistory();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let company_id = query.get("company_id")
  let date_type = query.get("date_type")
  let gender_id = query.get("gender_id")
  let customer_type_id = query.get("customer_type_id")
  let from_date = query.get("from_date")
  let to_date = query.get("to_date")
  let region_id = query.get("region_id")
  let selected_Region = query.get("selected_Region")
  let selected_id = query.get("selected_id")
  let select = query.get("select")
  let state_id = query.get("state_id")
  let customer_id = query.get("customer_id")
  let customer_name = query.get("customer_name")
  let state_name = query.get("state_name")


  useLayoutEffect(() => {
    setValues(history?.location?.values)
    setLoading(true)
    dispatch(getOmsReportByCustomer(token, { id, company_id, date_type, gender_id, from_date, from_date, to_date, customer_type_id, region_id, selected_Region, select, customer_id, state_id })).then((res) => {
      setData(res?.payload?.data)
      setSubmit(true)
      setLoading(false)
    });

  }, []);

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId))
    dispatch(getAllCompany(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token])

  const columns = [
    {
      title: "#",
      dataIndex: "serial_no",
      key: 'serial_no',
      align: 'left',
      width: 50,
      render: (text, object, index) => index + 1,
    },

    {
      title: 'Order #',
      dataIndex: 'order_header_no',
      key: 'order_header_no',
      align: 'left',
      width: 100,
      render: (record, text) => {
        return <Link style={{ color: "blue", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer"
          to={{
            pathname: `/sales-report`,
            search: `?order_header_id=${text.order_header_id}`
          }
          }
        > {record}</ Link >
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.order_header_no !== null) {
          return record.order_header_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.order_header_no - b.order_header_no
        )
      },
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
      align: 'left',
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.order_date !== null) {
          return record.order_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.order_date !== null) & (b.order_date !== null)) {
          return a.order_date.localeCompare(b.order_date);
        }
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'left',
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.start_date !== null) {
          return record.start_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.start_date !== null) & (b.start_date !== null)) {
          return a.start_date.localeCompare(b.start_date);
        }
      },
    },
    {
      title: 'Cancel Date',
      dataIndex: 'cancel_date',
      key: 'cancel_date',
      align: 'left',
      width: 140,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.cancel_date !== null) {
          return record.cancel_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.cancel_date !== null) & (b.cancel_date !== null)) {
          return a.cancel_date.localeCompare(b.cancel_date);
        }
      },
    },
    {
      title: 'PO #',
      dataIndex: 'reference_no',
      key: 'reference_no',
      align: 'left',
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.reference_no !== null) {
          return record.reference_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.reference_no !== null) & (b.reference_no !== null)) {
          return a.reference_no.localeCompare(b.reference_no);
        }
      },
    },
    {
      title: 'Sales Person',
      dataIndex: 'salesperson_name',
      key: 'salesperson_name',
      align: 'left',
      width: 180, filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.salesperson_name !== null) {
          return record.salesperson_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.salesperson_name !== null) & (b.salesperson_name !== null)) {
          return a.salesperson_name.localeCompare(b.salesperson_name);
        }
      },
    },
    {
      title: 'Term',
      dataIndex: 'term_name',
      key: 'term_name',
      align: 'left',
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.term_name !== null) {
          return record.term_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.term_name !== null) & (b.term_name !== null)) {
          return a.term_name.localeCompare(b.term_name);
        }
      },
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 120,
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.quantity !== null) {
          return record.quantity
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      width: 180,
      align: "right",
      render: (text) => (
        <span style={{ textAlign: "right" }}> {currencySymbol}{text} </span>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.net_amount !== null) {
          return record.net_amount
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.net_amount.replaceAll(',', '') - b.net_amount.replaceAll(',', '')
        )
      },
    },
    {
      title: "AUC",
      dataIndex: "auc",
      key: "auc",
      width: 120,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}>{currencySymbol}{parseFloat(text).toFixed(2)}</span>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.auc !== null) {
          return record.auc
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
        )
      },
    },
    
  ];

  const filterGender = gender.payload?.data.find((item) => {
    return item.gender_id === Number(gender_id)
  })

  const filterRegion = region.payload?.data.find((item) => {
    return item.region_id === Number(selected_id)
  })

  const filterCustomerType = customerTypes.payload?.data.find((item) => {
    return item.customer_type_id === Number(customer_type_id);
})


  const totalSum = () => {
    let sumAmount = data.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.net_amount.replace(/\$|,/g, ''));
    }, 0);
    return sumAmount;
  }

  const totalQuantity = () => {
    let sumQty = data.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.quantity.replace(/\$|,/g, ''));
    }, 0);
    return sumQty;

  }

  return (
    <>
      <div className='row report-wrapper-main mb-3'>
        <div className='col p-0'>
          <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
            <tbody>
              <tr>
                <td width="103">
                  Featuring:
                </td>
                <td>
                  <b>
                    {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                  </b>
                </td>
                <td width="103">
                  State:
                </td>
                <td>
                  <b>
                    {select === "true" ? "All" : state_name}
                  </b>
                </td>
              </tr>
              <tr>
                <td >
                  Customer Type:
                </td>
                <td>
                  <b>
                    {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                  </b>
                </td>
                <td style={{ width: "20px" }} className="no-border-btm" >
                  Region:
                </td>
                <td>
                  <b>
                    {filterRegion && filterRegion.region_name}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  From:
                </td>
                <td>
                  <b>
                    {moment(from_date).format("MM-DD-YYYY")}
                  </b>
                </td>
                <td style={{ width: "60px" }} className="no-border-btm" >
                  To:
                </td>
                <td>
                  <b>
                    {moment(to_date).format("MM-DD-YYYY")}
                  </b>
                </td>
              </tr>
              <tr>
                <td style={{ width: "80px" }}>
                  Show Orders By:
                </td>
                <td>
                  <b>
                    {date_type === "B" ? "Booking Date" : "Start Date"}
                  </b>
                </td>
                <td className="no-border-btm" >
                  Brand :
                </td>
                <td>
                  <b>
                  {company_id === "All"? "All" : company_id === "1"? "DL1961" : company_id === "2" ? "Warp + Weft" : ""}
                  </b>
                </td>
              </tr>
            </tbody >
          </table >
          <table className="tablestyleControls" style={{ marginTop: "5px" }} cellpadding="3">
            <tbody>
              <tr >
                <td style={{ width: "82.5px" }}>
                  Selected Customer:
                </td>
                <td rowSpan={"15"}>
                  <b>
                    {customer_name}
                  </b>
                </td>
              </tr>
            </tbody >
          </table >
        </div>
      </div>
      {loading ?
        <div className='maxWidth d-flex align-items-center justify-content-center spin-style mt-3' >
          <Spin size='large' />
        </div> :
        <>
          {(submit) ?
            <div className='maxWidth mt-3'>
              <Table
                showSorterTooltip={false}
                
                className='w-100 test04'
                size='small'
                pagination={{ pageSize: 1000 }}
                summary={pageData => {
                  let sumQty = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                  }, 0);
                  let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                  }, 0);
                  return (
                    <>
                      <Table.Summary.Row >
                        <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>

                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ fontWeight: "bolder" }}>Total</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{
                            fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                          }}>{sumQty.toLocaleString("en-US")}</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>{currencySymbol} {sumAmount.toLocaleString("en-US")}</p>
                        </Table.Summary.Cell>

                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
                bordered
                columns={columns}
                dataSource={omsReportByCustomer?.payload?.data}
                sortDirections={["ascend", "descend", "ascend"]}
              />
            </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
              fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
            }} >
              <tbody>
                <tr style={{
                  backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                }} >
                  <td colSpan="5">
                    No record(s)</td>
                </tr>
              </tbody>
            </table>}
        </>
      }
    </>
  )
}
export default OmsReportByCustomer;
