import {
  GET_COMMISSION_REPORT_REQUEST,
  GET_COMMISSION_REPORT_SUCCESS,
  GET_COMMISSION_REPORT_ERROR,
  GET_CHART_OF_ACCOUNT_REPORT_REQUEST,
  GET_CHART_OF_ACCOUNT_REPORT_SUCCESS,
  GET_CHART_OF_ACCOUNT_REPORT_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getCommissionReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_COMMISSION_REPORT_REQUEST });
  const newData = {
    from_date: moment(values.from_date).format("YYYY-MM-DD"),
    to_date: moment(values.to_date).format("YYYY-MM-DD"),
    brand_id: values.brand_id === "All" ? null : values.brand_id,
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getCommisionQuery`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_COMMISSION_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_COMMISSION_REPORT_ERROR,
        payload: [],
      });
    });
};


export const getChartOfAccountReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_CHART_OF_ACCOUNT_REPORT_REQUEST });
  const newData = {
    to_date: moment(values.to_date).format("YYYY-MM-DD"),
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/CoaReport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_CHART_OF_ACCOUNT_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CHART_OF_ACCOUNT_REPORT_ERROR,
        payload: [],
      });
    });
};
