import {
  GET_BRAND_ERROR,
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  ADD_BRAND_ERROR, ADD_BRAND_REQUEST, ADD_BRAND_SUCCESS, UPDATE_BRAND_ERROR, UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS, DELETE_BRAND_ERROR, DELETE_BRAND_REQUEST, DELETE_BRAND_SUCCESS
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BRAND_REQUEST:
      return {
        loading: true,
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_BRAND_REQUEST:
      return {
        loading: true,
      };
    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_BRAND_ERROR:
      return {
        ...state,
        loading: false
      };
    case UPDATE_BRAND_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_BRAND_ERROR:
      return {
        ...state,
        loading: false
      };
    case DELETE_BRAND_REQUEST:
      return {
        loading: true,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_BRAND_ERROR:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
