import React, { useEffect, useState, useRef } from "react";
import "./Distributor2.css";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getskuloopup } from "../../redux/actions/skuLookUp.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Select, Form, Input } from "antd";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getBrands } from "../../redux/actions/brand.action";

const Distributor2 = ({ token }) => {
  TabTitle("SKU Lookup")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    brand,
    getSKULookUpReport,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let query_sku = query.get("sku");

  useEffect(() => {
    if (query_sku) {
      const values = {
        sku: query_sku,
        brand_name: null
      }
      form.setFieldsValue({
        sku: values.sku
      });
      setLoading(true)
      dispatch(getskuloopup(token, values)).then((res) => {
        setLoading(false)
      });
    }
  }, [query_sku])

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  let result =
    getSKULookUpReport &&
    getSKULookUpReport?.payload?.data?.reduce(function (r, a) {
      r[a.item_code] = r[a.item_code] || [];
      r[a.item_code].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const { Option } = Select;

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      const distributorParam = {
        sku: values.sku ? values.sku : null,
        brand_name: values.brand_name === 'all' || values.brand_name === null ? null : values.brand_name,
      };
      dispatch(getskuloopup(token, distributorParam));
      setLoading(true);
    });
  };

  useEffect(() => {
    dispatch(getBrands(token));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="distributor header_class distributor_table">
      <div className="row justify-content-between distributr">
        {/**** / Logo *****/}
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div
                className="print_icon printing_class"
                name="ImgExcel"
                alt="Export to excel"
                id="ImgExcel"
                title="Export to excel"
                src={xlsx}
              >
                {wiseStockReport ? (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    onClick={onDownload}
                    style={{
                      borderWidth: "0px",
                      height: "14px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                )}
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              SKU Lookup
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="business_class_oms distributr">
        {/* Table Selects */}
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className=" col-12 ">
              <hr className="mt-0 mb-2" />

              <div className="row">
                {/* Second */}
                <div className="col-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" className="sku_input">
                        <Input size="small" className={"select_Inputs mb-0"} required />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Brand</label>
                    <div className="w-100">
                      <Form.Item name="brand_name" className="sku_input">
                        <Select size="small" defaultValue="all" showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className={"select_Inputs mb-0"}
                        >
                          <Option value='all'>All</Option>
                          {brand?.payload?.data?.map((res) => (
                            <Option key={res.brand_name} value={res.brand_name}>
                              {res.brand_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/* Third */}
                <div className="col-3">
                  <div className=" d-flex flex-row align-items-baseline" style={{ marginTop: "4px" }}>
                    {/* <label  className=" no_visibility">none</label> */}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {/* Table  Data */}

      <div className="business_class_oms " ref={tableRef}>
        {wiseStockReport ? (
          wiseStockReport.length > 0 ? (
            wiseStockReport.map((data, index) => {
              const sizes = data;

              let qty1 = null;
              let qty2 = null;
              let qty3 = null;
              let qty4 = null;
              let qty5 = null;
              let qty6 = null;
              let qty7 = null;
              let qty8 = null;
              let qty9 = null;
              let qty10 = null;
              let qty11 = null;
              let qty12 = null;
              let qty13 = null;
              let qty14 = null;
              let qty15 = null;
              let qty16 = null;

              for (let i = 0; i < 6; i++) {
                qty1 += parseInt(data[i].qty_1);
                qty2 += parseInt(data[i].qty_2);
                qty3 += parseInt(data[i].qty_3);
                qty4 += parseInt(data[i].qty_4);
                qty5 += parseInt(data[i].qty_5);
                qty6 += parseInt(data[i].qty_6);
                qty7 += parseInt(data[i].qty_7);
                qty8 += parseInt(data[i].qty_8);
                qty9 += parseInt(data[i].qty_9);
                qty10 += parseInt(data[i].qty_10);
                qty11 += parseInt(data[i].qty_11);
                qty12 += parseInt(data[i].qty_12);
                qty13 += parseInt(data[i].qty_13);
                qty14 += parseInt(data[i].qty_14);
                qty15 += parseInt(data[i].qty_15);
                qty16 += parseInt(data[i].qty_16);
              }

              let sumQ = 0;
              let sumQ2 = 0;

              for (let i = 0; i < 6; i++) {
                sumQ += parseInt(data[i].sum_quantity);
              }
              for (let i = data.length - 1; i > data.length - 6; i--) {
                sumQ2 += parseInt(data[i].sum_quantity);
              }

              return (
                <div key={index}>
                  <div className="table_data-Heading distributr">
                    {data[0].item_code} - {data[0].description}
                  </div>
                  <table className="table_distributor-data distributr">
                    <tbody>
                      <tr style={{ lineHeight: "32px", backgroundColor: "#e0e6ed" }}>
                        {/* <td className="font-weig">#</td> */}
                        <td
                          style={{ width: "70px", fontWeight: "500" }}
                          className="font-weig"
                        >
                          SKU
                        </td>
                        <td className="font-weig" style={{ width: "100px" }}>Location</td>
                        <td className="font-weig" style={{ width: "80px" }}>ETA</td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_1}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_2}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_3}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_4}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_5}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_6}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_7}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_8}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_9}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_10}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_11}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_12}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_13}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_14}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_15}
                        </td>
                        <td style={{ width: "30px", textAlign: "right", paddingRight: "2px" }} className="font-weig">
                          {sizes[0].size_16}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", width: "20px" }}>Quantity</td>
                        <td className="font-weig" style={{ width: "70px" }}>Brand</td>
                      </tr>
                      {data.map((values, index) => {
                        const filterQuantity = data.filter(
                          (val) => val.location_id === values.location_id
                        );

                        const totalQuantity = filterQuantity.map((qty) => {
                          return (
                            Number(qty.qty_1) +
                            Number(qty.qty_2) +
                            Number(qty.qty_3) +
                            Number(qty.qty_4) +
                            Number(qty.qty_5) +
                            Number(qty.qty_6) +
                            Number(qty.qty_7) +
                            Number(qty.qty_8) +
                            Number(qty.qty_9) +
                            Number(qty.qty_10) +
                            Number(qty.qty_11) +
                            Number(qty.qty_12) +
                            Number(qty.qty_13) +
                            Number(qty.qty_14) +
                            Number(qty.qty_15) +
                            Number(qty.qty_16)
                          );
                        });

                        return (
                          <>
                            {index === 5 ? (
                              <tr style={{ background: "#e1e1e1" }}>
                                <td className="font-weig"></td>
                                <td className="font-weig" style={{ textAlign: "left", paddingRight: "2px" }}>OTS - Final</td>
                                <td className="font-weig"></td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty1 !== qty1 ? "" : qty1}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty2 !== qty2 ? "" : qty2}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty3 !== qty3 ? "" : qty3}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty4 !== qty4 ? "" : qty4}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty5 !== qty5 ? "" : qty5}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty6 !== qty6 ? "" : qty6}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty7 !== qty7 ? "" : qty7}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty8 !== qty8 ? "" : qty8}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty9 !== qty9 ? "" : qty9}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty10 !== qty10 ? "" : qty10}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty11 !== qty11 ? "" : qty11}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty12 !== qty12 ? "" : qty12}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty13 !== qty13 ? "" : qty13}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty14 !== qty14 ? "" : qty14}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty15 !== qty15 ? "" : qty15}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  {qty16 !== qty16 ? "" : qty16}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>{numeral(sumQ).format("0,00,0")}</td>
                                <td />
                              </tr>
                            ) : null}

                            {values.loc_seq < 7 ? (
                              <tr key={index}>
                                <td>{values.item_code}</td>
                                <td>{values.location_name}</td>
                                <td>
                                  {
                                    "2020-01-01" ? null : moment(values.ex_factory_date).format(
                                      "MM-DD-YYYY")
                                  }
                                </td>

                                {filterQuantity.map((quan, index) => {
                                  return (
                                    <>
                                      {quan.qty_1 && (
                                        <td style={{ textAlign: "right", paddingRight: "2px" }} key={index}>{quan.qty_1}</td>
                                      )}
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_2}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_3}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_4}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_5}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_6}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_7}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_8}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_9}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_10}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_11}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_12}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_13}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_14}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_15}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_16}</td>
                                    </>
                                  );
                                })}
                                <td style={{ textAlign: "right", paddingRight: "2px" }}>{totalQuantity.toString()}</td>
                                <td style={{ paddingRight: "2px" }}>{values.brand_name || ''}</td>
                              </tr>
                            ) : null}
                            {values.loc_seq >= 100 ? (
                              <tr key={index}>
                                <td>{values.item_code}</td>
                                <td>{values.location_name}</td>
                                <td>
                                  {values.location_name === 'BERGEN WS' ? null : moment(values.ex_factory_date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                {filterQuantity.map((quan, index) => {
                                  return (
                                    <>
                                      {quan.qty_1 && (
                                        <td style={{ textAlign: "right", paddingRight: "2px" }} key={index}>{quan.qty_1}</td>
                                      )}
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_2}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_3}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_4}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_5}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_6}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_7}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_8}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_9}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_10}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_11}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_12}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_13}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_14}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_15}</td>
                                      <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_16}</td>
                                    </>
                                  );
                                })}
                                <td style={{ textAlign: "right", paddingRight: "2px" }}>{totalQuantity.toString()}</td>
                                <td style={{ paddingRight: "2px" }}>{values.brand_name || ''}</td>
                              </tr>
                            ) : null}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            })
          ) : (
            <div className="no-Data">
              <h1>No Data</h1>
            </div>
          )
        ) : (
          loading && (
            <div className="loader" style={{ marginTop: "20px" }}>
              <Spin size="medium" />
            </div>
          )
        )}
      </div>
    </div>
  );

};

export default Distributor2;
