import { GET_PRODUCTSALETRANSFER_REQUEST, GET_PRODUCTSALETRANSFER_SUCCESS, GET_PRODUCTSALETRANSFER_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js";
const axios = require("axios")

export const getProductSaleTransfer = (token, company_id, item_code, location_id, brand_id) => (dispatch) => {
    dispatch({ type: GET_PRODUCTSALETRANSFER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productsSaleTransfer`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { company_id, item_code, location_id, brand_id }
    })
        .then((response) => {
            return dispatch({ type: GET_PRODUCTSALETRANSFER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PRODUCTSALETRANSFER_ERROR,
                payload: error.response,
            });
        });
};