import {
    GET_PENDING_SUMMARY_REQUEST,
    GET_PENDING_SUMMARY_SUCCESS,
    GET_PENDING_SUMMARY_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  import moment from "moment";

  export const getPendingSummary = (token, values) => (dispatch) => {
    
    let cleanedVal = (values.customer === undefined ||  values.customer === null) ?null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
    let cleanedVal1 = (values.billing_addressee === undefined || values.billing_addressee === null)  ? null : values.billing_addressee.includes("'") ? values.billing_addressee.replace(/'/g, "''") : values.billing_addressee;
    dispatch({ type: GET_PENDING_SUMMARY_REQUEST });
    const newData = {
            customer_cat_id: values.cutomer_category === "All" || values.cutomer_category === undefined ? null : values.cutomer_category,
            
            date_to: moment(values.to_date).format("MM-DD-YYYY"),
            order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
            reference_no: values.po_no === undefined || values.po_no === "" ?  null : values.po_no,
            customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
            bill_addressee: cleanedVal1 === undefined || cleanedVal1 === "" ? null : cleanedVal1,
            sp_id: values.sale_person === "All" || values.sale_person === undefined ? null : values.sale_person,
            region_id: values.region === "All" || values.region === undefined ? null : values.region,
            gender_id: values.gender === "All" || values.gender === undefined ? null : values.gender,
            payment_term_id: values.terms === "All" || values.terms === undefined ? null : values.terms,
            store_id: values.store === undefined || values.store === "" ? null : values.store,
            company_id: values.subsidary === "All" || values.subsidary === undefined ? null : values.subsidary,
            source_type: values.source === undefined || values.source === "" ? null : values.source,
            sku: values.sku === undefined || values.sku === "" ? null : values.sku,
            status: values.status === undefined || values.status === "" ? null : values.status,
            location_id: values.location === undefined || values.location === "All" ? null : values.location,
            customer_id : values.customerNew === undefined ? null : values.customerNew
    };
    
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/getOmsPending`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_PENDING_SUMMARY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PENDING_SUMMARY_ERROR,
          payload: [],
        });
        
        
        
        
        
        
        
        
        
        
      });
  };
  