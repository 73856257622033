import { useState, useLayoutEffect, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { creditMemoColumns } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, Table, Select } from "antd";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getBrands } from "../../redux/actions/brand.action";
export default function CreditMemoList({ token }) {
	TabTitle("Credit Memo")
	const dispatch = useDispatch();
	const { creditMemoList, brand } = useSelector((state) => state);
	const [form] = Form.useForm();
	const dateFormat = "MM-DD-YYYY";
	const [show, setShow] = useState(false);
	const [dataLen, setDataLen] = useState(0);
	const { Panel } = Collapse;
	const history = useHistory();

	const [userRights, setUserRights] = useState({})
	const [menuId, setMenuId] = useState(1261);

	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		creditMemo: null,
		paymentTerm: null,
		gender: null,
		salePerson: null,
		arAccount: null,
		postingPeriod: null,
		customer: null,
		creditMemoid: null,
		brand_id: null,
	};

	useEffect(() => {
		dispatch(getBrands(token));
	}, [])

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => dateChecker(values.from_date, values.to_date, "creditMemoList", token, values, dispatch))
	};

	const handleFilter = (pagination, filters, sorter, extra) => {
		setShow(true);
	};

	let Amount = creditMemoList && creditMemoList?.payload?.data.reduce(function (
		previousValue,
		currentValue
	) {
		return (previousValue += parseFloat(
			currentValue.net_amount
		));
	},
		0);

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Credit Memo No": v.memo_header_no,
					"Customer": v.customer_name,
					"Posting Period": v.period_name,
					"payment Term": v.term_name,
					"Gender": v.gender_name,
					"Amount": v.net_amount,
					"Sales Person": v.sales_person_name,
					"Account": v.ar_account_name,
					"Memo": v.memo,
					"Brand": v.brand_name,
				};
			})
		}
	}

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Credit Memo List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/credit-memo/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={worker}
								form={form}
								onFinish={onFinishFilter}

								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="creditMemoid" label="Credit Memo id" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="creditMemo" label="Credit Memo #" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>

								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="customer" label="Customer" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="paymentTerm" label="Payment Term">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="gender" label="Gender">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="salePerson" label="Sale Person">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="arAccount" label="arAccount">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="brand_id" label="Brand">
											<Select
												size="small"
												showSearch
												allowClear
												optionFilterProp="children"
												filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100">
												{brand?.payload?.data?.map((res) => (
													<Option key={res.brand_id} value={res.brand_id}>
														{res.brand_name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={creditMemoList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{creditMemoList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: "10px" }}>
								{
									creditMemoList?.payload?.data && (
										<CSVLink
											filename={"Credit-Memo.csv"}
											data={downloadExcelFileData(creditMemoList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Table
									dataLen={dataLen}
									setDataLen={setDataLen}
									show={show}
									handleFilter={handleFilter}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									columns={creditMemoColumns}
									dataSource={creditMemoList?.payload?.data}
									loading={creditMemoList.loading}
									pagination={{
										defaultCurrent: 1,
										defaultPageSize: 100,
										pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
									}}
									summary={(pageData) => {
										return (
											<>
												<Table.Summary.Row>
													<Table.Summary.Cell></Table.Summary.Cell>
													<Table.Summary.Cell></Table.Summary.Cell>
													<Table.Summary.Cell></Table.Summary.Cell>
													<Table.Summary.Cell></Table.Summary.Cell>
													<Table.Summary.Cell> Total Amount :</Table.Summary.Cell>
													<Table.Summary.Cell>
														<p style={{ textAlign: "left", margin: "0" }} className="totalquantity">
															${Amount?.toLocaleString("en-US")}
														</p>
													</Table.Summary.Cell>
												</Table.Summary.Row>
											</>
										);
									}}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
