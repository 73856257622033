import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import Swal from "sweetalert2";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-loader-spinner";
import * as XLSX from "xlsx/xlsx.mjs";
import notification from "../../utils/notification";
import { sendStitchFixEdi } from "../../redux/actions/sendStitchFixEdi.action";
import { sendScheelsEdi } from "../../redux/actions/sendScheelsEdi.action";
const { Option } = Select;

const menuId = ReadCookie("menuId");
const userName = ReadCookie("name");
const company_id = ReadCookie("defaultCompany");

const ScheelsEdi = () => {
    TabTitle("Scheels Edi");
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const dispatch = useDispatch();
    const token = ReadCookie("token");
    const [controlSheet, setControlSheet] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const [excelData, setExcelData] = useState([]);
    const [sDate, setStartDate] = useState(moment());
    const [excel, setExcel] = useState([]);
    const [excelHeader, setExcelHeader] = useState([]);
    const [count, setCount] = useState();

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => {
                return index + 1;
            },
        },

        {
            title: "PO Number",



            dataIndex: "PO Number",
            key: "PO Number",
        },
        {
            title: "PO Date",
            dataIndex: "PO Date",
            key: "PO Date",
        },
        {
            title: "Dept #",
            dataIndex: "Dept #",
            key: "Dept #",
        },
        {
            title: "Retailers PO",
            dataIndex: "Retailers PO",
            key: "Retailers PO",
        },
        {
            title: "PO Line #",
            dataIndex: "PO Line #",
            key: "PO Line #",
        },

        {
            title: "Qty Ordered",
            dataIndex: "Qty Ordered",
            key: "Qty Ordered",
        },
        {
            title: "Unit Price",
            dataIndex: "Unit Price",
            key: "Unit Price",
        },
        {
            title: "UPC/EAN",
            dataIndex: "UPC/EAN",
            key: "UPC/EAN",
        },
        {
            title: "Size",
            dataIndex: "Size",
            key: "Size",
        },


































    ];

    useEffect(() => {
        form.setFieldsValue({
            company: "ARTISTIC DENIM MILLS.LTD",
            brand: "DL1961 PREMIUM DENIM",
            address1: "PLOT NO. 5-9,23-26, SECTOR-16",
            address2: "KORANGI INDUSTRIAL AREA",
            city: "KARACHI",
            zip: "",
            country: "PAKISTAN",
        });
    }, []);
    const onFinish = async () => {
        setLoading(true);
        dispatch(sendScheelsEdi(token, excel)).then((res) => {
            setLoading(false);
            notification("success", "Order Placed Successfully");



        });














    };

    const totalQuantity = () => {
        let sumQty = excel.reduce(function (previousValue, currentValue) {
            return (previousValue += Number(currentValue["Qty Ordered"]));
        }, 0);
        return sumQty;


    };

    const totalSum = () => {
        let sumAmount = controlSheet.reduce(function (previousValue, currentValue) {
            return (previousValue += Number(currentValue.amount));
        }, 0);
        return sumAmount;
    };

    const onSearch = async () => {

















    };

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Company Name": form.getFieldValue().company,
                    "Address 1": form.getFieldValue().address1,
                    "Address 2": form.getFieldValue().address2,
                    City: form.getFieldValue().city,
                    State: v.STATE,
                    Zip: form.getFieldValue().zip,
                    Country: form.getFieldValue().country,
                    "Non US Region": v.Non_US_Region,
                    Phone: v.phone,
                    Fax: v.fax,
                    Email: v.email,
                    "Expected Date": !form.getFieldValue().startDate
                        ? moment().format("MM-DD-YYYY")
                        : moment(form.getFieldValue().startDate).format("MM-DD-YYYY"),
                    Carrier: form.getFieldValue().carrier,
                    "Tracking Numbers": form.getFieldValue().awb,
                    "Driver Name": v.DriverName,
                    "License Plate": v.LicensePlate,
                    "Customer Po No": form.getFieldValue().customer_po,
                    "Authorized Number": v.AuthorizedNumber,
                    Memo: form.getFieldValue().memo,
                    Warehouse: v.WAREHOUSE,
                    "Shipment Type list": v.ShipmentTypelist,
                    Style: v.Style,
                    Color: v.COLOR,
                    Size: v.Size,
                    "Expected Quantity": v.ExpectedQuantity,
                    "Unit Cost": v.UnitCost,
                    "Product Description": v.ProductDescription,
                    "Product MSRP": v.ProductMSRP,
                    Comments: v.Comments,
                    "Shipment Type": v.ShipmentType,
                    UPC: v.UPC,



















                };
            })
        );
    }

    let json;
    let jsonArray = [];
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });


                jsonArray = json;
                excel.push(jsonArray);
                setExcel(jsonArray);
                excelHeader.push(Object.keys(jsonArray[0]));


                const uniqueStores = {};


                jsonArray.forEach((item) => {
                    uniqueStores[item.Store] = true;
                });


                const count = Object.keys(uniqueStores).length;


                setCount(count);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    const handleChanges = (val) => {
        dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
    };

    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const optimizedFn = useCallback(debounce(handleChanges), []);

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Scheels Edi"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0" style={{ display: "flex" }}>
                            <Form
                                onFinish={onFinish}
                                form={form}
                                name="control-hooks"
                                style={{ width: "20%" }}
                            >
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={12} xl={12} xxl={12}>
                                            <div className="customCol">
                                                <Form.Item





                                                >
                                                    <input
                                                        className="upload_input"
                                                        type="file"
                                                        name="upload"
                                                        id="upload"
                                                        onChange={readUploadFile}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>


                                    </Brow>
                                </div>
                            </Form>
                            <Bcol
                                lg={6}
                                xl={4}
                                xxl={3}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div>
                                    <div className="relative">
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="ant-btn ant-btn-primary mrpx-20"
                                            onClick={onFinish}
                                        >
                                            <span className="px-2">Send</span>
                                        </Button>
                                    </div>
                                </div>
                            </Bcol>

                            {/* {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )} */}
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {loading ? (
                    <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin size="large" />
                    </div>
                ) : excel && excel.length > 0 ? (
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <div>Total Store(s): {count}</div>
                                <div style={{ marginLeft: "20px" }}>
                                    Total Quantity: {numeral(totalQuantity()).format("0,00,0")}
                                </div>
                            </div>
                            {/* <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary"
                onClick={onSubmit}
              >
                <span className="px-2">Send</span>
              </Button> */}
                        </div>
                        { }
                        <Table









                            ref={tableRef}
                            size="small"
                            bordered={false}
                            type="company"
                            perPage={15}
                            scroll={{ x: "max-content" }}
                            dataSource={excel}
                            columns={columns}
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
                            }}





















































































                        />
                    </>
                ) : (

                    <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScheelsEdi;
