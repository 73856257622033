import React, { useEffect, useMemo } from "react";
import SalesInvoiceReportHeader from "./SalesInvoiceReportHeader/SalesInvoiceReportHeader";
import {
  getInvoiceColumns,
  getInvoiceColumnValue,
  getInvoicePrint,
} from "../../redux/actions/invoiceReport.action";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./SalesInvoiceReport.css";
import { Card } from "antd";
import moment from "moment";
import { TabTitle } from "../../utils/GeneralFunction";
import { currencySymbol } from "../../utils/constants";

const SalesInvoiceReport = () => {
  TabTitle("Sales-Invoice-Report")
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const { invoicePrint, invoiceColumns, invoiceColumnsValues } = useSelector(
    (state) => state
  );

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let invoice_header_id = query.get("invoice_header_id");

  useEffect(() => {
    dispatch(getInvoicePrint(token, invoice_header_id));
    dispatch(getInvoiceColumnValue(token, invoice_header_id));
    dispatch(getInvoiceColumns(token, invoice_header_id));
  }, [dispatch, menuId, token]);

  const footerData = invoicePrint && invoicePrint?.payload?.data[0];
  const isFactor = footerData && footerData.factor_id === 1

  let groupValues =
    invoiceColumnsValues &&
    invoiceColumnsValues?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));
  const groupedColumnValue = groupValues && Object.values(groupValues);
  const myData = useMemo(() => {
    return (
      invoiceColumns &&
      invoiceColumns?.payload?.data.sort((a, b) => a.gender_category_id - b.gender_category_id).map((it, i) => {
        return {
          column: it,
          detail: groupedColumnValue && groupedColumnValue[i],
        };
      })
    );
  }, [groupedColumnValue, invoiceColumns]);

  const addr = () => {
    return isFactor ?
      <>
        <p className="footer_order_p">
          Citibank, N.A., Canadian Branch
        </p>
        <p className="footer_order_p">
          Address : 123 Front Street West Toronto, Ontario M5J 2M
        </p>
        <p className="footer_order_p">
          Routing No : Bank Number - 328
        </p>
        <p className="footer_order_p">
          Branch No : 20012
        </p>
        <p className="footer_order_p">
          Switch Code : CITICATTBCH
        </p>
        <p className="footer_order_p">
          Account Name : The CIT Group/Commercial Services
        </p>
        <p className="footer_order_p">
          Account Number : 2017317028
        </p>
        <p className="footer_order_p">
          Reference : DLPD Canada Ltd.
        </p>
      </>
      :
      <>
        <p className="footer_order_p">
          TD Canada Trust
        </p>
        <p className="footer_order_p">
          Address : 77 Bloor Street West Toronto, Ontario M5S 1M2 Canada
        </p>
        <p className="footer_order_p">
          Account Number : 11042-004-5325371
        </p>
        <p className="footer_order_p">
          Swift Code : TDOMCATTTOR
        </p>
      </>
  }

  return (
    <div>
      {invoiceColumnsValues?.loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : (
        <div

          className="sales_report_view"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <table>
            <thead>
              <tr>
                <td className="footer_invoice">
                  <div>
                    <SalesInvoiceReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block2">
                    <div className="sales-report-Table-data">
                      {myData?.map((size, index) => {
                        return (
                          <div key={index}>
                            <table
                              className="sales-report-table"
                              style={{ width: "99%" }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="font_weight"
                                    style={{ width: "36px" }}
                                  >
                                    S.No
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "88px",
                                    }}
                                  >
                                    SKU #
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Brand
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "240px",
                                    }}
                                  >
                                    Style/Desc
                                    <span style={{ marginLeft: "5px" }}>
                                      {size?.column?.gender_category_name}
                                    </span>
                                  </td>
                                  <td
                                    key={index}
                                    className="sales_table_col font_weight text-center pl-0"
                                  >
                                    {size.column.hq23 && size.column.hq23}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq24 && size.column.hq24}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq25 && size.column.hq25}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq26 && size.column.hq26}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq27 && size.column.hq27}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq28 && size.column.hq28}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq29 && size.column.hq29}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq30 && size.column.hq30}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq31 && size.column.hq31}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq32 && size.column.hq32}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq33 && size.column.hq33}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq34 && size.column.hq34}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq35 && size.column.hq35}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq36 && size.column.hq36}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq37 && size.column.hq37}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq38 && size.column.hq38}
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Quantity
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Amount
                                  </td>
                                </tr>
                                {size.detail?.map((it, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td
                                        style={{
                                          marginLeft: "0",
                                        }}
                                      >
                                        {it.item_code}
                                      </td>
                                      <td>
                                        {it?.sku_brand_name}
                                      </td>
                                      <td>{it.description}</td>
                                      <td className="text-center pl-0">
                                        {it.q23 && it.q23}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q24 && it.q24}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q25 && it.q25}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q26 && it.q26}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q27 && it.q27}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q28 && it.q28}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q29 && it.q29}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q30 && it.q30}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q31 && it.q31}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q32 && it.q32}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q33 && it.q33}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q34 && it.q34}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q35 && it.q35}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q36 && it.q36}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q37 && it.q37}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q38 && it.q38}
                                      </td>
                                      <td>
                                        {it.item_quantity && it.item_quantity}
                                      </td>
                                      <td>
                                        {currencySymbol} {it.rate && it.rate}
                                      </td>
                                      <td>{currencySymbol} {it.amount && it.amount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <table className="invoice-report-table-total">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          colSpan={3}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className="font_weight total_cell"
                        >
                          Total Quantity
                        </td>
                        <td className="total_amount" style={{ width: "70px" }}>
                          {footerData && footerData.sum_quantity}
                        </td>
                      </tr>
                      <tr className="font_weight">
                        <td
                          colSpan={3}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className="font_weight total_cell"
                        >
                          Gross Total
                        </td>
                        <td className="total_amount" style={{ width: "70px" }}>
                          {currencySymbol} {footerData && footerData.sum_gross_amount}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "12px",
                          }}
                          className="font_weight"
                        >
                          LESS
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Disc Amount
                        </td>

                        <td>{currencySymbol} {footerData && footerData.discount}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "12px",
                          }}
                          className="font_weight"
                        >
                          ADD
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Tax
                        </td>
                        <td>{currencySymbol} {footerData && footerData?.tax_percent}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Freight
                        </td>
                        <td>{currencySymbol} {footerData && Number(footerData.shipping_cost).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Surcharge Amount
                        </td>
                        <td>{currencySymbol} {footerData && Number(footerData.surcharge_amount || 0).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          C. C. Charges
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Net Amount
                        </td>
                        <td>{currencySymbol} {footerData && footerData.net_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            { }
            {
              footerData && footerData.term === "CREDIT CARD" ?
                <tfoot>
                  <tr>
                    <td className="footer2_invoice">
                      <div className="sales-report-footer">
                        <div className="order_report_footer">
                          <div style={{ float: "left", fontSize: "14px" }}>
                            Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}
                          </div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Card style={{ width: "390px" }}>
                              <p
                                className="footer_order_p"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textDecoration: "underline",
                                }}
                              >
                                Terms & Conditions
                              </p>
                              <p className="footer_order_p">
                                1) No Return of goods after 15 days of shipment.
                                <br />
                                2) Damaged goods must be returned within 10 days
                                of shipment.
                                <br />
                                3) No Returns will be accepted without RA #.
                                <br />
                                4) Returns without an advance shipping notice (including tracking#) and an approved RA# will not be processed.
                              </p>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                :
                footerData && (footerData.term === "WIRE TRANSFER" || footerData.term === "CONSIGNMENT" || footerData.term === "PRE-PAYMENT" || footerData.factor_id == 2) ? (
                  <tfoot>
                    <tr>
                      <td className="footer2_invoice">
                        <div className="sales-report-footer">
                          <div
                            className="order_report_footer"
                          >
                            <div style={{ float: "left", fontSize: "14px" }}>
                              Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}
                            </div>
                            <br />
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Card style={{ width: "300px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Remit Payment To
                                </p>
                                <p className="footer_order_p">
                                  Customer Send Payment To:
                                </p>
                                <p className="footer_order_p">
                                  DLPD CANADA LTD.
                                </p>
                                <p className="footer_order_p">
                                  15 Wertheim Court, Suite 409,<br /> Richmond Hill, ON L4B 3H7,<br /> Canada
                                </p>
                              </Card>

                              <Card style={{ width: "300px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Customer Wire Payment To:
                                </p>
                                {addr()}
                              </Card>

                              <Card style={{ width: "420px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Terms & Conditions
                                </p>
                                <p className="footer_order_p">
                                  1) No Return of goods after 15 days of shipment.
                                  <br />
                                  2) Damaged goods must be returned within 10 days
                                  of shipment.
                                  <br />
                                  3) No Returns will be accepted without RA #.
                                  <br />
                                  4) Returns without an advance shipping notice (including tracking#) and an approved RA# will not be processed.
                                </p>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                ) : footerData && footerData.factor_id == 1 ? (
                  <tfoot>
                    <tr>
                      <td className="footer2_invoice">
                        <div className="sales-report-footer" style={{ height: '315px' }}>
                          <div
                            className="order_report_footer"

                          >
                            <div style={{ float: "left", fontSize: "14px" }}>
                              Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}
                            </div>
                            <br />
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Card style={{ width: "463px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  This Invoice has been sold or assigned to
                                </p>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  THE CIT GROUP / COMMERCIAL SERVICES INC.
                                </p>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  P.O BOX 37993 , CHARLOTTE, NC 28237-7992
                                </p>
                                <p className="footer_order_p">
                                  to whom the account has been assigned solely for
                                  collection
                                </p>
                                <p className="footer_order_p">
                                  and to whom notice must be given of any
                                  merchandise
                                </p>
                                <p className="footer_order_p">
                                  returns or claims.Payment made to any other party
                                  does
                                </p>
                                <p className="footer_order_p">
                                  not constitute valid payment of this invoice.
                                </p>
                              </Card>
                              <Card style={{ width: "300px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Customer Wire Payment To:
                                </p>
                                {addr()}
                              </Card>
                              <Card style={{ width: "463px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Terms & Conditions
                                </p>
                                <p className="footer_order_p">
                                  1) No Return of goods after 45 days of shipment.
                                  <br />
                                  2) Damaged goods must be returned within 10 days of
                                  shipment.
                                  <br />
                                  3) No Returns will be accepted without RA#.
                                  <br />
                                  4) Payments must be received within due date.
                                  <br />
                                  5) All Overdue invoices will be charged @1.8% per month.
                                  <br />
                                  6) No Return allowed on sale item.
                                  <br />
                                  7) Returns without an advance shipping notice (including tracking#) and an approved RA# will not be processed.
                                </p>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                )
                  :
                  (
                    <></>
                  )}
          </table>
        </div>
      )}
    </div>
  );
};

export default SalesInvoiceReport;

