import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../OMSBooking/styleSheet.css";
import { Select, DatePicker, Form, Table, Spin, Button, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Link, useParams, useLocation } from "react-router-dom";
import Tables from "../../components/Table/Tables";
import { CSVLink } from "react-csv";
import { Excel } from "antd-table-saveas-excel";
import { getOmsStateReport } from "../../redux/actions/omsRegionStateReport.action";
import { useLayoutEffect } from "react";
import { getReturnOmsStateReport } from "../../redux/actions/omsReturnRegionStateReport.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { getCustomerType } from '../../redux/actions/customerType.action';
import { currencySymbol } from "../../utils/constants";

const { Option } = Select;
const OmsRegionStateReport = ({ token, id, propsData, formData }) => {
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const paramsData = useParams();

  const dispatch = useDispatch();
  const [form] = Form.useForm();


  const [data, setData] = useState([]);
  const { gender, region, companyAll, omsReturnRegionStateReport, customerTypes } =
    useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let company_id = query.get("company_id");

  let gender_id = query.get("gender_id");
  let customer_type_id = query.get("customer_type_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_Region = query.get("selected_Region");
  let selected_id = query.get("selected_id");
  let select = query.get("select");
  let state_name = query.get("state_name");
  let region_name = query.get("region_name");

  console.log("aabc", formData)

  useLayoutEffect(() => {
    setLoading(true);
    dispatch(
      getReturnOmsStateReport(token, id, {
        company_id,
        gender_id,
        from_date,
        to_date,
        customer_type_id,
        region_id,
        selected_Region,
        select,
      })
    ).then((res) => {
      setSubmit(true);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getCustomerType(token));
    dispatch(getAllCompany(token));
  }, [dispatch, menuId, token]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_no",
      align: "left",
      key: "serial_no",
      width: 50,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_id",
      width: 140,
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/return-region-wise`,
              search: `?submited=${true}&report=${3}&company_id=${company_id}&gender_id=${gender_id}&region_id=${region_id}&customer_type_id=${customer_type_id}&from_date=${from_date}&to_date=${to_date}&select=${true}&selected_id=${selected_id}&state_id=${text.state_id}&state_name=${text.state_name}&region_name=${text.region_name}`,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.region_name !== null) {
          return record.region_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.region_name !== null & b.region_name !== null) {
          return a.region_name.localeCompare(b.region_name)
        }
      },
    },
    {
      title: "State",
      dataIndex: "state_name",
      align: "left",
      key: "state_name",
      width: 70,
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/return-region-wise`,
              search: `?submited=${true}&report=${3}&company_id=${formData.company_id}&gender_id=${formData.gender_id}&region_id=${formData.region_id}&customer_type_id=${formData.customer_type_id}&from_date=${moment(formData.from_date).format("MM-DD-YYYY")}&to_date=${moment(formData.to_date).format("MM-DD-YYYY")}&select=${false}&selected_id=${selected_id}&state_id=${text.state_id}&state_name=${text.state_name}&region_name=${text.region_name}`, state: { ...text, val: true }, values,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.state_name !== null) {
          return record.state_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.state_name !== null & b.state_name !== null) {
          return a.state_name.localeCompare(b.state_name)
        }
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
      align: "right",
      render: (text) => {
        return (
          <span style={{ textAlign: "right" }}> {numeral(text).format('0,0')}</span>
        )
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.quantity !== null) {
          return record.quantity.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return (
          a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
        )
      },

    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      width: 130,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}>{currencySymbol} {numeral(text).format('0,0.00')}</span>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.net_amount !== null) {
          return record.net_amount.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return (
          a.net_amount.replaceAll(',', '') - b.net_amount.replaceAll(',', '')
        )
      },

    },
    {
      title: "AUC",
      dataIndex: "auc",
      key: "auc",
      width: 120,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}>{currencySymbol}{parseFloat(text).toFixed(2)}</span>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.auc !== null) {
          return record.auc
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
        )
      },
    },
    {
      title: "%",
      align: "right",
      dataIndex: "percentage",
      key: "percentage",
      width: 70,
      render: text => <span style={{ textAlign: "right" }}> {text}% </span >,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.percentage !== null) {
          return record.percentage.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return (
          a.percentage.replaceAll(',', '') - b.percentage.replaceAll(',', '')
        )
      },
    },
  ];

  const filterGender = gender.payload?.data.find((item) => {
    return item.gender_id === Number(gender_id);
  });

  const filterRegion = region.payload?.data.find((item) => {
    return item.region_id === Number(selected_id);
  });

  const filterCustomerType = customerTypes.payload?.data.find((item) => {
    return item.customer_type_id === Number(customer_type_id);
  })

  return (
    <>
      <div className="row report-wrapper-main">
        <div className="col p-0">
          <table
            id="Table1"
            cellPadding="3"
            cellSpacing="0"
            align="center"
            className="tablestyleControls"
          >
            <tbody>
              <tr>
                <td width="103">Featuring:</td>
                <td>
                  <b>
                    {gender_id === "All"
                      ? "All"
                      : filterGender && filterGender.gender_name}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Customer Type:</td>
                <td>
                  {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                </td>
                <td style={{ width: "20px" }} className="no-border-btm">
                  Region:
                </td>
                <td>
                  <b>{filterRegion && filterRegion.region_name}</b>
                </td>
              </tr>
              <tr>
                <td>From:</td>
                <td>
                  <b>{moment(from_date).format("MM-DD-YYYY")}</b>
                </td>
                <td style={{ width: "60px" }} className="no-border-btm">
                  To:
                </td>
                <td>
                  <b>{moment(to_date).format("MM-DD-YYYY")}</b>
                </td>
              </tr>
              <tr>
                {/* <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td> */}
                {/* <td>
                                    <b>
                                        {date_type === "B" ? "Booking Date" : "Start Date"}
                                    </b>
                                </td> */}
                <td className="no-border-btm">Brand :</td>
                <td>
                  <b>{company_id === "All" ? "All" : company_id === "1" ? "DL1961" : company_id === "2" ? "Warp + Weft" : ""}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center spin-style mt-3">
          <Spin />
        </div>
      ) : (
        <>
          {submit ? (
            <div className="report-wrapper-main mt-3">
              <Table
                showSorterTooltip={false}

                className="w-100 test04"
                size="small"
                pagination={{ pageSize: 1000 }}
                summary={(pageData) => {
                  let sumQty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity
                    ));
                  },
                    0);
                  let sumAmount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net_amount
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{ paddingTop: "10px", fontWeight: "bolder" }}
                          >
                            Total
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{
                              fontWeight: "bolder",
                              textAlign: "right",
                              width: "100%",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {numeral(sumQty).format("0,0")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{
                              fontWeight: "bolder",
                              textAlign: "right",
                              width: "100%",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {currencySymbol} {numeral(sumAmount).format("0,0.00")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
                bordered
                columns={columns}
                dataSource={omsReturnRegionStateReport?.payload?.data}
              />
            </div>
          ) : (
            <table
              className="tablestyle"
              cellSpacing="0"
              cellPadding="2"
              align="Center"
              rules="all"
              border="1"
              id="GridView1"
              style={{
                fontSize: "8pt",
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderWidth: "1pt",
                    borderStyle: "Dashed",
                    fontWeight: "bold",
                  }}
                >
                  <td colSpan="5">No record(s)</td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};
export default OmsRegionStateReport;
