import {
  GET_SALE_TARGET_REPORT_REQUEST,
  GET_SALE_TARGET_REPORT_SUCCESS,
  GET_SALE_TARGET_REPORT_ERROR,
  GET_SALE_COMPARISION_MONTH_WISE_REQUEST,
  GET_SALE_COMPARISION_MONTH_WISE_SUCCESS,
  GET_SALE_COMPARISION_MONTH_WISE_ERROR,
  GET_SALE_COMPARISION_CUSTOMER_WISE_REQUEST,
  GET_SALE_COMPARISION_CUSTOMER_WISE_SUCCESS,
  GET_SALE_COMPARISION_CUSTOMER_WISE_ERROR,
  GET_SALE_TARGET_SALEPERSON_REPORT_REQUEST,
  GET_SALE_TARGET_SALEPERSON_REPORT_SUCCESS,
  GET_SALE_TARGET_SALEPERSON_REPORT_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getSaleTargetReport = (token, values, divisionArray) => (dispatch) => {
  dispatch({ type: GET_SALE_TARGET_REPORT_REQUEST });
  const newData = {
    cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
    ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
    ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,

    customer_type_id: values.customer_type_id || values.customerTypeID ? (values.customerTypeID ? values.customerTypeID : values.customer_type_id) : null,
    region_id: values.region_id || values.regionId ? (values.regionId ? values.regionId : values.region_id) : null,
    gender_id: values.gender_id || values.genderId ? (values.genderId ? values.genderId : values.gender_id) : null,
    sales_person_id: values.sales_person_id || values.salePersonId ? (values.salePersonId ? values.salePersonId : values.sales_person_id) : null,
    division_line: (divisionArray === "All" || divisionArray === undefined) ? null : divisionArray,

  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/funcGetSaleComparisonReport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_TARGET_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {

      dispatch({
        type: GET_SALE_TARGET_REPORT_ERROR,
        payload: [],
      });










    });
};

export const getSaleComparisionReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_COMPARISION_MONTH_WISE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/salecomparisonreportmonthwise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_COMPARISION_MONTH_WISE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {

      dispatch({
        type: GET_SALE_COMPARISION_MONTH_WISE_ERROR,
        payload: [],
      });
    });
};

export const getSaleComparisionCustomerWiseReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_COMPARISION_CUSTOMER_WISE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/saleComparisonReportCustomerwise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_COMPARISION_CUSTOMER_WISE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {

      dispatch({
        type: GET_SALE_COMPARISION_CUSTOMER_WISE_ERROR,
        payload: [],
      });
    });
};



export const getSaleTargetSalePersonReport = (token, values, divisionArray) => (dispatch) => {
  dispatch({ type: GET_SALE_TARGET_SALEPERSON_REPORT_REQUEST });
  const newData = {
    cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
    ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
    ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,

    customer_type_id: values.customer_type_id || values.customerTypeID ? (values.customerTypeID ? values.customerTypeID : values.customer_type_id) : null,
    region_id: values.region_id || values.regionId ? (values.regionId ? values.regionId : values.region_id) : null,
    gender_id: values.gender_id || values.genderId ? (values.genderId ? values.genderId : values.gender_id) : null,
    sales_person_id: values.sales_person_id || values.salePersonId ? (values.salePersonId ? values.salePersonId : values.sales_person_id) : null,
    division_line: (divisionArray === "All" || divisionArray === undefined) ? null : divisionArray,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/scmSaleTarget_SalesPersonWise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_TARGET_SALEPERSON_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {

      dispatch({
        type: GET_SALE_TARGET_SALEPERSON_REPORT_ERROR,
        payload: [],
      });
    });
};

