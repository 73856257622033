import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import moment from "moment";
import numeral from "numeral";
import { addSaleOrderHeader, updateSaleOrderHeader, getSaleOrderFormData, getOneSaleOrderHeader, } from "../../../../redux/actions/salesOrder.action";
import { DatePicker, Input, Checkbox, Tabs, Button, Form, Select, Tooltip, Card, Row, Col, Popconfirm, InputNumber, Collapse, Divider, Skeleton, Modal } from "antd";
import { getSingleBilling } from "../../../../redux/actions/billing";
import { getOneSalesOrderLine, deleteSalesOrderLine, getOnesaleOrderRelatedRecord } from "../../../../redux/actions/saleOrderLine.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { relatedTransactionColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getOneCustomerAddress } from "../../../../redux/actions/customerAddress.action";
import { getOmsAvailableBalance } from "../../../../redux/actions/omsAvailableBalance.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { onMouseDownSelectableHack } from "../../.././../components/DropdownSelected";
import SkeletonLoad from "../../../../components/Skeleton/SkeletonLoad";
import { CSVLink } from "react-csv";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { isObject } from "lodash";
import { getBrands } from "../../../../redux/actions/brand.action";
import { companyID, currencySymbol, defaultLocation } from "../../../../utils/constants";
import { sendOrderConfirmationOnHoldEmail } from "../../../../redux/actions/email.action";
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function SaleOrderForm({ formData, val, token, setDisable, formState, submit, loading: deleteLoading, changeState, setSaleOrderNewID, saleOrderNewID, mode, setLoadingNew, userRights, user_type }) {
  const { brand, saleOrderFormData, saleOrderRelatedRecord, customerAddress, commitCriteria, saleOrderLine, companyAll, systemLog } = useSelector((state) => state);
  const queryParams = new URLSearchParams(window.location.search);

  const focusInput = useRef(null);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const [menuId] = useState(53);
  const [gender, setGender] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [resaleExpDate, setResaleExpDate] = useState(null);
  const [cDate, setCancelDate] = useState(moment());
  const [sDate, setStartDate] = useState(moment());
  const [eDate, setEndDate] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const [isShipComplete, setIsShipComplete] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isOrderHold, setIsOrderHold] = useState(false);
  const [enableEDI, setEnableeEDI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountBasis, setDiscountBasis] = useState("");
  const [shippingToID, setShippingToID] = useState("");
  const [billingToID, setBillingToID] = useState("");
  const [itemIndex, setItemIndex] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [formFieldsValue, setFormFieldsValue] = useState({});
  const [openEmailModal, setOpenEmailModal] = useState(false);




  useEffect(() => {
    dispatch(getAllCompany(token));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    dispatch(getBrands(token));
  }, []);

  const handleDeleteOrder = (record) => {
    const dataSource = [...orderItems];
    const deleteSource = [...deletedItems];
    const deleteEditableItem = [...filteredItems];
    if (orderItems.length > 1) {
      if (record?.quantity_packed > 0) {
        return Swal.fire({
          icon: "error",
          title: `Cannot remove ${record?.item_code} as PT or SOA is already created for it`,
          text: "Please Remove it first",
        });
      } else if (record?.quantity_adjust > 0) {
        return Swal.fire({
          icon: "error",
          title: `Cannot remove ${record?.item_code} as PT or SOA is already created for it`,
          text: "Please Remove it first",
        });
      } else {
        deleteSource.push(record)
        setDeletedItems(deleteSource);
        setOrderItems(
          dataSource.filter((item) => item.item_id !== record.item_id)
        );
        setFilteredItems(
          deleteEditableItem.filter((item) => item.item_id !== record.item_id)
        );
        notification("success", "Item Deleted");
      }
    } else {
      return Swal.fire({
        icon: "error",
        title: "There is only 1 Line.",
        text: "Last line of the document cannot be deleted",
      });
    }
  };

  let sumDisc = 0;
  orderItems.map((val) => {
    sumDisc += parseFloat(val.discount)
  })

  let amountSum = 0;
  orderItems.map((val) => {
    amountSum += val.quantity * val.rate
  })

  const taxSum = orderItems.reduce((accum, val) => {
    let sumCheck = parseFloat(val.tax_amount === null ? 0 : Number(val.tax_amount));
    return accum + sumCheck;
  }, 0)

  const downloadExcelFileData = (data) => {
    if (isObject(data)) {
      if (data.length > 0) {
        return data && data?.map((v) => {
          return {
            "SKU": v.item_code,
            "Style": v.style_name,
            "Wash": v.wash_name,
            "Gender Category": v.gender_category_name,
            "Size": v.size,
            "Inseam": v.inseam,
            "Committed": v.quantity_committed,
            "Packed": v.quantity_packed,
            "Shipped": v.quantity_shipped,
            "Billed": v.quantity_billed,
            "BackOrder": v.quantity_backorder,
            "Adjusted": v.quantity_adjust,
            "Available": v.quantity_available,
            "On Hand": v.quantity_onhand,
            "Quantity": v.quantity,
            "Cost Price": v.rate,
            "Amount": v.quantity * v.rate,
            "Discount Percent": v.discount_percent,
            "Discount Amount": v.discount,
            "Tax Percent": v.tax_percent,
            "Tax Amount": v.tax_amount,
            "Net Amount": v.net_amount,
            "Commit Status": v.commit_status,
            "Rejected": v.quantity_rejected
          };
        })
      }
    }
  }

  const updateCostPrice = (type, id, value, p_qty) => {
    let copyArr = [...orderItems];
    copyArr[itemIndex].rate = value;
    setOrderItems(copyArr);
  };

  const updateQuantity = (type, id, value, p_qty) => {
    let copyArr = [...orderItems];
    let sumPacked_and_adjust = copyArr[itemIndex].quantity_adjust + parseFloat(copyArr[itemIndex].quantity_packed);
    if (value >= sumPacked_and_adjust) {
      copyArr[itemIndex].quantity = value;
    } else {
      Swal.fire({
        icon: "error",
        title: `Order Qty cannot be less than PT + SOA Qty!`,

      });
      copyArr[itemIndex].quantity = sumPacked_and_adjust;
    }
    setOrderItems(copyArr);
  };

  const updateTaxPercent = (p_qty, p_rate, tax_perc) => {
    let copyArr = [...orderItems];
    copyArr[itemIndex].tax_percent = tax_perc;
    copyArr[itemIndex].tax_amount = (((p_qty * p_rate) * tax_perc / 100));
    setOrderItems(copyArr);
  }

  const updateDiscount = (p_qty, p_rate, dis_perc) => {
    let copyArr = [...orderItems];
    copyArr[itemIndex].discount = (((p_qty * p_rate) * dis_perc / 100));
    setOrderItems(copyArr);
  };

  const updateCommitt = (p_qty, p_avail, p_shipped, p_adjust, p_commit_status, index) => {
    let copyArr = [...orderItems];

    let balance_qty = p_qty - ((p_shipped === null || p_shipped === undefined) ? 0 : p_shipped) - ((p_adjust === null || p_adjust === undefined) ? 0 : p_adjust);
    if (balance_qty > 0) {
      if (p_commit_status === 1) {
        if (copyArr[index].quantity_available < 0) {
          return
        } else {

          if (p_avail > balance_qty) {
            copyArr[index].quantity_committed = balance_qty + copyArr[index].quantity_committed;
            copyArr[index].quantity_backorder = 0;
            copyArr[index].commit_status = p_commit_status;
          }
          else {
            copyArr[index].quantity_committed = p_avail;
            copyArr[index].commit_status = p_commit_status;
            copyArr[index].quantity_backorder = balance_qty - p_avail;
          }
        }
      }
      else if (p_commit_status === 3) {
        let packedShipped = copyArr[index].quantity_packed - copyArr[index].quantity_shipped
        let totalCommitQty = copyArr[index].quantity_committed - (packedShipped);
        copyArr[index].quantity_committed = packedShipped;
        copyArr[index].quantity_backorder += totalCommitQty;
        copyArr[index].commit_status = p_commit_status;

      }
      setOrderItems(copyArr);
    }
    else {
      copyArr[index].commit_status = p_commit_status;
    }
  }

  const updateCommit_BackOrder = (value) => {
    let copyArr = [...orderItems];
    let availQty = copyArr[itemIndex].quantity_available < 0 ? 0 : copyArr[itemIndex].quantity_available;
    let backOrderQty = value - (copyArr[itemIndex].quantity_available < 0 ? 0 : copyArr[itemIndex].quantity_available);
    if (value < availQty) {
      copyArr[itemIndex].quantity_committed = value || 0;
      copyArr[itemIndex].quantity_backorder = 0;
    }
    else if (value >= availQty) {
      copyArr[itemIndex].quantity_committed = availQty;
      copyArr[itemIndex].quantity_backorder = backOrderQty;
    }
    let qtyCommitted = copyArr[itemIndex].quantity_committed;
    let qtyPacked = copyArr[itemIndex].quantity_packed;
    let qtyShipped = copyArr[itemIndex].quantity_shipped;
    let qtyBackorder = copyArr[itemIndex].quantity_backorder;

    if ((qtyCommitted - (qtyPacked - qtyShipped)) < 0) {
      copyArr[itemIndex].quantity_committed = (qtyPacked - qtyShipped);
      copyArr[itemIndex].quantity = qtyShipped + copyArr[itemIndex].quantity_committed + qtyBackorder;
    }
    setOrderItems(copyArr);
  }

  const columns = [
    {
      title: "SKU",
      dataIndex: "item_code",
      width: 100,
      fixed: 'left',
    },
    {
      title: "Style",
      dataIndex: "style_name",
      width: 120,
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      width: 140,
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
      width: 120,
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 80,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      width: 80,
    },
    {
      title: "Commited",
      editable: false,
      width: 80,
      render: (text, record) => {
        return <>{record.quantity_committed}</>;
      },
    },
    {
      title: "Packed",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {numeral(record.quantity_packed).format("0")}
          </>
        );
      },
    },
    {
      title: "Shipped",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_shipped}
          </>
        );
      },
    },
    {
      title: "Billed",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_billed}
          </>
        );
      },
    },
    {
      title: "BackOrder",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_backorder}
          </>
        );
      },
    },
    {
      title: "Adjusted",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {numeral(record.quantity_adjust).format("0")}
          </>
        );
      },
    },
    {
      title: "Available",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_available}
          </>
        );
      },
    },
    {
      title: "On Hand",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_onhand}
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 80,
      editable: true,
      render: (text, record, index) => {
        return <>
          {editModalShow && itemIndex === orderItems.findIndex((v) => v.item_code === record.item_code) ? (
            <InputNumber
              size="small"
              value={orderItems[itemIndex].quantity}
              onChange={(value) => {
                updateQuantity("up", record.item_code, value, orderItems[itemIndex].quantity_committed);
                updateCommitt(value, orderItems[itemIndex].quantity_available, orderItems[itemIndex].quantity_packed, record.quantity_adjust, record.commit_status, orderItems.findIndex((v) => v.item_code === record.item_code))
                updateDiscount(value, orderItems[itemIndex].rate, orderItems[itemIndex].discount_percent)
                updateTaxPercent(value, orderItems[itemIndex].rate, orderItems[itemIndex].tax_percent)
                updateCommit_BackOrder(value)
                handleDiscountBasis(form.getFieldValue().discountBasis);
              }}
            />
          ) : (
            <>
              {record.quantity}
            </>
          )}
        </>;
      },
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      width: 80,
      render: (text, record, index) => {
        return <>
          {editModalShow && itemIndex === orderItems.findIndex((v) => v.item_code === record.item_code) ? (
            <InputNumber
              size="small"
              defaultValue={orderItems[itemIndex].rate}
              onChange={(value) => {
                updateCostPrice("up", record.item_code, value)
                updateDiscount(orderItems[itemIndex].quantity, value, orderItems[itemIndex].discount_percent)
                updateTaxPercent(orderItems[itemIndex].quantity, value, orderItems[itemIndex].tax_percent)
                handleDiscountBasis(form.getFieldValue().discountBasis)
              }}
            />
          ) : (
            <>
              {record.rate}
            </>
          )}
        </>
      }
    },
    {
      title: "Amount",
      width: 80,
      render: (_, record, index) => {
        return numeral(record.quantity * record.rate).format("0,0.00");
      },
    },
    {
      title: "Discount Percent",
      width: 80,
      render: (_, record) => {
        return (
          <>
            {numeral(record.discount_percent).format("0,0.00")} %
          </>
        )

      },
    },
    {
      title: "Discount Amount",
      width: 80,
      render: (_, record) => {
        return numeral(record.discount).format("0,0.00");
      },
    },
    {
      title: "Tax Percent",
      width: 70,
      dataIndex: "tax_percent",
      editable: true,
      render: (_, record) => {
        return (<>  {editModalShow && itemIndex === orderItems.findIndex((v) => v.item_code === record.item_code) ? (
          <InputNumber
            size="small"
            defaultValue={orderItems[itemIndex].tax_percent}
            onChange={(value) => {
              updateTaxPercent(orderItems[itemIndex].quantity, orderItems[itemIndex].rate, value)
              handleDiscountBasis(form.getFieldValue().discountBasis)
            }}
          />
        ) : (
          <>
            {numeral(record.tax_percent || 0).format("0,0.00")} %
          </>
        )}
        </>
        )
      }
    },
    {
      title: "Tax Amount",
      width: 80,
      render: (_, record) => {
        return numeral(record.tax_amount || 0).format("0,0.00")
      }
    },
    {
      title: "Net Amount",
      width: 80,
      render: (_, record, index) => {
        let fn_qty = record.quantity;
        let fn_rate = record.rate;
        let fn_disperc = record.discount_percent;
        let fn_taxper = record.tax_percent;
        record.net_amount = numeral(((fn_qty * fn_rate) - (((fn_qty * fn_rate) * fn_disperc) / 100) + (((fn_qty * fn_rate) * fn_taxper) / 100)
        )).format("00.00");

        return numeral((
          (fn_qty * fn_rate)
          - (((fn_qty * fn_rate) * fn_disperc) / 100)
          + (((fn_qty * fn_rate) * fn_taxper) / 100)
        )).format("0,0.00")
      }
    },
    {
      title: "Commit Status",
      width: 150,
      editable: false,
      render: (text, record) => {
        return (
          <Select
            disabled={editModalShow && itemIndex === orderItems.findIndex((v) => v.item_code === record.item_code) ? false : true}
            size="small"

            autoComplete={"nope"}
            defaultValue={record.commit_status == 0 ? "None" : record.commit_status == 1 ? "Available Qty" : record.commit_status == 2 ? "Complete Qty" : record.commit_status == 3 ? "Do Not Commit" : ""}

            onChange={(value) => updateCommitt(record.quantity, record.quantity_available, record.quantity_packed, record.quantity_adjust, value, orderItems.findIndex((v) => v.item_code === record.item_code))}

          >
            {commitCriteria?.payload?.data?.map((item) => {
              return (
                <Option
                  key={item.commit_criteria_id}
                  value={item.commit_criteria_id}
                >
                  {item.commit_criteria_name}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Rejected",
      width: 80,
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.quantity_rejected}
          </>
        );
      },
    },
    {
      title: "Operation",
      width: 80,
      dataIndex: "operation",
      render: (_, record) => (
        formState === "add" || formState === "edit" ? (
          <Tooltip title="Delete" color={"white"}>
            <>
              <Popconfirm
                title={`Are you sure to delete the item ${record.item_code}?`}
                onConfirm={() => handleDeleteOrder(record)}
              >
                <DeleteOutlined className={`delete-Button`} />
              </Popconfirm>

              <EditOutlined
                className={`edit-Button`}
                onClick={(e) => {
                  showEditModal(record, orderItems.findIndex((v) => v.item_code === record.item_code))
                  const index = orderItems.findIndex((v) => v.item_code === record.item_code)
                  if (index === itemIndex) {
                    setItemIndex(null)
                  } else {
                    setItemIndex(index)
                  }
                }}

              />
            </>
          </Tooltip>
        ) : null
      ),
    },
  ];

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0])
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      }
    })
  }

  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }

    if (type === "factor") {
      form.setFieldsValue({ factor: value });
    }

    if (type === "paymentTermId") {
      const Item = saleOrderFormData?.payload?.customerTerm.filter(
        (item) => item.term_id === value
      );
      const factorId = Item[0]?.factor_id;
      form.setFieldsValue({ factor: factorId });
    }

    if (type === "gender") {
      form.setFieldsValue({
        category: null,
        region: null,
        salesPerson: null,
      });
      if (customer) {
        setGender(value);
        if (formState !== "formView") {
          dispatch(getSaleOrderGenderHeader(token, value, customer)).then(
            (res) => {
              if (
                res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                res?.payload.length > 0
              ) {
                form.setFieldsValue({
                  category: res.payload[0].sp_region_category_id,
                  region: res.payload[0].region_id,
                  salesPerson: res.payload[0].salesperson_id,
                });
              }
            }
          );
        }

      } else {
        setGender(null);
      }
    }
  }

  function handleCheckBox(e) {
    form.setFieldsValue({ [e.target.name]: e.target.checked });
    if (e.target.name === "isShipComplete") {
      setIsShipComplete(e.target.checked);
    }
    else if (e.target.name === "is_closed") {
      setIsClosed(e.target.checked);
      form.setFieldsValue({ is_closed: e.target.checked });
    }
    else if (e.target.name === "order_hold") {
      setIsOrderHold(e.target.checked);
      form.setFieldsValue({ order_hold: e.target.checked });
    }
  }

  function handleGenderChange(value) {
    if (customer) {
      setGender(value);
      dispatch(getSaleOrderGenderHeader(token, value, customer)).then((res) => {
        if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload.length > 0
        ) {
          form.setFieldsValue({
            category: res.payload[0].sp_region_category_id,
            region: res.payload[0].region_id,
            salesPerson: res.payload[0].salesperson_id,
          });
        } else if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload?.length === 0
        ) {
          form.setFieldsValue({
            category: null,
            region: null,
            salesPerson: null,
          });

        }
      });
    }
  }

  const onFinish = async (e) => {
    submit(false);
    if (orderItems.length === 0) {
      return Swal.fire({
        icon: "error",
        title: "Add Line Items",
        text: "The line item shouldn't be empty.",
      });
    }
    if (!form.getFieldValue().orderStatus) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select the Order Status",
      });
    }
    if (!form.getFieldValue().paymentTermId) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select the payment Term",
      });
    }
    else {
      form.setFieldsValue({ subsidary_name: companyAll?.payload?.data.find(x => x.company_id === companyID)?.company_name })
      await form.validateFields().then((values) => {
        setLoading(true);
        setDisable(true);
        let check = false;
        orderItems.forEach((val) => {
          if (val.quantity <= 0) {
            Swal.fire({
              icon: "error",
              title: "OOPS",
              text: `The line item Quantity shouldn't be 0.`,
            });
            check = true;
            setLoading(false);
          }
        })

        if (!check) {
          if (formState === "add") {
            dispatch(addSaleOrderHeader(token, form.getFieldValue(), companyId, orderItems.map((item) => {
              item.discount = item.discount;

              item.tax_amount = item.tax_amount;
              item.tax_percent = item.tax_percent;
              item.amount = item.quantity * item.rate;

              return item;
            }),

            )
            ).then((res) => {
              if (res.type === "ADD_SALEORDER_HEADER_SUCCESS") {
                setSaleOrderNewID(res?.payload?.order_header_id)
                form.setFieldsValue({ orderNumber: res?.payload?.order_header_no });
                notification("success", "Sale Order Created", res?.payload?.data);
                dispatch(getOneSalesOrderLine(token, res?.payload?.order_header_id)).then((res) => {
                  if (res.type === "GET_ONE_SALESORDERLINE_SUCCESS") {
                    const orderData = res.payload.data;
                    setOrderItems(orderData);
                    setVisibleItems(orderData);
                    setDisable(false);
                  }
                  dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
                });
                setLoading(false);
                history.push(`/sale-order?mode=edit&order_id=${res?.payload?.order_header_id}&customer_id=${customer}`)
              } else if (res.type === "ADD_SALEORDER_HEADER_ERROR") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data.error.errors[0]?.message,
                });
                setLoading(false);
                setDisable(false);
              }
            });
          }
          else if (formState === "edit") {
            if (deletedItems.length > 0) {
              dispatch(deleteSalesOrderLine(token, 123, deletedItems));
            }
            values.is_closed = form.getFieldValue().is_closed;
            // values.order_hold = form.getFieldValue().order_hold;
            dispatch(updateSaleOrderHeader(token, form.getFieldValue(), saleOrderNewID, companyId,
              orderItems.map((item) => {
                item.amount = item.quantity * item.rate;
                return item;
              }),
              shippingToID,
              billingToID
            )
            ).then((res) => {
              if (res.type === "UPDATE_SALEORDER_HEADER_SUCCESS") {
                dispatch(getOneSalesOrderLine(token, formData?.order_header_id)).then((res) => {
                  if (res.type === "GET_ONE_SALESORDERLINE_SUCCESS") {
                    const orderData = res.payload.data;
                    setVisibleItems(res.payload.data);
                    setOrderItems(res.payload.data);
                    setVisibleItems(orderData);
                    setLoading(false);
                    setDisable(false);
                  }
                  dispatch(getOneSaleOrderHeader(token, formData?.order_header_id)).then((res) => {
                    if (res.type === "GET_ONE_SALEORDERHEADER_SUCCESS") {
                      notification("success", "Sale Order Updated");
                      changeState("formView");
                      setLoading(false);
                      setDisable(false);
                    }
                  });
                });
              } else if (res.type === "UPDATE_SALEORDER_HEADER_ERROR") {
                setLoading(false);
                setDisable(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data,
                });
              }
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        endDate: eDate,
        orderNumber: null,
        orderDate: moment(new Date()),
        addressOneBilling: null,
        addressOneShipping: null,
        berganBilling: null,
        billingAddressID: null,
        billingCustomer: null,
        billingEmail: null,
        cancelDate: null,
        category: null,
        discount: null,
        cityBilling: null,
        cityShipping: null,
        company: null,
        countryBilling: null,
        countryShipping: null,
        customer: null,
        dc: null,
        deptDescription: null,
        divisionCode: null,
        divisionDescription: null,
        refID: null,
        freightAmount: null,
        freightCharges: null,
        freightTermId: null,
        gender: null,
        addressTwoBilling: null,
        is_closed: false,
        order_hold: false,
        isShipComplete: false,
        location: null,
        subsidary: companyID,
        brand_id: null,
        memo: null,
        orderStatus: null,
        paymentTermId: null,
        po: null,
        region: null,
        resaleCertificateNo: null,
        salesPerson: null,
        shippingAccountNo: null,
        shippingAddressID: null,
        shippingCost: null,
        shippingCustomer: null,
        shippingEmail: null,
        shippingMethodId: null,
        shippingTaxCode: null,
        shippingTaxRate: null,
        shipViaId: null,
        sourceType: null,
        customer: null,
        startDate: moment(sDate),
        stateBilling: null,
        stateShipping: null,
        store: null,
        discount: null,
        termForPrint: null,
        zipBilling: null,
        telBilling: null,
        telShipping: null,
        zipShipping: null,
        shipToCode: null,
        remarks: null,
        deptCode: null,
        specialInstructions: null,
        billing_firstname: null,
        billing_lastname: null,
        shipping_firstname: null,
        shipping_lastname: null,
        berganPaymentTermId: null,
        berganBillingId: null,
        discountId: null,
        discountCode: null,
        surcharge_amount: 0,
        discountBasis: null,
        discountPercent: 0,
        bill_to_non_us: null,
        ship_to_non_us: null,
        order_type: null,
        tax_duties: null,
        factor_decision: null,
        markForCode: null,
        markForCompanyName: null,
        markForAddress1: null,
        markForAddress2: null,
        markForCity: null,
        markForState: null,
        markForZip: null,
        markForCountry: null,
        markForEmail: null,
        markForPhone: null,
      });
      setIsShipComplete(false);
      setVisibleItems([]);
      setOrderItems([])
      setResaleExpDate(null);
      setIsClosed(false);
      setIsOrderHold(false);
    }
    else if (formData && mode === "edit") {
      setLoadingNew(true)
      dispatch(getSaleOrderFormData(token, companyId, menuId, "", formData.customer_id)).then(
        (res) => {
          if (res?.type === "GET_SALEORDERFORMDATA_SUCCESS") {
            if (formState === "formView") {
              if (formData.customer_id && formData.order_header_id) {
                dispatch(getOneCustomerAddress(token, formData.customer_id, formData.order_header_id))
                setSaleOrderNewID(formData.order_header_id);
                setBillingToID(formData.billing_address_id);
                setShippingToID(formData.shipping_address_id);
                dispatch(getOneSalesOrderLine(token, formData?.order_header_id)).then((res) => {
                  if (res.type === "GET_ONE_SALESORDERLINE_SUCCESS") {
                    const orderData = res.payload.data;
                    setVisibleItems(res.payload.data);
                    setOrderItems(res.payload.data);
                    setVisibleItems(orderData);
                  }
                }
                );
              }
            }
            if (res.type === "GET_GENDER_HEADER_DATA_SUCCESS" && res?.payload.length === 0) {
              notification("warning", "Empty List");
            }
            if (res?.payload?.customerDetail?.length === 0) {
            }
          }
        }
      );
      setCustomer(formData.customer_id);
      setIsShipComplete(formData.is_ship_complete);
      setIsClosed(formData.is_closed);
      setIsOrderHold(formData.order_hold);
      setDiscountBasis(formData.discount_basis);
      if (formData.source_type === "bergan-edi") {
        setEnableeEDI(false);
      }
      form.setFieldsValue({
        gender: formData.gender_id,
        region: formData.region_id,
        salesPerson: formData.sales_person_id,
        berganBilling: formData.bergan_billing_id,
        berganBillingId: formData.bergan_billing_id,
        berganPaymentTermId: formData.bergan_payment_term_id,
        cancelDate: moment(formData.cancel_date),
        category: formData.sp_region_category_id,
        company: formData.company_id,
        customer: formData.customer_id,
        dc: formData.dc_no,
        deptDescription: formData.department_description,
        divisionCode: formData.division_code,
        divisionDescription: formData.division_description,
        discountBasis: formData.discount_basis,
        discountId: formData.discount_id,
        discountCode: formData.discount_code,
        surcharge_amount: formData.surcharge_amount,
        discount: formData.discount_amount ? formData.discount_amount : 0,
        discountPercent: formData.discount_percent ? formData.discount_percent : 0,
        endDate: moment(formData.end_date),
        externalRefID: formData.reference_no,
        factor: formData.factor_id,
        freightAmount: formData.freight_amount,
        freightCharges: formData.freight_charges,
        freightTermId: formData.freight_term_id,
        gender: formData.gender_id,
        subsidary: companyID,
        is_closed: formData.is_closed ? true : false,
        order_hold: formData.order_hold ? true : false,
        isShipComplete: formData.is_ship_complete,
        location: formData.location_id,
        brand_id: formData.brand_id,
        markForAddress1: formData.mark_for_address_1,
        markForAddress2: formData.mark_for_address_2,
        markForCity: formData.mark_for_city,
        markForCode: formData.mark_for_code,
        markForCompanyName: formData.mark_for_company_name,
        markForCountry: formData.mark_for_country,
        markForEmail: formData.mark_email,
        markForPhone: formData.mark_for_phone,
        markForState: formData.mark_for_state,
        markForZip: formData.mark_for_zip,
        remarks: formData.remarks,
        orderDate: moment(formData.order_date),
        orderNumber: formData.order_header_no,
        orderStatus: formData.order_status_id,
        paymentTermId: formData.payment_term_id,
        po: formData.reference_no,
        region: formData.region_id,
        resaleCertificateDate: resaleExpDate,
        resaleCertificateNo: formData.release_certificate_no,
        salesPerson: formData.sales_person_id,
        shippingAccountNo: formData.ship_account_no,
        shippingAddressID: formData.shipping_address_id,
        shippingCost: formData.shipping_cost,
        shippingMethodId: formData.shipping_method_id,
        shippingTaxCode:
          formData.shipping_tax_code == null
            ? formData.shipping_tax_code
            : Number(formData.shipping_tax_code),
        shippingTaxRate: formData.shipping_tax_rate,

        shipViaId: formData.ship_via_id,
        sourceType: formData.source_type,
        startDate: moment(formData.start_date),
        store: formData.customer_store_no,
        termForPrint: formData.term_for_print,
        memo: formData.memo,

        refID: formData.external_ref_id,
        deptCode: formData.department_code,
        shipToCode: formData.ship_to_code,
        specialInstructions: formData.special_instructions,
        factor_decision: formData.factor_decision,


        shipping_firstname: formData.shipping_firstname,
        shipping_lastname: formData.shipping_lastname,
        addressOneShipping: formData.ship_to_address_1,
        addressTwoShipping: formData.ship_to_address_2,
        cityShipping: formData.ship_to_city_name,
        stateShipping: formData.ship_to_state_name,
        zipShipping: formData.ship_to_zip_code,
        countryShipping: formData.zipShipping,
        shippingEmail: formData.ship_to_email,
        telShipping: formData.ship_to_phone,
        countryShipping: formData.ship_to_country_name,


        billing_firstname: formData.billing_firstname,
        billing_lastname: formData.billing_lastname,
        addressOneBilling: formData.bill_to_address_1,
        addressTwoBilling: formData.bill_to_address_2,
        cityBilling: formData.bill_to_city_name,
        stateBilling: formData.bill_to_state_name,
        zipBilling: formData.bill_to_zip_code,
        countryBilling: formData.bill_to_country_name,
        billingEmail: formData.bill_to_email,
        telBilling: formData.bill_to_phone,
        billingCustomer: formData.billing_address_id,
        shippingCustomer: formData.shipping_address_id,

        bill_to_non_us: formData.bill_to_non_us,
        ship_to_non_us: formData.ship_to_non_us,
        order_type: formData.order_type_id,
        tax_duties: formData.tax_duties,
      });
      setLoadingNew(false);
    }
    else if (formData === null || mode === "add") {
      focusInput.current.focus();
      form.setFieldsValue({
        endDate: eDate,
        freightAmount: 0,
        freightCharges: 0,
        orderDate: moment(new Date()),
        cancelDate: cDate,
        subsidary: companyID,
        shippingAccountNo: null,
        shippingCost: null,
        startDate: sDate,
        location: null,
        brand_id: null
      });
      dispatch(
        getSaleOrderFormData(token, companyId, menuId, "", customerID, true)
      );
    }
  }, [formData]);

  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        sourceType: "Manual Order Entry",
        order_hold: false
      })
    }
  }, [formState])

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(getSystemLog(token, { table_name: "scm_sale_order_header", record_id: saleOrderNewID }));
    } else if (key === "line") {
      dispatch(getSystemLog(token, { table_name: "scm_sale_order_lines", record_id: saleOrderNewID }));
    } else if (key === "relatedTransaction" && formState === "formView" || formState === "edit") {
      dispatch(getOnesaleOrderRelatedRecord(token, saleOrderNewID));
    }
  };

  const discountAmount = (amount, orderItem = []) => {
    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }
    const sum = orderIt.map((item) => item.amount).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
    let totalAmount = orderIt.map((item) => item.quantity * item.rate);
    if (amount !== null || amount !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item) => {
          const totalSum = totalAmount.reduce((accum, value) => {
            return accum + value
          })
          item.discount = numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00").replace(/,/g, '');
          item.discount_percent = numeral((amount / totalSum) * 100).format("0,0.00");
          item.net_amount = item.quantity * item.rate - numeral((item.quantity * item.rate * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00");
          return item;
        });
        setOrderItems(calculateDiscount);
      }
    }
  };

  const discountPercent = (percent, orderItem = []) => {
    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }
    if (percent !== null || percent !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item, index) => {
          item.discount_percent = numeral(percent).format("0");
          item.discount = numeral(((item.quantity * item.rate) * percent) / 100).format("0,0.00").replace(/,/g, '');
          item.net_amount = numeral((item.quantity * item.rate) - (((item.quantity * item.rate) * percent) / 100) +
            (((item.quantity * item.rate) * item.tax_percent) / 100)).format("0.00");
          return item;
        });
        setOrderItems(calculateDiscount);
      }
    }
  };

  const handleDiscountBasis = (discountBasis) => {
    const discount = form?.getFieldValue()?.discount ? form.getFieldValue().discount : 0;
    const discountPercentage = form?.getFieldValue()?.discountPercent ? form.getFieldValue().discountPercent : 0;
    let orderItem = orderItems.length > 0 ? orderItems : visibleItems;
    if (discountBasis === "A") {
      if (discount !== null || discount !== undefined) {
        discountAmount(parseFloat(discount).toFixed(0), orderItem);
      }
      setDiscountBasis("A");
    } else if (discountBasis === "P") {
      if (discountPercentage !== null || discountPercentage !== undefined) {
        discountPercent(parseFloat(discountPercentage).toFixed(0), orderItem);
      }
      setDiscountBasis("P");
    } else {
      setDiscountBasis("");
    }
  };

  const onCustomerSelect = (e, valid) => {
    if (e) {
      if (formState === "add") form.setFieldsValue({ berganPaymentTermId: 1 });
      setCustomer(e);
      dispatch(getOneCustomerAddress(token, e)).then((res) => {
        if (res?.type === "GET_ONE_CUSTOMER_ADREESS_SUCCESS") {
          form.setFieldsValue({
            location: defaultLocation,
            gender: null,
            category: null,
            region: null,
            salesPerson: null,
          });
          let filterCustomer = res?.payload.data.filter((it) => it.default_bill_to)
          let filterDefaultShipping = res?.payload.data.filter((it) => it.default_ship_to)
          if (filterCustomer.length) {
            form.setFieldsValue({
              addressOneBilling: filterCustomer[0]?.address_address_1 === null ? "N/A" : filterCustomer[0]?.address_address_1,
              addressTwoBilling: filterCustomer[0]?.address_address_2 === null ? "N/A" : filterCustomer[0]?.address_address_2,
              billingEmail: filterCustomer[0]?.address_email === null ? "N/A" : filterCustomer[0]?.address_email,
              zipBilling: filterCustomer[0]?.address_zip_code === null ? "N/A" : filterCustomer[0]?.address_zip_code,
              stateBilling: filterCustomer[0]?.address_state_name === null ? "N/A" : filterCustomer[0]?.address_state_name,
              telBilling: filterCustomer[0]?.address_phone === null ? "N/A" : filterCustomer[0]?.address_phone,
              cityBilling: filterCustomer[0]?.address_city_name === null ? "N/A" : filterCustomer[0]?.address_city_name,
              countryBilling: filterCustomer[0]?.address_country_name === null ? "N/A" : filterCustomer[0]?.address_country_name,
              billingCustomer: filterCustomer[0]?.customer_address_id === null ? "N/A" : filterCustomer[0]?.customer_address_id,
              billing_firstname: filterCustomer[0]?.first_name === null ? "N/A" : filterCustomer[0]?.first_name,
              billing_lastname: filterCustomer[0]?.last_name === null ? "N/A" : filterCustomer[0]?.last_name,
              berganBillingId: filterCustomer[0]?.bergan_billing_id,
              freightTermId: filterCustomer[0]?.freight_term_id,
              isShipComplete: filterCustomer[0]?.is_ship_complete,
              paymentTermId: filterCustomer[0]?.term_id,
              shippingAccountNo: filterCustomer[0]?.shipping_account_no === "NULL" ? "" : filterCustomer[0]?.shipping_account_no,
              shippingMethodId: filterCustomer[0]?.shipping_method_id,
              shipViaId: filterCustomer[0]?.courier_service_id,
              factor: filterCustomer[0]?.factor_id,
              orderStatus: filterCustomer[0]?.order_status_id ? filterCustomer[0]?.order_status_id : 1,
              customer: filterCustomer[0]?.customer_id,
              order_type: filterCustomer[0]?.order_type_id ? filterCustomer[0]?.order_type_id : 1,
              bill_to_non_us: filterCustomer[0]?.bill_to_non_us,
              ship_to_non_us: filterCustomer[0]?.ship_to_non_us,
              tax_duties: filterCustomer[0]?.tax_duties,
              resaleCertificateNo: filterCustomer[0]?.resale_certificate_no,
              resaleCertificateDate: moment(filterCustomer[0]?.resale_certificate_date),
            });
          }
          else {
            form.setFieldsValue({
              addressOneBilling: res?.payload?.data[0]?.address_address_1 === null ? "N/A" : res?.payload?.data[0]?.address_address_1,
              addressTwoBilling: res?.payload?.data[0]?.address_address_2 === null ? "N/A" : res?.payload?.data[0]?.address_address_2,
              billingEmail: res?.payload?.data[0]?.address_email === null ? "N/A" : res?.payload?.data[0]?.address_email,
              zipBilling: res?.payload?.data[0]?.address_zip_code === null ? "N/A" : res?.payload?.data[0]?.address_zip_code,
              stateBilling: res?.payload?.data[0]?.address_state_name === null ? "N/A" : res?.payload?.data[0]?.address_state_name,
              telBilling: res?.payload?.data[0]?.address_phone === null ? "N/A" : res?.payload?.data[0]?.address_phone,
              cityBilling: res?.payload?.data[0]?.address_city_name === null ? "N/A" : res?.payload?.data[0]?.address_city_name,
              countryBilling: res?.payload?.data[0]?.address_country_name === null ? "N/A" : res?.payload?.data[0]?.address_country_name,
              billingCustomer: res?.payload?.data[0]?.customer_address_id === null ? "N/A" : res?.payload?.data[0]?.customer_address_id,
              billing_firstname: res?.payload?.data[0]?.first_name === null ? "N/A" : res?.payload?.data[0]?.first_name,
              billing_lastname: res?.payload?.data[0]?.last_name === null ? "N/A" : res?.payload?.data[0]?.last_name,
              berganBillingId: res?.payload?.data[0]?.bergan_billing_id,
              freightTermId: res?.payload?.data[0]?.freight_term_id,
              isShipComplete: res?.payload?.data[0]?.is_ship_complete,
              paymentTermId: res?.payload?.data[0]?.term_id,
              shippingAccountNo: res?.payload?.data[0]?.shipping_account_no === "NULL" ? "" : res?.payload?.data[0]?.shipping_account_no,
              shippingMethodId: res?.payload?.data[0]?.shipping_method_id,
              shipViaId: res?.payload?.data[0]?.courier_service_id,
              factor: res?.payload?.data[0]?.factorId,
              orderStatus: filterCustomer[0]?.order_status_id ? filterCustomer[0]?.order_status_id : 1,
              customer: res?.payload?.data[0]?.customer_id,
              order_type: filterCustomer[0]?.order_type_id ? filterCustomer[0]?.order_type_id : 1,
              bill_to_non_us: filterCustomer[0]?.bill_to_non_us,
              ship_to_non_us: filterCustomer[0]?.ship_to_non_us,
              tax_duties: filterCustomer[0]?.tax_duties,
              resaleCertificateNo: filterCustomer[0]?.resale_certificate_no,
              resaleCertificateDate: moment(filterCustomer[0]?.resale_certificate_date),
            });
          }
          if (filterDefaultShipping.length) {
            form.setFieldsValue({
              addressOneShipping: filterDefaultShipping[0]?.address_address_1 === null ? "N/A" : filterDefaultShipping[0]?.address_address_1,
              addressTwoShipping: filterDefaultShipping[0]?.address_address_2 === null ? "N/A" : filterDefaultShipping[0]?.address_address_2,
              shippingEmail: filterDefaultShipping[0]?.address_email === null ? "N/A" : filterDefaultShipping[0]?.address_email,
              zipShipping: filterDefaultShipping[0]?.address_zip_code === null ? "N/A" : filterDefaultShipping[0]?.address_zip_code,
              stateShipping: filterDefaultShipping[0]?.address_state_name === null ? "N/A" : filterDefaultShipping[0]?.address_state_name,
              telShipping: filterDefaultShipping[0]?.address_phone === null ? "N/A" : filterDefaultShipping[0]?.address_phone,
              cityShipping: filterDefaultShipping[0]?.address_city_name === null ? "N/A" : filterDefaultShipping[0]?.address_city_name,
              countryShipping: filterDefaultShipping[0]?.address_country_name === null ? "N/A" : filterDefaultShipping[0]?.address_country_name,
              shippingCustomer: filterDefaultShipping[0]?.customer_address_id === null ? "N/A" : filterDefaultShipping[0]?.customer_address_id,
              shipping_firstname: filterDefaultShipping[0]?.first_name === null ? "N/A" : filterDefaultShipping[0]?.first_name,
              shipping_lastname: filterDefaultShipping[0]?.last_name === null ? "N/A" : filterDefaultShipping[0]?.last_name,
            });
          }
          else {
            form.setFieldsValue({
              addressOneShipping: res?.payload?.data[0]?.address_address_1 === null ? "N/A" : res?.payload?.data[0]?.address_address_1,
              addressTwoShipping: res?.payload?.data[0]?.address_address_2 === null ? "N/A" : res?.payload?.data[0]?.address_address_2,
              shippingEmail: res?.payload?.data[0]?.address_email === null ? "N/A" : res?.payload?.data[0]?.address_email,
              zipShipping: res?.payload?.data[0]?.address_zip_code === null ? "N/A" : res?.payload?.data[0]?.address_zip_code,
              stateShipping: res?.payload?.data[0]?.address_state_name === null ? "N/A" : res?.payload?.data[0]?.address_state_name,
              telShipping: res?.payload?.data[0]?.address_phone === null ? "N/A" : res?.payload?.data[0]?.address_phone,
              cityShipping: res?.payload?.data[0]?.address_city_name === null ? "N/A" : res?.payload?.data[0]?.address_city_name,
              countryShipping: res?.payload?.data[0]?.address_country_name === null ? "N/A" : res?.payload?.data[0]?.address_country_name,
              shippingCustomer: res?.payload?.data[0]?.customer_address_id === null ? "N/A" : res?.payload?.data[0]?.customer_address_id,
              shipping_firstname: res?.payload?.data[0]?.first_name === null ? "N/A" : res?.payload?.data[0]?.first_name,
              shipping_lastname: res?.payload?.data[0]?.last_name === null ? "N/A" : res?.payload?.data[0]?.last_name,
            });
          }
        }
      })
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showEditModal = (record, index) => {
    setEditModalShow(true);
    let copyArr = [...orderItems];
    if (formData) {
      dispatch(getOmsAvailableBalance(token, formData.location_id, copyArr[index].item_id)).then((res) => {
        if (res?.type === "GET_OMS_AVAILABLE_BALANCE_SUCCESS") {
          copyArr[index].quantity_available = parseInt(res?.payload?.data[0].available_quantity) + parseInt(copyArr[index].quantity_committed);
          copyArr[index].quantity_onhand = res?.payload?.data[0].on_hand_quantity;
          setOrderItems(copyArr);
        }
      })
    }
  };

  const onBillingNameSelect = (e) => {
    setBillingToID(e);
    dispatch(getSingleBilling(token, formData?.order_header_id, formData?.customer_id || customer, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
        form.setFieldsValue({
          countryBilling: res.payload?.data[0].country_name,
          billingCustomer: res.payload?.data[0].customer_address_id,
          cityBilling: res.payload?.data[0].city_name,
          billingEmail: res.payload?.data[0].customer_email,
          stateBilling: res.payload?.data[0].state_name,
          telBilling: res.payload?.data[0].phone,
          zipBilling: res.payload?.data[0].zip_code,
          billing_firstname: res.payload?.data[0].customer_first_name,
          billing_lastname: res.payload?.data[0].customer_last_name,
          addressOneBilling: res.payload?.data[0].address_1,
          addressTwoBilling: res.payload?.data[0].address_2,
        });
      }
    });
  };

  const onShippingNameSelect = (e) => {
    setShippingToID(e);
    dispatch(getSingleBilling(token, formData?.order_header_id, formData?.customer_id || customer, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
        form.setFieldsValue({
          countryShipping: res.payload?.data[0].country_name,
          shippingCustomer: res.payload?.data[0].customer_address_id,
          cityShipping: res.payload?.data[0].city_name,
          shippingEmail: res.payload?.data[0].customer_email,
          stateShipping: res.payload?.data[0].state_name,
          telShipping: res.payload?.data[0].phone,
          zipShipping: res.payload?.data[0].zip_code,
          shipping_firstname: res.payload?.data[0].customer_first_name,
          shipping_lastname: res.payload?.data[0].customer_last_name,
          addressOneShipping: res.payload?.data[0].address_1,
          addressTwoShipping: res.payload?.data[0].address_2,
        });
      }
    });
  };

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);


  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const discount = form?.getFieldValue()?.discount ? form.getFieldValue().discount : 0;
    const discountPercentage = form?.getFieldValue()?.discountPercent ? form.getFieldValue().discountPercent : 0;
    const onlyKey = [...value].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => { return onlyKey.find((i) => i === f.item_code) });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
      setOrderItems(uniqueOrderItems);
      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
          discountAmount(parseFloat(discount).toFixed(0), uniqueOrderItems);
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
          discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
        }
      }
      if (form.getFieldValue().tax_type && form.getFieldValue().tax_value !== null || form.getFieldValue().tax_value !== undefined) {
        handleTaxRate(form.getFieldValue().tax_type, form.getFieldValue().tax_value, uniqueOrderItems);
      }
    } else {
      let uniqueOrderItems = [...visibleItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
          discountAmount(discount, uniqueOrderItems.map((item) => { return item; })
          );
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
          discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
        }
      }
      if (form.getFieldValue().tax_type && form.getFieldValue().tax_value !== null || form.getFieldValue().tax_value !== undefined) {
        handleTaxRate(form.getFieldValue().tax_type, form.getFieldValue().tax_value, uniqueOrderItems);
      }
    }
    notification("success", "Item Added Successfully");
  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  const sourceTypeChange = (e) => {
    setEnableeEDI(true)
  };

  const summary = () => {
    let orderSummary = orderItems.length > 0 ? orderItems : visibleItems;
    let amount = 0;
    let quantity = 0;
    let discount = 0;
    let surcharge_amount = form.getFieldValue().surcharge_amount || 0;
    let tax = 0;
    let shippingCost = form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0;
    let net = 0;
    if (orderSummary.length > 0) {
      let count = 0
      orderSummary?.forEach(function (item) {
        count += 1
        if (item.hasOwnProperty("rate")) {
          amount += item.rate * item.quantity;
          net += parseFloat(item.rate) * parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("quantity")) {
          quantity += parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("discount")) {
          discount = sumDisc || 0
        }
        if (item.hasOwnProperty("tax_amount")) {
          tax = taxSum;
        }
      });
    }
    return {
      quantity: quantity,
      amount: numeral(amount).format("0,0.00"),
      discount: discount,
      surcharge_amount: surcharge_amount,
      tax: tax,
      shippingCost: numeral(shippingCost).format("0,0.00"),
      shipping_tax_rate: numeral(formData?.shipping_tax_rate || 0).format("0,0.00"),
      net: numeral(numeral(amount).value() + numeral(shippingCost).value() - numeral(discount).value() + numeral(surcharge_amount).value() + numeral(tax).value() + numeral(formData?.shipping_tax_rate).value()).format("0,0.00"),
    };
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    setCustomer(val);
    dispatch(getSaleOrderFormData(token, companyId, menuId, val, "")).then(
      (res) => {
        if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload.length === 0
        ) {
          notification("warning", "Empty List");
        }
        if (res?.payload?.customerDetail?.length === 0) {
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);

  let sortedOrderItems = orderItems.length > 0 && orderItems.sort(function (a, b) {
    return a.item_code.localeCompare(b.item_code, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });

  function disableLinks() {
    let result = "auto";
    if (!userRights?.can_edit && user_type !== "PK") {
      result = "none";
    }
    else {
      result = "auto";
    }
    return result;
  }

  function checkTransactionDate(e) {
    let transaction_date = moment(e).format("YYYY-MM-DD")
    setLoading(true)
    dispatch(getTransationLock(token, transaction_date)).then((r) => {
      setLoading(false)
      if (r.payload.data.length > 0) {
        setCancelDate(moment())
        form.setFieldsValue({
          cancelDate: moment()
        })
        Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
      }
      else {
        setCancelDate(moment(e))
      }
    });
  }

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.brand_id && changedValues.brand_id !== formFieldsValue.brand_id) {
      setOrderItems([])
    }
    if (changedValues.tax_type || changedValues.tax_value) {
      // do something
      handleTaxRate(changedValues.tax_type || form.getFieldValue().tax_type, changedValues.tax_value || form.getFieldValue().tax_value)
    }
    setFormFieldsValue(allValues)
  };

  const handleTaxRate = (type, value, _OI = []) => {
    const _o = _OI.length ? _OI : orderItems
    if (type && value !== null || value !== undefined && _o.length) {
      let calculateTax = []
      if (type === 'A') {
        let totalSum = _o.reduce((acc, item) => acc + Number(item.amount), 0);
        calculateTax = _o.map((item) => {
          const amount = Number(item.amount)
          item.tax_amount = numeral((amount * numeral((value / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00").replace(/,/g, '');
          item.tax_percent = numeral((value / totalSum) * 100).format("0,0.00");
          item.net_amount = amount + numeral((amount * numeral((amount / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00");
          return item;
        });
      } else {
        calculateTax = _o.map((item, index) => {
          item.tax_percent = numeral(value).format("0");
          const amount = Number(item.amount)
          item.tax_amount = numeral(((amount) * value) / 100).format("0,0.00").replace(/,/g, '');
          item.net_amount = numeral((amount) + (((amount) * item.discount_percent) / 100) +
            (((amount) * value) / 100)).format("0.00");
          return item;
        });
      }
      setOrderItems(calculateTax);
      setVisibleItems(calculateTax);
    }
  }
  return (
    <>
      <EditableTable
        saleOrderNewID={saleOrderNewID}
        locationID={form.getFieldValue().location}
        mode={mode}
        token={token}
        companyId={companyId}
        brandId={form.getFieldValue().brand_id}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        setShowGrid={setShowGrid}
        formState={formState}
        visibleItems={visibleItems}
        filteredItems={filteredItems}
        setFilteredItems={setFilteredItems}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
        {deleteLoading ||
          saleOrderFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="orderNumber"
                        label="Order #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}

                      >
                        <Input
                          size="small"
                          disabled
                          className={`customInputs without_border`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Order Date"
                        name="orderDate"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[{ required: true, message: "Order Date is required" }]}
                      >
                        <DatePicker
                          size="small"
                          disabled={true}
                          // disabled={!(formState === "add" || formState === "edit")}
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().orderDate
                              : moment(new Date())
                          }
                          format="MM-DD-YYYY"
                          allowClear
                          className={`dateInput customInputs without_border`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="po"
                        label="P.O #"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          { required: true, message: "PO#  is required" },
                          { max: 100, message: "PO# can not be more than 100 characters" }
                        ]}
                      >
                        <Input
                          size="small"
                          className={`customInputs`}
                          maxLength={101}
                          disabled={!(formState === "add" || formState === "edit")}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="sourceType"
                        label="Source Type"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          size="small"
                          onChange={sourceTypeChange}
                          className={`customInputs`}
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="customer"
                        label="Customer Name"
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 52 },
                          lg: { span: 52 },
                          xxl: { span: 4 }
                        }}
                        rules={[{ required: true, message: "Please Select Customer" }]}
                      >
                        <Select
                          notFoundContent={saleOrderFormData.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          loading={saleOrderFormData.loading}
                          onSelect={(data) => onCustomerSelect(data, true)}
                          disabled={!(formState === "add" || formState === "edit")}
                          defaultValue={form.getFieldValue().customer}
                          onSearch={(value) => optimizedFn(value)}
                          ref={focusInput}
                          onMouseDownCapture={onMouseDownSelectableHack}
                        >
                          {saleOrderFormData?.payload?.customerDetail.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} - ${item.customer_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>


                        {/* </div> */}

                      </Form.Item>

                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="refID"
                        label="Ref ID"
                        labelAlign="left"
                        labelCol={{ span: 10 }}

                      >
                        <Input
                          size="small"
                          onChange={sourceTypeChange}
                          className={`customInputs`}
                          disabled={(formState === "formView" || formState === "edit")}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={8} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{
                          lg: { span: 52 },
                          xxl: { span: 10 },
                        }}
                      >
                        <Input
                          size="small"
                          onChange={sourceTypeChange}
                          className={`customInputs`}
                          disabled={!(formState === "add" || formState === "edit")}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="slc">
                      <Form.Item name="subsidary"
                        label="Subsidary"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 16 }}>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          disabled
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          onMouseDownCapture={onMouseDownSelectableHack}
                        >
                          {companyAll?.payload?.data?.map((item) => {
                            return (
                              <Option
                                key={item.company_id}
                                value={item.company_id}
                              >
                                {item.company_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4} xxl={3}>
                    <div className="slc" style={{ display: "flex", gap: 10 }}>
                      <Checkbox
                        disabled={formData && formData?.customer_is_active}
                        checked={formData && formData?.customer_is_active}
                      >Customer Active</Checkbox>
                      <a
                        style={{ pointerEvents: disableLinks() }}
                        target="_blank" href={`/customer-New-Info/edit/${formData?.customer_id}?edit=yes`}>
                        Customer View
                      </a>
                    </div>
                  </Bcol>

                  {
                    [1, 2, 3, 5, 17, 19, 20, 25, 40, 53].includes(formData?.payment_term_id) && <Bcol lg={6} xl={4} xxl={3}>
                      <Button onClick={() => setOpenEmailModal(!openEmailModal)}>CIT Hold/Declined Email</Button>
                    </Bcol>
                  }

                  {openEmailModal && <SendEmailComponent
                    openEmailModal={openEmailModal}
                    setOpenEmailModal={setOpenEmailModal}
                    formData={formData}
                    summary={summary}
                    token={token}
                    dispatch={dispatch}
                  />}
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Order Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Quantity:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {summary().quantity}
                        </p>
                      </Bcol>
                    </Brow>

                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Sub Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{summary().amount}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Less Discount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{numeral(summary().discount).format("0.00")}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Tax:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">{currencySymbol}{summary().tax.toFixed(2)}</p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Shipping Cost:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{summary().shippingCost}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Shipping Tax:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{summary().shipping_tax_rate}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Add Surcharge:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {currencySymbol}{numeral(summary().surcharge_amount).format("0.00")}
                        </p>
                      </Bcol>
                    </Brow>
                    <Divider className="my-1" />
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className=" mb-0">Total:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">{currencySymbol}{summary().net}</p>
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Brow className="secondSectio">
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Billing Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? <Skeleton
                      paragraph={{ rows: 6 }}
                    /> :
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="billingCustomer"
                            label="Name"
                            className="name-field-form selectNamee"
                            labelAlign="left"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                            rules={[{ required: true, message: "Billing Customer is required" }]}
                          >
                            <Select
                              size="small"
                              optionFilterProp="children"
                              tabIndex={4}
                              autoComplete={"nope"}
                              disabled={!(formState === "add" || formState === "edit")}
                              showSearch={true}
                              className="customInputs fw-400"
                              loading={customerAddress?.loading}
                              onSelect={onBillingNameSelect}
                              onMouseDownCapture={onMouseDownSelectableHack}
                            >
                              {customerAddress.payload?.data?.map((item, index) => {
                                return (
                                  <Option key={item.customer_address_id} value={item.customer_address_id}>{" "}
                                    {`${item.address_addressee}(${item.address_zip_code})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_firstname"
                            label={`First Name`}
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={false}
                              bordered={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billing_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              disabled={false}
                              bordered={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneBilling"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoBilling"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityBilling"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateBilling"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipBilling"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryBilling"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telBilling"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}

                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="billingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input

                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    }

                  </div>
                </Card>
              </Bcol>
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Shipping Information"
                >
                  <div className="secondSectionRow">
                    {customerAddress?.loading ? <Skeleton
                      paragraph={{ rows: 6 }}
                    /> :
                      <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            name="shippingCustomer"
                            label="Name"
                            labelAlign="left"
                            className="name-field-form"
                            labelCol={{
                              xs: { span: 24 },
                              sm: { span: 4 },
                              md: { span: 4 },
                              lg: { span: 2 },
                            }}
                            rules={[{ required: true, message: "Shipping Customer is required" }]}
                          >
                            <Select
                              size="small"
                              tabIndex={15}
                              autoComplete={"nope"}
                              optionFilterProp="children"
                              showSearch
                              allowClear
                              className="customInputs fw-400 "
                              loading={customerAddress?.loading}
                              onSelect={onShippingNameSelect}
                              disabled={!(formState === "add" || formState === "edit")}
                              onMouseDownCapture={onMouseDownSelectableHack}
                            >
                              {customerAddress?.payload?.data?.map((item) => {
                                return (
                                  <Option key={item.customer_address_id} value={item.customer_address_id}>{" "}
                                    {`${item.address_addressee} (${item.address_zip_code})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_firstname"
                            label="First Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shipping_lastname"
                            label="Last Name"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressOneShipping"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="addressTwoShipping"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <TextArea
                              autoSize={true}
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="cityShipping"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="stateShipping"
                            label="State"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="zipShipping"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="countryShipping"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="telShipping"
                            label="Tel"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                          <Form.Item
                            name="shippingEmail"
                            label="Email"
                            labelAlign="left"
                            labelCol={{ span: 4 }}
                          >
                            <Input
                              bordered={false}
                              readOnly
                              className={`customInputs without_bg fw-400 `}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                  </div>
                </Card>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>
          <SubTitles name={"Date"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Start Date is required" }]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={6}

                      format="MM-DD-YYYY"
                      disabled={!(formState === "add" || formState === "edit")}
                      allowClear
                      className={`dateInput customInputs fw-400 `}
                      onChange={(e) => setStartDate(moment(e).format("MM-DD-YYYY"))}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().startDate
                          : moment(sDate)
                      }
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="cancelDate"
                    label="Cancel Date"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Cancel Date is required" }]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={8}

                      format="MM-DD-YYYY"
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().cancelDate
                          : moment(cDate)
                      }
                      allowClear
                      className={`dateInput customInputs fw-400  `}
                      disabled={!(formState === "add" || formState === "edit")}
                      onChange={(e) => {
                        setCancelDate(moment(e));
                        checkTransactionDate(e);
                      }}
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <SubTitles name={"Sales Information"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol

                lg={6} xl={4} xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Gender is required" }]}
                  >
                    <Select
                      size="small"
                      tabIndex={9}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      // allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={formState === "edit" ? form.getFieldValue().gender : null}
                      onChange={(value) => handleGenderChange(value)}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.gender.map((item) => {
                        return (
                          <Option
                            key={item.gender_id}
                            disabled={!item.is_active}
                            value={item.gender_id}
                          >
                            {item.gender_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="category"
                    label="Category"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      size="small"
                      readOnly
                      className={`customInputs fw-400 `}
                      // className={`customInputs fw-400  without_border`}
                      // disabled={!(formState === "add" || formState === "edit")}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="region"
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Region is required" }]}
                  >
                    <Select
                      size="small"
                      tabIndex={10}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={true}
                      // disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={form.getFieldValue().region}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.adminRegionDetail.map(
                        (item) => {
                          return (
                            <Option key={item.region_id} value={item.region_id}>
                              {item.region_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="salesPerson"
                    label="Sales Person"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Sales Person is required" }]}
                  >
                    <Select
                      size="small"
                      tabIndex={12}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      // disabled={!(formState === "add" || formState === "edit")}
                      disabled={true}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.salesPersonDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.sales_person_id}
                              value={item.sales_person_id}
                            >
                              {item.salesperson_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true, message: "Location is required" }]}
                  >
                    <Select
                      tabIndex={13}
                      size="small"
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      onChange={(value) => handleChange(value)}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.locationDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.location_id}
                              value={item.location_id}
                            >
                              {item.location_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              {/* Brand Field */}
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="customCol slc">
                  <div className="relative">
                    <Form.Item rules={[{ required: true, message: "Brand is required" }]} name="brand_id" label="Brand" labelAlign="left" labelCol={{ span: 10 }} wrapperCol={{ span: 16 }}>
                      <Select size="small" showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100"
                        disabled={!(formState === "add")}
                      >
                        {brand?.payload?.data?.map((res) => (
                          <Option key={res.brand_id} value={res.brand_id}>
                            {res.brand_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Bcol>
            </Brow>
          </div>
          <Collapse
            bordered={false}
            defaultActiveKey={1}
            className="pbpx-10 bg-white"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Classification" key="1">
              <Tabs
                size="small"
                type="card"
                tabBarGutter={1}
                defaultActiveKey="shipping"
                onChange={(key) => handleTab(key)}
                centered
              >
                <TabPane tab="Shipping" key="shipping">
                  <div className="container-fluid pbpx-0 ptpx-5">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingAccountNo"
                            label="Shipping Account No"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              defaultValue={0}
                              size="small"
                              tabIndex={14}
                              className={`customInputs fw-400 `}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shipViaId"
                            label="Ship Via"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Ship Via is required" }]}
                          >
                            <Select
                              size="small"
                              tabIndex={15}
                              autoComplete={"nope"}
                              disabled={!(formState === "add" || formState === "edit")}
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              allowClear
                              loading={saleOrderFormData.loading}
                              defaultValue={form.getFieldValue().shipViaId}
                              onChange={(value) => handleChange(value)}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.shipVia.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.courier_service_id}
                                      disabled={!item.is_active}
                                      value={item.courier_service_id}
                                    >
                                      {item.service_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingMethodId"
                            label="Shipping Method"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please select Shipping Method" }]}
                          >
                            <Select
                              className={``}
                              size="small"
                              tabIndex={16}
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().shippingMethodId}
                              onChange={(e) => handleChange(e)}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.adminShipMethod.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.shipping_method_id}
                                      disabled={!item.is_active}
                                      value={item.shipping_method_id}
                                    >
                                      {item.shipping_method_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingCost"
                            label="Shipping Cost"
                            labelAlign="left"
                            labelCol={{ span: 10 }}






                          >
                            <InputNumber
                              defaultValue={0}
                              size="small"
                              onInput={(val) => {
                                form.setFieldsValue({ shippingCost: val });
                              }}
                              tabIndex={17}
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxCode"
                            label="Shipping Tax Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select
                              size="small"
                              tabIndex={18}
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().shippingTaxCode}
                              onChange={(value) => handleChange(value)}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.adminShipTaxCode.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.ship_tax_id}
                                      disabled={!item.is_active}
                                      value={item.ship_tax_id}
                                    >
                                      {item.ship_tax_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxRate"
                            label="Shipping Tax Rate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <InputNumber
                              size="small"
                              tabIndex={19}
                              className={`w-100 customInputs fw-400 `}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightTermId"
                            label="Freight Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please Select Freight Term" }]}
                          >
                            <Select
                              size="small"
                              tabIndex={20}
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().freightTermId}
                              onChange={(value) => handleChange(value)}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.freightTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.freight_term_id}
                                      disabled={!item.is_active}
                                      value={item.freight_term_id}
                                    >
                                      {item.freight_term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganBillingId"
                            label="Bergen Billing Option"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please Select Bergen Billing" }]}
                          >
                            <Select
                              tabIndex={21}
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().berganBillingId}
                              onChange={(value) =>
                                handleChange(value, "berganBillingId")
                              }
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.berganBilling.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergan_billing_id}
                                      disabled={!item.is_active}
                                      value={item.bergan_billing_id}
                                    >
                                      {item.bergan_billing_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganPaymentTermId"
                            label="Bergen Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Bergen Payment Term",
                              },
                            ]}
                          >
                            <Select
                              size="small"
                              name="py"
                              tabIndex={22}
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().berganPaymentTermId}
                              onChange={(value) =>
                                handleChange(value, "paymentTermId")
                              }
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.berganPaymentTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergen_payment_term_id}
                                      disabled={!item.is_active}
                                      value={item.bergen_payment_term_id}
                                    >
                                      {item.term_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="isShipComplete"
                            label="Ship Complete"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Checkbox
                              tabIndex={23}
                              name="isShipComplete"
                              checked={isShipComplete}
                              onChange={(e) => handleCheckBox(e)}
                              disabled={!(formState === "add" || formState === "edit")}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}></Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane tab="Billing" key="billing">
                  <div className="container-fluid pbpx-0 ptpx-15">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="termForPrint"
                            label="Term For Print"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={true}
                              defaultValue={form.getFieldValue().termForPrint}
                              onChange={(value) => handleChange(value)}
                              className="without_bg without_border"
                              onMouseDownCapture={onMouseDownSelectableHack}
                            >
                              {saleOrderFormData?.payload?.termForPrint.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="paymentTermId"
                            label="Payment Term"
                            labelAlign="left"
                            initialValue={form.getFieldValue().paymentTermId}
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Payment Term",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              name="paymentTermId"
                              autoComplete={"nope"}
                              allowClear
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                              showSearch
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().paymentTermId}
                              onChange={(value) =>
                                handleChange(value, "paymentTermId")
                              }
                              onMouseDownCapture={onMouseDownSelectableHack}
                            >
                              {saleOrderFormData?.payload?.customerTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane disabled={enableEDI} tab="EDI" key="edi">
                  <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForCode"
                          label="Mark for Code"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs `}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForCompanyName"
                          label="Mark for Company"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForAddress1"
                          label="Mark for Address 1"
                          labelAlign="left"
                          labelCol={{ span: 10 }}

                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForAddress2"
                          label="Mark for Address 2"
                          labelAlign="left"
                          labelCol={{ span: 10 }}

                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForCity"
                          label="Mark for City"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForState"
                          label="Mark for State"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForZip"
                          label="Mark for Zip"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI
                            }
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForCountry"
                          label="Mark for Country"
                          labelAlign="left"
                          labelCol={{ span: 10 }}
                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForEmail"
                          label="Mark for Email"
                          labelAlign="left"
                          labelCol={{ span: 10 }}

                        >
                          <Input
                            size="small"
                            type="email"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <Form.Item
                          name="markForPhone"
                          label="Mark for Phone"
                          labelAlign="left"
                          labelCol={{ span: 10 }}

                        >
                          <Input
                            size="small"
                            className={`customInputs`}
                            disabled={!(formState === "add" || formState === "edit") || enableEDI}
                          />
                        </Form.Item>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane tab="Other" key="other">
                  <div className="container-fluid pbpx-5 ptpx-15">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="orderStatus"
                            label="Order Status"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[{ required: true, message: "Please Select Order Status" }]}
                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().orderStatus}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.orderStatusDetail.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.order_status_id}
                                      value={item.order_status_id}
                                    >
                                      {item.status_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="factor"
                            label="Factor"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                              showSearch
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={formState === "edit" ? form.getFieldValue().factor : null}
                              onChange={(value) => handleChange(value)}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.factorDetail.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.factor_id}
                                      disabled={!item.is_active}
                                      value={item.factor_id}
                                    >
                                      {item.factor_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="factor_decision"
                            label="Factor Decision"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >

                            <Input
                              size="small"
                              className={`customInputs fw-400 `}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="dc"
                            label="DC#"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >

                            <Input
                              size="small"
                              className={`customInputs fw-400 `}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="resaleCertificateNo"
                            label="Resale Certificate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              className={`customInputs fw-400 `}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            label="Resale Expiry Date"
                            name="resaleCertificateDate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >

                            <DatePicker
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              placeholder={formState === "add" ? "Select Date" : "Not Available"}

                              format="MM-DD-YYYY"
                              allowClear
                              className="dateInput customInputs"
                              onChange={(e) => setResaleExpDate(moment(e))}
                            />


                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="store"
                            label="Store"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="deptDescription"
                            label="Department Desc"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs`}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="divisionDescription"
                            label="Division Desc"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="divisionCode"
                            label="Division Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="deptCode"
                            label="Department Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shipToCode"
                            label="Ship To Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="specialInstructions"
                            label="Special Instructions"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={!(formState === "add" || formState === "edit")}
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="bill_to_non_us"
                            label="Bill To Non-US"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Input
                              size="small"

                              className={`customInputs`}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="ship_to_non_us"
                            label="Ship To Non-US"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Input
                              size="small"

                              className={`customInputs`}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="order_type"
                            label="Order Type"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={!(formState === "add" || formState === "edit")}
                              defaultValue={form.getFieldValue().order_type}
                              onMouseDownCapture={onMouseDownSelectableHack}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {saleOrderFormData?.payload?.scmOrderType?.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.order_type_id}
                                      value={item.order_type_id}
                                    >
                                      {item.order_type_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="tax_duties"
                            label="Tax & Duties"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Input
                              size="small"

                              className={`customInputs`}
                              disabled={!(formState === "add" || formState === "edit")}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">


                          <Form.Item
                            name="order_hold"
                            label="Order Hold"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Checkbox
                              checked={isOrderHold}
                              name="order_hold"
                              onChange={(e) => handleCheckBox(e)}

                              disabled={formState === "formView"}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">


                          <Form.Item
                            name="is_closed"
                            label="Closed"
                            labelAlign="left"
                            labelCol={{ span: 10 }}

                          >
                            <Checkbox
                              checked={isClosed}
                              name="is_closed"
                              onChange={(e) => handleCheckBox(e)}

                              disabled={true}
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane tab="Related Transaction" key="relatedTransaction">
                  <div className="tabs">
                    <Tables
                      scrollX={"50rem"}
                      scroll={360}
                      perPage={15}
                      source={
                        formState === "add" ? [] :
                          saleOrderRelatedRecord?.payload?.data?.data ===
                            "Record not found"
                            ? []
                            : saleOrderRelatedRecord.payload
                      }
                      col={relatedTransactionColumns}
                      load={saleOrderRelatedRecord.loading}
                      paginate
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Panel>
          </Collapse>
          <SubTitles name={"Discount / Surcharge"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow className="discount_row">
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountId"
                    label="Discount"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select
                      size="small"
                      tabIndex={24}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={form.getFieldValue().discountId}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {saleOrderFormData?.payload?.adminDiscount &&
                        saleOrderFormData?.payload?.adminDiscount.map(
                          (item) => {
                            return (
                              <Option
                                key={item.discount_id}
                                value={item.discount_id}
                              >
                                {item.discount_name}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountCode"
                    label="Discount Code"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      tabIndex={25}
                      size="small"
                      className={`customInputs `}
                      disabled={!(formState === "add" || formState === "edit")}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountBasis"
                    label="Discount Type"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select

                      size="small"
                      tabIndex={26}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}


                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={formState === "edit" ? form.getFieldValue().discountBasis : null}
                      onChange={(value) => handleDiscountBasis(value)}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      <Option key={1} value={"A"}>
                        Amount
                      </Option>
                      <Option key={2} value={"P"}>
                        Percent
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={discountBasis === "A" ? "d-block" : "d-none"}
                lg={6}
                xl={4}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discount"
                    label="Discount Amount"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <InputNumber
                      min={0}
                      defaultValue={form.getFieldValue().discount}
                      size="small"
                      onInput={(val) => form.setFieldsValue({ discount: val })}
                      onChange={discountAmount}
                      disabled={!(formState === "add" || formState === "edit")}
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={discountBasis === "P" ? "d-block" : "d-none"}
                lg={6}
                xl={4}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountPercent"
                    label="Discount Percent"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <InputNumber
                      min={0}

                      size="small"
                      onInput={(val) => form.setFieldsValue({ discountPercent: val })}
                      onChange={discountPercent}
                      disabled={!(formState === "add" || formState === "edit")}
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      tabIndex={27}
                      size="small"
                      className={`customInputs`}
                      disabled={!(formState === "add" || formState === "edit")}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                lg={6}
                xl={4}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="surcharge_amount"
                    label="Surcharge Amount"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Input
                      tabIndex={25}
                      size="small"
                      defaultValue={0}
                      type="number"
                      className={`customInputs `}
                      disabled={!(formState === "add" || formState === "edit")}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xl={4} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="tax_type"
                    label="Tax Type"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <Select
                      size="small"
                      tabIndex={26}
                      autoComplete={"nope"}
                      filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={formState === "edit" ? form.getFieldValue().tax_type : null}
                      onMouseDownCapture={onMouseDownSelectableHack}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      <Option key={1} value={"A"}>
                        Amount
                      </Option>
                      <Option key={2} value={"P"}>
                        Percent
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={form.getFieldValue().tax_type ? "d-block" : "d-none"}
                lg={6}
                xl={4}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="tax_value"
                    label={form.getFieldValue().tax_type === 'A' ? "Tax Amount" : "Tax Percent"}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <InputNumber
                      min={0}
                      size="small"
                      disabled={!(formState === "add" || formState === "edit")}
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <Tabs
            size="small"
            type="card"
            tabBarGutter={2}
            defaultActiveKey="productInfo"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="productInfo"

            >
              <div className="pbpx-10" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={form.getFieldValue().location === null || form.getFieldValue().brand_id === null}
                  onClick={showModal}
                >
                  Add Item
                </Button>

                {saleOrderLine.loading ? (
                  <div className="mainProductContainer">
                    <SkeletonLoad />
                  </div>
                ) : (

                  <div style={{ padding: 10, display: "flex", gap: 20 }}>
                    {
                      saleOrderLine?.payload?.data && (
                        <>
                          <Button
                            type="primary"
                            className="modalButton"
                            // disabled={(formState === "edit" || location_id) ? false : true}
                            onClick={() => {
                              let data = orderItems?.map((v) => {
                                let packedShipped = v.quantity_packed - v.quantity_shipped
                                let totalCommitQty = v.quantity_committed - (packedShipped);
                                v.quantity_committed = packedShipped;
                                v.quantity_backorder += totalCommitQty;
                                v.commit_status = 3
                                return v;
                              })
                              setOrderItems(data);
                            }}
                          >
                            Do Not Commit
                          </Button>
                          <CSVLink
                            filename={"Sale-Order-Lines.csv"}
                            data={downloadExcelFileData(saleOrderLine?.payload?.data) || []}
                            onClick={() => {
                            }}
                          >
                            Download Excel
                          </CSVLink>
                        </>
                      )
                    }
                  </div>
                )}
              </div>
              <Tables
                className="abc"
                type={"saleOrderLine"}
                load={saleOrderLine.loading}
                scroll={({ x: 600 }, { y: 400 })}
                size="small"
                bordered
                perPage={100}
                source={sortedOrderItems.length > 0 ? sortedOrderItems : []}
                col={columns}
                paginate
                pagination={{ pageSize: 100 }}
              />

            </TabPane>
            {(formState === "edit" || formState === "formView") &&
              <TabPane tab="System Information" key="1">
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                </Tabs>
              </TabPane>}
          </Tabs>
        </div>
      </Form>
    </>
  );
}

const SendEmailComponent = ({ openEmailModal, setOpenEmailModal, formData, summary, dispatch, token }) => {
  const [lineData, setLineData] = useState({
    cit_no: null,
    client_customer: null,
    customer_name: null,
    po_no: null,
    reason: null,
    term_days: null,
  })
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    if (lineData.cit_no && lineData.client_customer && lineData.customer_name && lineData.po_no && lineData.reason && lineData.term_days) {
      setConfirmLoading(true);
      dispatch(sendOrderConfirmationOnHoldEmail(token, { ...lineData, order_header_id: formData.order_header_id, email: formData.customer_email, salesperson_email: formData.salesperson_email })).then((res) => {
        if (res.type === "SEND_EMAIL_SUCCESS") {
          setConfirmLoading(false);
          setOpenEmailModal(false);
          setLineData({
            cit_no: null,
            client_customer: null,
            customer_name: null,
            po_no: null,
            reason: null,
            term_days: null,
          })
          notification("success", res?.payload);
        } else if (res.type === "SEND_EMAIL_ERROR") {
          setConfirmLoading(false);
          notification("error", "SomeThing Went Wrong!");
        }
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Enter All Fields",
      });
    }
  }

  return (
    <div className="customer_email_modal">
      <Modal width={"90%"}
        title={
          <div style={{ fontSize: 14 }}>
            <div>
              From : arny@dl1961.com
            </div>
            <div>
              To : {formData?.customer_email}
            </div>
            <div>
              CC : {formData?.salesperson_email}, arny@dl1961.com, order@admdenim.com
            </div>
          </div>
        }
        visible={true}
        onOk={handleOk}
        okText={"Send Email"}
        onCancel={() => setOpenEmailModal(false)}
        confirmLoading={confirmLoading}
      >
        <p>Subject : DL1961 Order on hold | {formData?.customer_name} | PO # {formData?.reference_no} | SO # {formData?.order_header_no}</p>
        <p>Hello,</p>
        <p>Please find attached the details of the following PO(s). The order(s) have been put on hold/declined by CIT. Please reach out to Rhonda Piercy at rhonda.piercy@firstcitizens.com or (434) 791 – 6561. Additionally, kindly advise if you would like to proceed with credit card terms for this order.</p>

        <table className="customer_email_table">
          <tr>
            <th >CIT #</th>
            <th >Client Customer</th>
            <th >Customer Name</th>
            <th >PO #</th>
            <th >Reason*</th>
            <th >Term Days</th>
          </tr>
          <tr>
            <td><input placeholder="Enter CIT #" value={lineData.cit_no} onChange={(e) => setLineData({ ...lineData, cit_no: e.target.value })} /></td>
            <td><input placeholder="Enter Client Customer" value={lineData.client_customer} onChange={(e) => setLineData({ ...lineData, client_customer: e.target.value })} /></td>
            <td><input placeholder="Enter Customer Name" value={lineData.customer_name} onChange={(e) => setLineData({ ...lineData, customer_name: e.target.value })} /></td>
            <td><input placeholder="Enter PO #" value={lineData.po_no} onChange={(e) => setLineData({ ...lineData, po_no: e.target.value })} /></td>
            <td><input placeholder="Enter Reason*" value={lineData.reason} onChange={(e) => setLineData({ ...lineData, reason: e.target.value })} /></td>
            <td><input placeholder="Enter Term Days" value={lineData.term_days} onChange={(e) => setLineData({ ...lineData, term_days: e.target.value })} /></td>
          </tr>
        </table>

        <p>1. PO #{formData?.reference_no}, Net Amount due ${summary()?.net}, including Frieght ${numeral(formData?.shipping_cost).format("0.00")} including Surcharge ${numeral(formData?.surcharge_amount).format("0.00")}</p>
        <p style={{ fontWeight: "bold !important" }}>Payment and Shipping Information:</p>
        <ol>
          <li>Shipment: Once an order is approved by CIT, it will typically ship within 3 – 5 business days. Should you need the order to be processed within 24 hours, an additional rush fee of $20 will be applied to each order.</li>
          <li>Minimum Order Surcharge: Kindly note that a minimum order surcharge of $20 is applied to all orders under $500.</li>
          <li>Sales Tax: If you have not provided a valid resale certificate, you will not qualify for sales tax exemption.</li>
        </ol>
        <p>Thank you for your order.</p>
        <div>Wholesale Customer Service</div>
        <div>DL1961</div>
        <div>121 Varick St, 4th Floor</div>
        <div>New York, NY 10013</div>
        <div>P. 646.514.9736 x(117)</div>
        <div>www.dl1961.com</div>
        <p>Follow Us:
          @dl1961denim
        </p>
        <p className="customer_email_heading">*Reasons for CIT Order on Hold:</p>
        <ul>
          <li>AI / IU - Information unsatisfactory</li>
          <li>SP - Slow pay experience</li>
          <li>PD - Past due condition</li>
          <li>PC - Placed for collection</li>
          <li>CC - Contacting Customer</li>
          <li>NR - No reply to request for info</li>
          <li>RR - Duplicate Request</li>
          <li>CL - Credit Loss</li>
          <li>LF - Line Full</li>
        </ul>
      </Modal>
    </div>
  )
}
