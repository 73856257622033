import {
    GET_SEND_SCHEELS_EDI_REQUEST,
    GET_SEND_SCHEELS_EDI_SUCCESS,
    GET_SEND_SCHEELS_EDI_ERROR,
  } from "./types.js";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  import moment from "moment";
  export const sendScheelsEdi = (token, values) => (dispatch) => {
    dispatch({ type: GET_SEND_SCHEELS_EDI_REQUEST });
    const newData = {
      values,
    };
    return axios({
      method: "POST",
      url: `${BASEURL}create/sendScheelsEdi`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_SEND_SCHEELS_EDI_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SEND_SCHEELS_EDI_ERROR,
          payload: [],
        });
      });
  };