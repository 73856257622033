import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Row as Brow, Col as Bcol } from "reactstrap";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  InputNumber,
  Space, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getBookSummary } from "../../redux/actions/bookSummary.action";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;

const PendingSummary = () => {
  TabTitle("Pending Summary")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [pendingSummaryList, setPendingSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const [excelData, setExcelData] = useState([])
  const location = useLocation();
  const initialDate = moment("12-31-2025")


  const {
    gender,
    region,
    companyAll,
    OmsReprot,
    regionStateReport,
    omsRegionStateReport,
    omsReportByCustomer,
    quickPoLookup,
    adminLocation,
    style,
    salePersonForm,
    defaultCompany,
    paymentterm,
    customerTypes,
    getPendingSummaryReducer,
    saleOrderFormData
  } = useSelector((state) => state);


  


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let to_date = query.get("to_date");
  let customer_name = query.get("customer_name");
  let region_id = query.get("region_id");
  let gender_id = query.get("gender_id");

  
  

  useEffect(() => {
    if (to_date) {
      const values = {
        cutomer_category: null,
        to_date: to_date,
        order_no: null,
        customer: customer_name,
        billing_addressee: null,
        po_no: null,
        sale_person: null,
        region: region_id,
        gender: gender_id,
        terms: null,
        store: null,
        subsidary: null,
        source: null,
        sku: null,
        status: null,
        location: null,
      }
      form.setFieldsValue({
        
        customer: values.customer,
        region_id: values.region,
        gender_id: values.gender,
        to_date: moment(values.to_date)
      });
      setLoading(true);
      dispatch(getPendingSummary(token, values)).then((res) => {
        setPendingSummaryList(res?.payload?.data);
        setToogle(true);
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
        setLoading(false);
      });
    }
  }, [to_date])





  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getStyle(token, menuId));
    dispatch(loadSalePerson(token));
    dispatch(getDefaultCompany(token));
    dispatch(getPaymentTerm(token));
    dispatch(getCustomerType(token));
    dispatch(getAdminLocation(token, menuId));
    dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
  }, [dispatch, menuId, token]);





  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Order #",
      dataIndex: "order_header_no",
      key: "order_header_no",
      
      render: (text, record) => (
        <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
          to={"/sale-order?mode=edit&order_id=" + record.order_header_id + "/&customer_id/" + record.customer_id + "?edit=yes"}
        >
          {text}
        </Link>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.order_header_no - b.order_header_no;
      },
    },
    {
      title: "Order Date",
      render: (text, record) => {
        return <>{moment(record.order_date).format("MM-DD-YY")}</>
      },
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.order_date - b.order_date;
      },

    },
    {
      title: "PO #",
      dataIndex: "reference_no",
      key: "reference_no",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.reference_no - b.reference_no;
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.customer_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.customer_name - b.customer_name;
      },
    },
    {
      title: "Billing Addressee",
      dataIndex: "billing_addressee",
      key: "billing_addressee",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.billing_addressee.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.billing_addressee - b.billing_addressee;
      },
    },
    {
      title: "Start Date",
      
      render: (text, record) => {
        return <>{moment(record.start_date).format("MM-DD-YY")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.start_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.start_date - b.start_date;
      },
    },
    {
      title: "Cancel Date",
      
      render: (text, record) => {
        return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.cancel_date.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.cancel_date - b.cancel_date;
      },
    },
    {
      title: 'Pending Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      
      render: (text, record) => {
        return <>{parseFloat(record.quantity).toFixed(0)}</>;
      },
      align: "right",
    },
    {
      title: 'Commited',
      dataIndex: 'quantity_committed',
      key: 'quantity_committed',
      
      render: (text, record) => {
        return <>{parseFloat(record.quantity_committed).toFixed(0)}</>;
      },
      align: "right",
    },
    {
      title: "Pending Amount",
      dataIndex: "net",
      key: "net",
      
      align: "right",
      render: (text, record) => {
        return <>$ {numeral(record.net).format('0,0.00')}</>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.net.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.net - b.net;
      },
    },

    {
      title: "Term",
      dataIndex: "payment_term",
      key: "payment_term",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.payment_term - b.payment_term;
      },
    },

    {
      title: "CIT Status",
      dataIndex: "factor_decision",
      key: "factor_decision",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.factor_decision.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.factor_decision - b.factor_decision;
      },
    },

    {
      title: "Order Status",
      dataIndex: "status",
      key: "status",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.status.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.status - b.status;
      },
    },

    {
      title: "Hold Status",
      dataIndex: "holdstatus",
      key: "holdstatus",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.holdstatus.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.holdstatus - b.holdstatus;
      },
    },

    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.region_name - b.region_name;
      },
    },

    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.location_name - b.location_name;
      },
    },

    {
      title: "Sale Person Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.salesperson_name - b.salesperson_name;
      },
    },

    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.gender_name - b.gender_name;
      },
    },

    {
      title: "Customer Type",
      dataIndex: "customer_type_name",
      key: "customer_type_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.customer_type_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.customer_type_name - b.customer_type_name;
      },
    },

    {
      title: "Billing State",
      dataIndex: "state_name",
      key: "state_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.state_name - b.state_name;
      },
    },

    {
      title: "Resale #",
      dataIndex: "resaleno",
      key: "resaleno",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.resaleno.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.resaleno - b.resaleno;
      },
    },
    {
      title: 'Resale Expiry',
      
      render: (text, record) => {
        return <>{moment(record.resale_expiry).format("MM-DD-YYYY")}</>;
      },
    },

    {
      title: "Store",
      dataIndex: "store_id",
      key: "store_id",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.store_id - b.store_id;
      },
    },

    {
      title: "Shipping Zip",
      dataIndex: "shipping_zip",
      key: "shipping_zip",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.shipping_zip.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.shipping_zip - b.shipping_zip;
      },
    },

    {
      title: "Shipper",
      dataIndex: "ship_via_description",
      key: "ship_via_description",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.ship_via_description.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.ship_via_description - b.ship_via_description;
      },
    },

    {
      title: "Bergan Billing",
      dataIndex: "bergan_billing_name",
      key: "bergan_billing_name",
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.bergan_billing_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.bergan_billing_name - b.bergan_billing_name;
      },
    },
  ];

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Order #": v.order_header_no,
          "Order Date": moment(v.order_date).format("MM-DD-YY"),
          "PO #": v.reference_no,
          "Customer": v.customer_name,
          "Billing Addressee": v.billing_addressee,
          "Start Date": moment(v.start_date).format("MM-DD-YY"),
          "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
          "Pending Quantity": parseFloat(v.quantity).toFixed(0),
          "Commited": parseFloat(v.quantity_committed).toFixed(0),
          "Pending Amount": numeral(v.net).format("0,0.00"),
          "Term": v.payment_term,
          "CIT Status": v.factor_decision,
          "Order Status": v.status,
          "Hold Status": v.holdstatus,
          "Region": v.region_name,
          "Loaction": v.location_name,
          "Sale person Name": v.salesperson_name,
          "Gender": v.gender_name,
          "Customer Type": v.customer_type_name,
          "Billing State": v.state_name,
          "Resale #": v.resaleno,
          "Resale Expiry": moment(v.resaleexpiry).format("MM-DD-YY"),
          "Store": v.store_id,
          "Shipping Zip": v.shipping_zip,
          "Shipper": v.ship_via_description,
          "Bergan Billing": v.bergan_billing_name,
        };
      })
    )
  }


  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getPendingSummary(token, values)).then((res) => {
        setPendingSummaryList(res?.payload?.data);
        setToogle(true);
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  const totalQuantity = () => {
    let sumQty = pendingSummaryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.quantity);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = pendingSummaryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.net);
    }, 0);
    return sumAmount;
  }



  return (
    <div className="styleContainer">
      <div
        className="styleBodyPendingSummary"
      >
        <PageHeader
          title={"Pending Summary"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="cutomer_category"
                          label="Customer Category"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}

                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            defaultValue={form.getFieldValue().cutomer_category}
                            className="styleInput w-100"
                          >
                            <Option value={null}>All</Option>
                            {
                              customerTypes?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                )
                              })
                            }
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>

                    {/* <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date" 
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment(("01-01-2023"))}
                                                    
                                                    
                                                    
                                                    
                                                    
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        
                                                        
                                                        

                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol> */}
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          initialValue={initialDate}
                          
                          
                          
                          
                          
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              
                            
                            
                            
                            
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="order_no"
                            label="Order #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          
                          
                          
                          
                          
                          >
                            <InputNumber
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="customer"
                            label="Customer"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          
                          
                          
                          
                          
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="billing_addressee"
                            label="Billing addressee"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="po_no"
                            label="PO #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          
                          
                          
                          
                          
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="sale_person"
                          label="Sale Person"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}

                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().sale_person}
                          >
                            <Option value={null}>All</Option>
                            {
                              salePersonForm?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="region"
                          label="Region"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                        

                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }

                            className="styleInput w-100"
                            
                            defaultValue={region_id === "All" ? region_id : (region_id ? Number(region_id) : null)}
                          >
                            <Option value={null}>All</Option>
                            {
                              region?.payload?.data.map((val) => {
                                return (

                                  <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="gender"
                          label="Gender"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                        
                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterOption={(input, options) =>
                              options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }

                            className="styleInput w-100"
                            
                            defaultValue={gender_id === "All" ? gender_id : (gender_id ? Number(gender_id) : null)}
                          >
                            <Option value={null}>All</Option>
                            {
                              gender?.payload?.data.map((val) => {
                                return (
                                  <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="terms"
                          label="Terms"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().terms}
                          >
                            <Option value={null}>All</Option>
                            {
                              paymentterm?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.term_id}>{val.term_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="source"
                          label="Source"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                        
                        
                        
                        
                        
                        >
                          <Input
                            size='small'
                            className="styleInput"
                          />
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="store"
                            label="Store #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          
                          
                          
                          
                          
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="subsidary"
                          label="Subsidiary"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={2}
                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterOption={(input, options) =>
                              options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }
                            defaultValue={form.getFieldValue().subsidary}
                            className="styleInput w-100"
                          >
                            <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="status"
                          label="Status"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                        
                        
                        
                        
                        
                        >
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            allowClear
                            loading={saleOrderFormData.loading}
                            
                            defaultValue={form.getFieldValue().orderStatus}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option value={null}>All</Option>
                            {saleOrderFormData?.payload?.orderStatusDetail.map(
                              (item) => {
                                return (
                                  <Option
                                    key={item.order_status_id}
                                    value={item.order_status_id}
                                  >
                                    {item.status_name}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="sku"
                            label="Sku #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          
                          
                          
                          
                          
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="style"
                          label="Style"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        
                        
                        
                        
                        
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().style}
                          >
                            <Option value={null}>All</Option>
                            {
                              style?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.style_id}>{val.style_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          name="location"
                          label="Location"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            autoComplete={"nope"}
                            loading={adminLocation.loading}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option value={null}>All</Option>
                            {
                              adminLocation?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={index} value={val.location_id}>
                                    {val.location_name}
                                  </Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>


          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {

          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            pendingSummaryList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Pending-Summary.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    {/* <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    
                    style={{
                      borderWidth: "0px",
                      height: "22px",
                      cursor: "pointer",
                    }}
                  /> */}
                    Download Excel
                  </CSVLink>
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: "max-content"
                }}
                
                dataSource={pendingSummaryList}
                columns={columns}
                loading={getPendingSummaryReducer.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let PendingQuantity = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity
                    ));
                  },
                    0);
                  let Commited = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity_committed
                    ));
                  },
                    0);
                  let PendingAmount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {PendingQuantity.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Commited.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {numeral(PendingAmount).format('0,0.00')}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )

        }
      </div>

    </div>
  )
}

export default PendingSummary;
