import {
    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    ADD_BRAND_REQUEST, ADD_BRAND_SUCCESS, ADD_BRAND_ERROR,
    DELETE_BRAND_ERROR, DELETE_BRAND_REQUEST, DELETE_BRAND_SUCCESS,
    UPDATE_BRAND_ERROR, UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS
} from "./types.js"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"

export const getBrands = (token) => (dispatch, getState) => {
    const { brand } = getState();
    // if data is present in store return promise resolved
    if (brand?.payload?.data?.length) {
        return Promise.resolve();
    }
    // if data is not present in store then fetch from server
    dispatch({ type: GET_BRAND_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/brands`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_BRAND_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_BRAND_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_BRAND_ERROR,
                payload: error.response,
            });
        })
};

export const addBrand = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_BRAND_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/brand`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_BRAND_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: ADD_BRAND_ERROR,
                payload: error.response,
            });
        });
};



export const updateBrand = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_BRAND_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/brand/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_BRAND_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_BRAND_ERROR,
                payload: error.response,
            });
        });
};


export const deleteBrand = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_BRAND_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/brand/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_BRAND_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_BRAND_ERROR,
                payload: error.response,
            });
        });
};

