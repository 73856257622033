import React, { useEffect, useState } from 'react'
import "./dashboard.css"
import { Col, Row, Skeleton, Tag, Card, Checkbox } from "antd";
import { Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { TabTitle } from '../../utils/GeneralFunction';

import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,

} from 'chart.js';
import { getGender } from '../../redux/actions/gender.action';
import { useDispatch, useSelector } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import { getAccountTypeStats, getAucStats, getCountryStats, getDashboardStats, getDivisionStats, getFabricStats, getFitCategoryStats, getPlanActualStats, getReturnRateStats, getSalesByMonthStats, getStyleStats } from '../../redux/actions/stats.action';

import { BarAccountTypeChart, BarAucChart, BarDivisionChart, BarPlanActualChart, BarReturnRateChart, BarSaleByMonthChart, CountryChart, FabricChart, FitChart, StylesChart } from './Charts';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    // ChartDataLabels
);

const { Title } = Typography;


export default function Dashboard() {

    TabTitle("Dashboard");
    const {
        gender,
        divisionStats,
        accountTypeStats,
        salesByMonthStats,
        returnRateStats,
        aucStats,
        actualPlanStats,
        styleStats,
        fitCategoryStats,
        fabricStats,
        countryStats
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");

    const [isFixed, setIsFixed] = useState(false);


    const [filter, setFilter] = useState({
        gender: [],
        year: ["2023", "2024", "2025"],
        customer_type_id: [2, 1, 14, 3, 10]
    });

    const [colors, setColors] = useState(["007ea7", "33658a", "5fa8d3", "7d8597", "979dac"]);
    const [activeColor, setActiveColor] = useState(1);

    const filteredDivisions = divisionStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));
    const filterAccountType = accountTypeStats?.payload?.data.filter((x) => filter.customer_type_id.includes(x.customer_type_id) && filter.year.includes(x.yearr) && filter.gender.includes(x.gender_id));
    const filterSalesByMonth = salesByMonthStats?.payload?.data.filter((x) => filter.year.includes(x.yearr) && filter.gender.includes(x.gender_id) && filter.customer_type_id.includes(x.customer_type_id));
    const filterReturnRate = returnRateStats?.payload?.data.filter((x) => filter.year.includes(x.yearr) && filter.gender.includes(x.gender_id) && filter.customer_type_id.includes(x.customer_type_id));
    const filterAuc = aucStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));
    const filterPlanActual = actualPlanStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr));
    const filterStyle = styleStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));
    const filterFitCategory = fitCategoryStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));
    const filterFabric = fabricStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));
    const filterCountry = countryStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr) && filter.customer_type_id.includes(x.customer_type_id));

    useEffect(() => {
        let isMounted = true;
        dispatch(getGender(token, menuId)).then((val) => {
            if (isMounted && val.type === "GET_GENDER_SUCCESS") {
                let data = val.payload.data;
                let gender_ids = data.filter((x) => !["Digital", "Gifting", "N/A"].includes(x.gender_name)).map(item => item.gender_id);

                setFilter((prevFilter) => ({
                    ...prevFilter,
                    gender: gender_ids,
                }));

                dispatch(getDivisionStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getAccountTypeStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getSalesByMonthStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getReturnRateStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getAucStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getPlanActualStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getStyleStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getFitCategoryStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getFabricStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getCountryStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
            }
        });
        // Cleanup function to set isMounted to false when unmounted
        return () => {
            isMounted = false;
        };
    }, [dispatch, token, menuId]);


    const groupedDivision = filteredDivisions?.reduce((acc, item) => {
        const { gender_name } = item;
        if (!acc[gender_name]) {
            acc[gender_name] = [];
        }
        acc[gender_name].push(item);
        return acc;
    }, {});

    const groupedAccountType = filterAccountType?.reduce((acc, item) => {
        const { customer_type_name } = item;
        if (!acc[customer_type_name]) {
            acc[customer_type_name] = [];
        }
        acc[customer_type_name].push(item);
        return acc;
    }, {});
    const groupedSalesByMonth = filterSalesByMonth?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});

    const groupedReturnRate = filterReturnRate?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});


    const groupedAUC = filterAuc?.reduce((acc, item) => {
        const { gender_name } = item;
        if (!acc[gender_name]) {
            acc[gender_name] = [];
        }
        acc[gender_name].push(item);
        return acc;
    }, {});

    const groupedPlanActual = filterPlanActual?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});

    const groupedStyle = filterStyle?.reduce((acc, item) => {
        const { style_name } = item;
        if (!acc[style_name]) {
            acc[style_name] = [];
        }
        acc[style_name].push(item);
        return acc;
    }, {});

    const groupedFitCategory = filterFitCategory?.reduce((acc, item) => {
        const { fit_category_name } = item;
        if (!acc[fit_category_name]) {
            acc[fit_category_name] = [];
        }
        acc[fit_category_name].push(item);
        return acc;
    }, {});

    const groupedFabric = filterFabric?.reduce((acc, item) => {
        const { fabric_name } = item;
        if (!acc[fabric_name]) {
            acc[fabric_name] = [];
        }
        acc[fabric_name].push(item);
        return acc;
    }, {});

    const groupedCountry = filterCountry?.reduce((acc, item) => {
        const { state_full_name } = item;
        if (!acc[state_full_name]) {
            acc[state_full_name] = [];
        }
        acc[state_full_name].push(item);
        return acc;
    }, {});


    const customerTypeId = [
        {
            value: "Majors",
            id: 2
        },
        {
            value: "Speciality",
            id: 1
        },
        {
            value: "DS",
            id: 14
        },
        {
            value: "Ecom",
            id: 3
        },
        
        {
            value: "OP",
            id: 10
        },
    ]



    const handleScroll = () => {
        if (window.scrollY > 200) {  // 100px is the threshold, change as needed
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };

    // Adding the event listener for scroll on mount and cleaning up on unmount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='dashboard-container'>
            <div className='dashboard-hd-main'>Dashboard</div>
            <div className='dashboard-card-main filter-card'
                style={{
                    position: isFixed ? 'fixed' : 'static',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100
                }}
            >
                <div className='dashboard-card-content'>
                    {/* <div className='filter-hd'>Gender Category :</div> */}
                    <div>
                        {
                            gender?.payload?.data.filter((x) => !["Digital", "Gifting", "N/A"].includes(x.gender_name)).map((item, idx) => {
                                return (
                                    <Tag key={idx} className={`dashboard_tag ${filter.gender.includes(item.gender_id) && 'dashboard_tag_active'}`} onClick={() => {
                                        if (filter.gender.includes(item.gender_id)) {
                                            let removeGender = filter.gender.filter((v) => v !== item.gender_id);
                                            setFilter({ ...filter, gender: removeGender })
                                        } else {
                                            setFilter({ ...filter, gender: [...filter.gender, item.gender_id] })
                                        }
                                    }}>
                                        {item.gender_name}
                                    </Tag>
                                )
                            })
                        }
                    </div>

                    {/* <div className="filter-hd">Account Type :</div> */}

                    <div>
                        {
                            customerTypeId.map((val, idx) => {
                                return (
                                    <Tag key={idx} className={`dashboard_tag ${filter.customer_type_id.includes(val.id) && 'dashboard_tag_active'}`} onClick={() => {
                                        if (filter.customer_type_id.includes(val.id)) {
                                            let removeCustomerTypeId = filter.customer_type_id.filter((v) => v !== val.id);
                                            setFilter({ ...filter, customer_type_id: removeCustomerTypeId })
                                        } else {
                                            setFilter({ ...filter, customer_type_id: [...filter.customer_type_id, val.id] })
                                        }
                                    }}>
                                        {val.value}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                    {/* <div className="filter-hd">Year :</div> */}
                    <div>
                        {
                            ["2023", "2024", "2025"].map((val, idx) => {
                                return (
                                    <Tag key={idx} className={`dashboard_tag ${filter.year.includes(val) && 'dashboard_tag_active'}`} onClick={() => {
                                        if (filter.year.includes(val)) {
                                            let removeYear = filter.year.filter((v) => v !== val);
                                            setFilter({ ...filter, year: removeYear })
                                        } else {
                                            setFilter({ ...filter, year: [...filter.year, val] })
                                        }
                                    }}>
                                        {val}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                    {/* <div className="filter-hd">Color Theme :</div>
                    <div>
                        <Tag className={`dashboard_tag ${activeColor === 1 && 'color_tag_active_1'}`}
                            onClick={() => {
                                setColors(["007ea7", "33658a", "5fa8d3", "7d8597", "979dac"]);
                                setActiveColor(1)
                            }}
                        >
                            Dark Color
                        </Tag>
                        <Tag className={`dashboard_tag ${activeColor === 2 && 'color_tag_active_2'}`}
                            onClick={() => {
                                setColors(["598392", "979dac", "d4a373", "a3cef1", "748cab"]);
                                setActiveColor(2);
                            }}
                        >
                            Light Color
                        </Tag>

                    </div> */}
                </div>
            </div>
            {/* <div className='dashboard_filters_container'>
                <div>
                    {
                        gender?.payload?.data.filter((x) => !["Digital", "Gifting", "N/A"].includes(x.gender_name)).map((item, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.gender.includes(item.gender_id) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.gender.includes(item.gender_id)) {
                                        let removeGender = filter.gender.filter((v) => v !== item.gender_id);
                                        setFilter({ ...filter, gender: removeGender })
                                    } else {
                                        setFilter({ ...filter, gender: [...filter.gender, item.gender_id] })
                                    }
                                }}>
                                    {item.gender_name}
                                </Tag>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        customerTypeId.map((val, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.customer_type_id.includes(val.id) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.customer_type_id.includes(val.id)) {
                                        let removeCustomerTypeId = filter.customer_type_id.filter((v) => v !== val.id);
                                        setFilter({ ...filter, customer_type_id: removeCustomerTypeId })
                                    } else {
                                        setFilter({ ...filter, customer_type_id: [...filter.customer_type_id, val.id] })
                                    }
                                }}>
                                    {val.value}
                                </Tag>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        ["2023", "2024", "2025"].map((val, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.year.includes(val) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.year.includes(val)) {
                                        let removeYear = filter.year.filter((v) => v !== val);
                                        setFilter({ ...filter, year: removeYear })
                                    } else {
                                        setFilter({ ...filter, year: [...filter.year, val] })
                                    }
                                }}>
                                    {val}
                                </Tag>
                            )
                        })
                    }
                </div>


            </div> */}

            <div>
                {/* <Row className='mb-20' gutter={[{ xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }, { xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }]}
                >
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Users" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Active Customer" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Sales" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Sales Target" trendValue={0} trendType="up" value={0} />
                    </Col>

                </Row> */}
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row">
                        {
                            divisionStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    <div >
                                        <div className='dashboard-card-hd text-center'>
                                            Sales By Division
                                        </div>
                                        {
                                            groupedDivision && Object.keys(groupedDivision)?.length > 0 ? (
                                                <div className='dashboard-chart-container'>
                                                    <div>
                                                        <BarDivisionChart groupedData={groupedDivision} colors={colors} />
                                                    </div>

                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }


                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row">
                        {
                            accountTypeStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    <div >
                                        <div className='dashboard-card-hd text-center'>
                                            Sales By Account Type
                                        </div>
                                        {
                                            groupedAccountType && Object.keys(groupedAccountType)?.length > 0 ? (
                                                <div className='dashboard-chart-container'>
                                                    <div>
                                                        <BarAccountTypeChart groupedData={groupedAccountType} colors={colors} />

                                                    </div>

                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            salesByMonthStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Sales By Month
                                        </div>
                                        {
                                            groupedSalesByMonth && Object.keys(groupedSalesByMonth)?.length > 0 ? (
                                                <div >
                                                    <BarSaleByMonthChart groupedData={groupedSalesByMonth} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }


                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            returnRateStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Return Rate
                                        </div>
                                        {
                                            groupedReturnRate && Object.keys(groupedReturnRate)?.length > 0 ? (
                                                <div >
                                                    <BarReturnRateChart groupedData={groupedReturnRate} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            aucStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            AUC
                                        </div>
                                        {
                                            groupedAUC && Object.keys(groupedAUC)?.length > 0 ? (
                                                <div >
                                                    <BarAucChart groupedData={groupedAUC} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            actualPlanStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Plan vs Actual
                                        </div>
                                        {
                                            groupedPlanActual && Object.keys(groupedPlanActual)?.length > 0 ? (
                                                <div >
                                                    <BarPlanActualChart groupedData={groupedPlanActual} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            styleStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Top 20 Styles
                                        </div>
                                        {
                                            groupedStyle && Object.keys(groupedStyle)?.length > 0 ? (
                                                <div >
                                                    <StylesChart groupedData={groupedStyle} colors={colors} />
                                                    {/* <BarReturnRateChart groupedData={groupedReturnRate} /> */}
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            fitCategoryStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Top 20 Fit
                                        </div>
                                        {
                                            groupedFitCategory && Object.keys(groupedFitCategory)?.length > 0 ? (
                                                <div >
                                                    <FitChart groupedData={groupedFitCategory} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            fabricStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Top 20 Fabrics
                                        </div>
                                        {
                                            groupedFabric && Object.keys(groupedFabric)?.length > 0 ? (
                                                <div >
                                                    <FabricChart groupedData={groupedFabric} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="gutter-row" >
                        {
                            countryStats?.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ height: 500, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            USA Heat Map
                                        </div>
                                        {
                                            groupedCountry && Object.keys(groupedCountry)?.length > 0 ? (
                                                <div >
                                                    <CountryChart groupedData={groupedCountry} colors={colors} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>
                  

                </Row>
            </div>

        </div>
    )
}

// const Card = ({ title = "", trendValue, trendType, value = 0, }) => {
//     return (
// <div className='dashboard-card-main'>
//     <div className='dashboard-card-content'>
//         <div className='dashboard-card-hd'>
//             {title}
//         </div>
//         <div className='dashboard-card-text'>
//             <div className='dashboard-card-text-left'>
//                 {value}
//             </div>
//             <div className='dashboard-card-text-right'>
//                 <div className='stats-icon'>{trendType === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}</div>
//                 <div className='stats-value'>{trendValue}%</div>
//             </div>
//         </div>
//     </div>
// </div>
//     )
// }

