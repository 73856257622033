import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Input, Select, DatePicker, Table, InputNumber, Spin } from "antd";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Loader from 'react-loader-spinner';
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getPtDetail } from "../../redux/actions/ptDetail.action";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getStyle } from "../../redux/actions/style";
import { ReadCookie } from "../../utils/readCookie";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { getPickTicketStatus } from '../../redux/actions/pickTicketStatus.action';
import moment from "moment";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { companyID, currencySymbol } from "../../utils/constants";
import { getBrands } from "../../redux/actions/brand.action";
import filterHelper, { sorterHelper } from "../../utils/filterHelper";
const { Option } = Select;

const PtDetail = () => {
    TabTitle("Pick Ticket Detail Report")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pTDetailList, setPTDetailList] = useState([]);
    const [excelData, setExcelData] = useState([])
    const submitButton = useRef(null);
    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const { gender, region, brand, salePersonForm, defaultCompany, paymentterm, customerTypes, PtDetail, saleOrderFormData, adminLocation, pickTicketStatus } = useSelector((state) => state);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getBrands(token));
        dispatch(getDefaultCompany(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
        dispatch(getPickTicketStatus(token, menuId))
    }, [dispatch, menuId, token]);

    const BDCols = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'PT #',
            dataIndex: 'ship_header_no',
            key: 'ship_header_no',
            width: 80,
            ...filterHelper('ship_header_no','num')
        },
        {
            title: 'PT Date',
            width: 80,
            render: (text, record) => {
                return <>{moment(record.ship_date).format("MM-DD-YY")}</>
            },
            ...sorterHelper('ship_date','date')
        },
        {
            title: 'Order #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            width: 80,
            ...filterHelper('order_header_no','num')
        },
        {
            title: 'Start Date',
            width: 100,
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YY")}</>
            },
            ...sorterHelper('start_date','date')
        },
        {
            title: 'Cancel Date',
            width: 90,
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
            },
            ...sorterHelper('cancel_date','date')
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            width: 150,
            ...filterHelper('reference_no')
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 150,
            ...filterHelper('customer_name')
        },
        {
            title: 'Billing Addressee',
            dataIndex: 'billing_addressee',
            key: 'billing_addressee',
            width: 150,
            ...filterHelper('billing_addressee')
        },
        {
            title: 'Customer Category',
            dataIndex: 'customer_type_name',
            key: 'customer_type_name',
            width: 150,
            ...filterHelper('customer_type_name')
        },
        {
            title: 'SKU',
            dataIndex: 'parent_item_code',
            key: 'parent_item_code',
            width: 70,
            ...filterHelper('parent_item_code')
        },
        {
            title: 'Inseam',
            dataIndex: 'inseam',
            key: 'inseam',
            width: 80,
            ...sorterHelper('inseam',"num")
        },
        {
            title: 'Style',
            dataIndex: 'style_name',
            key: 'style_name',
            width: 150,
            ...filterHelper('style_name')
        },
        {
            title: 'Wash',
            dataIndex: 'wash_name',
            key: 'wash_name',
            width: 100,
            ...filterHelper('wash_name')
        },
        {
            children: [
                {
                    title: 'T-Jacket',
                    dataIndex: 'stage',
                    key: 'stage',
                    width: 90,
                    children: [
                        {
                            title: 'Shirt/RTW-P',
                            dataIndex: 'stage',
                            key: 'stage',
                            width: 90,
                            children: [
                                {
                                    title: 'Shirt/RTW-R',
                                    dataIndex: 'stage',
                                    key: 'stage',
                                    width: 90,
                                    children: [
                                        {
                                            title: 'Y-Adult',
                                            dataIndex: 'stage',
                                            key: 'stage',
                                            width: 90,
                                            children: [
                                                {
                                                    title: 'W-Plus',
                                                    dataIndex: 'stage',
                                                    key: 'stage',
                                                    width: 90,
                                                    children: [
                                                        {
                                                            title: 'Kids',
                                                            dataIndex: 'stage',
                                                            key: 'stage',
                                                            width: 90,
                                                            children: [
                                                                {
                                                                    title: 'Men',
                                                                    dataIndex: 'stage',
                                                                    key: 'stage',
                                                                    width: 90,
                                                                    children: [
                                                                        {
                                                                            title: 'Women',
                                                                            dataIndex: 'stage',
                                                                            key: 'stage',
                                                                            width: 90,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"2/3"',
                    align: 'center',
                    dataIndex: 'size1qty1',
                    key: 'size1qty1',
                    width: 50,
                    children: [
                        {
                            title: '1XL',
                            dataIndex: 'size1qty1',
                            key: 'size1qty1',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'XS',
                                    dataIndex: 'size1qty1',
                                    key: 'size1qty1',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '00',
                                            dataIndex: 'size1qty1',
                                            key: 'size1qty1',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '10W',
                                                    dataIndex: 'size1qty1',
                                                    key: 'size1qty1',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '2',
                                                            dataIndex: 'size1qty1',
                                                            key: 'size1qty1',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '27',
                                                                    dataIndex: 'size1qty1',
                                                                    key: 'size1qty1',
                                                                    align: 'center',
                                                                    width: 50,
                                                                    children: [
                                                                        {
                                                                            title: '23',
                                                                            dataIndex: 'size1qty1',
                                                                            key: 'size1qty1',
                                                                            width: 50,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"4/5"',
                    align: 'center',
                    dataIndex: 'size1qty2',
                    key: 'size1qty2',
                    width: 50,
                    children: [
                        {
                            title: '2XL',
                            align: 'center',
                            dataIndex: 'size1qty2',
                            key: 'size1qty2',
                            width: 55,
                            children: [
                                {
                                    title: 'S',
                                    align: 'center',
                                    dataIndex: 'size1qty2',
                                    key: 'size1qty2',
                                    width: 55,
                                    children: [
                                        {
                                            title: '0',
                                            align: 'center',
                                            dataIndex: 'size1qty2',
                                            key: 'size1qty2',
                                            width: 55,
                                            children: [
                                                {
                                                    title: '12W',
                                                    align: 'center',
                                                    dataIndex: 'size1qty2',
                                                    key: 'size1qty2',
                                                    width: 55,
                                                    children: [
                                                        {
                                                            title: '3',
                                                            align: 'center',
                                                            dataIndex: 'size1qty2',
                                                            key: 'size1qty2',
                                                            width: 50,
                                                            children: [
                                                                {
                                                                    title: '28',
                                                                    align: 'center',
                                                                    dataIndex: 'size1qty2',
                                                                    key: 'size1qty2',
                                                                    width: 50,
                                                                    children: [
                                                                        {
                                                                            title: '24',
                                                                            align: 'center',
                                                                            dataIndex: 'size1qty2',
                                                                            key: 'size1qty2',
                                                                            width: 50,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '"6/6X"',
                    dataIndex: 'size1qty3',
                    key: 'size1qty3',
                    align: 'center',
                    width: 60,
                    children: [
                        {
                            title: '3XL',
                            dataIndex: 'size1qty3',
                            key: 'size1qty3',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'M',
                                    dataIndex: 'size1qty3',
                                    key: 'size1qty3',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '1',
                                            dataIndex: 'size1qty3',
                                            key: 'size1qty3',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '14W',
                                                    dataIndex: 'size1qty3',
                                                    key: 'size1qty3',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '4',
                                                            dataIndex: 'size1qty3',
                                                            key: 'size1qty3',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '29',
                                                                    dataIndex: 'size1qty3',
                                                                    key: 'size1qty3',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '25',
                                                                            dataIndex: 'size1qty3',
                                                                            key: 'size1qty3',
                                                                            width: 55,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty4',
                    key: 'size1qty4',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty4',
                            key: 'size1qty4',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: 'L',
                                    dataIndex: 'size1qty4',
                                    key: 'size1qty4',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '2',
                                            dataIndex: 'size1qty4',
                                            key: 'size1qty4',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '16W',
                                                    dataIndex: 'size1qty4',
                                                    key: 'size1qty4',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '5',
                                                            dataIndex: 'size1qty4',
                                                            key: 'size1qty4',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '30',
                                                                    dataIndex: 'size1qty4',
                                                                    key: 'size1qty4',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '26',
                                                                            dataIndex: 'size1qty4',
                                                                            key: 'size1qty4',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty5',
                    key: 'size1qty5',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty5',
                            key: 'size1qty5',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'XL',
                                    dataIndex: 'size1qty5',
                                    key: 'size1qty5',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '3',
                                            dataIndex: 'size1qty5',
                                            key: 'size1qty5',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '18W',
                                                    dataIndex: 'size1qty5',
                                                    key: 'size1qty5',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '6',
                                                            dataIndex: 'size1qty5',
                                                            key: 'size1qty5',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '31',
                                                                    dataIndex: 'size1qty5',
                                                                    key: 'size1qty5',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '27',
                                                                            dataIndex: 'size1qty5',
                                                                            key: 'size1qty5',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty6',
                    key: 'size1qty6',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty6',
                            key: 'size1qty6',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'XXL',
                                    dataIndex: 'size1qty6',
                                    key: 'size1qty6',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '4',
                                            dataIndex: 'size1qty6',
                                            key: 'size1qty6',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '20W',
                                                    dataIndex: 'size1qty6',
                                                    key: 'size1qty6',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '6x',
                                                            dataIndex: 'size1qty6',
                                                            key: 'size1qty6',
                                                            width: 50,
                                                            align: 'center',

                                                            children: [
                                                                {
                                                                    title: '32',
                                                                    dataIndex: 'size1qty6',
                                                                    key: 'size1qty6',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '28',
                                                                            dataIndex: 'size1qty6',
                                                                            key: 'size1qty6',
                                                                            width: 50,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty7',
                    key: 'size1qty7',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty7',
                            key: 'size1qty7',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty7',
                                    key: 'size1qty7',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '5',
                                            dataIndex: 'size1qty7',
                                            key: 'size1qty7',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '22W',
                                                    dataIndex: 'size1qty7',
                                                    key: 'size1qty7',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '7',
                                                            dataIndex: 'size1qty7',
                                                            key: 'size1qty7',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '33',
                                                                    dataIndex: 'size1qty7',
                                                                    key: 'size1qty7',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '29',
                                                                            dataIndex: 'size1qty7',
                                                                            key: 'size1qty7',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty8',
                    key: 'size1qty8',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty8',
                            key: 'size1qty8',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty8',
                                    key: 'size1qty8',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '6',
                                            dataIndex: 'size1qty8',
                                            key: 'size1qty8',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '24W',
                                                    dataIndex: 'size1qty8',
                                                    key: 'size1qty8',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '7x',
                                                            dataIndex: 'size1qty8',
                                                            key: 'size1qty8',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '34',
                                                                    dataIndex: 'size1qty8',
                                                                    key: 'size1qty8',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '30',
                                                                            dataIndex: 'size1qty8',
                                                                            key: 'size1qty8',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty9',
                    key: 'size1qty9',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty9',
                            key: 'size1qty9',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty9',
                                    key: 'size1qty9',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '7',
                                            dataIndex: 'size1qty9',
                                            key: 'size1qty9',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '26W',
                                                    dataIndex: 'size1qty9',
                                                    key: 'size1qty9',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '8',
                                                            dataIndex: 'size1qty9',
                                                            key: 'size1qty9',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '35',
                                                                    dataIndex: 'size1qty9',
                                                                    key: 'size1qty9',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '31',
                                                                            dataIndex: 'size1qty9',
                                                                            key: 'size1qty9',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty10',
                    key: 'size1qty10',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty10',
                            key: 'size1qty10',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty10',
                                    key: 'size1qty10',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '8',
                                            dataIndex: 'size1qty10',
                                            key: 'size1qty10',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '28W',
                                                    dataIndex: 'size1qty10',
                                                    key: 'size1qty10',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '10',
                                                            dataIndex: 'size1qty10',
                                                            key: 'size1qty10',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '36',
                                                                    dataIndex: 'size1qty10',
                                                                    key: 'size1qty10',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '32',
                                                                            dataIndex: 'size1qty10',
                                                                            key: 'size1qty10',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty11',
                    key: 'size1qty11',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty11',
                            key: 'size1qty11',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty11',
                                    key: 'size1qty11',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '9',
                                            dataIndex: 'size1qty11',
                                            key: 'size1qty11',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '30W',
                                                    dataIndex: 'size1qty11',
                                                    key: 'size1qty11',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '12',
                                                            dataIndex: 'size1qty11',
                                                            key: 'size1qty11',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '38',
                                                                    dataIndex: 'size1qty11',
                                                                    key: 'size1qty11',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '33',
                                                                            dataIndex: 'size1qty11',
                                                                            key: 'size1qty11',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty12',
                    key: 'size1qty12',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty12',
                            key: 'size1qty12',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty12',
                                    key: 'size1qty12',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '10',
                                            dataIndex: 'size1qty12',
                                            key: 'size1qty12',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '32W',
                                                    dataIndex: 'size1qty12',
                                                    key: 'size1qty12',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '14',
                                                            dataIndex: 'size1qty12',
                                                            key: 'size1qty12',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '40',
                                                                    dataIndex: 'size1qty12',
                                                                    key: 'size1qty12',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '34',
                                                                            dataIndex: 'size1qty12',
                                                                            key: 'size1qty12',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty13',
                    key: 'size1qty13',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty13',
                            key: 'size1qty13',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty13',
                                    key: 'size1qty13',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty13',
                                            key: 'size1qty13',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty13',
                                                    key: 'size1qty13',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '16',
                                                            dataIndex: 'size1qty13',
                                                            key: 'size1qty13',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '42',
                                                                    dataIndex: 'size1qty13',
                                                                    key: 'size1qty13',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '35',
                                                                            dataIndex: 'size1qty13',
                                                                            key: 'size1qty13',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty14',
                    key: 'size1qty14',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty14',
                            key: 'size1qty14',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty14',
                                    key: 'size1qty14',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty14',
                                            key: 'size1qty14',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty14',
                                                    key: 'size1qty14',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '18',
                                                            dataIndex: 'size1qty14',
                                                            key: 'size1qty14',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '44',
                                                                    dataIndex: 'size1qty14',
                                                                    key: 'size1qty14',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '36',
                                                                            dataIndex: 'size1qty14',
                                                                            key: 'size1qty14',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty15',
                    key: 'size1qty15',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty15',
                            key: 'size1qty15',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty15',
                                    key: 'size1qty15',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty15',
                                            key: 'size1qty15',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty15',
                                                    key: 'size1qty15',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '20',
                                                            dataIndex: 'size1qty15',
                                                            key: 'size1qty15',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '46',
                                                                    dataIndex: 'size1qty15',
                                                                    key: 'size1qty15',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '37',
                                                                            dataIndex: 'size1qty15',
                                                                            key: 'size1qty15',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
                {
                    title: '-',
                    dataIndex: 'size1qty16',
                    key: 'size1qty16',
                    width: 50,
                    align: 'center',
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty16',
                            key: 'size1qty16',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty16',
                                    key: 'size1qty16',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty16',
                                            key: 'size1qty16',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty16',
                                                    key: 'size1qty16',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '-',
                                                            dataIndex: 'size1qty16',
                                                            key: 'size1qty16',
                                                            width: 50,
                                                            align: 'center',

                                                            children: [
                                                                {
                                                                    title: '48',
                                                                    dataIndex: 'size1qty16',
                                                                    key: 'size1qty16',
                                                                    width: 50,
                                                                    align: 'center',
                                                                    children: [
                                                                        {
                                                                            title: '38',
                                                                            dataIndex: 'size1qty16',
                                                                            key: 'size1qty16',
                                                                            width: 45,
                                                                            align: 'center',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },]
                                        },
                                    ],
                                }
                            ]
                        }
                    ]
                },
            ],
        },
        {
            title: 'Qty',
            dataIndex: "total_qty",
            key: 'total_qty',
            width: 60,
            align: "right",
            ...sorterHelper('total_qty','num')
        },
        {
            title: 'Rate',
            width: 60,
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.rate).format('0,0.00')}</>
            },
            align: "right",
            ...sorterHelper('rate','num')
        },
        {
            title: 'Amount',
            dataIndex: "amount",
            key: 'amount',
            width: 70,
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.amount).format('0,0.00')}</>
            },
            align: "right",
            ...sorterHelper('amount','num')
        },
        {
            title: 'Tax Amount',
            dataIndex: "tax_amount",
            key: 'tax_amount',
            width: 100,
            align: "right",
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.tax_amount).format('0,0.00')}</>
            },
            ...sorterHelper('tax_amount','num')
        },
        {
            title: 'Discount',
            width: 80,
            render: (text, record) => {
                return <>{currencySymbol} {numeral(record.discount_amount).format('0,0.00')}</>
            },
            align: "right",
            ...sorterHelper('discount_amount','num')
        },
        {
            title: 'SKU Type',
            dataIndex: "sku_type",
            key: 'sku_type',
            width: 100,
            ...filterHelper('sku_type')
        },
        {
            title: 'Gender',
            dataIndex: 'gender_category_name',
            key: 'gender_category_name',
            width: 100,
            ...filterHelper('gender_category_name')
        },
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            width: 100,
            ...filterHelper('brand_name')
        },
        {
            title: 'Status',
            dataIndex: "ship_status_name",
            key: 'ship_status_name',
            width: 80,
            ...filterHelper('ship_status_name')
        },
        {
            title: 'Terms',
            dataIndex: "term_name",
            key: 'term_name',
            width: 80,
            ...filterHelper('term_name')
        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location_name',
            width: 120,
            ...filterHelper('location_name')
        },
        {
            title: 'PT Status',
            dataIndex: 'pt_status_name',
            key: 'pt_status_name',
            width: 100,
            ...filterHelper('pt_status_name')
        },
        {
            title: 'NS Status',
            dataIndex: 'status_name',
            key: 'status_name',
            width: 100,
            ...filterHelper('status_name')
        },
        {
            title: 'Region',
            dataIndex: "region_name",
            key: 'region_name',
            width: 120,
            ...filterHelper('region_name')
        },
        {
            title: 'Sales Person',
            dataIndex: "salesperson_name",
            key: 'salesperson_name',
            width: 150,
            ...filterHelper('salesperson_name')
        },
    ];

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "PT #": v.ship_header_no,
                    "PT Date": moment(v.ship_date).format("MM-DD-YY"),
                    "Order #": v.order_header_no,
                    "Start Date": moment(v.start_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "PO #": v.reference_no,
                    "Customer": v.customer_name,
                    "Billing Addressee": v.billing_addressee,
                    "Customer Category": v.customer_type_name,
                    "Store": v.store_id,
                    "Order Date": moment(v.order_date).format("MM-DD-YY"),
                    "SKU": v.parent_item_code,
                    "Inseam": v.inseam,
                    "Style": v.style_name,
                    "Wash": v.wash_name,
                    "=\"\"2/3\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"00\"\"&CHAR(10)&\"\"10W\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.size1qty1,
                    "=\"\"4/5\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"0\"\"&CHAR(10)&\"\"12W\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.size1qty2,
                    "=\"\"6/6X\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"1\"\"&CHAR(10)&\"\"14W\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.size1qty3,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"16W\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.size1qty4,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"18W\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.size1qty5,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"XXL\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"20W\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.size1qty6,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"22W\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.size1qty7,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"24W\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.size1qty8,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"26W\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.size1qty9,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"28W\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.size1qty10,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"9\"\"&CHAR(10)&\"\"30W\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.size1qty11,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"32W\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.size1qty12,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.size1qty13,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.size1qty14,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.size1qty15,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.size1qty16,
                    "Qty": v.total_qty,
                    "Currency": currencySymbol,
                    "Rate": parseFloat(v.rate).toFixed(1),
                    "Amount": parseFloat(v.amount).toFixed(2),
                    "Tax Amount": parseFloat(v.tax_amount).toFixed(2),
                    "Discount Amount": parseFloat(v.discount_amount).toFixed(2),
                    "SKU Type": v.sku_type,
                    "Gender": v.gender_category_name,
                    "Brand": v.brand_name,
                    "Status": v.ship_status_name,
                    "Term": v.payment_term,
                    "Location": v.location_name,
                    "PT Status": v.pt_status_name,
                    "NS Status": v.status_name,
                    "Region": v.region_name,
                    "Sale person Name": v.salesperson_name,
                };
            })
        )
    }

    const onFinish = async () => {
        await form.validateFields().then((values) => {
            setLoading(true)
            dispatch(getPtDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPTDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        })
    }

    const totalQuantity = () => {
        let sumQty = pTDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.total_qty);
        }, 0);
        return sumQty;
    }

    const totalSum = () => {
        let sumAmount = pTDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }

    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Pick Ticket Detail"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        defaultValue={form.getFieldValue().cutomer_category}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().startOf('year')}

                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}


                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf('year')}

                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}

                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="invoice_no"
                                                        label="Invoice #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}

                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_no"
                                                        label="PT #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}

                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}

                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}

                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"

                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().region}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().gender}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        {/* <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="store"
                                                        label="Store #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}

                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol> */}
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item name="subsidary"
                                                    label="Subsidiary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={companyID}
                                                    >
                                                    <Select
                                                        showSearch
                                                        defaultValue={defaultCompany?.payload?.company_id}

                                                        optionFilterProp="children"
                                                        size="small"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >

                                                        <Option
                                                            key={defaultCompany?.payload?.company_id}
                                                            value={defaultCompany?.payload?.company_id}
                                                        >
                                                            {defaultCompany?.payload?.company_name}
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Pt Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        allowClear
                                                        loading={pickTicketStatus.loading}
                                                        defaultValue={form.getFieldValue().orderStatus}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {pickTicketStatus?.payload?.data.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.pt_status_id}
                                                                        value={item.pt_status_id}
                                                                    >
                                                                        {item.pt_status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="sku"
                                                        label="Sku #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="brand_id"
                                                    label="Brand"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option >All</Option>
                                                        {
                                                            brand?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.brand_id} value={val.brand_id}>{val.brand_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>

                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (pTDetailList.length > 0 ? (
                        < Table
                            title={() =>
                                <CSVLink
                                    filename={"PT-Detail.csv"}
                                    data={excelData}
                                    onClick={() => {

                                    }}
                                >
                                    Download Excel
                                </CSVLink>
                            }
                            ref={tableRef}
                            columns={BDCols}
                            loading={PtDetail?.loading}
                            dataSource={pTDetailList}
                            size="small"
                            bordered
                            scroll={{
                                x: 'calc(2500px + 80%)',
                                y: 450,
                            }}
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                            }}
                            summary={(pageData) => {
                                let Size1 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty1
                                    ));
                                },
                                    0);
                                let Size2 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty2
                                    ));
                                },
                                    0);
                                let Size3 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty3
                                    ));
                                },
                                    0);
                                let Size4 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty4

                                    ));
                                },
                                    0);
                                let Size5 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty5

                                    ));
                                },
                                    0);
                                let Size6 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty6

                                    ));
                                },
                                    0);
                                let Size7 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty7

                                    ));
                                },
                                    0);
                                let Size8 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty8

                                    ));
                                },
                                    0);
                                let Size9 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty9

                                    ));
                                },
                                    0);
                                let Size10 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty10

                                    ));
                                },
                                    0);
                                let Size11 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty11

                                    ));
                                },
                                    0);
                                let Size12 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty12

                                    ));
                                },
                                    0);
                                let Size13 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty13

                                    ));
                                },
                                    0);
                                let Size14 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty14

                                    ));
                                },
                                    0);
                                let Size15 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty15

                                    ));
                                },
                                    0);
                                let Size16 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty16

                                    ));
                                },
                                    0);
                                let OrderQty = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.total_qty
                                    ));
                                },
                                    0);
                                let Amount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.amount
                                    ));
                                },
                                    0);
                                let TaxAmount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.tax_amount
                                    ));
                                },
                                    0);
                                let Discount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.discount_amount
                                    ));
                                },
                                    0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size1.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size2.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size3.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size4.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size5.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size6.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size7.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size8.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size9.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size10.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size11.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size12.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size13.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size14.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size15.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size16.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {OrderQty.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(Amount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(TaxAmount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {currencySymbol} {numeral(Discount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    ) : <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                    )
                }

            </div>

        </div>

    )
}

export default PtDetail;