import {
	ADD_INVENTORYADJUSTMENT_HEADER_ERROR, ADD_INVENTORYADJUSTMENT_HEADER_REQUEST, ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR, GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST, GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
import axios from "axios";

export const addInvAdjustmentNewHeader = (token, formData, values) => (dispatch) => {
	dispatch({ type: ADD_INVENTORYADJUSTMENT_HEADER_REQUEST });
	let newData = {
		adjustment_header_id: formData.adjustment_header_id,
		adjust_date: moment(formData.transferDate).format("MM-DD-YYYY"),
		gl_period_id: formData.postingPeriod,
		memo: formData.memo,
		company_id: formData.subsidiary,
		location_id: formData.adjustmentLocation,
		brand_id: formData.brand_id,
		account_id: formData.account_id,
		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/InvStockAdjustmentHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_INVENTORYADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneInvAdjustmentNewHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/InvStockAdjustmentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};