import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { Input, Space, Tag } from "antd";
import moment from "moment";
import numeral from "numeral";
import { Link } from "react-router-dom";
const queryParams = new URLSearchParams(window.location.search);
const Order_id = queryParams.get("order_id");
const styleColumns = [
  {
    title: "Style ID",
    dataIndex: "style_id",
    key: "style_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.style_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.style_id - b.style_id;
    },
  },
  {
    title: "Style Name",
    dataIndex: "style_name",
    key: "style_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.style_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.style_name.localeCompare(b.style_name);
    },
  },
];

const shippingColumns = [
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },

  {
    title: "ADDRESSEE",
    dataIndex: "addressee",
    key: "addressee",
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <p>Edit </p>
        <p>Delete</p>
      </Space>
    ),
  },
];

const customerColumns = [
  {
    title: "Customer ID",
    dataIndex: "customer_id",
    key: "customer_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_id === parseInt(value);
    },
  },
  {
    title: "Alternate Email Address",
    dataIndex: "alternate_email_address",
    key: "alternate_email_address",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.alternate_email_address
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Customer Category ID",
    dataIndex: "customer_category_id",
    key: "customer_category_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_category_id === parseInt(value);
    },
  },
  {
    title: "Customer Code",
    dataIndex: "customer_code",
    key: "customer_code",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_code
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Customer Type ID",
    dataIndex: "customer_type_id",
    key: "customer_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_type_id === parseInt(value);
    },
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
    key: "email_address",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.email_address
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Fax No",
    dataIndex: "fax_no",
    key: "fax_no",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fax_no === parseInt(value);
    },
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.first_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Middle Name",
    dataIndex: "middle_name",
    key: "middle_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.middle_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.last_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Mobile No",
    dataIndex: "mobile_no",
    key: "mobile_no",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.mobile_no === parseInt(value);
    },
  },
  {
    title: "Old Customer Code",
    dataIndex: "old_customer_code",
    key: "old_customer_code",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.old_customer_code
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Opening Date",
    dataIndex: "opening_date",
    key: "opening_date",
  },
  {
    title: "Order Status ID",
    dataIndex: "order_status_id",
    key: "order_status_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.order_status_id === parseInt(value);
    },
  },
  {
    title: "Phone No",
    dataIndex: "phone_no",
    key: "phone_no",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.phone_no === parseInt(value);
    },
  },
  {
    title: "Primary Subsidiary ID",
    dataIndex: "primary_subsidiary_id",
    key: "primary_subsidiary_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.primary_subsidiary_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.remarks
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Resale Certificate Date",
    dataIndex: "resale_certificate_date",
    key: "resale_certificate_date",
  },
  {
    title: "Resale Certificate No",
    dataIndex: "resale_certificate_no",
    key: "resale_certificate_no",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.release_certificate_no === parseInt(value);
    },
  },
  {
    title: "Term ID",
    dataIndex: "term_id",
    key: "term_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.term_id === parseInt(value);
    },
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.website
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
];

const paymentCol = [
  {
    title: "Payment_Header_Id",
    dataIndex: "payment_header_id",
    key: "payment_header_id",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_header_id - b.payment_header_id;
    },
  },
  {
    title: "Customer_Name",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Payment_Header_No",
    dataIndex: "payment_header_no",
    key: "payment_header_no",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_header_no - b.payment_header_no;
    },
  },
  {
    title: "Payment_Amount",
    dataIndex: "payment_amount",
    key: "payment_amount",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_amount
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_amount - b.payment_amount;
    },
  },
];

const logColumns = [
  {
    title: "Log ID",
    dataIndex: "log_id",
    key: "log_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.log_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.log_id - b.log_id;
    },
  },
  {
    title: "Record_id",
    dataIndex: "record_id",
    key: "record_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.record_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.record_id - b.record_id;
    },
  },
  {
    title: "Column Name",
    dataIndex: "entity_column_name",
    key: "entity_column_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.entity_column_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.entity_column_name - b.entity_column_name;
    },
  },
  {
    title: "Column Value",
    dataIndex: "p_column_value",
    key: "p_column_value",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.p_column_value
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.p_column_value - b.p_column_value;
    },
  },
  {
    title: "Action Name",
    dataIndex: "action_name",
    key: "action_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.action_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.action_name - b.action_name;
    },
  },
  {
    title: "Action Date",
    dataIndex: "dml_action_date",
    key: "dml_action_date",
    render: (text, record) => moment(text).format("llll"),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.dml_action_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.dml_action_date - b.dml_action_date;
    },
  },
  {
    title: "User ID",
    dataIndex: "login_id",
    key: "login_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.login_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.login_id - b.login_id;
    },
  },
];

const washColumns = [
  {
    title: "ID",
    dataIndex: "wash_id",
    key: "wash_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.wash_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.wash_id - b.wash_id;
    },
  },
  {
    title: "Wash Name",
    dataIndex: "wash_name",
    key: "wash_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.wash_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.wash_name.length - b.wash_name.length;
    },
  },
];

const washTypeColumns = [
  {
    title: "wash Type ID",
    dataIndex: "wash_type_id",
    key: "wash_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,

    onFilter: (value, record) =>
      record.wash_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.wash_type_id - b.wash_type_id;
    },
  },
  {
    title: "Wash Type Name",
    dataIndex: "wash_type_name",
    key: "wash_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.wash_type_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.wash_type_name.length - b.wash_type_name.length;
    },
  },
];

const cutColumns = [
  {
    title: "Cut ID",
    dataIndex: "cut_id",
    key: "cut_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.cut_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.cut_id - b.cut_id;
    },
  },
  {
    title: "Cut Name",
    dataIndex: "cut_name",
    key: "cut_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.cut_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.cut_name.length - b.cut_name.length;
    },
  },
];

const fabricColumns = [
  {
    title: "Fabric ID",
    dataIndex: "fabric_id",
    key: "fabric_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_id - b.fabric_id;
    },
  },
  {
    title: "Fabric Name",
    dataIndex: "fabric_name",
    key: "fabric_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fabric_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fabric_name.length - b.fabric_name.length;
    },
  },
];

const fitCategoryColumns = [
  {
    title: "Fit Category ID",
    dataIndex: "fit_category_id",
    key: "fit_category_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fit_category_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fit_category_id - b.fit_category_id;
    },
  },
  {
    title: "Fit Category Name",
    dataIndex: "fit_category_name",
    key: "fit_category_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fit_category_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fit_category_name.length - b.fit_category_name.length;
    },
  },
];

const genderCategoryColumns = [
  {
    title: "Gender Name",
    dataIndex: "gender_category_name",
    key: "gender_category_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.gender_category_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.gender_category_name.length - b.gender_category_name.length;
    },
  },
  {
    title: "Size Chart",
    dataIndex: "size_chart",
    key: "size_chart",
    render: (text) => {
      let data = text.toString().split(",");
      return (
        <>
          {data.map((e) => (
            <Tag style={{ margin: "1px" }}>{e}</Tag>
          ))}
        </>
      );
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.size_chart.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.size_chart - b.size_chart;
    },
  },
];

const paymentTypeColumns = [
  {
    title: "Payment Type ID",
    dataIndex: "payment_type_id",
    key: "payment_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_type_id - b.payment_type_id;
    },
  },
  {
    title: "Payment Type Name",
    dataIndex: "payment_type_name",
    key: "payment_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.payment_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.payment_type_name.length - b.payment_type_name.length;
    },
  },
];

const riseColumns = [
  {
    title: "Rise ID",
    dataIndex: "rise_id",
    key: "rise_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,

    onFilter: (value, record) =>
      record.rise_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.rise_id - b.rise_id;
    },
  },
  {
    title: "Rise Name",
    dataIndex: "rise_name",
    key: "rise_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.rise_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.rise_name.length - b.rise_name.length;
    },
  },
];

const adminModuleColumns = [
  {
    title: "Admin Module ID",
    dataIndex: "module_id",
    key: "module_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,

    onFilter: (value, record) =>
      record.module_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.module_id - b.module_id;
    },
  },
  {
    title: "Admin Module Name",
    dataIndex: "module_name",
    key: "module_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.module_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.module_name.length - b.module_name.length;
    },
  },
];

const customerTypeColumns = [
  {
    title: "Customer Type ID",
    dataIndex: "customer_type_id",
    key: "customer_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,

    onFilter: (value, record) =>
      record.customer_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_type_id - b.customer_type_id;
    },
  },
  {
    title: "Customer Type Name",
    dataIndex: "customer_type_name",
    key: "customer_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.customer_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.customer_type_name.length - b.customer_type_name.length;
    },
  },
];

const genderColumns = [
  {
    title: "Gender ID",
    dataIndex: "gender_id",
    key: "gender_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gender_id - b.gender_id;
    },
  },
  {
    title: "Gender Name",
    dataIndex: "gender_name",
    key: "gender_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      record.gender_name.toString().toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.gender_name.length - b.gender_name.length;
    },
  },
];

const productClassColumns = [
  {
    title: "Product Class ID",
    dataIndex: "product_class_id",
    key: "product_class_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.product_class_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.product_class_id - b.product_class_id;
    },
  },
  {
    title: "Product Class Name",
    dataIndex: "product_class_name",
    key: "product_class_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.product_class_name.toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.product_class_name.length - b.product_class_name.length;
    },
  },
];

const ProductTypeColumns = [
  {
    title: "Product Type ID",
    dataIndex: "product_type_id",
    key: "product_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.product_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.product_type_id - b.product_type_id;
    },
  },
  {
    title: "Product Type Name",
    dataIndex: "product_type_name",
    key: "product_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.product_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.product_type_name.length - b.product_type_name.length;
    },
  },
];

const inseamLabelColumns = [
  {
    title: "Inseam Label ID",
    dataIndex: "inseam_label_id",
    key: "inseam_label_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.inseam_label_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.inseam_label_id - b.inseam_label_id;
    },
  },
  {
    title: "Inseam Label Name",
    dataIndex: "inseam_label_name",
    key: "inseam_label_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.inseam_label_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.inseam_label_name.length - b.inseam_label_name.length;
    },
  },
];

const productStatusColumns = [
  {
    title: "Product Status ID",
    dataIndex: "product_status_id",
    key: "product_status_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.product_status_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.product_status_id - b.product_status_id;
    },
  },
  {
    title: "Product Status Name",
    dataIndex: "product_status_name",
    key: "product_status_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.product_status_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.product_status_name.length - b.product_status_name.length;
    },
  },
];

const riseLabelColumns = [
  {
    title: "Rise Label ID",
    dataIndex: "rise_label_id",
    key: "rise_label_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      record.rise_label_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.rise_label_id - b.rise_label_id;
    },
  },
  {
    title: "Rise Label Name",
    dataIndex: "rise_label_name",
    key: "rise_label_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.rise_label_name.toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.rise_label_name.length - b.rise_label_name.length;
    },
  },
];

const seasonColumns = [
  {
    title: "Season ID",
    dataIndex: "season_id",
    key: "season_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.season_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.season_id - b.season_id;
    },
  },
  {
    title: "Season Name",
    dataIndex: "season_name",
    key: "season_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.season_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.season_name.length - b.season_name.length;
    },
  },
];

const inseamColumns = [
  {
    title: "Inseam ID",
    dataIndex: "inseam_id",
    key: "inseam_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.inseam_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.inseam_id - b.inseam_id;
    },
  },
  {
    title: "Inseam Name",
    dataIndex: "inseam_name",
    key: "inseam_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.inseam_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.inseam_name.length - b.inseam_name.length;
    },
  },
  {
    title: "Sequence",
    dataIndex: "seq",
    key: "seq",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.seq.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.seq - b.seq;
    },
  },
];

const sizeColumns = [
  {
    title: "Size ID",
    dataIndex: "size_id",
    key: "size_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.size_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.size_id - b.size_id;
    },
  },
  {
    title: "Size Name",
    dataIndex: "size_name",
    key: "size_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.size_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.size_name.length - b.size_name.length;
    },
  },
];

const regionColumns = [
  {
    title: "Region ID",
    dataIndex: "region_id",
    key: "region_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.region_id - b.region_id;
    },
  },
  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.region_name.localeCompare(b.region_name);
    },
  },
];

const stateColumns = [
  {
    title: "State ID",
    dataIndex: "state_id",
    key: "state_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.state_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.state_id - b.state_id;
    },
  },
  {
    title: "State Name",
    dataIndex: "state_name",
    key: "state_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.state_name.localeCompare(b.states_name);
    },
  },
];

const countryColumns = [
  {
    title: "Country ID",
    dataIndex: "country_id",
    key: "country_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.country_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.country_id - b.country_id;
    },
  },
  {
    title: "Country Name",
    dataIndex: "country_name",
    key: "country_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.country_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.country_name.localeCompare(b.country_name);
    },
  },
];

const brandColumns = [
  {
    title: "Brand ID",
    dataIndex: "brand_id",
    key: "brand_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.country_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.country_id - b.country_id;
    },
  },
  {
    title: "Brand Name",
    dataIndex: "brand_name",
    key: "brand_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.country_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.country_name.localeCompare(b.country_name);
    },
  },
];

const cityColumns = [
  {
    title: "City ID",
    dataIndex: "city_id",
    key: "city_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.city_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.city_id - b.city_id;
    },
  },
  {
    title: "City Name",
    dataIndex: "city_name",
    key: "city_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.city_name !== null) {
        return record.city_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.city_name !== null) & (b.city_name !== null)) {
        return a.city_name.localeCompare(b.city_name);
      }
    },
  },
];

const regionCatColumns = [
  {
    title: "Category ID",
    dataIndex: "sp_region_category_id",
    key: "sp_region_category_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.sp_region_category_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.sp_region_category_id - b.sp_region_category_id;
    },
  },
  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.region_name !== null) {
        return record.region_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.region_id !== null) & (b.region_id !== null)) {
        return a.region_id - b.region_id;
      }
    },
  },
  {
    title: "Sales Person",
    dataIndex: "salesperson_name",
    key: "sales_person_id",
    render: (text, record) => (text === " " ? "N/A" : text),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.salesperson_name !== null) {
        return record.salesperson_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.sales_person_id !== null) & (b.sales_person_id !== null)) {
        return a.sales_person_id - b.sales_person_id;
      }
    },
  },
];

const shipViaColumns = [
  {
    title: "Ship Via ID",
    dataIndex: "ship_via_id",
    key: "ship_via_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_via_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ship_via_id - b.ship_via_id;
    },
  },
  {
    title: "Description",
    dataIndex: "ship_via_description",
    key: "ship_via_description",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.city_name !== null) {
        return record.ship_via_description
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if (
        (a.ship_via_description !== null) &
        (b.ship_via_description !== null)
      ) {
        return a.ship_via_description.localeCompare(b.ship_via_description);
      }
    },
  },
];

const shipMethodColumns = [
  {
    title: "Method ID",
    dataIndex: "shipping_method_id",
    key: "shipping_method_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.shipping_method_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.shipping_method_id - b.shipping_method_id;
    },
  },
  {
    title: "Method Name",
    dataIndex: "shipping_method_name",
    key: "shipping_method_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.shipping_method_name !== null) {
        return record.shipping_method_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if (
        (a.shipping_method_name !== null) &
        (b.shipping_method_name !== null)
      ) {
        return a.shipping_method_name.localeCompare(b.shipping_method_name);
      }
    },
  },
];

const freightTermColumns = [
  {
    title: "Freight Term ID",
    dataIndex: "freight_term_id",
    key: "freight_term_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.freight_term_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.freight_term_id - b.freight_term_id;
    },
  },
  {
    title: "Freight Term Name",
    dataIndex: "freight_term_name",
    key: "freight_term_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.shipping_method_name !== null) {
        return record.freight_term_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.freight_term_name !== null) & (b.freight_term_name !== null)) {
        return a.freight_term_name.localeCompare(b.freight_term_name);
      }
    },
  },
];

const bergenBillingColumns = [
  {
    title: "Bergen Billing ID",
    dataIndex: "bergan_billing_id",
    key: "bergan_billing_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.bergan_billing_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.bergan_billing_id - b.bergan_billing_id;
    },
  },
  {
    title: "Bergen Billing Name",
    dataIndex: "bergan_billing_name",
    key: "bergan_billing_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.shipping_method_name !== null) {
        return record.bergan_billing_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.bergan_billing_name !== null) & (b.bergan_billing_name !== null)) {
        return a.bergan_billing_name.localeCompare(b.bergan_billing_name);
      }
    },
  },
];

const courierServiceColumn = [
  {
    title: "Courier Service ID",
    dataIndex: "courier_service_id",
    key: "courier_service_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.courier_service_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.courier_service_id - b.courier_service_id;
    },
  },
  {
    title: "Courier Service Name",
    dataIndex: "service_name",
    key: "service_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.service_name !== null) {
        return record.service_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.service_name !== null) & (b.service_name !== null)) {
        return a.service_name.localeCompare(b.service_name);
      }
    },
  },
];



const saleOrderColumns = [
  {
    title: "Order ID",
    dataIndex: "order_header_id",
    key: "order_header_id",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },

  {
    title: "Order No",
    dataIndex: "order_header_no",
    key: "order_header_no",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.order_header_no.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: "Order Date",
    dataIndex: "order_date",
    key: "order_date",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "PO #",
    dataIndex: "reference_no",
    key: "reference_no",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.reference_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },


  {
    title: "Term",
    dataIndex: "term_name",
    key: "term_name",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },

  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },

  {
    title: "SalePerson Name",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },

  {
    title: "Gender Name",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link to={"/sale-order/edit/" + record.order_header_id + '/' + record.customer_id}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className='pop-search-gen pop-11em'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
];
const saleOrder_Adj_Columns = [
  {
    title: "Adj No",
    dataIndex: "order_header_adj_no",
    key: "order_header_adj_no",
    render: (text, record) => (
      <Link

        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}

      >{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Order ID",
    dataIndex: "order_header_id",
    key: "order_header_id",
    render: (text, record) => (
      <Link

        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}

      >{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },

  {
    title: "Order No",
    dataIndex: "order_header_no",
    key: "order_header_no",
    render: (text, record) => (
      <Link
        to={`/sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}






      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.order_header_no.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: "Adj Date",
    dataIndex: "adj_date",
    key: "adj_date",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "PO #",
    dataIndex: "reference_no",
    key: "reference_no",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.reference_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        </>
      );
    },
    filterIcon: (filtered) => filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => record.customer_name.toString().toLowerCase().includes(value.toLowerCase())
  },

  {
    title: "Term",
    dataIndex: "term_name",
    key: "term_name",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_name.toString().toLowerCase().includes(value.toLowerCase()),
  },

  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
  },

  {
    title: "SalePerson Name",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
  },

  {
    title: "Gender Name",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={`sale-order-adjustment-new?mode=${"edit"}&order_id=${record.order_header_adj_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
  },
];
const orderShipmentColumns = [
  {
    title: "PT No",
    dataIndex: "ship_header_no",
    key: "ship_header_no",
    render: (text, record) => (
      <Link to={`order-shipment?mode=edit&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "SO No",
    dataIndex: "order_header_no",
    key: "order_header_no",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "PT Date",
    dataIndex: "ship_date",
    key: "ship_date",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_date.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "PO No",
    dataIndex: "po_no",
    key: "po_no",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.po_no.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Sale Person Name",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Gender Name",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Term",
    dataIndex: "payment_term_name",
    key: "payment_term_name",
    render: (text, record) => (
      <Link
        to={`order-shipment?mode=${"edit"}&order_id=${record.ship_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_term_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
];

const menuColumns = [
  {
    title: "Menu ID",
    dataIndex: "menu_id",
    key: "menu_id",
    width: "35%",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.menu_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.menu_id - b.menu_id;
    },
  },
  {
    title: "Menu Name",
    dataIndex: "menu_name",
    key: "menu_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.menu_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.menu_id - b.menu_id;
    },
  },
];

const userColumns = [
  {
    title: "User ID",
    dataIndex: "user_id",
    key: "user_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.user_id.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Login Id",
    dataIndex: "login_id",
    key: "login_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-11em"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.login_id.toLowerCase().includes(value.toLowerCase());
    },
  },
];

const paymentColumns = [
  {
    title: "Term ID",
    dataIndex: "term_id",
    key: "term_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.term_id - b.term_id;
    },
  },
  {
    title: "Term Name",
    dataIndex: "term_name",
    key: "term_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.term_name !== null) {
        return record.term_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.term_name !== null) & (b.term_name !== null)) {
        return a.term_name.localeCompare(b.term_name);
      }
    },
  },
];

const taxColumn = [
  {
    title: "Tax ID",
    dataIndex: "tax_id",
    key: "tax_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.tax_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.tax_id - b.tax_id;
    },
  },
  {
    title: "Tax Name",
    dataIndex: "tax_name",
    key: "tax_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.term_name !== null) {
        return record.tax_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.tax_name !== null) & (b.tax_name !== null)) {
        return a.tax_name.localeCompare(b.tax_name);
      }
    },
  },
  {
    title: "Tax Percentage",
    dataIndex: "tax_percentage",
    key: "tax_percentage",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) =>
      record.tax_percentage.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.tax_percentage - b.tax_percentage;
    },
  },
];

const chartofaccountColumns = [









































  {
    title: "Account No",
    dataIndex: "account_number",
    key: "account_number",
    width: 120,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.account_number !== null) {
        return record.account_number
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.account_number !== null) & (b.account_number !== null)) {
        return a.account_number.localeCompare(b.account_number);
      }
    },
  },
  {
    title: "Description",
    dataIndex: "full_name",
    key: "full_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.full_name !== null) {
        return record.full_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.full_name !== null) & (b.full_name !== null)) {
        return a.full_name.localeCompare(b.full_name);
      }
    },
  },
];

const invoiceHeaderColumns = [
  {
    title: "Invoice #",
    dataIndex: "invoice_header_no",
    key: "invoice_header_no",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.invoice_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Invoice Date",
    dataIndex: "invoice_date",
    key: "invoice_date",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.invoice_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "PO #",
    dataIndex: "po_no",
    key: "po_no",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.po_no.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "PT #",
    dataIndex: "ship_header_no",
    key: "ship_header_no",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Sale Order #",
    dataIndex: "order_header_no",
    key: "order_header_no",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}

      >
        {text}
      </Link>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}

      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Sale Person Name",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Gender Name",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Term",
    dataIndex: "payment_term_name",
    key: "payment_term_name",
    render: (text, record) => (
      <Link
        to={`/invoice?mode=edit&order_header_id=${record.order_header_id}&invoice_header_id=${record.invoice_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-25vw"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_term_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
];

const adminCompanyColumns = [
  {
    title: "User Company ID",
    dataIndex: "user_company_id",
    key: "user_company_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.user_company_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.user_company_id - b.user_company_id;
    },
  },
  {
    title: "User Id",
    dataIndex: "user_id",
    key: "user_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.user_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.user_id - b.user_id;
    },
  },
  {
    title: "Company Id",
    dataIndex: "company_id",
    key: "company_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.company_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.company_id - b.company_id;
    },
  },
  {
    title: "User Name",
    dataIndex: "full_name",
    key: "full_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.company_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.full_name - b.full_name;
    },
  },
];

const accountTypeColumn = [
  {
    title: "Account Type ID",
    dataIndex: "account_type_id",
    key: "account_type_id",
    width: 120,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.account_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.account_type_id - b.account_type_id;
    },
  },
  {
    title: "Account Type Code",
    dataIndex: "account_type_code",
    key: "account_type_code",
    width: 130,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "220px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.account_type_code !== null) {
        return record.account_type_code
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.account_type_code !== null) & (b.account_type_code !== null)) {
        return a.account_type_code.localeCompare(b.account_type_code);
      }
    },
  },
];

const orderStatusColumns = [
  {
    title: "Order Status ID",
    dataIndex: "order_status_id",
    key: "order_status_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_status_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.order_status_id - b.order_status_id;
    },
  },
  {
    title: "Status Name",
    dataIndex: "status_name",
    key: "status_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.status_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.status_name.length - b.status_name.length;
    },
  },
];

const shipStatusColumns = [
  {
    title: "Ship Status ID",
    dataIndex: "ship_status_id",
    key: "ship_status_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_status_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ship_status_id - b.ship_status_id;
    },
  },
  {
    title: "Ship Status Name",
    dataIndex: "ship_status_name",
    key: "ship_status_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.ship_status_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.ship_status_name.length - b.ship_status_name.length;
    },
  },
];
const pickTicketStatusColumns = [
  {
    title: "Pick Ticket Status ID",
    dataIndex: "pt_status_id",
    key: "pt_status_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.pt_status_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.pt_status_id - b.pt_status_id;
    },
  },
  {
    title: "Pick Ticket Status Name",
    dataIndex: "pt_status_name",
    key: "pt_status_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.pt_status_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.pt_status_name.length - b.pt_status_name.length;
    },
  },
];

const saleReturnColumns = [
  {
    title: "Return#",
    dataIndex: "return_header_no",
    key: "return_header_no",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.return_header_no - b.return_header_no;
    },
  },
  {
    title: "RA Approval#",
    dataIndex: "ra_no",
    key: "ra_no",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ra_no - b.ra_no;
    },
  },
  {
    title: "Return Date",
    dataIndex: "return_date",
    key: "return_header_id",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_date.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.return_date - b.return_date;
    },
  },
  {
    title: "Invoice#",
    dataIndex: "invoice_header_no",
    key: "invoice_header_no",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.invoice_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.invoice_header_no - b.invoice_header_no;
    },
  },
  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
  {
    title: "Order#",
    dataIndex: "order_header_no",
    key: "order_header_no",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.order_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.order_header_no - b.order_header_no;
    },
  },
  {
    title: "PO#",
    dataIndex: "reference_no",
    key: "reference_no",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.po_no.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.po_no - b.po_no;
    },
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Sales Person",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.salesperson_name - b.salesperson_name;
    },
  },

  {
    title: "Gender",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gender_name - b.gender_name;
    },
  },
  {
    title: "Region",
    dataIndex: "region_name",
    key: "region_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.region_name - b.region_name;
    },
  },
  {
    title: "Location",
    dataIndex: "location_name",
    key: "location_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.location_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.location_name - b.location_name;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Term",
    dataIndex: "payment_term_name",
    key: "payment_term_name",
    render: (text, record) => (
      <Link
        to={`return-authorization?mode=edit&return_id=${record.return_header_id}&customer_id=${record.customer_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_term_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_term_name - b.payment_term_name;
    },
  },
];

const accountingPeriod = [
  {
    title: "Fiscal Year ID",
    dataIndex: "fiscal_year_id",
    key: "fiscal_year_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fiscal_year_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fiscal_year_id - b.fiscal_year_id;
    },
  },
  {
    title: "Fiscal Year Name",
    dataIndex: "fiscal_year_name",
    key: "fiscal_year_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className='pop-search-gen pop-220'
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {

            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fiscal_year_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fiscal_year_name.length - b.fiscal_year_name.length;
    },
  },
];

const receiptColumns = [
  {
    title: "Item Receipt #",
    dataIndex: "receipt_header_no",
    key: "receipt_header_no",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.receipt_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.receipt_header_no - b.receipt_header_no;
    },
  },
  {
    title: "Receipt Date",
    dataIndex: "receipt_date",
    key: "receipt_date",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.receipt_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.receipt_date - b.receipt_date;
    },
  },
  {
    title: "CN #",
    dataIndex: "note_header_no",
    key: "note_header_no",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>

    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.note_header_no.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.note_header_no - b.note_header_no;
    },
  },
  {
    title: "Return #",
    dataIndex: "return_header_no",
    key: "return_header_no",
    render: (text, record) => (


      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.return_header_no - b.return_header_no;
    },
  },
  {
    title: "RA Approval #",
    dataIndex: "ra_no",
    key: "ra_no",
    render: (text, record) => (


      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ra_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ra_no - b.ra_no;
    },
  },
  {
    title: "Invoice #",
    dataIndex: "invoice_header_no",
    key: "invoice_header_no",
    render: (text, record) => (


      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.invoice_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.invoice_header_no - b.invoice_header_no;
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Brand",
    dataIndex: "brand_name",
    key: "brand_name",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    sorter: (a, b) => {
      return a.brand_name - b.brand_name;
    },
  },
  {
    title: "Lot #",
    dataIndex: "lot_no",
    key: "lot_no",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.lot_no.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.lot_no - b.lot_no;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link to={`/item-receipt-new?mode=${"edit"}&return_id=${record.receipt_header_id}`}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
];

const returnReasonColumn = [
  {
    title: "Return Reason Id",
    dataIndex: "return_reason_id",
    key: "return_reason_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_reason_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.return_reason_id - b.return_reason_id;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "220px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.description !== null) {
        return record.description
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.description !== null) & (b.description !== null)) {
        return a.description.localeCompare(b.description);
      }
    },
  },
];

const adminLocationColumn = [
  {
    title: "Location Id",
    dataIndex: "location_id",
    key: "location_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.location_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.location_id - b.location_id;
    },
  },
  {
    title: "Location Name",
    dataIndex: "location_name",
    key: "location_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "220px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.location_name !== null) {
        return record.location_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.location_name !== null) & (b.location_name !== null)) {
        return a.location_name.localeCompare(b.location_name);
      }
    },
  },
];

const salesTargetColumn = [
  {
    title: "Target Id",
    dataIndex: "target_id",
    key: "target_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.target_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.target_id - b.target_id;
    },
  },

  {
    title: "Target Month",
    dataIndex: "target_month",
    key: "target_month",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.target_month
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.target_month - b.target_month;
    },
  },
  {
    title: "Target Amount",
    dataIndex: "target_amount",
    key: "target_amount",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            style={{
              position: "absolute",
              top: -65,
              right: 0,
              borderRadius: "10px",
              width: "170px",
            }}
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.target_amount
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.target_amount - b.target_amount;
    },
  },
];

const creditColumns = [
  {
    title: "Note Header Id",
    dataIndex: "note_header_id",
    key: "note_header_id",
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.note_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.note_header_id - b.note_header_id;
    },
  },
  {
    title: "Note Header No",
    dataIndex: "note_header_no",
    key: "note_header_no",
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.receipt_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.note_header_no - b.note_header_no;
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Location Name",
    dataIndex: "location_name",
    key: "location_name",
    render: (text, record) => (
      <Link to={"/credit-note/edit/" + record.note_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.location_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.location_name - b.location_name;
    },
  },
];

const itemTransferColumns = [
  {
    title: "Transfer Header No",
    dataIndex: "transfer_header_no",
    key: "transfer_header_no",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transfer_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transfer_header_no - b.transfer_header_no;
    },
  },
  {
    title: "Transfer Date",
    dataIndex: "transfer_date",
    key: "transfer_date",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transfer_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transfer_date - b.transfer_date;
    },
  },
  {
    title: "From Location",
    dataIndex: "from_location_name",
    key: "from_location_name",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.from_location_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.from_location_name - b.from_location_name;
    },
  },
  {
    title: "To Location",
    dataIndex: "to_location_name",
    key: "to_location_name",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.to_location_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.to_location_name - b.to_location_name;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Asn No",
    dataIndex: "asn_no",
    key: "asn_no",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.asn_no.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.asn_no - b.asn_no;
    },
  },
  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link
        to={"/item-transfer/edit/" + record.transfer_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.memo.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
];

const vendroCatColumns = [
  {
    title: "Category ID",
    dataIndex: "vendor_category_id",
    key: "vendor_category_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_category_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_category_id - b.vendor_category_id;
    },
  },
  {
    title: "Category Name",
    dataIndex: "category_name",
    key: "category_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.category_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.category_name.localeCompare(b.category_name);
    },
  },
];

const billColumns = [
  {
    title: "Bill Header No",
    dataIndex: "bill_header_no",
    key: "bill_header_no",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.bill_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.bill_header_no - b.bill_header_no;
    },
  },
  {
    title: "Invoice No",
    dataIndex: "reference_no",
    key: "reference_no",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.reference_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.reference_no - b.reference_no;
    },
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_name - b.vendor_name;
    },
  },

  {
    title: "Posting Period",
    dataIndex: "gl_period_name",
    key: "gl_period_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gl_period_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gl_period_name - b.gl_period_name;
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.account_name - b.account_name;
    },
  },
  {
    title: "Currency",
    dataIndex: "currency_name",
    key: "currency_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.currency_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.currency_name - b.currency_name;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.amount.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.amount - b.amount;
    },
  },
  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.memo.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
  },
  {
    title: "Location",
    dataIndex: "location_name",
    key: "location_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.location_name - b.location_name;
    },
  },
  {
    title: "Ship Via Name",
    dataIndex: "ship_via_name",
    key: "ship_via_name",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ship_via_name?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.ship_via_name - b.ship_via_name;
    },
  },
  {
    title: "Bill Date",
    dataIndex: "bill_date",
    key: "bill_date",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.bill_date.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.bill_date - b.bill_date;
    },
  },
  {
    title: "Delivery Date",
    dataIndex: "delivery_date",
    key: "delivery_date",
    render: (text, record) => (
      <Link to={"/bill/edit/" + record.bill_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.delivery_date?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.delivery_date - b.delivery_date;
    },
  },

];

const returnTypeColumns = [
  {
    title: "Return Type ID",
    dataIndex: "return_type_id",
    key: "return_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.return_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.return_type_id - b.return_type_id;
    },
  },
  {
    title: "Return Type Name",
    dataIndex: "return_type_name",
    key: "return_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.return_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.return_type_name.length - b.return_type_name.length;
    },
  },
];
const fabricCompositionColumns = [
  {
    title: "Fabric Composition ID",
    dataIndex: "fabric_composition_id",
    key: "fabric_composition_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_composition_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_composition_id - b.fabric_composition_id;
    },
  },
  {
    title: "Fabric Composition",
    dataIndex: "composition_name",
    key: "composition_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.composition_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.composition_name.length - b.composition_name.length;
    },
  },
];
const itemTypeColumns = [
  {
    title: "Item Type ID",
    dataIndex: "item_type_id",
    key: "item_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.item_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.item_type_id - b.item_type_id;
    },
  },
  {
    title: "Item Type",
    dataIndex: "item_type_name",
    key: "item_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.item_type_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.item_type_name.length - b.item_type_name.length;
    },
  },
];
const barganPaymentTermColumns = [
  {
    title: "Bargan Payment Term ID",
    dataIndex: "bergen_payment_term_id",
    key: "bergen_payment_term_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.bergen_payment_term_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.bergen_payment_term_id - b.bergen_payment_term_id;
    },
  },
  {
    title: "Bargan Payment Term Type",
    dataIndex: "term_description",
    key: "term_description",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.term_description
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.term_description.length - b.term_description.length;
    },
  },
];
const paymentMethodTypeColumns = [
  {
    title: "PaymentMethod Type ID",
    dataIndex: "method_type_id",
    key: "method_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.method_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.method_type_id - b.method_type_id;
    },
  },
  {
    title: "PaymentMethod Type",
    dataIndex: "method_type_name",
    key: "method_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.method_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.method_type_name.length - b.method_type_name.length;
    },
  },
];
const adminMenuTypeColumns = [
  {
    title: "AdminMenu Type ID",
    dataIndex: "menu_type_id",
    key: "menu_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.menu_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.menu_type_id - b.menu_type_id;
    },
  },
  {
    title: "AdminMenu Type",
    dataIndex: "menu_type_name",
    key: "menu_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.menu_type_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.menu_type_name.length - b.menu_type_name.length;
    },
  },
];
const adminJobColumns = [
  {
    title: "Admin Job ID",
    dataIndex: "job_id",
    key: "job_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.job_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.job_id - b.job_id;
    },
  },
  {
    title: "Admin Job Type",
    dataIndex: "job_title",
    key: "job_title",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.job_title.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.job_title.length - b.job_title.length;
    },
  },
];
const adminDiscountColumns = [
  {
    title: "Admin Discount ID",
    dataIndex: "discount_id",
    key: "discount_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.discount_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.discount_id - b.discount_id;
    },
  },
  {
    title: "Admin Discount Type",
    dataIndex: "discount_name",
    key: "discount_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.discount_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.discount_name.length - b.discount_name.length;
    },
  },
];
const ScmBrandColumn = [
  {
    title: "Scm Brand ID",
    dataIndex: "brand_id",
    key: "brand_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.brand_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.brand_id - b.brand_id;
    },
  },
  {
    title: "Scm Brand Name",
    dataIndex: "brand_name",
    key: "brand_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.brand_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.brand_name - b.brand_name;
    },
  },
];

const ScmFigureColumn = [
  {
    title: "Scm Figure ID",
    dataIndex: "figure_id",
    key: "figure_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.figure_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.figure_id - b.figure_id;
    },
  },
  {
    title: "Scm Figure Name",
    dataIndex: "figure_name",
    key: "figure_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.figure_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.figure_name - b.figure_name;
    },
  },
];

const factorColumns = [
  {
    title: "Factor ID",
    dataIndex: "factor_id",
    key: "factor_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.factor_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.factor_id - b.factor_id;
    },
  },
  {
    title: "Factor Type",
    dataIndex: "factor_name",
    key: "factor_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.factor_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.factor_name.length - b.factor_name.length;
    },
  },
];

const fabricClassColumns = [
  {
    title: "Fabric Class ID",
    dataIndex: "fabric_class_id",
    key: "fabric_class_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_class_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_class_id - b.fabric_class_id;
    },
  },
  {
    title: "Fabric Class",
    dataIndex: "fabric_class_name",
    key: "fabric_class_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fabric_class_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fabric_class_name.length - b.fabric_class_name.length;
    },
  },
];
const fabricClass2Columns = [
  {
    title: "Fabric Class2 ID",
    dataIndex: "fabric_class_id2",
    key: "fabric_class_id2",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_class_id2
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_class_id2 - b.fabric_class_id2;
    },
  },
  {
    title: "Fabric Class 2",
    dataIndex: "fabric_class_name2",
    key: "fabric_class_name2",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fabric_class_name2
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fabric_class_name2.length - b.fabric_class_name2.length;
    },
  },
];
const fabricTypeColumns = [
  {
    title: "Fabric Type ID",
    dataIndex: "fabric_type_id",
    key: "fabric_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_type_id - b.fabric_type_id;
    },
  },
  {
    title: "Fabric Type",
    dataIndex: "fabric_type_name",
    key: "fabric_type_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fabric_type_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fabric_type_name.length - b.fabric_type_name.length;
    },
  },
];
const fabricUsableColumns = [
  {
    title: "Fabric Usable ID",
    dataIndex: "fabric_usable_id",
    key: "fabric_usable_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fabric_usable_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fabric_usable_id - b.fabric_usable_id;
    },
  },
  {
    title: "Fabric Usable",
    dataIndex: "fabric_usable_name",
    key: "fabric_usable_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.fabric_usable_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.fabric_usable_name.length - b.fabric_usable_name.length;
    },
  },
];

const commitCriteriaColumns = [
  {
    title: "Commit_Criteria_ID",
    dataIndex: "commit_criteria_id",
    key: "commit_criteria_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.commit_criteria_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.commit_criteria_id - b.commit_criteria_id;
    },
  },
  {
    title: "Commit Criteria Description",
    dataIndex: "commit_criteria_name",
    key: "commit_criteria_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.city_name !== null) {
        return record.commit_criteria_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if (
        (a.commit_criteria_name !== null) &
        (b.commit_criteria_name !== null)
      ) {
        return a.commit_criteria_name.localeCompare(b.commit_criteria_name);
      }
    },
  },
];

const chargeBackTypeColumns = [
  {
    title: "Charge Back Type ID",
    dataIndex: "charge_back_type_id",
    key: "charge_back_type_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.charge_back_type_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.charge_back_type_id - b.charge_back_type_id;
    },
  },
  {
    title: "Charge Back TypeDescription",
    dataIndex: "item_name",
    key: "item_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.city_name !== null) {
        return record.item_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.item_name !== null) & (b.item_name !== null)) {
        return a.item_name.localeCompare(b.item_name);
      }
    },
  },
];

const payCustomerPaymentCols = [
  {
    title: "Payment Header No",
    dataIndex: "payment_header_no",
    key: "payment_header_no",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_header_no - b.payment_header_no;
    },
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
    key: "payment_date",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_date - b.payment_date;
    },
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Payment Amount",
    dataIndex: "payment_amount",
    key: "payment_amount",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {parseFloat(text)}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_amount.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_amount - b.payment_amount;
    },
  },
  {
    title: "Payment Terms",
    dataIndex: "term_name",
    key: "term_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.term_name - b.term_name;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Debit Account",
    dataIndex: "debit_account_name",
    key: "debit_account_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.debit_account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.debit_account_name - b.debit_account_name;
    },
  },
  {
    title: "AR Account",
    dataIndex: "credit_account_name",
    key: "credit_account_name",
    render: (text, record) => (
      <Link
        to={"/customer-payment/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.credit_account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.credit_account_name - b.credit_account_name;
    },
  },
];

const depositCols = [
  {
    title: "Deposit Header No",
    dataIndex: "deposit_header_no",
    key: "deposit_header_no",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Period Name",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Subsidiary",
    dataIndex: "company_name",
    key: "company_name",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
  {
    title: "Deposit Date",
    dataIndex: "deposit_date",
    key: "deposit_date",
    render: (text, record) => (
      <Link to={"/deposit/edit/" + record.deposit_header_id + "?edit=yes"}>
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.deposit_header_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.deposit_header_id - b.deposit_header_id;
    },
  },
];
const customerRefundCols = [
  {
    title: "Customer Refund No",
    dataIndex: "refund_header_no",
    key: "refund_header_no",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.refund_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.refund_header_no - b.refund_header_no;
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Subsidiary",
    dataIndex: "company_name",
    key: "company_name",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Applied Amount",
    dataIndex: "applied_amount",
    key: "applied_amount",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {parseFloat(text).toFixed(2)}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "A/R Account",
    dataIndex: "ar_account_name",
    key: "ar_account_name",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Date",
    dataIndex: "refund_date",
    key: "refund_date",
    render: (text, record) => (
      <Link
        to={"/customer-refund/edit/" + record.refund_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.refund_date.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.refund_date - b.refund_date;
    },
  },
];
const journalVoucherCols = [
  {
    title: "Voucher Header No",
    dataIndex: "voucher_header_no",
    key: "voucher_header_no",
    render: (text, record) => (
      <Link
        to={"/journal-voucher/edit/" + record.voucher_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.voucher_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.voucher_header_no - b.voucher_header_no;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={"/journal-voucher/edit/" + record.voucher_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },

  {
    title: "Date",
    dataIndex: "voucher_date",
    key: "voucher_date",
    render: (text, record) => (
      <Link
        to={"/journal-voucher/edit/" + record.voucher_header_id + "?edit=yes"}
      >
        {moment(text).format("MM-DD-YYYY")}
        {/* {text} */}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.voucher_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.voucher_date - b.voucher_date;
    },
  },
  {
    title: "Gender",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link
        to={"/journal-voucher/edit/" + record.voucher_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gender_name - b.gender_name;
    },
  },















































  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link
        to={"/journal-voucher/edit/" + record.voucher_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.memo?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
];

const relatedTransactionColumns = [
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
    key: "transaction_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_id - b.transaction_id;
    },
  },

  {
    title: "Transaction No",
    dataIndex: "transaction_no",
    key: "transaction_no",
    render: (text, record) => (
      <a
        href={`
      
      ${record?.transaction_type === "sale order" ? `sale-order?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
            : record?.transaction_type === "Pick Ticket" ? `order-shipment?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
              : record?.transaction_type === "Invoice" ? `invoice?mode=edit&order_header_id=${Order_id}&invoice_header_id=${record.transaction_id}&customer_id=${record.customer_id}`
                : record?.transaction_type === "Return" ? `return-authorization?mode=edit&return_id=${record.transaction_id}&customer_id=${record.customer_id}`
                  : record?.transaction_type === "Adjustment" ? `sale-order-adjustment-new?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
                    : record?.transaction_type === "Item Receipt" ? `item-receipt-new?mode=edit&return_id=${record.transaction_id}`
                      : record?.transaction_type === "Credit Note" ? `credit-note/edit/${record.transaction_id}?edit=yes`
                        : record?.transaction_type === "Payment" ? `customer-payment/edit/${record.transaction_id}?edit=yes`
                          : record?.transaction_type === "Receipt" ? `item-receipt-new?mode=edit&return_id=${record.transaction_id}`
                            : ``
          } 
    
    `}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_no - b.transaction_no;
    },
  },
  {
    title: "Transaction Type",
    dataIndex: "transaction_type",
    key: "transaction_type",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_type
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_type - b.transaction_type;
    },
  },
  {
    title: "Transaction Date",
    dataIndex: "transaction_date",
    key: "transaction_date",
    render: (text, record) => {
      return (
        <>
          {moment(text).format("MM-DD-YYYY")}
        </>
      )
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_date - b.transaction_date;
    },
  },








































  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },

















































































  {
    title: "Term Name",
    dataIndex: "term_name",
    key: "term_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.term_name - b.term_name;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.status.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.status - b.status;
    },
  },
  {
    title: "PT Status",
    dataIndex: "pt_status",
    key: "pt_status",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.pt_status.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.pt_status - b.pt_status;
    },
  },
];

const termForPrintColumns = [
  {
    title: "Print Term ID",
    dataIndex: "term_id",
    key: "term_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,

    onFilter: (value, record) =>
      record.term_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.term_id - b.term_id;
    },
  },
  {
    title: "Print Term Name",
    dataIndex: "term_name",
    key: "term_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.term_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.term_name.length - b.term_name.length;
    },
  },
];

const adminCurrencyColumns = [
  {
    title: "Currency ID",
    dataIndex: "currency_id",
    key: "currency_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.currency_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.currency_id - b.currency_id;
    },
  },
  {
    title: "Currency Name",
    dataIndex: "currency_name",
    key: "currency_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.currency_name.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.currency_name.length - b.currency_name.length;
    },
  },
];

const BillPaymentColumns = [
  {
    title: "Payment Header No",
    dataIndex: "payment_header_no",
    key: "payment_header_no",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_header_no - b.payment_header_no;
    },
  },

  {
    title: "Payee",
    dataIndex: "vendor_name",
    key: "vendor_name",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_name - b.vendor_name;
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.account_name - b.account_name;
    },
  },
  {
    title: "AP Account",
    dataIndex: "ap_account_name",
    key: "ap_account_name",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ap_account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ap_account_name - b.ap_account_name;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Payment Amount",
    dataIndex: "payment_amount",
    key: "payment_amount",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_amount
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_amount - b.payment_amount;
    },
  },
  {
    title: "Subsidiary",
    dataIndex: "company_name",
    key: "company_name",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.company_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.company_name - b.company_name;
    },
  },
  {
    title: "Payment Header Date",
    dataIndex: "payment_date",
    key: "payment_date",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {moment(text).format('MM-DD-YYYY')}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_date - b.payment_date;
    },
  },
];

const BillDirectPaymentColumns = [
  {
    title: "Bill Direct Payment No",
    dataIndex: "direct_payment_header_no",
    key: "direct_payment_header_no",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.direct_payment_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.direct_payment_header_no - b.direct_payment_header_no;
    },
  },
  {
    title: "Payment Header Date",
    dataIndex: "payment_date",
    key: "payment_date",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_date - b.payment_date;
    },
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_id",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_name - b.vendor_name;
    },
  },

  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.account_name - b.account_name;
    },
  },

  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },

  {
    title: "Payment Amount",
    dataIndex: "payment_amount",
    key: "payment_amount",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.payment_amount.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.payment_amount - b.payment_amount;
    },
  },

  {
    title: "Reference No",
    dataIndex: "reference_no",
    key: "reference_no",
    render: (text, record) => (
      <Link
        to={
          "/bill-direct-payments/edit/" +
          record.direct_payment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.reference_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.reference_no - b.reference_no;
    },
  },
];



const fundTransferColumns = [
  {
    title: "Fund Transfer Header No",
    dataIndex: "transfer_header_no",
    key: "transfer_header_no",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transfer_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transfer_header_no - b.transfer_header_no;
    },
  },
  {
    title: "From Account",
    dataIndex: "from_account_name",
    key: "from_account_name",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.from_account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.from_account_name - b.from_account_name;
    },
  },
  {
    title: "To Account",
    dataIndex: "to_account_name",
    key: "to_account_name",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.to_account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.to_account_name - b.to_account_name;
    },
  },
  {
    title: "Subsidiary",
    dataIndex: "company_name",
    key: "company_name",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.company_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.company_name - b.company_name;
    },
  },
  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Fund Transfer Amount",
    dataIndex: "transfer_amount",
    key: "transfer_amount",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transfer_amount
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transfer_amount - b.transfer_amount;
    },
  },
  {
    title: "Fund Transfer Date",
    dataIndex: "transfer_date",
    key: "transfer_date",
    render: (text, record) => (
      <Link to={"/transfer/edit/" + record.transfer_header_id + "?edit=yes"}>
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transfer_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transfer_date - b.transfer_date;
    },
  },
];

const invAdjustmentTransferColumns = [
  {
    title: "Adjustment Header No",
    dataIndex: "adjustment_header_no",
    key: "adjustment_header_no",
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.adjustment_header_no
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.adjustment_header_no - b.adjustment_header_no;
    },
  },
  {
    title: "Transaction Date",
    render: (text, record) => {
      return <> {moment(record.adjust_date).format("MM-DD-YY")}</>
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.adjust_date
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.adjust_date - b.adjust_date;
    },
  },
  {
    title: "Period Name",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.period_name
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.memo
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Adjustment Location",
    dataIndex: "location_name",
    key: "location_name",
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.location_name
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.location_name - b.location_name;
    },
  },
  {
    title: "Adjustment Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link
        to={
          "/inventory-adjustment/edit/" +
          record.adjustment_header_id +
          "?edit=yes"
        }
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.account_name
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    sorter: (a, b) => {
      return a.account_name - b.account_name;
    },
  },




];

const customerCatCols = [
  {
    title: "ID",
    dataIndex: "customer_sales_category_id",
    key: "customer_sales_category_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_sales_category_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_sales_category_id - b.customer_sales_category_id;
    },
  },
  {
    title: "Region",
    dataIndex: "region_name",
    key: "region_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.region_id !== null) {
        return record.region_id
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.region_id !== null) & (b.region_id !== null)) {
        return a.region_id - b.region_id;
      }
    },
  },
  {
    title: "Sales Person",
    dataIndex: "salesperson_name",
    key: "sales_person_id",
    render: (text, record) => (text === " " ? "N/A" : text),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.sales_person_id !== null) {
        return record.sales_person_id
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.sales_person_id !== null) & (b.sales_person_id !== null)) {
        return a.sales_person_id - b.sales_person_id;
      }
    },
  },
  {
    title: "Gender",
    dataIndex: "gender_name",
    key: "gender_id",
    render: (text, record) => (text === " " ? "N/A" : text),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      if (record.sales_person_id !== null) {
        return record.sales_person_id
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    sorter: (a, b) => {
      if ((a.sales_person_id !== null) & (b.sales_person_id !== null)) {
        return a.sales_person_id - b.sales_person_id;
      }
    },
  },
];

const billCreditColumns = [
  {
    title: "Transaction No",
    dataIndex: "bill_credit_header_no",
    key: "bill_credit_header_no",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.bill_credit_header_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.bill_credit_header_no - b.bill_credit_header_no;
    },
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_name - b.vendor_name;
    },
  },

  {
    title: "Posting Period",
    dataIndex: "gl_period_name",
    key: "gl_period_name",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gl_period_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gl_period_name - b.gl_period_name;
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.account_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.account_name - b.account_name;
    },
  },
  {
    title: "Currency",
    dataIndex: "currency_name",
    key: "currency_name",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.currency_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.currency_name - b.currency_name;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount_exp",
    key: "amount_exp",
    render: (text, record) => (
      <Link to={"/bill-credit/edit/" + record.bill_credit_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.amount_exp
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.amount_exp - b.amount_exp;
    },
  },

];

const creditMemoColumns = [
  {
    title: "Credit Memo No",
    dataIndex: "memo_header_no",
    key: "memo_header_no",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.memo_header_no
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.memo_header_no - b.memo_header_no;
    },
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },

  {
    title: "Posting Period",
    dataIndex: "period_name",
    key: "period_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.period_name
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.period_name - b.period_name;
    },
  },
  {
    title: "Payment Term",
    dataIndex: "term_name",
    key: "term_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.term_name
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.term_name - b.term_name;
    },
  },

  {
    title: "Gender",
    dataIndex: "gender_name",
    key: "gender_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.gender_name?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.gender_name - b.gender_name;
    },
  },
  {
    title: "Amount",
    dataIndex: "net_amount",
    key: "net_amount",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        ${numeral(text).format("0,00.00")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.net_amount?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.net_amount - b.net_amount;
    },
  },
  {
    title: "Sale Person",
    dataIndex: "sales_person_name",
    key: "sales_person_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.sales_person_name?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.sales_person_name - b.sales_person_name;
    },
  },
  {
    title: "Account",
    dataIndex: "ar_account_name",
    key: "ar_account_name",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.ar_account_name?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.ar_account_name - b.ar_account_name;
    },
  },
  {
    title: "Memo",
    dataIndex: "memo",
    key: "memo",
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.memo?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.memo - b.memo;
    },
  },
  {
    title: "Brand", dataIndex: "brand_name", key: "brand_name", width: 130,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <Input
          allowClear
          autoFocus
          className="search-pop-style"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => { confirm() }}
          onBlur={() => { confirm() }}
        />
      )
    },
    filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
    onFilter: (value, record) => {
      if (record.brand_name) return record.brand_name.toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record) => (
      <Link to={"/credit-memo/edit/" + record.memo_header_id + "?edit=yes"}>
        {text}
      </Link>
    ),
  },
];

const relatedTransactions = [
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
    key: "transaction_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_id - b.transaction_id;
    },
  },

  {
    title: "Transaction No",
    dataIndex: "transaction_no",
    key: "transaction_no",
    render: (text, record) => (
      <a
        href={`
      ${record?.transaction_type === "Sale Order" ? `/sale-order?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
            : record?.transaction_type === "Pick Ticket" ? `/order-shipment?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
              : record?.transaction_type === "Invoice" ? `/invoice?mode=edit&order_header_id=${Order_id}&invoice_header_id=${record.transaction_id}&customer_id=${record.customer_id}`
                : record?.transaction_type === "Return Auth" ? `/return-authorization?mode=edit&return_id=${record.transaction_id}&customer_id=${record.customer_id}`
                  : record?.transaction_type === "Adjustment" ? `/sale-order-adjustment-new?mode=edit&order_id=${record.transaction_id}&customer_id=${record.customer_id}`
                    : record?.transaction_type === "Customer Payment" ? `/customer-payment/edit/${record.transaction_id}?edit=yes`
                      : record?.transaction_type === "Credit Memo" ? `/credit-memo/edit/${record.transaction_id}?edit=yes`
                        : record?.transaction_type === "Item Receipt" ? `/item-receipt-new?mode=edit&return_id=${record.transaction_id}`
                          : record?.transaction_type === "Credit Note" ? `/credit-note/edit/${record.transaction_id}?edit=yes`
                            : ``
          } 
      
      `}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_no
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_no - b.transaction_no;
    },
  },
  {
    title: "Transaction Type",
    dataIndex: "transaction_type",
    key: "transaction_type",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_type
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_type - b.transaction_type;
    },
  },
  {
    title: "Transaction Date",
    dataIndex: "transaction_date",
    key: "transaction_date",

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.transaction_date
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.transaction_date - b.transaction_date;
    },
  },

  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.customer_name - b.customer_name;
    },
  },
  {
    title: "PO No",
    dataIndex: "reference_no",
    key: "reference_no",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record?.reference_no

        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.reference_no - b.reference_no;
    },
  },


];

const seasonYearColumns = [
  {
    title: "Season ID",
    dataIndex: "season_year_id",
    key: "season_year_id",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.season_year_id.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.season_year_id - b.season_year_id;
    },
  },
  {
    title: "Season Year",
    dataIndex: "season_year",
    key: "season_year",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            allowClear
            autoFocus
            className="pop-search-gen pop-220"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) => {
      return record.season_year.toLowerCase().includes(value.toLowerCase());
    },
    sorter: (a, b) => {
      return a.season_year.localeCompare(b.season_year);
    },
  },
];

const salesPersonListColumns = [
  {
    title: "Employee Id",
    dataIndex: "sales_person_id",
    key: "sales_person_id",
    render: (text, record) => (
      <Link
        to={`/sales-person?mode=edit&salePersonID=${record.sales_person_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.sales_person_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.sales_person_id - b.sales_person_id;
    },
  },
  {
    title: "Hire Date",
    dataIndex: "hire_date",
    key: "hire_date",
    render: (text, record) => (
      <Link
        to={`/sales-person?mode=edit&salePersonID=${record.sales_person_id}`}
      >
        {moment(text).format("MM-DD-YYYY")}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.hireDate
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.hireDate - b.hireDate;
    },
  },
  {
    title: "Name",
    dataIndex: "salesperson_name",
    key: "salesperson_name",
    render: (text, record) => (
      <Link
        to={`/sales-person?mode=edit&salePersonID=${record.sales_person_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.salesperson_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.salesperson_name - b.salesperson_name;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text, record) => (
      <Link
        to={`/sales-person?mode=edit&salePersonID=${record.sales_person_id}`}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.email
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.email - b.email;
    },
  },
  {
    title: "Is salesperson",
    dataIndex: "is_salesperson",
    key: "is_salesperson",
    render: (text, record) => (
      <Link
        to={`/sales-person?mode=edit&salePersonID=${record.sales_person_id}`}
      >
        {text && text == 'true' ? 'Yes' : 'No'}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.is_salesperson
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.is_salesperson - b.is_salesperson;
    },
  },


















































































































































];
const vendorColumns = [
  {
    title: "Vendor Id",
    dataIndex: "vendor_id",
    key: "vendor_id",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>




    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_id - b.vendor_id;
    },
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_name.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_name - b.vendor_name;
    },
  },

  {
    title: "Vendor Code",
    dataIndex: "vendor_code",
    key: "vendor_code",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.vendor_code
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.vendor_code - b.vendor_code;
    },
  },
  {
    title: "Web Address",
    dataIndex: "web_address",
    key: "web_address",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.web_address
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.web_address - b.web_address;
    },
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
    key: "email_address",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.email_address
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.email_address - b.email_address;
    },
  },
  {
    title: "office",
    dataIndex: "office",
    key: "office",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.office.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.office - b.office;
    },
  },
  {
    title: "mobile",
    dataIndex: "mobile",
    key: "mobile",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.mobile.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.mobile - b.mobile;
    },
  },
  {
    title: "fax",
    dataIndex: "fax",
    key: "fax",
    render: (text, record) => (
      <Link to={`/vendor?mode=edit&vendor_id=${record.vendor_id}`}>{text}</Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.fax.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.fax - b.fax;
    },
  },
];

const InterLogColumns = [
  {
    title: "Item Code",
    dataIndex: "item_code",
    key: "item_code",

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.item_code
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.item_code - b.item_code;
    },
  },

  {
    title: "Actual Size Qty",
    dataIndex: "actual_size_qty",
    key: "actual_size_qty",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.actual_size_qty.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.actual_size_qty - b.actual_size_qty;
    },
  },
  {
    title: "Date",
    dataIndex: "date_time",
    key: "date_time",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.date_time
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.date_time - b.date_time;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.description.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.description - b.description;
    },
  },
];

const QueryBuilderColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => (
      <Link
        to={"/bill-payments/edit/" + record.payment_header_id + "?edit=yes"}
      >
        {text}
      </Link>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <>
          <Input
            autoFocus
            allowClear
            className="pop-search-gen pop-170"
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        </>
      );
    },
    filterIcon: (filtered) =>
      filtered ? <FilterFilled /> : <SearchOutlined />,
    onFilter: (value, record) =>
      record.id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => {
      return a.id - b.id;
    },
  },

























































































































































































































































































































































];
export { styleColumns, logColumns, washColumns, washTypeColumns, cutColumns, fabricColumns, fitCategoryColumns, genderColumns, genderCategoryColumns, riseColumns, adminModuleColumns, customerTypeColumns, ProductTypeColumns, productClassColumns, inseamLabelColumns, productStatusColumns, riseLabelColumns, seasonColumns, inseamColumns, sizeColumns, shippingColumns, customerColumns, regionColumns, stateColumns, countryColumns, cityColumns, regionCatColumns, shipViaColumns, shipMethodColumns, freightTermColumns, bergenBillingColumns, courierServiceColumn, saleOrderColumns, menuColumns, userColumns, paymentColumns, taxColumn, chartofaccountColumns, orderShipmentColumns, adminCompanyColumns, invoiceHeaderColumns, accountTypeColumn, orderStatusColumns, pickTicketStatusColumns, shipStatusColumns, accountingPeriod, saleReturnColumns, receiptColumns, returnReasonColumn, salesTargetColumn, adminLocationColumn, creditColumns, itemTransferColumns, vendroCatColumns, billColumns, returnTypeColumns, fabricCompositionColumns, itemTypeColumns, fabricClassColumns, fabricClass2Columns, fabricTypeColumns, fabricUsableColumns, commitCriteriaColumns, chargeBackTypeColumns, payCustomerPaymentCols, paymentTypeColumns, paymentCol, relatedTransactionColumns, termForPrintColumns, adminJobColumns, adminDiscountColumns, factorColumns, adminMenuTypeColumns, paymentMethodTypeColumns, adminCurrencyColumns, barganPaymentTermColumns, BillPaymentColumns, ScmBrandColumn, ScmFigureColumn, customerRefundCols, journalVoucherCols, BillDirectPaymentColumns, fundTransferColumns, depositCols, invAdjustmentTransferColumns, customerCatCols, saleOrder_Adj_Columns, billCreditColumns, creditMemoColumns, relatedTransactions, seasonYearColumns, salesPersonListColumns, vendorColumns, InterLogColumns, QueryBuilderColumns, brandColumns };

