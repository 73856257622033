import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, DatePicker, Form, Select, Spin, Table } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getDropshipTotal } from "../../redux/actions/dropshipSummaryReports.action.js";
import { getAdminLocation } from "../../redux/actions/adminLocation.action.js";
import { getGender } from "../../redux/actions/gender.action.js";

const DropshipTotal = ({ token }) => {
    TabTitle("DropShip Total")
    const userName = ReadCookie("name");
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const { gender, dropshipTotal } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");

    const { onDownload: onDownloadReport } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Dropship Total",
        sheet: "Sheet 1",
    });

    useEffect(() => {
        dispatch(getGender(token, menuId));
        dispatch(getAdminLocation(token, menuId));
    }, [dispatch, menuId, token]);

    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            dispatch(getDropshipTotal(token, values)).then((res) => {
                if (res.type === "GET_DROPSHIP_TOTAL_REPORTS_SUCCESS") {
                    setLoading(false);
                }
            });
        }).catch((error) => {
            setLoading(false);
        })
    }

    const handlePrint = () => {
        window.print();
    };

    const columns = [
        {
            title: "#",
            className: "b_bottom",
            render: (text, record, index) => {
                return index + 1
            },
            width: "20px"
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            sorter: (a, b) => {
                return a.sku.localeCompare(b.sku)
            },
        },
        {
            title: "Inseam",
            dataIndex: "inseam",
            key: "inseam",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            sorter: (a, b) => {
                return a.inseam - b.inseam
            },
        },
        {
            title: "Style",
            dataIndex: "style_name",
            key: "style_name",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            sorter: (a, b) => {
                return a.style_name - b.style_name
            },
        },
        {
            title: "Wash",
            dataIndex: "wash_name",
            key: "wash_name",
            className: " b_left b_bottom",
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>
                );
            },
            sorter: (a, b) => {
                return a.wash_name - b.wash_name
            },
        },
        {
            title: "Moda Operandi, Inc.",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "moda_shipped",
                    key: "moda_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.moda_shipped - b.moda_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "moda_returned",
                    key: "moda_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.moda_returned - b.moda_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "moda_net",
                    key: "moda_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.moda_net - b.moda_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "moda_ret_rate",
                    key: "moda_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.moda_ret_rate - b.moda_ret_rate
                    },
                },
            ]
        },
        {
            title: "Maisonette, Inc.",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "maisonet_shipped",
                    key: "maisonet_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.maisonet_shipped - b.maisonet_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "maisonet_returned",
                    key: "maisonet_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.maisonet_returned - b.maisonet_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "maisonet_net",
                    key: "maisonet_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.maisonet_net - b.maisonet_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "maisonet_ret_rate",
                    key: "maisonet_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.maisonet_ret_rate - b.maisonet_ret_rate
                    },
                },
            ]
        },
        {
            title: "Nordstrom D2C",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "nord_shipped",
                    key: "nord_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.nord_shipped - b.nord_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "nord_returned",
                    key: "nord_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.nord_returned - b.nord_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "nord_net",
                    key: "nord_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.nord_net - b.nord_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "nord_ret_rate",
                    key: "nord_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.nord_ret_rate - b.nord_ret_rate
                    },
                },
            ]
        },
        {
            title: "Neiman Marcus Drop Ship",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "neiman_shipped",
                    key: "neiman_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.neiman_shipped - b.neiman_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "neiman_returned",
                    key: "neiman_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.neiman_returned - b.neiman_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "neiman_net",
                    key: "neiman_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.neiman_net - b.neiman_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "neiman_ret_rate",
                    key: "neiman_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.neiman_ret_rate - b.neiman_ret_rate
                    },
                },
            ]
        },
        {
            title: "Saks Fifth Avenue Dropship",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "saksdrp_shipped",
                    key: "saksdrp_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksdrp_shipped - b.saksdrp_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "saksdrp_returned",
                    key: "saksdrp_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksdrp_returned - b.saksdrp_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "saksdrp_net",
                    key: "saksdrp_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksdrp_net - b.saksdrp_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "saksdrp_ret_rate",
                    key: "saksdrp_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksdrp_ret_rate - b.saksdrp_ret_rate
                    },
                },
            ]
        },
        {
            title: "Saks Fifth Avenue Marketplace",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "saksmrkt_shipped",
                    key: "saksmrkt_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksmrkt_shipped - b.saksmrkt_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "saksmrkt_returned",
                    key: "saksmrkt_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksmrkt_returned - b.saksmrkt_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "saksmrkt_net",
                    key: "saksmrkt_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksmrkt_net - b.saksmrkt_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "saksmrkt_ret_rate",
                    key: "saksmrkt_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}%
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.saksmrkt_ret_rate - b.saksmrkt_ret_rate
                    },
                },
            ]
        },
        {
            title: "Total",
            children: [
                {
                    title: "SHP U",
                    dataIndex: "total_shipped",
                    key: "total_shipped",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.total_shipped - b.total_shipped
                    },
                },
                {
                    title: "RTRN U",
                    dataIndex: "total_returned",
                    key: "total_returned",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.total_returned - b.total_returned
                    },
                },
                {
                    title: "NET U",
                    dataIndex: "total_net",
                    key: "total_net",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return (
                            <>
                                {text}
                            </>
                        );
                    },
                    sorter: (a, b) => {
                        return a.total_net - b.total_net
                    },
                },
                {
                    title: "RET %",
                    dataIndex: "total_ret_rate",
                    key: "total_ret_rate",
                    className: " b_left b_bottom",
                    align: "right",
                    render: (text, record) => {
                        return {
                            props: {
                                style: {
                                    color: text >= 30 ? 'red' : 'inherit' // Set color conditionally
                                }
                            },
                            children: `${(text)}%`
                        };
                    },
                    sorter: (a, b) => {
                        return a.total_ret_rate - b.total_ret_rate
                    },
                },
            ]
        },
    ];

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="report-wrapper-main header_class">
                            <div className="row justify-content-between pb-0">
                                <div className="col-3 d-flex align-items-start  box-space">
                                    <div className="logobrand">
                                        <img id="ImgLogo" alt="logo" src={logo} />
                                    </div>
                                </div>

                                <div className="col-3 box-space right-content-header text-right">
                                    <div className=" d-flex justify-content-end">
                                        <div className=" d-flex justify-content-end printing_class">
                                            <div className="print_icon printing_class">
                                                <i
                                                    id="Image1"
                                                    title="Print"
                                                    alt="Print"
                                                    className="fa-solid fa-print printing_class"
                                                    onClick={handlePrint}
                                                ></i>
                                            </div>
                                            <div className="print_icon printing_class">
                                                {dropshipTotal?.payload?.data ? (
                                                    <input
                                                        type="image"
                                                        name="ImgExcel"
                                                        alt="Export to excel"
                                                        id="ImgExcel"
                                                        title="Export to excel"
                                                        src={xlsx}
                                                        onClick={onDownloadReport}
                                                        style={{
                                                            borderWidth: "0px",
                                                            height: "14px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="image"
                                                        name="ImgExcel"
                                                        alt="Export to excel"
                                                        id="ImgExcel"
                                                        title="Export to excel"
                                                        src={xlsx}
                                                        style={{ borderWidth: "0px", height: "14px" }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <span style={{ fontWeight: "bolder" }}>
                                            Dropship Total
                                            <br />
                                            Rpt # 006
                                            <br />
                                            Login as:
                                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                                {" "}
                                                {userName}
                                            </span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col">
                                    <Form
                                        form={form}
                                        name="control-hooks"
                                        onFinish={onFinish}
                                    >
                                        <div className="main_form row">
                                            <div className="col-12">
                                                <hr className="my-1" />
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="gender_id" className="labels width30">
                                                                From:
                                                            </label>
                                                            <div style={{ marginBottom: "-2px" }} className=" w-100">
                                                                <Form.Item
                                                                    className="formFullWidth"
                                                                    name="from_date"
                                                                    initialValue={moment().startOf("year")}
                                                                >
                                                                    <DatePicker
                                                                        size="small"
                                                                        defaultValue={moment().format("MM-DD-YYYY")}
                                                                        className={"select_Inputs"}
                                                                        format={"MM-DD-YYYY"}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="gender_id" className="labels width30">
                                                                To:
                                                            </label>
                                                            <div style={{ marginBottom: "-2px" }} className=" w-100">
                                                                <Form.Item
                                                                    className="formFullWidth"
                                                                    name="to_date"
                                                                    initialValue={moment().endOf("year")}
                                                                >
                                                                    <DatePicker
                                                                        size="small"
                                                                        defaultValue={moment().format("MM-DD-YYYY")}
                                                                        className={"select_Inputs"}
                                                                        format={"MM-DD-YYYY"}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <label htmlFor="" className="labels width30">Gender:</label>
                                                            <div className=" w-100">
                                                                <Form.Item name="gender_id" initialValue={"All"}>
                                                                    <Select
                                                                        className="formFullWidth"
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        size="small"
                                                                    >
                                                                        <Option value={null}>All</Option>
                                                                        {gender &&
                                                                            gender?.payload?.data.map((it, index) => {
                                                                                return (
                                                                                    <Option key={index} value={it.gender_id}>
                                                                                        {it.gender_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className=" d-flex flex-row align-items-baseline">
                                                            <div style={{ marginTop: "3px", display: "flex", justifyContent: "right" }} d-flex flex-row align-items-baseline >
                                                                <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                                                                    Search
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table_content"
                ref={tableRef} >
                <div >
                    {loading ? (
                        <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin />
                        </div>
                    ) : dropshipTotal?.payload?.data.length ? (

                        <Table
                            style={{ fontSize: '10.3px' }}
                            className="test04 mt-3 sales_target_report_table sales_report_res_table_intransit ledger_print speciality_customer_size sales_report_res_table dropship-table-height"
                            size="small"
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000]
                            }}
                            summary={pageData => {
                                let modaShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.moda_shipped)
                                }, 0);
                                let modaReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.moda_returned)
                                }, 0);
                                let modaNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.moda_net)
                                }, 0);
                                let moisonetShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.maisonet_shipped)
                                }, 0);
                                let moisonetReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.maisonet_returned)
                                }, 0);
                                let moisonetNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.maisonet_net)
                                }, 0);
                                let nordShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.nord_shipped)
                                }, 0);
                                let nordReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.nord_returned)
                                }, 0);
                                let nordNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.nord_net)
                                }, 0);
                                let neimanShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.neiman_shipped)
                                }, 0);
                                let neimanReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.neiman_returned)
                                }, 0);
                                let neimanNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.neiman_net)
                                }, 0);
                                let saksdrpShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksdrp_shipped)
                                }, 0);
                                let saksdrpReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksdrp_returned)
                                }, 0);
                                let saksdrpNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksdrp_net)
                                }, 0);
                                let saksmrktShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksmrkt_shipped)
                                }, 0);
                                let saksmrktReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksmrkt_returned)
                                }, 0);
                                let saksmrktNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.saksmrkt_net)
                                }, 0);
                                let totalShip = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.total_shipped)
                                }, 0);
                                let totalReturn = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.total_returned)
                                }, 0);
                                let totalNet = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.total_net)
                                }, 0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(modaShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(modaReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(modaNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(moisonetShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(moisonetReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(moisonetNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(nordShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(nordReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(nordNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(neimanShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(neimanReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(neimanNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksdrpShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksdrpReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksdrpNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksmrktShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksmrktReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(saksmrktNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(totalShip.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(totalReturn.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="sales_table_summary">
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                                    {numeral(totalNet.toLocaleString("en-US")).format("0,00")}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            scroll={{ x: "max-content" }}
                            dataSource={dropshipTotal?.payload?.data}
                        />
                    ) : (
                        <div
                            style={{
                                backgroundColor: '#CCCCCC',
                                borderWidth: '1pt',
                                borderStyle: 'Dashed',
                                fontWeight: 'bold',
                                height: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No record(s)
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DropshipTotal;

