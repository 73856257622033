import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
    Button,
    Row,
    Col,
    Form,
    Input,
    Checkbox,
    Tabs,
    Divider,
    Spin,
    Image,
} from "antd";
import "../Style/style.css";
import { upcSetup } from "../../redux/actions/upcSetup";
import * as XLSX from "xlsx";
import notification from "../../utils/notification";
import { Colors } from "chart.js";
import style from "tween-one/es/plugins/StylePlugin";
import { TabTitle } from "../../utils/GeneralFunction";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { imageUploaderNu } from "../../redux/actions/imageUploaderNu";
import Tables from "../../components/Table/Tables";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

const { Dragger } = Upload;
const { TabPane } = Tabs;

export default function ImageUploaderNu({ token }) {
    TabTitle("Image Uploader Nu");
    const dispatch = useDispatch();
    const { factor, log, userRights } = useSelector((state) => state);

    const [minimize, setMinimize] = useState(false);

    const [excelFile, setExcelFile] = useState(null);
    const [excelFile1, setExcelFile1] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [response, setResponse] = useState(null);
    const [fileDataMap, setFileDataMap] = useState(new Map());
    const [uploadedFiles, setUploadedFiles] = useState(new Set());
    const [uploadedFileNames, setUploadedFileNames] = useState(new Set()); // Set to track uploaded file names

    const fileType = ["text/csv"];

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                };
            } else {
                setExcelFile(null);
                setExcelFileError("Please select only excel file types");
            }
        } else {
        }
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const getFileId = (file) => {
        return `${file.name}-${file.size}`;
    };
    // const props = {
    //   name: "file",
    //   accept: ".xls,.xlsx", // Only allow Excel files
    //   multiple: false, // Restrict to only one file at a time
    //   maxCount: 1, // Allow only 1 file
    //   action: "https://httpbin.org/post", // Mock endpoint (replace with real one)
    // beforeUpload: (file) => {
    // 	// Check if file is Excel
    // 	const isExcel =
    // 	  file.type === "application/vnd.ms-excel" ||
    // 	  file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    // 	if (!isExcel) {
    // 	  message.error(`${file.name} is not a valid Excel file.`);
    // 	  return false; // Prevent upload if it's not an Excel file
    // 	}

    // 	// Check if the file name has already been uploaded
    // 	if (uploadedFileNames.has(file.name)) {
    // 	  message.error(`${file.name} has already been uploaded.`);
    // 	  return false; // Prevent upload if the file name is a duplicate
    // 	}

    // 	// Check if the file has already been uploaded using its unique fileId (checksum-based)
    // 	const fileId = getFileId(file);
    // 	if (uploadedFiles.has(fileId)) {
    // 	  message.error(`${file.name} has already been uploaded.`);
    // 	  return false; // Prevent upload if file is already uploaded
    // 	}

    // 	return true; // Allow upload if no duplicates are found
    //   },
    //   onChange(info) {
    //     console.log(info, "info");
    //     console.log(excelData, "excelData");
    //     const { fileList } = info; // Extract fileList from info
    //     const currentFile = fileList[fileList.length - 1]; // We assume there’s only one file
    //     // Check if currentFile exists
    //     if (!currentFile) return;
    //     // Track progress using the `percent`
    //     const { name, originFileObj, percent } = currentFile;
    //     console.log(currentFile.status, "currentFile.status");
    //     // Process the file after it is uploaded
    //     const currentFileId = getFileId(currentFile);
    //     const isFileAlreadyProcessed = excelData.some(
    //       (dataItem) => dataItem._fileId === currentFileId
    //     );
    //     if (currentFile.status == "done" && !isFileAlreadyProcessed) {
    //       // setLoading(true);
    //       const reader = new FileReader();
    //       reader.readAsArrayBuffer(originFileObj);
    //       reader.onload = (e) => {
    //         const workbook = XLSX.read(e.target.result, { type: "buffer" });
    //         const worksheetName = workbook.SheetNames[0];
    //         const worksheet = workbook.Sheets[worksheetName];
    //         const data = XLSX.utils.sheet_to_json(worksheet);
    //         const fileId = getFileId(currentFile);
    //         //   console.log(data, 'data');
    //         //   console.log(excelData, 'excelData');
    //         const fileDataWithId = data.map((item) => ({
    //           ...item,
    //           _fileId: fileId, // Associate the data with the unique file ID
    //         }));

    //         setUploadedFiles((prev) => new Set(prev.add(fileId)));
    // 	  setUploadedFileNames((prev) => new Set(prev.add(name))); // Add file name to the set of uploaded file names

    //         setExcelData((prevData) => [...prevData, ...fileDataWithId]); // Append new data to existing
    //         // Store the data in fileDataMap using file name or unique ID as the key
    //         setFileDataMap(
    //           (prevMap) => new Map(prevMap.set(fileId, fileDataWithId))
    //         );
    //         setLoading(false);
    //         setBtnLoading(false);
    //       };
    //     }
    //   },

    //   onRemove(file) {
    //     setBtnLoading(true);

    //     // Use the file's unique ID to find and remove its data
    //     const fileId = getFileId(file); // Generate fileId from file name and size
    //   const fileName = file.name;
    //     // Remove the fileId from the set of uploaded files
    //     setUploadedFiles((prev) => {
    //       const updatedSet = new Set(prev);
    //       updatedSet.delete(fileId); // Remove the fileId from the set
    //       return updatedSet;
    //     });
    //   setUploadedFileNames((prev) => {
    //       const updatedSet = new Set(prev);
    //       updatedSet.delete(fileName); // Remove the file name from the set of uploaded names
    //       return updatedSet;
    //     });

    //     // Remove the file's data from excelData, ensuring we're only removing the data of the removed file
    //     setExcelData((prevData) =>
    //       prevData.filter((dataItem) => dataItem._fileId !== fileId)
    //     );

    //     // Also remove from fileDataMap
    //     setFileDataMap((prevMap) => {
    //       const updatedMap = new Map(prevMap);
    //       updatedMap.delete(fileId); // Remove the file data associated with this fileId
    //       return updatedMap;
    //     });

    //     setBtnLoading(false);
    //   },
    // };

    const maxFiles = 5;
    const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls
    ];

    // Before upload validation for Excel files
    const beforeUpload = (file) => {
        const isExcel = allowedFileTypes.includes(file.type);
        if (!isExcel) {
            message.error("You can only upload Excel files!");
        }
        return isExcel;
    };

    // Handle file changes (add/remove)
    const handleChange = (info) => {
        const { file, fileList: newFileList } = info;

        // Check if the file already exists by name (for duplicates)
        const isDuplicate = newFileList.some(
            (f) => f.name === file.name && f.uid !== file.uid
        );
        if (isDuplicate) {
            message.error(`File with name ${file.name} is already uploaded!`);
            return;
        }

        // Check if file count exceeds maxFiles
        if (newFileList.length > maxFiles) {
            message.error(`You can only upload up to ${maxFiles} files!`);
            return;
        }

        // Update fileList state
        setFileList(newFileList);
    };

    // Custom request function for upload simulation (mock upload)
    const customRequest = ({ file, onSuccess, onError }) => {
        // Simulate a successful file upload
        setBtnLoading(false);
        setTimeout(() => {
            onSuccess();
        }, 1000); // Simulating a 1-second upload
    };
    console.log(fileList, "fileList");
    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (fileList.length > 0) {
            try {
                // Array to hold parsed data from each file
                const allData = [];

                // Helper function to read Excel file and convert to JSON
                const readExcelFile = (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        // Read file as an array buffer
                        reader.readAsArrayBuffer(file);

                        reader.onload = (e) => {
                            try {
                                // Parse the Excel file
                                const workbook = XLSX.read(e.target.result, { type: "buffer" });
                                const worksheetName = workbook.SheetNames[0]; // Get the first sheet
                                const worksheet = workbook.Sheets[worksheetName];

                                // Convert sheet to JSON (array of objects)
                                const data = XLSX.utils.sheet_to_json(worksheet);
                                resolve(data); // Resolve with parsed data
                            } catch (error) {
                                reject(error); // Reject if any error occurs while reading or parsing
                            }
                        };

                        reader.onerror = (error) => {
                            reject(error); // Reject if an error occurs while reading the file
                        };
                    });
                };

                // Process each file in the fileList
                const filePromises = fileList.map((file) =>
                    readExcelFile(file.originFileObj)
                );
                const parsedData = await Promise.all(filePromises); // Wait for all files to be parsed

                // Merge all parsed data into one array
                parsedData.forEach((data) => allData.push(...data));

                // Now, allData contains the merged data from all Excel files
                console.log("Merged Data: ", allData);

                dispatch(imageUploaderNu(token, allData)).then((res) => {
                    setFileList([])
                    setLoading(false);
                    if (res.type === "ADD_NU_IMAGE_SUCCESS") {
                        notification(
                            "success",
                            "File Uploaded Successfully",
                            res?.payload?.data
                        );
                        setResponse(res?.payload?.data);
                    }
                    else {
                        notification(
                            "error",
                            "Error Occurs"
                        );
                    }
                });
                // Dispatch API request with merged data
                // const res = await dispatch(imageUploaderNu(token, allData));

                // setLoading(false);

                // if (res.type === "ADD_NU_IMAGE_SUCCESS") {
                //   notification("success", "File(s) Uploaded Successfully", res?.payload?.data);
                //   setResponse(res?.payload?.data);
                // }
            } catch (error) {
                setLoading(false);
                console.error("Error processing file(s):", error);
                notification("error", "File Upload Failed", error.message);
            }
            // console.log("done");
            // dispatch(imageUploaderNu(token, fileList)).then((res) => {
            //   setLoading(false);
            //   if (res.type === "ADD_NU_IMAGE_SUCCESS") {
            //     notification(
            //       "success",
            //       "File Uploaded Successfully",
            //       res?.payload?.data
            //     );
            //     setResponse(res?.payload?.data);
            //   }
            // });
        }
        // if (excelData !== null) {
        //   console.log("done");
        //   dispatch(imageUploaderNu(token, excelData)).then((res) => {
        //     setLoading(false);
        //     if (res.type === "ADD_NU_IMAGE_SUCCESS") {
        //       notification(
        //         "success",
        //         "File Uploaded Successfully",
        //         res?.payload?.data
        //       );
        //       setResponse(res?.payload?.data);
        //     }
        //   });
        // } else {
        // }
    };
    //   console.log(response);
    const updatedColumns = [
        {
            title: "S.No",
            align: "left",
            width: 70,
            render: (text, record, index) => {

                return <>{(record.s_no)}</>;
            },
        },
        {
            title: "Parent Sku",
            dataIndex: "item_code",
            key: "item_code",
            render: (text, record) => (
                <Link
                    target={"blank"}
                    to={"product-info?mode=edit&product_id=" + record.item_id}
                >
                    {text}
                </Link>
            ),
        },
        {
            title: "Image",
            dataIndex: "image_url",
            key: "image_url",
            render: (text, record) => (
                <div className="image-nu">
                    <Image src={text} />
                </div>
            ),
        },
    ];

    return (
        <div className="styleContainer">
            <div className="styleBody h-100 w-50" style={{ margin: "0 auto" }}>
                <PageHeader
                    title={"Image Uploader Nu"}
                    minimize={setMinimize}
                    check={minimize}
                />
                <div style={{ padding: "10px" }}>
                    {/* <text >Click here to <a href={window.location.origin + '/Template_UPC_Upload.csv'} download="Template_UPC_Upload.csv" style={{ color: 'blue', textDecoration: "underline", fontSize: "16px" }} >Download</a> Bulk UPC Upload Template</text> */}
                </div>
                {/* <text>Click here to <a href={window.location.origin + '/Template_UPC_Upload.csv'} download="Template_UPC_Upload.csv" style={{ color: 'blue', textDecoration: "underline" }} >Download</a> Bulk UPC Upload Template</text>
				<div className="firstSectionRo">
					<Form.Item >
						<Input type="file" ClassName="form-control" onChange={handleFile} />
						{excelFileError && <div className="text-danger">{excelFileError}</div>}
					</Form.Item>
					<Button type="submit" className="btn btn-success" style={{ marginTop: 5 + 'px' }} onClick={handleSubmit}>Submit</Button>
				</div> */}
                <div className="mx-2">
                    {/* <Dragger
            // customRequest={dummyRequest}
            {...props2}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload..
            </p>
          </Dragger> */}
                    <Upload.Dragger
                        name="file"
                        multiple
                        customRequest={customRequest}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        fileList={fileList}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Max {maxFiles} files.
                        </p>
                    </Upload.Dragger>
                </div>
                {loading && (
                    <div
                        // className="w-50"
                        style={{
                            textAlign: "center",
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Spin tip="Uploading..." />
                    </div>
                )}

                <Button
                    disabled={loading || btnLoading || fileList.length == 0}
                    style={{ width: "fit-content" }}
                    //   htmlType="submit"
                    type="primary"
                    className="ant-btn ant-btn-primary mrpx-20 m-2"
                    onClick={handleSubmit}
                >
                    <span className="px-2">Upload</span>
                </Button>
                {/* Display the uploaded and parsed Excel data */}
                {/* {excelData && (
        <div style={{ marginTop: '20px' }}>
          <h3>Uploaded Excel Data:</h3>
          <pre>{JSON.stringify(excelData, null, 2)}</pre>
        </div>
      )} */}

                {!loading && response && (
                    <div className="tabs mx-2">
                        <CSVLink
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            filename={"Image-Uploader-Nu.csv"}
                            data={response}
                            onClick={() => { }}
                        >
                            Download Excel
                        </CSVLink>
                        <Tables
                            scrollX={"50rem"}
                            type="combination"
                            scroll={250}
                            perPage={10}
                            source={response && response.sort()}
                            col={updatedColumns}
                            paginate
                        // onPageChange={handlePageChange}
                        // pagination={{
                        //   current: currentPage,
                        //   pageSize: 10,
                        //   onShowSizeChange: (page) => handlePageChange(page),
                        // }}
                        // onChange= {()=> {console.log('runnn');}}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
