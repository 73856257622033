import { Button, Form, Input, Select, DatePicker, Table, InputNumber, Spin, Tooltip, Progress } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import numeral from "numeral";
import moment from "moment";

import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getBookSummary } from "../../redux/actions/bookSummary.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import PageHeader from "../../components/PageHeader/PageHeader";
import { getGender } from "../../redux/actions/gender.action";
import { getRegion } from "../../redux/actions/region.action";
import { getStyle } from "../../redux/actions/style";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
import { companyID, currencySymbol } from "../../utils/constants";
import { getBrands } from "../../redux/actions/brand.action";
import filterHelper from "../../utils/filterHelper";
const { Option } = Select;

const BookSummary = () => {
  TabTitle("Booked Summary")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const [bookSummaryList, setBookSummaryList] = useState([]);
  const tableRef = useRef(null);
  const companyId = ReadCookie("defaultCompany");
  const { gender, brand, region, adminLocation, style, salePersonForm, defaultCompany, paymentterm, customerTypes, getBookSummaryReducer, saleOrderFormData } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getStyle(token, menuId));
    dispatch(loadSalePerson(token));
    dispatch(getDefaultCompany(token));
    dispatch(getPaymentTerm(token));
    dispatch(getCustomerType(token));
    dispatch(getAdminLocation(token, menuId));
    dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
    dispatch(getBrands(token));
  }, [dispatch, menuId, token]);




  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Order #',
      dataIndex: 'order_header_no',
      key: 'order_header_id',
      render: (text, record) => (
        <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
          to={"/sale-order?mode=edit&order_id=" + record.order_header_id + "/&customer_id/" + record.customer_id + "?edit=yes"}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Order Dates",
      dataIndex: 'order_date',
      key: 'order_date',
      render: (text, record) => {
        return <>{moment(record.order_date).format("MM-DD-YY")}</>
      },
      ...filterHelper('order_date', 'date')
    },
    {
      title: "PO #",
      dataIndex: "reference_no",
      key: "reference_no",
      ...filterHelper('reference_no')
    },
    {
      title: "Start Date",
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text, record) => {
        return <>{moment(record.start_date).format("MM-DD-YY")}</>
      },
      ...filterHelper('start_date', 'date')
    },
    {
      title: "Cancel Date",
      dataIndex: 'cancel_date',
      key: 'cancel_date',
      render: (text, record) => {
        return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
      },
      ...filterHelper('cancel_date', 'date')
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      ...filterHelper('customer_name')
    },
    {
      title: "Billing Addressee",
      dataIndex: "billing_addressee",
      key: "billing_addressee",
      ...filterHelper('billing_addressee')
    },
    {
      title: "Payment Term",
      dataIndex: "payment_term",
      key: "payment_term",
      ...filterHelper('payment_term')
    },
    {
      title: 'Quantity',
      render: (text, record) => {
        return <>{parseFloat(record.quantity).toFixed(0)}</>
      },
      align: "right",
    },
    {
      title: "Net Amount",
      dataIndex: "net",
      key: "net",
      render: (text, record) => {
        return <>{currencySymbol} {numeral(record.net).format("0,0.00")}</>
      },
      align: "right",
      ...filterHelper('net','num')
    },
    {
      title: "CIT Status",
      dataIndex: "factor_decision",
      key: "factor_decision",
      ...filterHelper('factor_decision')
    },
    {
      title: "Source",
      dataIndex: "source_type",
      key: "source_type",
      ...filterHelper('source_type')
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...filterHelper('status')
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      ...filterHelper('location_name')
    },
    {
      title: "Store",
      dataIndex: "store_id",
      key: "store_id",
      ...filterHelper('store_id','num')
    },
    {
      title: "DC #",
      dataIndex: "dc_no",
      key: "dc_no",
      ...filterHelper('dc_no','num')
    },
    {
      title: "Department code",
      dataIndex: "department_code",
      key: "department_code",
      ...filterHelper('department_code','num')
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      ...filterHelper('gender_name')
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      ...filterHelper('brand_name')
    },
    {
      title: "Sale person Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      ...filterHelper('salesperson_name')
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
      ...filterHelper('region_name')
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      ...filterHelper('state_name')
    },
    {
      title: 'Resale Expiry',
      dataIndex: 'resaleexpiry',
      key: 'resaleexpiry',
      render: (text, record) => {
        return <>{text === "N/A" ? text : moment(record.resaleexpiry).format("MM-DD-YYYY")}</>
      }
    },
    {
      title: "Resale #",
      dataIndex: "resaleno",
      key: "resaleno",
      ...filterHelper('resaleno','num')
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
      ...filterHelper('memo')
    }
  ];


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Order #": v.order_header_no,
          "Order Date": moment(v.order_date).format("MM-DD-YY"),
          "PO #": v.reference_no,
          "Start Date": moment(v.start_date).format("MM-DD-YY"),
          "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
          "Customer": v.customer_name,
          "Billing Addressee": v.billing_addressee,
          "Payment Term": v.payment_term,
          "Quantity": parseFloat(v.quantity).toFixed(0),
          "Currency": currencySymbol,
          "Net Amount": numeral(v.net).format("0,0.00"),
          "CIT Status": v.factor_decision,
          "Source": v.source_type,
          "Status": v.status,
          "Loaction": v.location_name,
          "Store": v.store_id,
          "DC #": v.dc_no,
          "Department code": v.department_code,
          "Gender": v.gender_name,
          "Brand": v.brand_name,
          "Sale person Name": v.salesperson_name,
          "Region": v.region_name,
          "State": v.state_name,
          "Resale Expiry": moment(v.resaleexpiry).format("MM-DD-YY"),
          "Resale #": v.resaleno,
          "Memo": v.memo,
        };
      })
    )
  }


















  const onFinish = async () => {
    setLoading(true);

    await form.validateFields().then((values) => {

      dispatch(getBookSummary(token, values)).then((res) => {

        setBookSummaryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
        setLoading(false);

      }).catch((error) => {
        setLoading(false);
      });
    });
  };

  const totalQuantity = () => {
    let sumQty = bookSummaryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.quantity);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = bookSummaryList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.net);
    }, 0);
    return sumAmount;
  }



  return (
    <div className="styleContainer">
      <div className="styleBodyPendingSummary">
        <PageHeader
          title={"Book Summary"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="cutomer_category"
                          label="Customer Category"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            defaultValue={form.getFieldValue().cutomer_category}
                            className="styleInput w-100"
                          >
                            <Option value={null}>All</Option>
                            {
                              customerTypes?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                )
                              })
                            }
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>


                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="order_no"
                            label="Order #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <InputNumber
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="customer"
                            label="Customer"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="billing_addressee"
                            label="Billing addressee"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="po_no"
                            label="PO #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}

                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="sale_person"
                          label="Sale Person"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterSort={(optionA, optionB) =>

                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().sale_person}
                          >
                            <Option value={null}>All</Option>
                            {
                              salePersonForm?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={val.sales_person_id} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="region"
                          label="Region"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"

                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().region}
                          >
                            <Option value={null}>All</Option>
                            {
                              region?.payload?.data.map((val) => {
                                return (

                                  <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="gender"
                          label="Gender"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().gender}
                          >
                            <Option value={null}>All</Option>
                            {
                              gender?.payload?.data.map((val) => {
                                return (
                                  <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="terms"
                          label="Terms"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"

                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().terms}
                          >
                            <Option value={null}>All</Option>
                            {
                              paymentterm?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.term_id}>{val.term_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="source"
                          label="Source"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                        >
                          <Input
                            size='small'
                            className="styleInput"
                          />
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="store"
                            label="Store #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="subsidary"
                          label="Subsidiary"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={companyID}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            defaultValue={form.getFieldValue().subsidary}
                            className="styleInput w-100"
                          >
                            <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="status"
                          label="Status"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            loading={saleOrderFormData.loading}
                            defaultValue={form.getFieldValue().orderStatus}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option value={null}>All</Option>
                            {saleOrderFormData?.payload?.orderStatusDetail.map(
                              (item) => {
                                return (
                                  <Option
                                    key={item.order_status_id}
                                    value={item.order_status_id}
                                  >
                                    {item.status_name}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="sku"
                            label="Sku #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="style"
                          label="Style"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().style}
                          >
                            <Option value={null}>All</Option>
                            {
                              style?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.style_id}>{val.style_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          name="location"
                          label="Location"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            autoComplete={"nope"}
                            loading={adminLocation.loading}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option value={null}>All</Option>
                            {
                              adminLocation?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={val.location_id} value={val.location_id}>
                                    {val.location_name}
                                  </Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="dc"
                            label="DC #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="dept"
                            label="Department code"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="memo"
                            label="Memo"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="brand_id"
                          label="Brand"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().brand_id}
                          >
                            <Option value={null}>All</Option>
                            {
                              brand?.payload?.data.map((val) => {
                                return (
                                  <Option key={val.brand_id} value={val.brand_id}>{val.brand_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm mb-2 mrpx-10"
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>


          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>

        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            bookSummaryList && bookSummaryList.length > 0 ? (
              <>
                {/* <FunctionalComponentWithHook /> */}
                <Table
                  title={() =>
                    <CSVLink
                      filename={"booked-summary.csv"}
                      data={excelData}
                      onClick={() => {

                      }}
                    >
                      Download Excel
                    </CSVLink>
                  }
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"




                  scroll={{
                    x: "max-content"
                  }}

                  rowKey="order_header_id"
                  dataSource={bookSummaryList}
                  columns={columns}
                  loading={getBookSummaryReducer.loading}

                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 100,
                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                  }}
                  summary={(pageData) => {
                    let sumQty = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let Net = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.net
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {sumQty.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {currencySymbol} {numeral(Net).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : {currencySymbol}{numeral(totalSum()).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </>) : <div
                style={{
                  backgroundColor: "#CCCCCC",
                  borderWidth: "1pt",
                  borderStyle: "Dashed",
                  fontWeight: "bold",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
              No record(s)
            </div>
          )
        }
      </div>

    </div>
  )
}

export default BookSummary;