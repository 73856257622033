import React, { useState, useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../utils/readCookie";
import ProtectedRoute from "../utils/protected.route";
import GenderCategory from "../screens/GenderCategory/GenderCategory";
import ProductStatus from "../screens/ProductStatus/PorductStatus";
import ProductClass from "../screens/ProductClass/ProductClass";
import SaleOrderList from "../screens/SaleOrder/SaleOrderList";
import InseamLabel from "../screens/InseamLabel/InseamLabel";
import PaymentType from "../screens/PaymentType/PaymentType";
import FitCategory from "../screens/FitCategory/FitCategory";
import SalePerson from "../screens/salesPerson/salePerson";
import RiseLabel from "../screens/RiseLabel/RiseLabel";
import SaleOrder from "../screens/SaleOrder/SaleOrder";
import Dashboard from "../screens/Dashboard/Dashboard";
import ItemType from "../screens/ItemType/ItemType";
import NotFound from "../screens/NotFound/NotFound";
import CustomerNewForm from "../screens/CustomerNew/components/Form/CustomerNewForm";
import WashType from "../screens/WashType/WashType";
import Product from "../screens/Product/Product";
import Gender from "../screens/Gender/Gender";
import Inseam from "../screens/Inseam/Inseam";
import Season from "../screens/Season/Season";
import Fabric from "../screens/Fabric/Fabric";
import Login from "../screens/Login/Login";
import Style from "../screens/Style/Style";
import Rise from "../screens/Rise/Rise";
import Wash from "../screens/Wash/Wash";
import Size from "../screens/Size/Size";
import Cut from "../screens/Cut/Cut";
import Region from "../screens/Region/Region";
import State from "../screens/State/State";
import Country from "../screens/Country/Country";
import City from "../screens/City/City";
import RegionSale from "../screens/RegionSale/RegionSale";
import ShipVia from "../screens/Ship/ShipVia";
import ShippingMethod from "../screens/ShippingMethod/ShippingMethod";
import FreightTerm from "../screens/FreightTerm/FreightTerm";
import BerganBilling from "../screens/BerganBilling/BerganBilling";
import CourierService from "../screens/CourierService/CourierService";
import AdminUser from "../screens/User/AdminUser";
import UserRights from "../screens/UserRights/UserRights";
import ProductList from "../screens/Product/ProductList";
import AdminMenu from "../screens/AdminMenu/AdminMenu";
import Paymentterm from "../screens/Paymentterm/Paymentterm";
import TaxSetup from "../screens/AdminTax/TaxSetup";
import ChartOfAccount from "../screens/ChartOfAccount/ChartofAccount";
import InvoiceList from "../screens/InvoiceNew/InvoiceListNew";
import Company from "../screens/UserCompany/Company";
import AccountType from "../screens/AccountType/AccountType";
import ReturnReason from "../screens/ReturnReason/ReturnReason";
import AdminLocation from "../screens/AdminLocation/AdminLocation";
import SalesTarget from "../screens/SalesTarget/SalesTarget";
import OrderStatus from "../screens/orderStatus/OrderStatus";
import PickTicketStatus from "../screens/PickTicketStatus/PickTicketStatus";
import ShipStatus from "../screens/ShipStatus/ShipStatus";
import FiscalYear from "../screens/FiscalYear/FiscalYear";
import ItemReceiptList from "../screens/Receipt/receiptList";
import ProductDetail from "../screens/Product/ProductDetail"
import Vendor from "../screens/Vendor/vendor";
import ItemCreditNoteList from "../screens/Credit/creditList";
import ItemCredit from "../screens/Credit/ItemCredit";
import ItemTransfer from "../screens/ItemTransfer/ItemTransfer";
import ItemTransferList from "../screens/ItemTransfer/ItemTransferList";
import VendorCategory from "../screens/VendorCategory/vendorCategory";
import Bill from "../screens/Bill/Bill";
import BillList from "../screens/Bill/BillList";
import ProductReportList from "../screens/ProducList/ProductReportList";
import OrderReprot from "../screens/OrderReports/OrderReport";
import ReturnType from "../screens/AdminReturnType/ReturnType";
import SaleOrderAdjustmentNew from "../screens/saleOrderAdjustmentNew/saleOrderAdjustmentNew";
import FabricComposition from "../screens/FabricComposition/FabricComposition";
import FabricClass from "../screens/FabricClass.js/FabricClass";
import FabricClass2 from "../screens/FabricClass2/FabricClass2";
import FabricType from "../screens/FabricType/FabricType";
import FabricUsable from "../screens/FabricUsable/FabricUsable";
import ChargeBackType from "../screens/ChargeBackType/ChargeBackType";
import ChargeBackTypeList from "../screens/ChargeBackType/ChargeBackTypeList";
import CommitCriteria from "../screens/CommitCriteria/CommitCriteria";
import CustomerPayment from "../screens/CustomerPayment/CustomerPayment";
import PaymentHeaderList from "../screens/CustomerPayment/PaymentHeaderList";
import InventoryAdjustment from "../screens/InventoryAdjustmentNew/InventoryAdjustmentNew";
import InventoryAdjustmentNewList from "../screens/InventoryAdjustmentNew/InventoryAdjusmentNewList";
import PendingPOD from "../screens/Pending POD/PendingPOD";
import SalesTargetRetport from "../screens/SalesTargetReport/SalesTargetReport";
import AdminModule from "../screens/AdminModule/AdminModule";
import CustomerType from "../screens/CustomerType/CutomerType";
import TermForPrint from "../screens/TermForPrint/TermForPrint";
import BusinessDashboard from "../screens/BusinessDashboard/BusinessDashboard";
import OmsBooking from "../screens/OMSBooking/OmsBooking";
import AdminJob from "../screens/AdminJob/AdminJob";
import AdminDiscount from "../screens/AdminDiscount/AdminDiscount";
import Factor from "../screens/Factor/factor";
import AdminMenuType from "../screens/AdminMenuType/AdminMenuType";
import PaymentMethodType from "../screens/PaymentMethodType/paymentMethodType";
import AdminCurrency from "../screens/AdminCurrency/AdminCurrency";
import BarganPaymentTerm from "../screens/BaraganPaymentTerm/BarganPaymentTerm";
import ScmBrand from "../screens/ScmBrand/ScmBrand";
import SalesReport from "../screens/SalesReport/SalesReport";
import NewSalesReport from "../screens/SalesReport/NewSalesReport.js";
import BillPaymentList from "../screens/BillPayment/BillPaymentList";
import OmsPendingOrder from "../screens/OMSPendingOrder/OmsPendingOrder";
import CustomerRefund from "../screens/CustomerRefund/CustomerRefund";
import customerRefundList from "../screens/CustomerRefund/CustomerRefundList";
import Distributor from "../screens/Distributor/Distributor";
import OmsShipping from "../screens/OMSShipping/OmsShipping";
import OmsPendingPOD from "../screens/OMSPendingPOD/OmsPendingPOD";
import ScmFigure from "../screens/ScmFigure/ScmFigure";
import JournalVoucher from "../screens/JournalVoucher/JournalVoucher";
import JournalVoucherList from "../screens/JournalVoucher/JournalVoucherList";
import BillPayment from "../screens/BillPayment/BillPayment"
import OmsReturn from "../screens/OMSReturn/OmsReturn";
import OmsStockDetails from "../screens/OMSStockDetails/OmsStockDetails";
import BillDirectPayment from "../screens/BillDirectPayment/BillDirectPayment";
import BillDirectPaymentList from "../screens/BillDirectPayment/BillDirectPaymentList";
import Payment from "../screens/Payment/Payment";
import FundTransfer from "../screens/FundTransfer/FundTransfer";
import FundTransferList from "../screens/FundTransfer/FundTransferList";
import Deposit from "../screens/Deposit/Deposit";
import DepositList from "../screens/Deposit/DepositList";
import CustomerPaymentLedger from "../screens/CustomerPaymentLedger/CustomerPaymentLedger"
import POLookup from "../screens/QuickPOLookUP/POLookup";
import OmsStockSummaryInitial from "../screens/OMSStockSummary/omsStocksummaryInitial";
import OmsStockSummary from "../screens/OMSStockSummarySKU/OmsStockSummary";
import OmsStockSummaryStyle from "../screens/OMSStockSummary/OmsStockSummary";
import OrderReport from "../screens/OrderReport/OrderReport";
import ProductType from "../screens/ProductType/ProductType";
import CustomerCategory from "../screens/CustomerCategory/CustomerCategory";
import SalesReturnReport from "../screens/SalesReturnReport/SalesReturnReport";
import SalesinvoiceReport from "../screens/SaleInvoiceReport/SalesInvoiceReport";
import UpcSetup from "../screens/UpcSetup/upcSetup";
import PendingStyleWise from "../screens/PendingStyleWiseReport/PendingStyleWise";
import BookedStyleWise from "../screens/BookedStyleWiseReport/BookedStyleWise";
import ShippedStyleWise from "../screens/ShippedStyleWiseReport/ShippedStyleWise";
import QueryTool from "../screens/QueryTool/QueryTool";
import BookSummary from "../Reports/Booked/BookSummary";
import B_Grade_Stock from "../screens/B Grade Stock/BGradeStock";
import ProductReport from "../screens/ProductReport/ProductReport";
import PendingSummary from "../screens/PendingSummary/PendingSummary";
import ShippedSummary from "../screens/ShippedSummary/ShippedSummary";
import ReturnSummary from "../screens/ReturnSummary/ReturnSummary";
import PODStyleWise from "../screens/PODStyleWise/PODStyleWise";
import BookDetail from "../Reports/Booked/BookDetail";
import PendingDetailReport from "../screens/Pending Order Report/PendingDetailReport";
import ShippedDetailReport from "../screens/ShippedReports/ShippedDetailReport.js";
import orderShipmentNew from "../screens/OrderShipmentNew/OrderShipmentNew";
import orderShipmentListNew from "../screens/OrderShipmentNew/OrderShipmentListNew";
import PickTicketSummary from "../screens/PickTicket Summary/PickTicketSummary";
import RelocateItems from "../screens/RelocateItems/RelocateItems";
import ReturnAuthorization from "../screens/SaleReturnNew/SaleReturn.js";
import ReturnAuthList from "../screens/SaleReturnNew/ReturnAuthList.js";
import SaleOrderAdjustmentNewList from "../screens/saleOrderAdjustmentNew/saleOrderAdjustmentNewList";
import CreditNote from "../screens/CreditNote/CreditNote";
import UpdateTool from "../screens/UpdateTool/UpdateTool";
import PendingPodSummary from "../screens/PendingPodSummary/PendingPodSummary"
import ItemReceiptNew from "../screens/Receipt copy/ItemReceiptNew";
import ItemReceiptListNew from "../screens/Receipt copy/ItemReceiptList";
import PtSummary from "../screens/PickTicketSummary/PtSummary";
import OrderAdjustmentReport from "../screens/OrderAdjustmentReport/OrderAdjustmentReport";
import OrderAdjDetail from "../screens/OrderAdjDetail/OrderAdjDetail";
import InvoiceNew from "../screens/InvoiceNew/InvoiceNew";
import PtDetail from "../screens/PtDetail/PtDetail"
import CreditMemoReport from "../screens/CreditMemoReports/CreditMemoReport"
import CreditOpenInvoicedetailReport from "../screens/CreditOpenInvoicedetailReport/CreditOpenInvoiceDetailReport"
import SalesAdjustmentReport from "../screens/SalesAdjustmentReport/SalesAdjustmentReport"
import CustomerNewList from "../screens/CustomerNew/CustomerNewList";
import CustomerNew from "../screens/CustomerNew/CustomerNew";
import ControlSheet from "../screens/ControlSheet/ControlSheet";
import ReturnStyleWise from "../screens/ReturnStyleWiseReport/ReturnStyleWise";
import CustomerInfo from "../screens/CustomerInfoReport/CustomerInfo"
import ProductInfo from "../screens/ProductInfoReport/ProductInfo"
import ReturnDetailReport from "../screens/ReturnDetailReport/ReturnDetailReport";
import BillCredit from "../screens/BillCredit/BillCredit";
import BillCreditList from "../screens/BillCredit/BillCreditList";
import CreditMemo from "../screens/CreditMemo/CreditMemo";
import CreditMemoList from "../screens/CreditMemo/CreditMemoList";
import customerRefundReport from "../screens/customerRefundReport/customerRefundReport"

import PaymentReceiptReport from "../screens/paymentReceiptReport/paymentReceiptReport"
import IntransitReport from "../screens/Intransit/IntransitReport";
import ProductionReport from "../screens/Production/productionReport";
import "./main.css";
import Distributor2 from "../screens/NewReport/Distributor2";
import OTSDashboard from "../screens/OTSDashboard/OTSDashboard"
import PendingPodSummaryAr from "../screens/PendingPodSummaryAr/PendingPodSummaryAr";
import CustomerBalances from "../screens/CustomerBalances/CustomerBalances";
import InternationalOrder from "../screens/InternationalOrder/InternationalOrder";
import CanadaOrder from "../screens/CanadaOrder/CanadaOrder";

import PurchaseBill from "../screens/PurchaseBill/purchaseBill";
import CitReport from "../screens/CITReport/citReport";
import CreditPrintReport from "../screens/CreditPrintReport/CreditPrintReport";
import DlShowroom from "../screens/dlShowroom/dlShowroom";
import InventoryReport from "../screens/InventoryReport/InventoryReport";
import DscoInvoiceReport from "../screens/DscoInvoiceReport/DscoInvoiceReport";
import PODDetail from "../screens/PODDetail/PODDetail";
import SeasonYear from "../screens/SeasonYear/SeasonYear";
import UpcSetup1 from "../screens/UpcSetup/uploadProps";
import SalesPersonList from "../screens/salesPerson/salesPersonList";
import InvoiceEmail from "../screens/InvoiceEmail/InvoiceEmail";
import ProductInfoNew from "../screens/ProductInfo/ProductInfoNew";
import UpdateProductInfo from "../screens/UpdateProductInfo/UpdateProductInfo";
import VendorList from "../screens/Vendor/VendorList";
import ImediateOtsBySize from "../screens/OMSStockSummarySKU/OmsStockSummary2";
import LocationWise from "../screens/LocationWise/LocationWise";
import CommissionReport from "../screens/CommissionReport/CommissionReport";
import ProductInfoBySize from "../screens/ProductInfoBySize/ProductInfoBySize";
import GoodRequired from "../screens/GoodRequired/GoodRequired";
import ArAgingSummary from "../screens/ArAgingSummary/ArAgingSummary";
import SkuLookUpNu from "../screens/SKULookUpNu/SkuLookUpNu";
import PendingSkuLookUp from "../screens/PendingSKULookUp/PendingSkuLookUp";
import InterLog from "../screens/InterLog/InterLog";
import CustomerPaymentLedgerAR from "../screens/CustomerPaymentLedgerAR/CustomerPaymentLedgerAR";
import TotalOTSbySize from "../screens/TotalOTSbySize/TotalOTSbySize";
import SelectQuery from "../screens/SelectQuery/SelectQuery";
import InvoicePending from "../screens/InvoicePending/InvoicePending";
import IncomeStatement from "../screens/IncomeStatement/IncomeStatement";
import Routine from "../screens/Routines/Routine";
import RoutineOrder from "../screens/RoutineOrder/RoutineOrder";
import StockUpdate from "../screens/StockUpdate/StockUpdate.js";
import ImediateOtsBySize2 from "../screens/ImediateOtsBySize2/ImediateOtsBySize2";
import ImediateOtsBySize3 from "../screens/ImediateOtsBySize3/ImediateOtsBySize3";
import IncomeDetail from "../screens/incomeDetail/incomeDetail";
import VenderLedger from "../screens/VenderLedger/VenderLedger";
import BalanceSheet from "../screens/BalanceSheet/BalanceSheet";
import UPCReport from "../screens/UPCReport/UPCReport";
import BalanceDetail from "../screens/BalanceDetail/balanceDetail";
import DoNotCommit from "../screens/DoNotCommit/DoNotCommit";
import NewOrderReport from "../screens/OrderReport/NewOrderReport";
import ControlSheetLog from "../screens/ControlSheetLog/controlSheetLog";
import ProductToBergen from "../screens/ProductToBergen/productToBergen";
import PaymentLedgerSummaryReport from "../screens/PaymentLedgerSummaryReport/PaymentLedgerSummaryReport";
import AsnToBergen from "../screens/AsnToBergen/AsnToBergen.js";
import OMSStyleReport from "../screens/OMSStyleReport/OMSStyleReport";
import OMSWashReport from "../screens/OMSWashReport/OMSWashReport";
import ProductImageReport from "../screens/ProductByImage/ProductImageReport";
import UploadEdi from "../screens/BergenEdi/BergenEdi";
import BergenEdi from "../screens/BergenEdi/BergenEdi";
import StitchFixEdi from "../screens/StitchFixEdi/StitchFixEdi";
import StyleLedger from "../screens/StyleLedger/StyleLedger.js";
import TotalOts from "../screens/TotalOts/TotalOts";
import PtToBergen from "../screens/PtToBergen/ptToBergen";
import ChartofAccountReport from "../screens/ChartofAccountReport/ChartofAccountReport.js";
import OmsStyleReportNew from "../screens/OmsStyleReportNew/OmsStyleReportNew.jsx";
import OmsWashReportNew from "../screens/OmsWashReportNew/OmsWashReportNew.jsx"
import StyleShot from "../screens/StyleShot/StyleShot.jsx";
import StyleDistro from "../screens/StyleDistro/StyleDistro.jsx";
import VendorSummary from "../screens/VendorSummary/VendorSummary.js";
import Expense from "../screens/Expense/Expense.js";
import RAToBergen from "../screens/RAToBergen/raToBergen";
import ARInventory from "../screens/ARInventory/ARInventory.jsx";
import RAToBergen2 from "../screens/RAToBergen/raToBergen2";
import InventoryValuation from "../screens/InventoryValuation/InventoryValuation.jsx";
import JournalLedger from "../screens/JournalLedger/JournalLedger.jsx";
import GeneralLedger from "../screens/GeneralLedger/GeneralLedger.jsx";
import OmsStockSummaryAts from "../screens/OMSStockSummaryATS/OmsStockSummaryAts.jsx";
import PickTicketDetail from "../screens/PickTicketDetail/PickTicketDetail.js";
import StockStyleWise from "../screens/StockStyleWise/stockStyleWise.js";
import CustomerPaymentLedgerNew from "../screens/CustomerPaymentLedgerNew/customerPaymentLedgerNew.js";
import BalanceSheetNew from "../screens/BalanceSheetNew/BalanceSheetNew.jsx";
import APAgingDetail from "../screens/APAgingDetail/APAgingDetail.jsx";
import IncomeStatementNew from "../screens/IncomeStatementNew/IncomeStatementNew.jsx";
import JournalLedgerNew from "../screens/JournalLedgerNew/JournalLedgerNew.jsx";
import AvalaraInvoice from "../screens/AvalaraInvoice/AvalaraInvoice.jsx";
import SalesTargetRetportDashboard from "../screens/SalesTargetReportDashboard/SalesTargetReportDashboard.jsx";
import CheckPay from "../screens/CheckPay/CheckPay.js";
import CrmSkuWise from "../screens/CrmSkuWise/CrmSkuWise.js";
import CRMReport from "../screens/CRMReport/crmReport.js";
import TransactionLock from "../screens/TransactionLock/TransactionLock.js";
import SalesTargetReportSalePerson from "../screens/SalesTargetReportSalePerson/SalesTargetReportSalePerson.jsx";
import ChargeBackDetail from "../screens/ChargeBackDetail/chargeBackDetail.js";
import ForgetPassword from "../screens/ForgetPassword/ForgetPassword.js";
import { Button, Modal } from "antd";
import UpcToItemCode from "../screens/UpcToItemCode/UpcToItemCode.js";
import { getRights } from "../redux/actions/userRights.action.js";
import NetShippedStyleWise from "../screens/NetShippedStyleWise/netShippedStyleWise.jsx";
import CustomerHold from "../screens/CustomerHold/CustomerHold.js";
import MissingUpc from "../screens/MissingUpc/missingUpc.jsx";
import CustomerPaymentLedgerDateWise from "../screens/CustomerPaymentLedgerDateWise/CustomerPaymentLedgerDateWise.js";
import PendingOrderAvailabilityBySize from "../screens/PendingOrderAvailabilityBySize/PendingOrderAvailabilityBySize.js";
import NegativeOrderOtsWise from "../screens/NegativeOrderOtsWise/NegativeOrderOtsWise.js";
import NegativeOrderOtsWise2 from "../screens/NegativeOrderOtsWise/NegativeOrderOtsWise2.js";
import Brand from "../screens/Brand/Brand.js";
import SkuExistsErp from "../screens/SkuExistsErp/SkuExistsErp.js";
import OnlineOrdersSummary from "../screens/OnlineOrdersSummary/OnlineOrdersSummary.js";
import SpecialityByRegion from "../screens/SpecialityByRegion/SpecialityByRegion.jsx";
import SpecialityByState from "../screens/SpecialityByState/SpecialityByState.jsx";
import SpecialityByCustomer from "../screens/SpecialityByCustomer/SpecialityByCustomer.jsx";
import EcommerceByStyle from "../screens/EcommerceByStyle/EcommerceByStyle.jsx";
import DLBulk from "../screens/DLBulk/DLBulk.jsx";
import WWBulk from "../screens/WWBulk/WWBulk.jsx";
import ProductMigration from "../screens/ProductMigration/ProductMigration.jsx";
import SpecialtyByStyle from "../screens/SpecialtyByStyle/SpecialtyByStyle.js";
import RoutineLog from "../screens/RoutineLog/routineLog.jsx";
import ImageUploaderNu from "../screens/ImageUploaderNu/imageUploaderNu.js";
import ScheelsEdi from "../screens/ScheelsEdi/ScheelsEdi.js";
import Profile from "../screens/Profile/Profile.js";
import LeaderBoard from "../screens/LeaderBoard/LeaderBoard.js";
import CustomerRefundReportNew from "../screens/customerRefundReportNew/customerRefundReportNew.js";
import ControlSheet2 from "../screens/ControlSheet/ControlSheet2.js";
import InvoiceEmailTest from "../screens/InvoiceEmail/InvoiceEmailTest";
import ProductionFinished from "../screens/ProductionFinished/ProductionFinished.js";
import OMSStockStyleWiseSummary from "../screens/OMSStockStyleWiseSummary/OMSStockStyleWiseSummary";
import ProductImageUrl from "../screens/ProductImageUrl/ProductImageUrl.js";
import CustomerbyPO from "../screens/CustomerbyPO/CustomerbyPO.js";
import DropshipSKU from "../screens/DropshipSKU/DropshipSKU.js";
import DropshipTotal from "../screens/DropshipTotal/DropshipTotal.js";

export default function Routes() {
	const [token, setToken] = useState();
	const { auth, menus } = useSelector((state) => state);
	const [routeName, setRouteName] = useState([]);
	const [isloading, setisLoading] = useState();
	const history = useHistory();
	const [location, setLocation] = useState(window.location.pathname);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalValue, setModalValue] = useState(null)
	const [userRights, setUserRights] = useState();
	const dispatch = useDispatch();


	useEffect(() => {
		setLocation(window.location.pathname);
	}, [location]);

	useEffect(() => {
		setToken(ReadCookie("token"));

	}, [auth]);

	useLayoutEffect(() => {
		if (token) {
			dispatch(getRights(token, 1356)).then((res) => {
				if (res.type === "GET_USER_RIGHTS_SUCCESS") {
					if (res?.payload) {
						setUserRights(res?.payload)
					} else {
						setUserRights(null);
					}
				}
			});
		}
	}, [token])

	useLayoutEffect(() => {
		setisLoading(true);
		menus?.payload?.map((first) => {
			first?.children?.map((second) => {
				if (second.children?.length > 0) {
					second?.children?.map((third) => {
						if (third.col_runtime_name) {
							setRouteName((item) => [...item, third.col_runtime_name, "/paymentType"]);
						}
					});
				}
			});
		});
		setisLoading(false);
	}, [menus]);

	const routeChecker = (tofind, component, routeName) => {
		if (routeName.includes(tofind)) {
			return component;
		} else if (!routeName.includes(tofind) && routeName.length !== 0) {
			return NotFound;
		} else {
			return Dashboard;
		}
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		if (modalValue === "t") {
			setIsModalVisible(true);
		}
	}, [modalValue]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const displayModal = (() => {
		return (
			<Modal title="Change Your Password"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<p>Please change your password.</p>
				<Link style={{ color: "#0d6efd", textDecoration: "underline" }} to={"forget-password"} onClick={() => handleCancel()}
				>{"Click Here to reset Password"}</Link>
				{/* setIsModalVisible(false) */}

			</Modal >
		)
	})



	return (
		<>
			<Router history={history}>
				{token && modalValue ? displayModal() : ""}
				<Switch>
					<Route
						exact
						path="/login"
						render={() => {
							if (token) {
								if (userRights !== null) {
									if (userRights?.can_view === false) {
										return <Redirect to="/" />
									} else if (userRights?.can_view === true) {
										localStorage.setItem("TEST", true);
										return <Redirect to="/sales-target-report-saleperson" />
									}
								} else {
									return <Redirect to="/" />
								}
							} else {
								return <Login setModalValue={setModalValue} modalValue={modalValue} />;
							}
						}}
					/>
					<ProtectedRoute path="/sku-lookup" component={Distributor2} />
					<ProtectedRoute path="/pending-sku-lookup" component={PendingSkuLookUp} />
					<ProtectedRoute path="/sku-lookup-nu" component={SkuLookUpNu} />
					<ProtectedRoute exact path="/" component={Dashboard} />
					<ProtectedRoute exact path="/style" component={routeChecker("/style", Style, routeName)} />
					{/* <ProtectedRoute exact path="/product-info" component={routeChecker("/product-info", Product, routeName)} /> */}
					<ProtectedRoute exact path="/product-info" component={routeChecker("/product-info", ProductInfoNew, routeName)} />
					<ProtectedRoute exact path="/product-info/edit/:id" component={routeChecker("/product-info", Product, routeName)} />
					<ProtectedRoute exact path="/product-info/getAll" component={routeChecker("/product-info", ProductList, routeName)} />
					<ProtectedRoute exact path="/customer-info" component={routeChecker("/customer-info", CustomerNew, routeName)} />
					<ProtectedRoute exact path="/customer-New-Info/add" key={'customer-New-Info'} component={CustomerNewForm} />
					<ProtectedRoute exact path="/customer-New-Info/edit/:id?" key={'customer-New-Info-edit'} component={CustomerNew} />
					<ProtectedRoute exact path="/sales-person" component={routeChecker("/sales-person", SalePerson, routeName)} />
					<ProtectedRoute exact path="/region" component={routeChecker("/region", Region, routeName)} />
					<ProtectedRoute exact path="/state" component={routeChecker("/state", State, routeName)} />
					<ProtectedRoute exact path="/country" component={routeChecker("/country", Country, routeName)} />
					<ProtectedRoute exact path="/city" component={routeChecker("/city", City, routeName)} />
					<ProtectedRoute exact path="/cat" component={routeChecker("/cat", RegionSale, routeName)} />
					<ProtectedRoute exact path="/shipvia" component={routeChecker("/shipvia", ShipVia, routeName)} />
					<ProtectedRoute exact path="/shipmethod" component={routeChecker("/shipmethod", ShippingMethod, routeName)} />
					<ProtectedRoute exact path="/freightterm" component={routeChecker("/freightterm", FreightTerm, routeName)} />
					<ProtectedRoute exact path="/berganbill" component={routeChecker("/berganbill", BerganBilling, routeName)} />
					<ProtectedRoute exact path="/courierservice" component={routeChecker("/courierservice", CourierService, routeName)} />
					<ProtectedRoute exact path="/adminuser" component={routeChecker("/adminuser", AdminUser, routeName)} />
					<ProtectedRoute path="/userrights" component={routeChecker("/userrights", UserRights, routeName)} />
					<ProtectedRoute path="/paymentterm" component={routeChecker("/paymentterm", Paymentterm, routeName)} />
					<ProtectedRoute path="/admin-menu" component={routeChecker("/admin-menu", AdminMenu, routeName)} />
					<ProtectedRoute path="/tax-setup" component={routeChecker("/tax-setup", TaxSetup, routeName)} />
					<ProtectedRoute path="/chartofacct" component={routeChecker("/chartofacct", ChartOfAccount, routeName)} />
					<ProtectedRoute path="/company" component={Company} />
					<ProtectedRoute path="/order-status" component={OrderStatus} />
					<ProtectedRoute path="/pt-status" component={PickTicketStatus} />
					<ProtectedRoute path="/ship-status" component={ShipStatus} />
					<ProtectedRoute path="/acct-period" component={FiscalYear} />
					<ProtectedRoute path="/accounttype" component={routeChecker("/accounttype", AccountType, routeName)} />
					<ProtectedRoute exact path="/wash" component={routeChecker("/wash", Wash, routeName)} />
					<ProtectedRoute exact path="/wash-type" component={routeChecker("/wash-type", WashType, routeName)} />
					<ProtectedRoute exact path="/fit-cut" component={routeChecker("/fit-cut", Cut, routeName)} />
					<ProtectedRoute exact path="/fabric" component={routeChecker("/fabric", Fabric, routeName)} />
					<ProtectedRoute exact path="/fit-category" component={routeChecker("/fit-category", FitCategory, routeName)} />
					<ProtectedRoute exact path="/gender" component={routeChecker("/gender", Gender, routeName)} />
					<ProtectedRoute exact path="/gender-cat" component={routeChecker("/gender-cat", GenderCategory, routeName)} />
					<ProtectedRoute exact path="/paymentType" component={routeChecker("/paymentType", PaymentType, routeName)} />
					<ProtectedRoute exact path="/rise" component={routeChecker("/rise", Rise, routeName)} />
					<ProtectedRoute exact path="/inseamlabel" component={routeChecker("/inseamlabel", InseamLabel, routeName)} />
					<ProtectedRoute exact path="/productclass" component={routeChecker("/productclass", ProductClass, routeName)} />
					<ProtectedRoute exact path="/productstatus" component={routeChecker("/productstatus", ProductStatus, routeName)} />
					<ProtectedRoute exact path="/itemtype" component={routeChecker("/itemtype", ItemType, routeName)} />
					<ProtectedRoute exact path="/rise-label" component={routeChecker("/rise-label", RiseLabel, routeName)} />
					<ProtectedRoute exact path="/season" component={routeChecker("/season", Season, routeName)} />
					<ProtectedRoute exact path="/inseam" component={routeChecker("/inseam", Inseam, routeName)} />
					<ProtectedRoute exact path="/size" component={routeChecker("/size", Size, routeName)} />
					<ProtectedRoute path="/return-reason" component={routeChecker("/return-reason", ReturnReason, routeName)} />
					<ProtectedRoute path="/location" component={routeChecker("/location", AdminLocation, routeName)} />
					<ProtectedRoute path="/sales-targets" component={routeChecker("/sales-targets", SalesTarget, routeName)} />
					<ProtectedRoute path="/order-status" component={OrderStatus} />
					<ProtectedRoute path="/vendor" component={routeChecker("/vendor", Vendor, routeName)} />
					<ProtectedRoute path="/vendorCategory" component={routeChecker("/vendorCategory", VendorCategory, routeName)} />
					{/* <ProtectedRoute exact path="/order-shipment/list" component={OrderShipmentList} />
				<ProtectedRoute exact path="/order-shipment/edit/:id/:customerCode?/:shipHeaderNo?" component={OrderShipment} /> */}
					<ProtectedRoute path="/invoice-list" component={InvoiceList} />
					<ProtectedRoute path="/item-receipt/list" component={ItemReceiptList} />
					<ProtectedRoute path="/credit-note/edit/:saleReturnId?" component={ItemCredit} />
					<ProtectedRoute path="/credit-note/list" component={ItemCreditNoteList} />
					<ProtectedRoute exact path="/item-transfer/list" key={'item-transefer-list'} component={ItemTransferList} />
					<ProtectedRoute exact path="/item-transfer/add" key={'item-transfer-add'} component={ItemTransfer} />
					<ProtectedRoute exact path="/item-transfer/edit/:id?" key={'item-transfer-edit'} component={ItemTransfer} />
					<ProtectedRoute exact path="/inventory-adjustment" key={'inventory-adjustment'} component={InventoryAdjustment} />
					<ProtectedRoute exact path="/inventory-adjustment/edit/:id?" key={'inventory-adjustment-edit'} component={InventoryAdjustment} />
					<ProtectedRoute exact path="/inventory-adjustment/list" key={'inventory-adjustment-list'} component={InventoryAdjustmentNewList} />
					<ProtectedRoute exact path="/bill/list" component={BillList} />
					<ProtectedRoute path="/vendor-info/getAll" component={VendorList} />
					<ProtectedRoute exact path="/bill/add" key={'bill-add'} component={Bill} />
					<ProtectedRoute exact path="/bill/edit/:id?" component={Bill} />
					<ProtectedRoute exact path="/vendor/edit/:id?" component={Vendor} />
					<ProtectedRoute path="/product-list" component={ProductReportList} />
					<ProtectedRoute path="/order-report" component={routeChecker("/order-report", OrderReprot, routeName)} />
					<ProtectedRoute exact path="/return-type" component={routeChecker("/return-type", ReturnType, routeName)} />
					<ProtectedRoute exact path="/fabric-composition" component={routeChecker("/fabric-composition", FabricComposition, routeName)} />
					<ProtectedRoute exact path="/fabric-class-2" component={routeChecker("/fabric-class-2", FabricClass2, routeName)} />
					<ProtectedRoute exact path="/fabric-class" component={routeChecker("/fabric-class", FabricClass, routeName)} />
					<ProtectedRoute exact path="/fabric-type" component={routeChecker("/fabric-type", FabricType, routeName)} />
					<ProtectedRoute exact path="/fabric-usable" component={routeChecker("/fabric-usable", FabricUsable, routeName)} />
					<ProtectedRoute exact path="/charge-back-type/edit/:id" component={routeChecker("/charge-back-type", Product, routeName)} />
					<ProtectedRoute exact path="/charge-back-type/getAll" component={routeChecker("/charge-back-type", ChargeBackTypeList, routeName)} />
					<ProtectedRoute exact path="/commit-criteria" component={routeChecker("/commit-criteria", CommitCriteria, routeName)} />
					<ProtectedRoute exact path="/charge-back-type" component={routeChecker("/charge-back-type", ChargeBackType, routeName)} />
					<ProtectedRoute exact path="/customer-payment" component={routeChecker("/customer-payment", CustomerPayment, routeName)} />
					<ProtectedRoute exact path="/customer-payment/add" key={'customer-payment-add'} component={CustomerPayment} />
					<ProtectedRoute exact path="/customer-payment/edit/:id?" key={'customer-payment-edit'} component={CustomerPayment} />
					<ProtectedRoute exact path="/Payment-Header/list" key={'payment-header-list'} component={PaymentHeaderList} />
					<ProtectedRoute path="/Pending-POD" component={routeChecker("/Pending-POD", PendingPOD, routeName)} />
					<ProtectedRoute path="/paymenttype" component={routeChecker("/paymenttype", PaymentType, routeName)} />
					<ProtectedRoute path="/sales-target-report" component={routeChecker("/sales-target-report", SalesTargetRetport, routeName)} modalValue={modalValue} />
					{/* <ProtectedRoute path="/sales-target-report-dashboard" component={routeChecker("/sales-target-report-dashboard", SalesTargetRetportDashboard, routeName)} /> */}

					<ProtectedRoute exact path="/admin-module" component={routeChecker("/admin-module", AdminModule, routeName)} />
					<ProtectedRoute exact path="/customertype" component={routeChecker("/customertype", CustomerType, routeName)} />
					<ProtectedRoute exact path="/ProductDetail" component={routeChecker("/ProductDetail", ProductDetail, routeName)} />
					<ProtectedRoute exact path="/term-for-print" component={routeChecker("/term-for-print", TermForPrint, routeName)} />
					<ProtectedRoute exact path="/admin-job" component={routeChecker("/admin-job", AdminJob, routeName)} />
					<ProtectedRoute exact path="/admin-discount" component={routeChecker("/admin-discount", AdminDiscount, routeName)} />
					<ProtectedRoute exact path="/factor" component={routeChecker("/factor", Factor, routeName)} />
					<ProtectedRoute exact path="/admin-menu-type" component={routeChecker("/admin-menu-type", AdminMenuType, routeName)} />
					<ProtectedRoute exact path="/paymethodtype" component={routeChecker("/paymethodtype", PaymentMethodType, routeName)} />
					<ProtectedRoute exact path="/admin-currency" component={routeChecker("/admin-currency", AdminCurrency, routeName)} />
					<ProtectedRoute exact path="/admin-bargan-Payment-Term" component={routeChecker("/admin-bargan-Payment-Term", BarganPaymentTerm, routeName)} />
					<ProtectedRoute exact path="/bill-payments/list" key={'bill-payments-list'} component={BillPaymentList} />
					<ProtectedRoute exact path="/bill-payments/add" key={'bill-payments-add'} component={BillPayment} />
					<ProtectedRoute exact path="/bill-payments/edit/:id?" key={'bill-payments-edit'} component={BillPayment} />
					<ProtectedRoute exact path="/credit-memo/list" component={CreditMemoList} />
					<ProtectedRoute exact path="/credit-memo/add" key={'credit-memo-add'} component={CreditMemo} />
					<ProtectedRoute exact path="/credit-memo/edit/:id?" component={CreditMemo} />
					{/* DL REGION WISE REPORTS */}
					<ProtectedRoute exact path="/return-region-wise" component={routeChecker("/return-region-wise", OmsReturn, routeName)} />
					<ProtectedRoute exact path="/shipped-region-wise" component={routeChecker("/shipped-region-wise", OmsShipping, routeName)} />
					<ProtectedRoute exact path="/pod-region-wise" component={routeChecker("/pod-region-wise", OmsPendingPOD, routeName)} />
					<ProtectedRoute exact path="/pending-region-wise" component={routeChecker("/pending-region-wise", OmsPendingOrder, routeName)} />
					<ProtectedRoute exact path="/booked-region-wise" component={routeChecker("/booked-region-wise", OmsBooking, routeName)} />

					<ProtectedRoute exact path="/scm_brand" component={routeChecker("/scm_brand", ScmBrand, routeName)} />
					<ProtectedRoute path="/business-dashboard" component={BusinessDashboard} />
					<ProtectedRoute exact path="/customer-refund" component={routeChecker("/customer-refund", CustomerRefund, routeName)} />
					<ProtectedRoute exact path="/customer-refund/add" key={'customer-refund-add'} component={CustomerRefund} />
					<ProtectedRoute exact path="/customer-refund/edit/:id?" key={'customer-refund-edit'} component={CustomerRefund} />
					<ProtectedRoute exact path="/customer-refund/list" key={'customer-refund-list'} component={customerRefundList} />
					<ProtectedRoute exact path="/journal-voucher" component={routeChecker("/journal-voucher", JournalVoucher, routeName)} />
					<ProtectedRoute exact path="/journal-voucher/add" key={'journal-voucher-add'} component={JournalVoucher} />
					<ProtectedRoute exact path="/journal-voucher/edit/:id?" key={'journal-voucher-edit'} component={JournalVoucher} />
					<ProtectedRoute exact path="/journal-voucher/list" key={'journal-voucher-list'} component={JournalVoucherList} />
					<ProtectedRoute exact path="/scm-figure" component={routeChecker("/scm-figure", ScmFigure, routeName)} />
					<ProtectedRoute path="/distributor" component={Distributor} />
					<ProtectedRoute path="/oms-stock-summary" component={OmsStockSummary} />
					<ProtectedRoute path="/immediate-ots-by-size" component={ImediateOtsBySize} />
					<ProtectedRoute path="/immediate-ots-by-size-2" component={ImediateOtsBySize2} />
					<ProtectedRoute path="/immediate-ots" component={ImediateOtsBySize3} />
					<ProtectedRoute path="/total-ots" component={TotalOts} />
					<ProtectedRoute path="/oms-stock-detail" component={OmsStockDetails} />
					<ProtectedRoute exact path="/payment" component={routeChecker("/payment", Payment, routeName)} />
					<ProtectedRoute exact path="/transfer/add" key={'transfer-add'} component={FundTransfer} />
					<ProtectedRoute exact path="/transfer/edit/:id?" key={'transfer-edit'} component={FundTransfer} />
					<ProtectedRoute exact path="/transfer/list" key={'transfer-list'} component={FundTransferList} />
					<Route path="/sales-report" component={SalesReport} />
					<Route path="/new-sales-report" component={NewSalesReport} />
					<ProtectedRoute exact path="/bill-direct-payments/list" key={'bill-direct-payments-list'} component={BillDirectPaymentList} />
					<ProtectedRoute exact path="/bill-direct-payments/add" key={'bill-direct-payments-add'} component={BillDirectPayment} />
					<ProtectedRoute exact path="/bill-direct-payments/edit/:id?" key={'bill-direct-payments-edit'} component={BillDirectPayment} />
					<ProtectedRoute exact path="/deposit/add" key={'deposit-add'} component={Deposit} />
					<ProtectedRoute exact path="/deposit/edit/:id?" key={'deposit-edit'} component={Deposit} />
					<ProtectedRoute exact path="/deposit/list" key={'deposit-list'} component={DepositList} />
					<ProtectedRoute exact path="/customer-category" key={'customer-category'} component={CustomerCategory} />
					<ProtectedRoute path="/customer-payment-ledger" key={'customer-payment-ledger'} component={CustomerPaymentLedger} />
					<ProtectedRoute path="/oms-stock-style-inventory" key={'oms-stock-style-inventory'} component={OmsStockSummaryInitial} />
					<ProtectedRoute path="/oms-stock-style-inventory-detail" key={'oms-stock-style-inventory-detail'} component={OmsStockSummaryStyle} />
					<ProtectedRoute path="/product-type" key={'product-type'} component={ProductType} />
					<ProtectedRoute path="/quick-po-lookup" component={POLookup} />
					<Route path="/Order-shipment-report" component={OrderReport} />
					<Route path="/New-order-shipment-report" component={NewOrderReport} />
					<ProtectedRoute exact path="/customer-category" component={routeChecker("/customer-category", CustomerCategory, routeName)} />
					<ProtectedRoute exact path="/upc-setup" component={routeChecker("/upc-setup", UpcSetup, routeName)} />
					<ProtectedRoute exact path="/b-grade-stock" component={routeChecker("/b-grade-stock", B_Grade_Stock, routeName)} />
					<ProtectedRoute exact path="/bill-credit/list" component={BillCreditList} />
					<ProtectedRoute exact path="/bill-credit/add" key={'bill-credit'} component={BillCredit} />
					<ProtectedRoute exact path="/bill-credit/edit/:id?" component={BillCredit} />
					{/* DL PK Reports */}
					<ProtectedRoute path="/shipped-summary" component={ShippedSummary} />
					<ProtectedRoute path="/shipped-detail" component={ShippedDetailReport} />
					<ProtectedRoute path="/booked-detail-report" component={BookDetail} />
					<ProtectedRoute path="/booked-order-summary-report" component={BookSummary} />
					<ProtectedRoute path="/pending-order-summary-report" component={PendingSummary} />
					<ProtectedRoute path="/pending-detail-summary-report" component={PendingDetailReport} />
					<ProtectedRoute path="/return-summary" component={ReturnSummary} />
					<Route path="/sales-return" component={SalesReturnReport} />
					<Route path="/sales-inovice-report" component={SalesinvoiceReport} />

					{/* DL US Reports */}
					{/* Style Wise Reports */}
					<ProtectedRoute path="/booked-style-wise" component={BookedStyleWise} />
					<ProtectedRoute path="/pending-style-wise" component={PendingStyleWise} />
					<ProtectedRoute path="/shipped-style-wise" component={ShippedStyleWise} />
					<ProtectedRoute path="/pod-style-wise" component={PODStyleWise} />
					<ProtectedRoute path="/return-style-wise" component={ReturnStyleWise} />
					<ProtectedRoute path="/product-report" component={ProductReport} />
					<ProtectedRoute path="/sale-order" component={SaleOrder} />
					<ProtectedRoute path="/sale-order-list" component={SaleOrderList} />
					<ProtectedRoute path="/order-shipment" component={orderShipmentNew} />
					<ProtectedRoute path="/order-shipment-new-list" component={orderShipmentListNew} />
					<ProtectedRoute path="/sale-order-adjustment-new" component={SaleOrderAdjustmentNew} />
					<ProtectedRoute path="/sale-order-adjustment-new-list" component={SaleOrderAdjustmentNewList} />
					<ProtectedRoute exact path="/customer-info/list" key={'customer-info-list'} component={CustomerNewList} />
					<ProtectedRoute path="/create-pick-ticket" component={PickTicketSummary} />
					<Route path="/query-tool" component={QueryTool} />
					<ProtectedRoute path="/update-tool" component={UpdateTool} />
					<ProtectedRoute path="/relocate-items" component={RelocateItems} />
					<ProtectedRoute path="/return-authorization" component={ReturnAuthorization} />
					<ProtectedRoute path="/return-authorization-list" component={ReturnAuthList} />
					<ProtectedRoute path="/Pending-POD-summary-report" component={PendingPodSummary} />
					<ProtectedRoute path="/Pending-POD-summary-ar" component={PendingPodSummaryAr} />
					<ProtectedRoute path="/item-receipt" component={ItemReceiptNew} />
					<ProtectedRoute path="/item-receipt-new" component={ItemReceiptNew} />
					<ProtectedRoute path="/item-receipt-list-new" component={ItemReceiptListNew} />
					<ProtectedRoute path="/credit-note" component={CreditNote} />
					<ProtectedRoute path="/pick-ticket-summary-report" component={PtSummary} />
					<ProtectedRoute path="/order-adjustment-report" component={OrderAdjustmentReport} />
					<ProtectedRoute path="/order-adjustment-detail-report" component={OrderAdjDetail} />
					<ProtectedRoute path="/invoice" component={InvoiceNew} />
					<ProtectedRoute path="/pick-ticket-detail-report" component={PtDetail} />
					<Route path="/setup-new" component={UpcSetup1} />
					<Route path="/credit-memo-report" component={CreditMemoReport} />
					<ProtectedRoute path="/credit-open-invoice-detail" component={CreditOpenInvoicedetailReport} />
					<Route path="/sale-adjustment-report" component={SalesAdjustmentReport} />
					<ProtectedRoute path="/control-sheet" component={ControlSheet} />
					<ProtectedRoute path="/customer-information" component={CustomerInfo} />
					<ProtectedRoute path="/product-information" component={ProductInfo} />
					<ProtectedRoute path="/return-detail" component={ReturnDetailReport} />
					<ProtectedRoute path="/customer-payment-report" component={customerRefundReport} />
					<ProtectedRoute path="/customer-refund-report" component={CustomerRefundReportNew} />
					<ProtectedRoute path="/intransit-Report" component={IntransitReport} />
					<ProtectedRoute path="/payment-receipt-report" component={PaymentReceiptReport} />
					<ProtectedRoute path="/production-Report" component={ProductionReport} />
					<ProtectedRoute path="/ots-dashboard" component={OTSDashboard} />
					<ProtectedRoute path="/customer-balances" component={CustomerBalances} />
					<ProtectedRoute path="/international-order" component={InternationalOrder} />
					<ProtectedRoute path="/canada-order" component={CanadaOrder} />
					<ProtectedRoute path="/purchase_bill" component={PurchaseBill} />
					<ProtectedRoute path="/cit-report" component={CitReport} />
					<Route path="/credit-print-report" component={CreditPrintReport} />
					<ProtectedRoute path="/dl-Showroom-Report" component={DlShowroom} />
					<ProtectedRoute path="/inventory-report" component={InventoryReport} />
					<ProtectedRoute path="/dsco-invoice-report" component={DscoInvoiceReport} />
					<ProtectedRoute path="/pod-detail-report" component={PODDetail} />
					<ProtectedRoute exact path="/season-year" component={routeChecker("/season-year", SeasonYear, routeName)} />
					<ProtectedRoute exact path="/sales-person/list" key={'sales-person-list'} component={SalesPersonList} />
					<ProtectedRoute exact path="/invoice-email" component={InvoiceEmail} />
					<ProtectedRoute exact path="/update-product" component={UpdateProductInfo} />
					<ProtectedRoute path="/location-wise-report" component={LocationWise} />
					<ProtectedRoute path="/commission-report" component={CommissionReport} />
					<ProtectedRoute path="/product-information-by-size" component={ProductInfoBySize} />
					<ProtectedRoute path="/good-required" component={GoodRequired} />
					<ProtectedRoute path="/Ar-aging-summary" component={ArAgingSummary} />
					<ProtectedRoute path="/inter-log" component={InterLog} />
					<ProtectedRoute path="/total-ots-by-size" component={TotalOTSbySize} />
					<ProtectedRoute path="/customer-payment-ledger-AR" key={'customer-payment-ledger-AR'} component={CustomerPaymentLedgerAR} />
					<ProtectedRoute path="/select-query" key={'customer-payment-ledger-AR'} component={SelectQuery} />
					<ProtectedRoute exact path="/invoice-pending" component={InvoicePending} />
					<ProtectedRoute exact path="/income-statement" component={IncomeStatement} />
					<ProtectedRoute exact path="/routines" component={Routine} />
					<ProtectedRoute exact path="/routine-order" component={RoutineOrder} />
					<ProtectedRoute exact path="/stock-update" component={StockUpdate} />
					<ProtectedRoute path="/income-detail" component={IncomeDetail} />
					<ProtectedRoute path="/vendor-ledger" key={'vender-ledger'} component={VenderLedger} />
					<ProtectedRoute path="/balance-sheet" component={BalanceSheet} />
					<ProtectedRoute path="/upc-report" component={UPCReport} />
					<ProtectedRoute path="/balance-detail" component={BalanceDetail} />
					<ProtectedRoute path="/do-not-commit" component={DoNotCommit} />
					<ProtectedRoute path="/control-sheet-log" component={ControlSheetLog} />
					<ProtectedRoute path="/product-to-bergen" component={ProductToBergen} />
					<ProtectedRoute path="/ra-to-bergen" component={RAToBergen} />
					<ProtectedRoute path="/ra-to-bergen2" component={RAToBergen2} />
					<ProtectedRoute path="/pt-uk-to-bergen" component={PtToBergen} />
					<ProtectedRoute path="/packing-list-to-bergen" component={AsnToBergen} />
					<ProtectedRoute path="/bergen-edi" component={BergenEdi} />
					<ProtectedRoute path="/stitchfix-edi" component={StitchFixEdi} />
					<ProtectedRoute path="/payment-ledger-summary-report" key={'payment-ledger-summary-report'} component={PaymentLedgerSummaryReport} />
					<ProtectedRoute path="/oms-style-report" component={OMSStyleReport} />
					<ProtectedRoute path="/oms-wash-report" component={OMSWashReport} />
					<ProtectedRoute path="/product-image-report" key={'product-image-report'} component={ProductImageReport} />
					<ProtectedRoute path="/transaction-ledger" component={StyleLedger} />
					<ProtectedRoute path="/chart-of-account-report" component={ChartofAccountReport} />
					<ProtectedRoute path="/style-oms-report" component={OmsStyleReportNew} />
					<ProtectedRoute path="/wash-oms-report" component={OmsWashReportNew} />
					<ProtectedRoute path="/style-shot" component={StyleShot} />
					<ProtectedRoute path="/avalara-invoice" component={AvalaraInvoice} />
					<ProtectedRoute path="/style-distro" component={StyleDistro} />
					<ProtectedRoute path="/vendor-summary" key={'vendor-summary'} component={VendorSummary} />
					<ProtectedRoute path="/expense" key={'expense'} component={Expense} />
					<ProtectedRoute path="/ar-inventory" key={'ar-inventory'} component={ARInventory} />
					<ProtectedRoute path="/inventory-valuation" key={'inventory-valuation'} component={InventoryValuation} />
					<ProtectedRoute path="/journal-ledger" key={'journal-ledger'} component={JournalLedger} />
					<ProtectedRoute path="/journal-ledger-new" key={'journal-ledger'} component={JournalLedgerNew} />
					<ProtectedRoute path="/General-ledger" key={'General-ledger'} component={GeneralLedger} />
					<ProtectedRoute path="/oms-stock-summary-ats" component={OmsStockSummaryAts} />
					<ProtectedRoute path="/pending-order-availability-by-size" component={PickTicketDetail} />
					<ProtectedRoute path="/stock-style-wise" component={StockStyleWise} />
					<ProtectedRoute path="/ar-aging-detail" component={CustomerPaymentLedgerNew} />
					<ProtectedRoute path="/balance-sheet-new" component={BalanceSheetNew} />
					<ProtectedRoute path="/ap-aging-detail" component={APAgingDetail} />
					<ProtectedRoute exact path="/income-statement-new" component={IncomeStatementNew} />
					<ProtectedRoute exact path="/sales-target-dashboard" component={SalesTargetRetportDashboard} />
					<ProtectedRoute exact path="/pay-check" component={CheckPay} />
					<ProtectedRoute exact path="/crm-sku-wise" component={CrmSkuWise} />
					<ProtectedRoute path="/crm-report" component={CRMReport} />
					<ProtectedRoute path="/transaction-lock" component={TransactionLock} />
					<ProtectedRoute path="/sales-target-report-saleperson" component={routeChecker("/sales-target-report-saleperson", SalesTargetReportSalePerson, routeName)} />
					<ProtectedRoute path="/charge-back-detail" component={ChargeBackDetail} />
					<ProtectedRoute path="/forget-password" component={ForgetPassword} />
					<ProtectedRoute path="/upc-to-itemcode" component={UpcToItemCode} />
					<ProtectedRoute path="/net-shipped-customer-wise" key={'net-shipped-customer-wise'} component={NetShippedStyleWise} />
					<ProtectedRoute path="/customer-hold" component={CustomerHold} />
					<ProtectedRoute path="/missing-upc" component={MissingUpc} />
					<ProtectedRoute path="/customer-payment-ledger-date-wise" key={'customer-payment-ledger-date-wise'} component={CustomerPaymentLedgerDateWise} />
					<ProtectedRoute path="/pending-order-summary-Ots" key={'pending-order-summary-Ots'} component={PendingOrderAvailabilityBySize} />
					<ProtectedRoute path="/negative-ots-order-wise" key={'negative-ots-order-wise'} component={NegativeOrderOtsWise} />
					<ProtectedRoute path="/negative-ots-order-wise2" key={'negative-ots-order-wise2'} component={NegativeOrderOtsWise2} />
					<ProtectedRoute path="/brand" component={routeChecker("/brand", Brand, routeName)} />
					<ProtectedRoute path="/sku-exists-erp" key={"sku-exists-erp"} component={SkuExistsErp} />
					<ProtectedRoute path="/online-orders-summary" key={'online-orders-summary'} component={OnlineOrdersSummary} />
					<ProtectedRoute path="/speciality-by-region" key={'speciality-by-region'} component={SpecialityByRegion} />
					<ProtectedRoute path="/speciality-by-state" key={'speciality-by-state'} component={SpecialityByState} />
					<ProtectedRoute path="/speciality-by-customer" key={'speciality-by-customer'} component={SpecialityByCustomer} />
					<ProtectedRoute path="/ecommerce-by-style" key={'ecommerce-by-style'} component={EcommerceByStyle} />
					<ProtectedRoute path="/dl-bulk" component={DLBulk} />
					<ProtectedRoute path="/ww-bulk" component={WWBulk} />
					<ProtectedRoute path="/product-migration" component={routeChecker("/product-migration", ProductMigration, routeName)} />
					<ProtectedRoute path="/specialty-by-style" key={'specialty-by-style'} component={SpecialtyByStyle} />
					<ProtectedRoute path="/routine-log" key={'routine-log'} component={RoutineLog} />
					<ProtectedRoute path="/image-uploader-nu" component={ImageUploaderNu} />
					<ProtectedRoute path="/scheels-edi" component={ScheelsEdi} />
					<ProtectedRoute exact path="/profile" component={Profile} />
					<ProtectedRoute exact path="/leaderboard" component={LeaderBoard} />
					<ProtectedRoute path="/control-sheet2" component={ControlSheet2} />
					<ProtectedRoute exact path="/invoice-email-test" component={InvoiceEmailTest} />
					<ProtectedRoute path="/production-finished" component={ProductionFinished} />
					<ProtectedRoute path="/oms-stock-summary-style-wise" component={OMSStockStyleWiseSummary} />
					<ProtectedRoute path="/product-image-url" component={ProductImageUrl} />
					<ProtectedRoute path="/dropship-sku" component={DropshipSKU} />
					<ProtectedRoute path="/dropship-total" component={DropshipTotal} />
					<ProtectedRoute path="/customer-by-po" key={'customer-by-po'} component={CustomerbyPO} />
					<ProtectedRoute path="*" component={NotFound} />
					<Route exact={true} path="*" component={NotFound} />
				</Switch >
			</Router >
		</>
	);
}