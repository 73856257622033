import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, Select, Button, Table } from "antd";
import { Row as Brow, Col as Bcol, TabPane } from "reactstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import moment from "moment";
import Swal from "sweetalert2";
import { ReadCookie } from "../../utils/readCookie";
import { addPurchaseBill, addCreateBill } from "../../redux/actions/purchaseBill.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { TabTitle } from "../../utils/GeneralFunction";
import notification from "../../utils/notification";

export default function PurchaseBill({ token }) {
  TabTitle("PurchaseBill")
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [LocationValue, setLocationValue] = useState(null);
  const [LocationId, setLocationId] = useState(null);
  const [via, setVia] = useState(null);
  const [billNumber, setBillNumber] = useState(null);
  const [rate, setRate] = useState(null);
  const [dDate, setDeliveryDate] = useState(moment());
  const [data, setData] = useState([]);
  const { adminLocation } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({ icon: "error", title: "Oops...", text: errors[0]?.errors[0] });
  };

  const moveToErp = async (e) => {
    setLoading(true)
    


    const newArr = data.map(v => ({ ...v, location_id: LocationId, delivery_date: moment(dDate).format("MM-DD-YYYY") }))

    if (form.getFieldValue().location === undefined || null) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Select Location",
      });
      setLoading(false)

    }
    else
      await dispatch(addCreateBill(token, newArr)).then((res) => {
        
        
        if (res?.payload?.data?.data === "Bill Already Exist") {
          Swal.fire({ icon: "error", title: "Oops...", text: "Bill Already Exist" })
        }
        if (res?.payload?.data === "Bill Created Successfully") {
          notification("success", "Bill", res?.payload?.data);
        }
        setLoading(false)
      });
  }
  const renderStateColumn = (LocationValue) => {
    return <span>{LocationValue}</span>; 
  };
  const LinesColumns = [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text, object, index) => index + 1
    },
    {
      title: "Invoice ID",
      dataIndex: "Invoice_ID",
    },
    {
      title: "Invoice Date",
      dataIndex: "Invoice_Date",
      render: (text) => moment(text).format("MM-DD-YYYY")
    },
    {
      title: "Item Code",
      dataIndex: "Product2",
    },

    {
      title: "Size",
      dataIndex: "ActualSize",
    },
    {
      title: "Inseam",
      dataIndex: "Inseam",
    },
    {
      title: "Quantity",
      dataIndex: "ActualSizeQty",
    },
    {
      title: "Rate",
      dataIndex: "Rate",
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
    },
    {
      title: "Memo",
      dataIndex: "Memo",
    },
    {
      title: "lOCATION",
      dataIndex: 'LocationValue',
      key: 'LocationValue',
      render: () => renderStateColumn(LocationValue),
    },
    {
      title: "VIA",
      dataIndex: "VIA",
    },
  ];

  function handleChangeLocation(value, type) {
    
    
    if (data.length > 0) setData([])
    setLocationValue(type?.children);
    setLocationId(value);

    
    if (type === "company") {
      form.setFieldsValue({ company: value });
    }
  }
  function handleRate(value, type) {
    setRate(value);
    if (type === "company") {
      form.setFieldsValue({ company: value });
    }
  }
  function handleVia(value, type) {
    setVia(value);
    if (type === "company") {
      form.setFieldsValue({ company: value });
    }
  }
  const onFinish = async (e) => {
    await dispatch(
      addPurchaseBill(token, billNumber, dDate, rate, via, LocationId)
    ).then((res) => {
      if (res?.payload?.data?.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No Data",
        });
      } else {
        setData(res?.payload?.data);
      }

    });
  };
  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"Purchase Bill"} />
        <div className="bodyStyle">
          <Form onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}>
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <Form.Item
                      name="billNo"
                      label="Bill No"
                      labelCol={{ span: 7 }}
                      rules={[{ required: true, message: "Bill No is required" }]}
                    >
                      <Input
                        tabIndex={18}
                        size="small"
                        className="customInputs"
                        onChange={(e) => setBillNumber(e.target.value)}
                      />
                    </Form.Item>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Delivery Date"
                        name="dileveryDate"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Delivery Date is required" }]}
                      >
                        <DatePicker
                          size="small"
                          format="MM-DD-YYYY"
                          allowClear
                          className="dateInput customInputs"
                          onChange={(e) => setDeliveryDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>

                 
                </Brow>
                <Brow>
                <Bcol lg={6} xl={4}>
                    <div className="slc">
                      <Form.Item
                        name="via"
                        label="VIA"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "VIA is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="styleInput w-100"
                          onChange={(value) => handleVia(value)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                        >
                          <Option value={"AIR"}>AIR</Option>
                          <Option value={"SEA"}>SEA</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4}>
                    <div className="slc">
                      <Form.Item
                        name="rate"
                        label="Rate"
                        labelCol={{ span: 7 }}
                        rules={[{ required: true, message: "Rate is required" }]}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="styleInput w-100"
                          onChange={(value) => handleRate(value)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                        >
                          <Option value={"FOB"}>FOB</Option>
                          <Option value={"LDP"}>LDP</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="location"
                        label="Location"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                      >
                        <Select
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          autoComplete={"nope"}
                          loading={adminLocation?.loading}
                          onChange={(value, type) => handleChangeLocation(value, type)}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                        >
                          {adminLocation?.payload?.data.map((val, index) => {
                            return (
                              <Option key={index} value={val.location_id}>
                                {val.location_name}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
                <Bcol lg={6} xxl={1}>
                  <Button
                    htmlType="submit"
                    className="btn btn-success"
                    style={{ marginLeft: "13px" }}
                  >
                    Submit
                  </Button>
                </Bcol>
              </Bcol>
            </Brow>
          </Form>
          {data && data.length > 0 ? (
            <TabPane tab="Data">
              <Table
                type={"company"}
                loading={loading}
                scroll={({ x: 600 }, { y: 400 })}
                size="small"
                rowKey={'index'}
                style={{ paddingTop: 10 }}
                dataSource={data}
                bordered
                columns={LinesColumns}
                pagination={false}
                summary={pageData => {
                  let sumQty = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += currentValue.ActualSizeQty
                  }, 0);
                  let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += currentValue.AMOUNT
                  }, 0);
                  return (
                    <>
                      <Table.Summary.Row >
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p>{sumQty.toLocaleString("en-US")}</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p>$ {sumAmount.toLocaleString("en-US")}</p>
                        </Table.Summary.Cell>
                        <Bcol lg={6} xxl={1}>
                          <div>
                            <Button
                              htmlType="submit"
                              className="btn btn-success"
                              style={{ marginLeft: 300 }}
                              
                              onClick={moveToErp}
                            >
                              Move To ERP
                            </Button>
                          </div>
                        </Bcol>
                      </Table.Summary.Row>

                    </>
                  );

                }}

              />
            </TabPane>
          ) :
            null}
        </div>
      </div>
    </div>
  );
}
