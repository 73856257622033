import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Tooltip,
  Table,
  Popconfirm,
  Checkbox,
  InputNumber,
  Spin,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getPaymentTerm } from "../../../../redux/actions/paymentterm.action";
import { loadCustomers } from "../../../../redux/actions/customerForm";
import {
  getARAccounts,
  getCustomerPayment,
} from "../../../../redux/actions/AR_Account.action";
import { getAdminPaymentTypes } from "../../../../redux/actions/adminPaymentType.action";
import { getAdminCurrency } from "../../../../redux/actions/adminCurrency.action";
import {
  addCustomerPaymentHeader,
  getAllCustomerPaymentHeaders,
  getOneCustomerSelectedinvoice,
  updateCustomerPaymentHeader,
} from "../../../../redux/actions/customerPaymnetHeader.action";
import {
  addCustomerPaymentLine,
  deleteCustomerPaymentLine,
  getCustomerPaymentDetails,
  getOneCustomerPaymentLine,
  updateCustomerPaymentLine,
} from "../../../../redux/actions/customerPaymentLine.action";
import {
  getOneCustomerPaymentNoteLine,
  deleteCustomerPaymentNoteLine,
} from "../../../../redux/actions/payCustomerPaymentNote.action";
import {
  creaditNoteInvoice,
  createCreaditNoteInvoice,
} from "../../../../redux/actions/creaditNoteInvoice.action";
import { addCustomerPaymentImpact } from "../../../../redux/actions/customerpaymentimpact";
import numeral from "numeral";
import { getGenderCategory } from "../../../../redux/actions/genderCategory.action";
import { getPaymentMethodType } from "../../../../redux/actions/paymentMethodType.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { CSVLink } from "react-csv";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { getGender } from "../../../../redux/actions/gender.action";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { getBrands } from "../../../../redux/actions/brand.action";
import { currencySymbol, defaultCurrency } from "../../../../utils/constants";
const { Option } = Select;
const { TabPane } = Tabs;

export default function CustomerPaymentForm({
  formData,
  val,
  token,
  formState,
  submit,
  saleOrderID,
  setDisable
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const submitButton = useRef(null);
  const focusInput = useRef(null);
  const [form] = Form.useForm();
  const {
    itemTransferFormData,
    systemLog,
    invoiceGlPeriod,
    paymentterm,
    customerForms,
    arAccount,
    customrPayment,
    customerPaymentDetail,
    adminPaymentType,
    currency,
    glImpact,
    gender,
    brand
  } = useSelector((state) => state);
  const [companyId] = useState(ReadCookie("defaultCompany"));
  const [oDate, setOrderDate] = useState(moment());

  const [customer, setCustomer] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [customerPaymentNote, setcustomerPaymentNote] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list1, setList] = useState([]);
  const [creaditNoteItems, setCreaditNoteItems] = useState([]);
  const [deleteCreaditNoteItems, setDeleteCreaditNoteItems] = useState([]);
  const [selectedCreaditNoteItems, setSelectedCreaditNoteItems] = useState([]);
  const [CopyselectedRowKeys, setCopyselectedRowKeys] = useState([]);
  const [CopyCreaditselectedRowKeys, setCreaditCopyselectedRowKeys] = useState(
    []
  );
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAutoSelect, setIsCheckedAutoSelect] = useState(false);
  const [net_totalSum, setNettotalSum] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment().format("MMM YYYY"));
  const [customerId, setCustomerId] = useState(null);
  const [menuId] = useState(408);
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getAdminCurrency(token, menuId));
    dispatch(getARAccounts(token));
    dispatch(getAdminPaymentTypes(token));
    dispatch(getPaymentMethodType(token, menuId));
    dispatch(getCustomerPayment(token));
    dispatch(getGender(token, menuId));
    dispatch(getBrands(token));

    const handleKeyDown = (e) => {
      if (
        e.keyIdentifier === 'U+000A' ||
        e.keyIdentifier === 'Enter' ||
        e.keyCode === 13
      ) {
        if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
          e.preventDefault();
          return false;
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);

  useEffect(() => {
    dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
      let result = res?.payload?.find((item) => {
        return item.period_name === currentDate;
      });
      setCurrentDate(result?.period_id);
      form.setFieldsValue({ postingPeriod: result?.period_id });
    });
  }, [token, dispatch]);

  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        asnNo: null,
        department: null,
        fromLocation: null,
        shipVia: null,
        memo: null,
        postingPeriod: currentDate,
        payment_type_id: 1,
        toLocation: null,
        transferDate: null,
        payment_date: oDate,
        currency_id: defaultCurrency,
        gender_id: null,
        brand_id: null,
      });
      setOrderItems([]);
    }
  }, [formState]);

  useEffect(() => {
    if (data?.state && formState === "add") {
      setCustomerId(data?.state?.customer_id);
      form.setFieldsValue({
        customer_id: data?.state?.customer_name,
        asnNo: 123,
        department: 123,
        fromLocation: null,
        shipVia: null,
        memo: null,
        postingPeriod: currentDate,
        payment_type_id: 1,
        toLocation: null,
        transferDate: null,
        payment_date: oDate,
        currency_id: defaultCurrency,
        brand_id: null,
        gender_id: null
      });
      onSelectCustomer(data?.state?.customer_id, {
        invoice_header_no: data?.state?.invoice_header_no,
      });
    }
  }, [data?.state, formState === "add"]);

  useEffect(() => {
    if (formState === "formView") {
      setLoading(true)
      dispatch(getOneCustomerPaymentLine(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS") {
          setOrderItems(res.payload.data);
          setLoading(false)
          setList(res.payload.data);
        }
      });
      dispatch(
        getGLImpact(token, {
          transaction_header_id: saleOrderID,
          transaction_type: "CUSTOMERPAYMENT",
        })
      ).then((res) => {
      });
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      setLoading(true)
      dispatch(getOneCustomerPaymentNoteLine(token, saleOrderID)).then(
        (res) => {
          if (res.type === "GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS") {
            setcustomerPaymentNote(res.payload.data);
            setList(res.payload.data);
            setCreaditNoteItems(res.payload.data);
            setLoading(false)
          }
        }
      );
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      dispatch(getOneCustomerPaymentLine(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS") {
          setOrderItems(res.payload.data);
          setList(res.payload.data);
        }
      });
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      dispatch(getOneCustomerPaymentNoteLine(token, saleOrderID)).then(
        (res) => {
          if (res.type === "GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS") {
            setcustomerPaymentNote(res.payload.data);
            setList(res.payload.data);
          }
        }
      );
    }
  }, [formState]);

  useEffect(() => {
    if (formData) {
      dispatch(loadCustomers(token, null, null, null, null, null, null, formData.customer_id, null, null, null, null, null, null, null, null, null, null, null, null))
      form.setFieldsValue({
        payment_header_no: formData.payment_header_no,
        debit_account_id: Number(formData.debit_account_id),
        payment_date: moment(formData.payment_date),
        postingPeriod: formData.gl_period_id,
        payment_term_id: formData.payment_term_id,
        customer_id: formData.customer_id,
        customer_name: formData.customer_name,
        payment_type_id: formData.payment_type_id,
        credit_account_id: formData.credit_account_id,
        currencyType: formData.currencyType,
        memo: formData.memo,
        instrument_no: formData.instrument_no,
        payment_amount:
          numeral(formData.payment_amount).format("0.00") ||
          numeral(net_totalSum).format("0.00"),
        applied: formData.applied,
        to_apply: formData.to_apply,
        un_applied: formData.un_applied,
        remarks: formData.remarks,
        currency_id: formData.currency_id,
        gender_id: formData.gender_id,
        brand_id: formData.brand_id,
      });
      setCustomerId(formData.customer_id)
    }
  }, [formData]);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);

  useEffect(() => {
    let filtered = customerPaymentNote.filter((g) => g.isSelected === true);
    setVisibleItems(filtered);
  }, [customerPaymentNote]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  useEffect(() => {
    addAutoPayment();
  }, [isCheckedAutoSelect]);

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0]);
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      };
    });
  };

  const glImpcatCols = [
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.debit).format("0,0.00")}</>;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.credit).format("0,0.00")}</>;
      },
    },
    {
      title: "created_date",
      dataIndex: "created_date",
    },
  ];

  const handleDeleteOrder = (record) => {
    const dataSource = [...orderItems];
    const deleteDataSource = [...deletedItems];
    deleteDataSource.push(record);
    setDeletedItems(deleteDataSource);
    if (dataSource.length > 0) {
      setOrderItems(
        dataSource.filter(
          (item) => item.invoice_header_id !== record.invoice_header_id
        )
      );
    } else {
      const dataSource = [...visibleItems];
      setOrderItems(
        dataSource.filter(
          (item) => item.invoice_header_id !== record.invoice_header_id
        )
      );
    }
    notification("success", "Item Transfer Deleted");
  };

  const paymentHandleDeleteOrder = (record) => {
    const dataSource = [...creaditNoteItems];
    const deleteDataSource = [...deleteCreaditNoteItems];
    deleteDataSource.push(record);
    setDeleteCreaditNoteItems(deleteDataSource);
    if (dataSource.length > 0) {
      setCreaditNoteItems(
        dataSource.filter(
          (item) => item.note_header_no !== record.note_header_no
        )
      );
    } else {
      const dataSource = [...visibleItems];
      setCreaditNoteItems(
        dataSource.filter(
          (item) => item.note_header_no !== record.note_header_no
        )
      );
    }
    notification("success", "Item Transfer Deleted");
  };

  const onFinish = async (event) => {
    submit(false);
    await form.validateFields().then((values) => {
      setLoading(true);
      setDisable(true);
      let headerId;
      if (!customerId) {
        setLoading(false);
        setDisable(false);
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Select Customer ID",
        });
      }
      if (!companyId) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong please login again to proceed!",
        });
      }
      if (formState === "add") {
        values.applied = parseFloat(paymentAmount) + parseFloat(sumCreaditNote);
        values.to_apply = selectedInvoiceSum;
        values.un_applied = parseFloat(paymentAmount) + sumCreaditNote - sumDiscFormView
        dispatch(
          addCustomerPaymentHeader(token, values, customerId, parseInt(companyId))
        ).then((res) => {
          if (res.type === "ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS") {
            form.setFieldsValue({
              payment_header_no: res.payload.payment_header_no,
            });
            headerId = res.payload.payment_header_id;
            dispatch(
              addCustomerPaymentLine(
                token,
                selectedOrderItems.map(
                  (v) => (
                    delete v.paid_amount,
                    delete v.invoice_amount,
                    delete v.invoice_date,
                    delete v.invoice_header_no,
                    delete v.balance_amount,
                    delete v.reference_no,
                    delete v.term_name,
                    delete v.brand_id,
                    delete v.brand_name,
                    delete v.gender_name,
                    delete v.edi,
                    delete v.new_line_add,
                    {
                      ...v,
                      invoice_header_id: v.invoice_header_id,
                      payment_header_id: res.payload.payment_header_id,
                      is_selected: v.is_selected,
                    }
                  )
                )
              )
            ).then((res) => {
              if (res.type === "ADD_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS") {
                dispatch(
                  createCreaditNoteInvoice(
                    token,
                    selectedCreaditNoteItems.map(
                      (v) => (
                        delete v.remaining_amount,
                        delete v.note_header_no,
                        delete v.note_date,
                        delete v.note_amount,
                        delete v.return_header_no,
                        delete v.gender_name,
                        delete v.ra_no,
                        delete v.invoice_header_no,
                        {
                          ...v,
                          payment_header_id: headerId,
                        }
                      )
                    )
                  )
                ).then((res) => {
                  if (res.type === "CREATE_CREDIT_NOTE_INVOICE_SUCCESS") {
                    dispatch(addCustomerPaymentImpact(token, headerId))
                  }
                  notification(
                    "success",
                    "Customer Payment",
                    res?.payload?.data
                  );
                  setLoading(false);
                  setDisable(false);
                  history.push({
                    pathname: `/customer-payment/edit/${headerId}`,
                    search: `?edit=yes`,
                  });
                });
              } else if (res.type === "ADD_PAY_CUSTOMER_PAYMENT_LINE_ERROR") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.payload?.data?.data,
                });
              }
            });
          } else if (res.type === "ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      } else if (formState === "edit") {
        setLoading(true);
        setDisable(true);
        if (deletedItems.length > 0) {
          dispatch(deleteCustomerPaymentLine(token, deletedItems, 123));
        }
        if (deleteCreaditNoteItems.length > 0) {
          dispatch(
            deleteCustomerPaymentNoteLine(token, deleteCreaditNoteItems, 123)
          );
        }
        dispatch(
          updateCustomerPaymentHeader(
            token,
            values,
            saleOrderID,
            parseInt(companyId)
          )
        ).then((res) => {
          if (res.type === "UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS") {
            dispatch(
              updateCustomerPaymentLine(token, orderItems, saleOrderID)
            ).then((res) => {
              if (res.type === "UPDATE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS") {
                setLoading(false);
                setDisable(false);
                notification("success", "Customer Payment Update");
              }
            });
          } else if (res.type === "UPDATE_PAY_CUSTOMER_PAYMENT_LINE_ERROR") {
            setLoading(false);
            setDisable(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      }
    });
  };

  const handleTab = (key) => {
    if (key === "2") {
      dispatch(
        getAllCustomerPaymentHeaders(token, "2022-01-01", " 2022-01-31")
      );
    } else if (key === "header") {
      dispatch(
        getSystemLog(token, {
          table_name: "pay_customer_payment_header",
          record_id: saleOrderID,
        })
      );
    } else if (key === "line") {
      dispatch(
        getSystemLog(token, {
          table_name: "pay_customer_payment_line",
          record_id: saleOrderID,
        })
      );
    }
  };

  const onSelectCustomer = (e, obj = {}) => {
    setCustomer(e);
    dispatch(getCustomerPaymentDetails(token, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_PAYMENT_DETAIL_SUCCESS") {
        if (Object.keys(obj).length > 0) {

          let dd = res.payload.data.find(
            (v) => v.invoice_header_no === obj.invoice_header_no
          );
          dd.is_selected = true;
          setOrderItems(
            res.payload.data.filter((v) => {
              if (v.invoice_header_no === obj.invoice_header_no) {
                v.is_selected = true;
                v.amount_paid = v.balance_amount;
              }
              return v;
            })
          );
        } else {
          setOrderItems(res.payload.data);
          setList(res.payload.data);
        }
      }
    });
    const data = {
      customer_id: e,
    };
    dispatch(creaditNoteInvoice(token, data)).then((res) => {
      if (res.type === "GET_ALL_CREDIT_NOTE_INVOICE_SUCCESS") {
        setCreaditNoteItems(res.payload.data);
      }
    });
  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    setDisable(false);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  const handleCustomerChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(
      loadCustomers(
        token,
        null,
        null,
        null,
        null,
        null,
        val,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    ).then((res) => {
      if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
        setCustomer(res?.payload?.data);
      }
    });
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

  const handleDiscountTaken = (value, balance_amount, index) => {
    let copyOrderItems = [...orderItems];
    copyOrderItems[index].discount_taken = value;
    copyOrderItems[index].amount_paid = balance_amount - value;
    setOrderItems(copyOrderItems);
  };

  let sumCreaditNote = 0;
  selectedCreaditNoteItems.map((val) => {
    sumCreaditNote += parseFloat(val.amount_applied);
  });

  let sumDiscFormView = 0;
  orderItems.map((val) => {
    sumDiscFormView += parseFloat(val.amount_paid);
  });

  let netCalculation;
  let netTotalCalculation;
  netCalculation = sumDiscFormView - sumCreaditNote;
  netTotalCalculation = parseFloat(
    parseFloat(paymentAmount) +
    sumCreaditNote -
    parseFloat(sumDiscFormView).toFixed(2)
  );

  useEffect(() => {
    if (isChecked === true) {
      setNettotalSum(netTotalCalculation);
    } else {
      setNettotalSum(netCalculation);
    }
  }, [isChecked, netTotalCalculation, netCalculation]);

  let sumCreaditNoteFormView = 0;

  creaditNoteItems.map((val) => {
    sumCreaditNoteFormView += parseFloat(val.amount_applied);
  });


  let unappliedamount = 0;
  const orderItemsSum = orderItems.filter((v) => v.is_selected === true);

  let selectedInvoiceSum = orderItemsSum.reduce((accum, value) => {
    return accum + parseFloat(value.amount_paid);
  }, 0);

  let orderItemSumView = orderItems.reduce((accum, value) => {
    return accum + parseFloat(value.amount_paid);
  }, 0);

  const creaditNoteSum = creaditNoteItems.filter((v) => v.is_selected === true);
  let selectedCreaditSum = creaditNoteSum.reduce((accum, value) => {
    return accum + parseFloat(value.amount_applied);
  }, 0);

  unappliedamount =
    parseFloat(paymentAmount) +
    parseFloat(selectedCreaditSum) -
    parseFloat(selectedInvoiceSum);

  useEffect(() => {
    if (!isChecked) {
      if (formState !== "formView") {
        form.setFieldsValue({ payment_amount: numeral(selectedInvoiceSum - selectedCreaditSum).format("0.00") });
        setPaymentAmount(selectedInvoiceSum - selectedCreaditSum);
      }
    }
  }, [paymentAmount, selectedInvoiceSum, selectedCreaditSum]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let rownum = selectedRowKeys.length - 1;
      let duparry = [...orderItems];
      let getItem;
      let filteredKeywords = CopyselectedRowKeys.filter(
        (v) => !selectedRowKeys.includes(v)
      );
      if (formState === "add") {
        if (filteredKeywords.length > 0) {
          getItem = duparry.findIndex(
            (val) => val.invoice_header_id === filteredKeywords[0]
          );
          duparry[getItem].amount_paid = 0;
          duparry[getItem].discount_taken = 0;
          if (selectedRows.length === 0) {
            orderItems.map((v) => {
              v.amount_paid = 0;
              v.is_selected = false;
              return v;
            });
          }
          setSelectedOrderItems([]);
        }
        else {
          getItem = duparry.findIndex(
            (val) => val.invoice_header_id === selectedRowKeys[rownum]
          );
          if (isChecked === true) {
            if (
              parseFloat(unappliedamount) -
              parseFloat(duparry[getItem].balance_amount) >
              0
            ) {
              duparry[getItem].amount_paid = parseFloat(
                duparry[getItem].balance_amount
              );
              duparry[getItem].is_selected = true;
            } else {
              duparry[getItem].amount_paid =
                parseFloat(duparry[getItem].balance_amount) +
                (parseFloat(unappliedamount) -
                  parseFloat(duparry[getItem].balance_amount));
              duparry[getItem].is_selected = true;
            }
          } else {
            duparry[getItem].amount_paid = duparry[getItem].balance_amount;
            duparry[getItem].is_selected = true;
            if (selectedRows.length > 0) {
              selectedRows.map((v) => {
                if (v.amount_paid === null) {
                  v.amount_paid = v.balance_amount;
                  v.is_selected = true;
                }
                return v;
              });
            }
          }
          setOrderItems(duparry);
        }
        setSelectedOrderItems(selectedRows);
        setCopyselectedRowKeys([...selectedRowKeys]);
      }
    },
  };

  const addAutoPayment = useCallback(() => {
    let remaining = parseFloat(paymentAmount) + parseFloat(selectedCreaditSum);
    let copyArr = [...orderItems];
    if (isCheckedAutoSelect) {
      copyArr.map((v) => {
        if (remaining !== 0) {
          if (remaining - v.balance_amount > 0) {
            v.is_selected = true;
            v.amount_paid = v.balance_amount;
            remaining = remaining - v.balance_amount;
          } else {
            v.is_selected = true;
            v.amount_paid = remaining;
            remaining = 0;
          }
        }
        return v;
      });
      setOrderItems(copyArr);
      setSelectedOrderItems(copyArr.filter((v) => v.is_selected === true));
    }
  }, [isCheckedAutoSelect]);

  const onChangeAutoSelect = (e) => {
    if (isChecked) {
      setIsCheckedAutoSelect(e.target.checked);
    }
  };

  const rowSelection_CreaditNote = {
    onChange: (selectedRowKeys, selectedRows) => {
      let rownum = selectedRowKeys.length - 1;
      let duparry = [...creaditNoteItems];
      let getItem;
      var filteredKeywords = CopyCreaditselectedRowKeys.filter(
        (v) => !selectedRowKeys.includes(v)
      );
      if (filteredKeywords.length > 0) {
        getItem = duparry.findIndex(
          (val) => val.note_header_id === filteredKeywords[0]
        );
        duparry[getItem].is_selected = true;
        duparry[getItem].amount_applied = 0;
        setSelectedCreaditNoteItems([]);
      } else {
        getItem = duparry.findIndex(
          (val) => val.note_header_id === selectedRowKeys[rownum]
        );
        duparry[getItem].amount_applied = duparry[getItem].remaining_amount;

        duparry[getItem].is_selected = true;
        setCreaditNoteItems(duparry);
      }
      setSelectedCreaditNoteItems(selectedRows);
      setCreaditCopyselectedRowKeys([...selectedRowKeys]);
    },
  };

  const changingPaymentSelect = (rowIndex, columnKey, record) => (event) => {
    const newrightsColumns = [...list1];
    newrightsColumns[rowIndex][columnKey] = event.target.checked;
    setList(newrightsColumns);
  };

  const handleCreaditPayment = (value, index) => {
    let copyCreaditNoteItem = [...creaditNoteItems];
    copyCreaditNoteItem[index].amount_applied = value;
    setCreaditNoteItems(copyCreaditNoteItem);
  };

  const handlePayment = (value, record, index) => {
    if (formState === "add") {
      let copyArr = [...selectedOrderItems];
      copyArr.filter((v) => {
        if (v?.invoice_header_id === record?.invoice_header_id) {
          v.amount_paid = value;
        };
        return v;
      })
      setSelectedOrderItems(copyArr);
    } else if (formState === "edit") {
      let copyArr = [...orderItems];
      copyArr[index].amount_paid = value;
      setSelectedOrderItems(copyArr);
    }

  };

  const columns = [
    {
      title: "Invoice Header ID",
      dataIndex: "invoice_header_id",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
    },
    {
      title: "Invoice Header No",
      dataIndex: "invoice_header_no",
      render: (text, record, index) => {
        return <>
          {
            record.new_line_add ? (
              <Input placeholder="Invoice" defaultValue={record?.invoice_header_no ? record?.invoice_header_no : ""} onPressEnter={(e) => newLineInvoiceHandler(e, record)} />
            ) : record.invoice_header_no
          }
        </>;
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      render: (text, record, index) => {
        return moment(record.invoice_date).format("MM-DD-YYYY");
      },
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      editable: true,
      render: (text, record, index) => {
        return numeral(record.invoice_amount).format("0.00");
      },
    },
    {
      title: "Balance",
      dataIndex: "balance_amount",
      editable: true,
      render: (text, record, index) => {
        return numeral(record.balance_amount).format("0.00");
      },
    },
    {
      title: "Discount Taken",
      dataIndex: "discount_taken",
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              size="small"
              onChange={(value) =>
                handleDiscountTaken(value, record.balance_amount, orderItems.findIndex((v) => v.invoice_header_id === record.invoice_header_id))
              }
              value={record.discount_taken || 0}
            />
          </>
        );
      },
    },
    {
      title: "Payment",
      dataIndex: "amount_paid",
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              size="small"
              onChange={(value) => handlePayment(value, record, orderItems.findIndex((v) => v.invoice_header_id === record.invoice_header_id))}
              value={record.amount_paid || 0}
            />
          </>
        );
      },
    },
    {
      title: "Payment Term",
      dataIndex: "amount_paid",
      render: (text, record, index) => {
        return record?.term_name;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      render: (text, record, index) => {
        return record.gender_name;
      },
    },
    {
      title: "PO#",
      dataIndex: "reference_no",
      render: (text, record, index) => {
        return record.reference_no;
      },
    },
    {
      title: "EDI#",
      dataIndex: "edi",
      render: (text, record, index) => {
        return record.edi;
      },
    },
     {
      title: "Brand",
      dataIndex: "brand_name",
      render: (text, record, index) => {
        return record.brand_name;
      },
    },
    {
      title: formState === "edit" && "Operation",
      dataIndex: formState === "edit" && "operation",
      render: (_, record) => (
        <Tooltip title="Delete" color={"white"}>
          {formState === "edit" ? (
            <Popconfirm
              title={`Are you sure to delete ?`}
              onConfirm={() => handleDeleteOrder(record)}
            >
              <DeleteOutlined className="delete-Button" />
            </Popconfirm>
          ) : null}
        </Tooltip>
      ),
    },
  ];

  const paymentcolumns = [
    {
      title: "Selected",
      dataIndex: "is_selected",
      key: "is_selected",
      render: (val, rec, rowInd) => (
        <Checkbox
          checked={val}

          onChange={changingPaymentSelect(rowInd, "is_selected", rec)}
        />
      ),
    },
    {
      title: "Payment Header ID",
      dataIndex: "payment_header_id",
    },
    {
      title: "Note Header Number",
      dataIndex: "note_header_no",
    },
    {
      title: "Transaction ID",
      dataIndex: "applied_to_transaction_id",
      editable: true,
    },
    {
      title: "Transaction Type",
      dataIndex: "applied_to_transaction_type",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, rec) => (
        <Tooltip title="Delete" color={"white"}>
          <Popconfirm
            title={`Are you sure to delete ?`}
            onConfirm={() => paymentHandleDeleteOrder(rec)}
          >
            <DeleteOutlined className="delete-Button" />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  const creaditTakeColums = [
    {
      title: "Note Header Number",
      dataIndex: "note_header_no",
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
    },
    {
      title: "Note Date",
      dataIndex: "node_date",
      editable: true,
      render: (text, record, index) => {
        return <>{moment(record.note_date).format("MM-DD-YYYY")}</>;
      },
    },
    {
      title: "Return Header Number",
      dataIndex: "return_header_no",
      editable: true,
      render: (text, record, index) => {
        return <>{record.return_header_no}</>;
      },
    },
    {
      title: "RA Approval",
      dataIndex: "ra_no",
      editable: true,
      render: (text, record, index) => {
        return <>{record.ra_no}</>;
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_header_no",
      editable: true,
      render: (text, record, index) => {
        return <>{record.invoice_header_no}</>;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      render: (text, record, index) => {
        return <>{record.transaction_type}</>;
      },
    },
    {
      title: "Gender Name",
      dataIndex: "gender_name",
      render: (text, record, index) => {
        return <>{record.gender_name}</>;
      },
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining_amount",
      render: (text, record, index) => {
        return <>{numeral(record.remaining_amount).format("0.00")}</>;
      },
    },
    {
      title: "Payment",
      dataIndex: "amount_applied",
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              size="small"
              onChange={(value) => handleCreaditPayment(value, creaditNoteItems.findIndex((v) => v.note_header_no === record.note_header_no))}
              value={record.amount_applied}
            />
          </>
        );
      },
    },
    {
      title: formState === "edit" && "Operation",
      dataIndex: formState === "edit" && "operation",
      render: (_, rec) => (
        <Tooltip title="Delete" color={"white"}>
          {formState === "edit" ? (
            <Popconfirm
              title={`Are you sure to delete ?`}
              onConfirm={() => paymentHandleDeleteOrder(rec)}
            >
              <DeleteOutlined className="delete-Button" />
            </Popconfirm>
          ) : null}
        </Tooltip>
      ),
    },
  ];

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  function onChange(e) {
    setIsChecked(e.target.checked);
  }

  const addItem = (event) => {
    let copyArr = [...orderItems, {
      amount_paid: 0,
      balance_amount: 0,
      created_by: null,
      created_date: null,
      discount_taken: 0,
      invoice_amount: 0,
      invoice_date: moment(),
      invoice_header_id: Math.floor(Math.random() * 4000),
      invoice_header_no: null,
      is_deleted: null,
      is_selected: null,
      last_updated_by: null,
      last_updated_date: null,
      payment: 0,
      payment_header_id: null,
      payment_invoice_id: null,
      transaction_type: null,
      new_line_add: true
    }];
    setOrderItems(copyArr);
  }

  const newLineInvoiceHandler = (event, record) => {
    event.preventDefault();
    if (event.code === "Enter") {
      const data = {
        customer_id: form.getFieldValue().customer_id,
        invoice_header_no: event.target.value,
        new_id: record?.invoice_header_id
      }
      dispatch(getOneCustomerSelectedinvoice(token, data)).then((res) => {
        if (res?.payload) {
          let copyArr = [...orderItems];
          let foundIndex = copyArr.findIndex(x => x.invoice_header_id == res.payload.new_id);
          copyArr[foundIndex].amount_paid = res.payload.amount_paid;
          copyArr[foundIndex].balance_amount = res.payload.balance_amount;
          copyArr[foundIndex].discount_taken = 0;
          copyArr[foundIndex].invoice_amount = res.payload.invoice_amount;
          copyArr[foundIndex].invoice_date = res.payload.invoice_date;
          copyArr[foundIndex].invoice_header_id = res.payload.invoice_header_id;
          copyArr[foundIndex].invoice_header_no = res.payload.invoice_header_no;
          copyArr[foundIndex].payment = 0;
          copyArr[foundIndex].payment_header_id = null;
          copyArr[foundIndex].payment_invoice_id = null;
          copyArr[foundIndex].transaction_type = res.payload.transaction_type;
          setOrderItems(copyArr);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "There is No Data for this Invoice"
          });
        }
      })
    }
  }

  function checkTransactionDate(e) {
    let transaction_date = moment(e).format("YYYY-MM-DD")
    setLoading(true)
    dispatch(getTransationLock(token, transaction_date)).then((r) => {
      setLoading(false)
      if (r.payload.data.length > 0) {
        setOrderDate(moment())
        form.setFieldsValue({
          payment_date: moment()
        })
        Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
      }
      else {
        setOrderDate(moment(e))
      }
    });
  }

  return (
    <>
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
        {itemTransferFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={8} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="payment_header_no"
                        label="Payment No"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().paymentNo}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Date"
                        name="payment_date"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Bill Date is required" },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          format="MM-DD-YYYY"

                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().payment_date
                              : moment(oDate)
                          }
                          onChange={(e) => {
                            setOrderDate(moment(e));
                            let result = invoiceGlPeriod?.payload?.find(
                              (item) => {
                                return (
                                  item.period_name ===
                                  moment(e).format("MMM YYYY")
                                );
                              }
                            );
                            setCurrentDate(result?.period_id);
                            form.setFieldsValue({
                              postingPeriod: result?.period_id,
                            });
                            checkTransactionDate(e);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        labelAlign="left"
                        name="customer_id"
                        label="Customer"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Customer is required" },
                        ]}
                      >
                        <Select
                          notFoundContent={
                            customerForms.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          onSelect={(e) => {
                            onSelectCustomer(e)
                            setCustomerId(e);
                          }
                          }
                          loading={customerForms?.loading}

                          onSearch={(value) => {
                            optimizedFn(value);
                          }}
                          ref={focusInput}
                          value={customer}
                        >
                          {customerForms?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.customer_id}
                                value={item.customer_id}
                              >
                                {`${item.customer_code} ${item.customer_name}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Posting Period"
                        name="postingPeriod"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().postingPeriod}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().postingPeriod}
                          onChange={(value) => {
                            form.setFieldsValue({
                              postingPeriod: value,
                            });
                          }}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option
                                key={item.period_id}
                                value={item.period_id}
                              >
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Payment Terms"
                        name="payment_term_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().paymentTerm}
                        rules={[
                          {
                            required: true,
                            message: "Payment Term is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().paymentTerm}

                          onChange={(value) => {
                            form.setFieldsValue({
                              paymentTerm: value,
                            });
                          }}
                        >
                          {paymentterm?.payload?.data.map((item) => {
                            return (
                              <Option key={item.term_id} value={item.term_id}>
                                {item.term_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Payment"
                        name="payment_type_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().payment_type_id}
                        rules={[
                          { required: true, message: "Payment is required" },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={adminPaymentType?.loading}
                          defaultValue={form.getFieldValue().paymentType}
                          onChange={(value) => {
                            form.setFieldsValue({
                              paymentType: value,
                            });
                          }}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {adminPaymentType?.payload?.data?.map((item) => {
                            return (
                              <Option
                                key={item.payment_type_id}
                                value={item.payment_type_id}
                              >
                                {item.payment_type_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="A/R Account"
                        name="credit_account_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().arAccount}
                        rules={[
                          {
                            required: true,
                            message: "A/R Account is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={arAccount?.loading}
                          defaultValue={form.getFieldValue().arAccount}
                          onChange={(value) => {
                            form.setFieldsValue({
                              arAccount: value,
                            });
                          }}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {arAccount?.payload?.data?.map((item) => {
                            return (
                              <Option
                                key={item.account_id}
                                value={item.account_id}
                              >
                                {item.account_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Debit Account"
                        name="debit_account_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().debit_account_id}
                        rules={[
                          {
                            required: true,
                            message: "Debit Account is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          allowClear
                          tabIndex={19}
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"

                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={customrPayment?.loading}
                          defaultValue={form.getFieldValue().debit_account_id}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {customrPayment?.payload?.data?.map((item) => {
                            return (
                              <Option
                                key={item.account_id}
                                value={item.account_id}
                              >
                                {item.account_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Currency"
                        name="currency_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().currency_id}
                        rules={[
                          { required: true, message: "Currency is required" },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={adminPaymentType?.loading}
                          defaultValue={form.getFieldValue().currency_id}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {currency &&
                            currency?.payload?.data.map((val) => {
                              return (
                                <Option
                                  key={val.currency_id}
                                  value={val.currency_id}
                                >
                                  {val.currency_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Gender"
                        name="gender_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().currency_id}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={gender?.loading}
                          defaultValue={form.getFieldValue().gender_id}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {gender &&
                            gender?.payload?.data.map((val) => {
                              return (
                                <Option
                                  key={val.gender_id}
                                  value={val.gender_id}
                                >
                                  {val.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Brand"
                        name="brand_id"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        defaultValue={form.getFieldValue().currency_id}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={brand?.loading}
                          defaultValue={form.getFieldValue().gender_id}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {brand && brand?.payload?.data?.map((res) => (
                            <Option key={res.brand_id} value={res.brand_id}>
                              {res.brand_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="remarks"
                        label="Remarks"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="instrument_no"
                        label="Check"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={6}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="payment_amount"
                        label="Payment Amount"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          {
                            required: true,
                            message: "Payment Amount is required",
                          },
                        ]}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          value={numeral(paymentAmount).format("0.00")}
                          onChange={(e) => setPaymentAmount(e.target.value)}
                        />
                      </Form.Item>
                      <p>{numeral(unappliedamount).format("0.00") || 0}</p>
                      <Checkbox onChange={onChange}>Checkbox</Checkbox>
                      <Checkbox
                        disabled={!isChecked}
                        onChange={onChangeAutoSelect}
                      >
                        Auto Select
                      </Checkbox>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={4}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Payment Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">TO APPLY:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        {formState === "add" ? (
                          <p className="mb-0 summary-value">
                            {currencySymbol}{numeral(paymentAmount + sumCreaditNote).format("00.00")}
                          </p>
                        ) : formState === "formView" ? (
                          <p className="mb-0 summary-value">{formData && numeral(formData.to_apply).format("00.00")}</p>
                        ) : (
                          <p className="mb-0 summary-value">
                            {currencySymbol}
                            {numeral(
                              (formData &&
                                parseFloat(formData.payment_amount)) +
                              parseFloat(sumCreaditNoteFormView)
                            ).format("00.00")}
                          </p>
                        )}
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">APPLIED:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        {
                          formState === "formView" ? (
                            <p className="mb-0 summary-value">{formData && numeral(formData.applied).format("00.00")}</p>
                          ) :
                            <p className="mb-0 summary-value">
                              {currencySymbol}{numeral(selectedInvoiceSum).format("00.00")}
                            </p>
                        }
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">UNAPPLIED:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        {formState === "add" ? (
                          <p className="mb-0 summary-value">
                            {currencySymbol}
                            {numeral(
                              parseFloat(paymentAmount) +
                              sumCreaditNote -
                              sumDiscFormView
                            ).format("00.00")}
                          </p>
                        ) : formState === "formView" ? (
                          <p className="mb-0 summary-value">{formData && numeral(formData.un_applied).format("00.00")}</p>
                        ) : (
                          <p className="mb-0 summary-value">
                            {currencySymbol}
                            {numeral(
                              (formData &&
                                parseFloat(formData.payment_amount)) +
                              parseFloat(sumCreaditNoteFormView) -
                              sumDiscFormView
                            ).format("00.00")}
                          </p>
                        )}
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
            {selectedOrderItems.length > 0 ? (
              <CSVLink
                data={selectedOrderItems.map((v) => {
                  return {
                    "Invoice Header Id": v.invoice_header_id,
                    "Invoice Header No": v.invoice_header_no,
                    "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
                    "Invoice Amount": v.invoice_amount,
                    "Balance Amount": v.balance_amount,
                    "Amount Paid": v.amount_paid,
                  };
                })}
              >
                Download
              </CSVLink>
            ) : null}
          </div>
          <Button disabled={formState !== "edit"} onClick={(e) => addItem(e)}>Add Item</Button>

          <Tabs
            type="card"
            size="small"
            defaultActiveKey="1"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Invoice Detail" key="1">
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
              >
                { }
                <TabPane
                  tab={
                    "Invoice Apply" +
                    " " +
                    `${numeral(selectedInvoiceSum || orderItemSumView).format("$0.00")}`
                  }
                  key="1"
                >
                  {customerPaymentDetail.loading && (
                    <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                      <Spin size='large' />
                    </div>
                  )}
                  {(orderItems.length > 0 || visibleItems.length > 0) && (
                    <Table
                      type={"company"}
                      loading={customerPaymentDetail.loading}
                      scroll={({ x: 600 }, { y: 400 })}
                      size="small"
                      bordered
                      dataSource={orderItems.sort((a, b) => a.invoice_header_no - b.invoice_header_no)}
                      columns={columns}
                      rowKey="invoice_header_id"
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={{ pageSize: 100 }}
                    />
                  )}
                </TabPane>
                <TabPane
                  tab={
                    "Credit Take" +
                    " " +
                    `${numeral(selectedCreaditSum).format("$0.00")}`
                  }
                  key="2"
                >
                  {(creaditNoteItems.length > 0 ||
                    customerPaymentNote.length > 0) && (
                      <Table
                        type={"company"}
                        loading={creaditNoteItems.loading}
                        scroll={({ x: 600 }, { y: 400 })}
                        size="small"
                        bordered
                        dataSource={creaditNoteItems}
                        columns={creaditTakeColums}
                        rowKey="note_header_id"
                        rowSelection={{
                          ...rowSelection_CreaditNote,
                        }}
                        pagination={{ pageSize: 100 }}
                      />
                    )}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Payment Information" key="paymentInfo">
              {(customerPaymentNote.length > 0 || visibleItems.length > 0) && (
                <Table

                  type={"company"}
                  loading={customerPaymentNote.loading}
                  scroll={({ x: 600 }, { y: 400 })}
                  size="small"
                  pagination={{ pageSize: 100 }}
                  bordered
                  dataSource={customerPaymentNote}
                  columns={paymentcolumns}

                />
              )}
            </TabPane>
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="GL Impact" key="gl_impact">
                <div className="tabs">
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={glImpact?.payload?.data}
                    col={glImpcatCols}
                    load={glImpact?.loading}
                    paginate
                  />
                </div>
              </TabPane>
            )}
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="System Information" key="logs">
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && <Tables
                        type="company"
                        scrollX={"50rem"}
                        scroll={360}
                        perPage={15}
                        source={systemLog?.payload?.data}
                        dataSource={[]}
                        col={renderColumns()}
                        load={systemLog.loading}
                        paginate
                      />}
                    </div>
                  </TabPane>
                </Tabs>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Form>
    </>
  );
}
