import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form, Button, Modal, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getAvailableProduct } from "../../redux/actions/availableProduct.action";

const EditableNew = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid, orderItems, adjustmentLocation, locationID, brandId }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { availableProductSale } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);

	const updateQuantity = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			let add = parseInt(item.quantity_available);
			if (item.item_code === id) {
				if (type === "up") {
					if (add + (value) < 0) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Quantity is Less than Balance",
						});
						value = 0
					}
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
				if (type === "down") {
					if (add + (value) < 0) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Quantity is Less than Balance",
						});
						value = 0
					}
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		dispatch(getAvailableProduct(token, companyId, selectedId, adjustmentLocation, locationID, brandId)).then((res) => {
			if (res.type === "GET_PRODUCTAVAILABLE_SUCCESS") {
				const arr = res?.payload?.map(items => {
					let found = orderItems.find(f => f.item_id === items.item_id)
					if (found) {
						found.quantity_available = items.quantity_available
						found.size = items.size
						found.gender_category_name = items.gender_category_name
						found.style_name = items.style_name
						found.wash_name = items.wash_name
						found.already = true
						return found
					} else {
						items.commit_status = 1;
						return items
					}
				})
				setData(arr)
			} else if (res.type === "GET_PRODUCTAVAILABLE_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Select Adjustment Location",
				});
			}
		});
	};

	const handleOk = () => {
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addOrderItems = () => {
		const removeOthers = filteredItems.map((item) => {
			return ({
				...item,
				estimated_cost: item.rate
			})
		})
		setOrderItems(removeOthers);
		setData([]);
		setSelectedId(null);
		setShowGrid(true);
	};

	const rowSelection = (event, record, index) => {
		let copyArr = [...data];
		copyArr.filter((v) => {
			if (v.item_id === record?.item_id) {
				if (event.target.checked) {
					v.isSelected = true;
					v.quantity_backorder = 0;
					v.quantity = 1;
				} else {
					v.isSelected = false;
					v.quantity = 0;
				}
			}
			return v;
		});
		setSelectedItems(copyArr.filter((v) => v.isSelected));
		setFilteredItems(copyArr.filter((v) => v.isSelected));
		setData(copyArr);
	}

	const columns = [
		{
			title: "Selected",
			render: (text, record, index) => {
				return (
					<Checkbox
						defaultChecked={record.already}
						disabled={record.already}
						onChange={(event) => rowSelection(event, record, index)}
					></Checkbox>
				);
			},
		},
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style Name",
			dataIndex: "style_name",
		},
		{
			title: "Wash Name",
			dataIndex: "wash_name",
		},
		{
			title: "Brand",
			dataIndex: "brand_name",
		},
		{
			title: "Inseam",
			dataIndex: "inseam",
			width: "10%",
		},
		{
			title: "Rate",
			dataIndex: "rate",
			width: "10%",
		},
		{
			title: "Size",
			dataIndex: "size",
			width: "8%",
		},
		{
			title: "Available Quantity",
			dataIndex: "quantity_available",
		},
		{
			title: "Quantity Committed",
			dataIndex: "quantity_committed",
		},
		{
			title: "Quantity On Hand",
			dataIndex: "quantity_on_hand",
		},
		{
			title: "Pod Quantity",
			dataIndex: "pod_quantity",
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						value={record.quantity}
						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={1}
					/>
				);
			},
		},
		{
			title: "Amount",
			editable: false,
			render: (text, record) => {
				return <>{parseFloat(record.quantity ? record.rate * record.quantity : record.rate).toFixed(2)}</>;
			},
		},
	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: selectedItems.length > 0 ? "" : "none",
				},
			}}
			okText="Add Item"
			destroyOnClose={true}
			width={1200}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
			cancelText={'Close'}
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>
				<Table
					size="small"
					loading={availableProductSale.loading}
					scroll={{ x: "50rem", y: 360 }}

					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default EditableNew;