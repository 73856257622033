import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  DatePicker,
  Input,
  Tabs,
  Button,
  Form,
  Select,
  Tooltip,
  Table,
  Popconfirm,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  addItemTransferHeader,
  updateItemTransferHeader,
} from "../../../../redux/actions/itemTransfer.action";
import {
  addItemTransferLine,
  getOneItemTransferLineDetail,
  deleteItemTransferLine,
  updateItemTransferLine,
} from "../../../../redux/actions/itemTransferLine.action";
import { getProductForInt } from "../../../../redux/actions/ProductForInt";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import Summary from "../../../../components/ItemTransferSummary/itemTransferSummary";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import * as XLSX from "xlsx";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
import { getBrands } from "../../../../redux/actions/brand.action";
import { currencySymbol } from "../../../../utils/constants";
const { Option } = Select;
const { TabPane } = Tabs;

export default function ItemTransferForm({
  formData,
  val,
  token,
  formState,
  submit,
  saleOrderID,
  changeState,
  setDisable
}) {
  const dispatch = useDispatch();
  const submitButton = useRef(null);
  const [form] = Form.useForm();
  const {
    itemTransferFormData,
    ProductSale,
    invoiceGlPeriod,
    brand,
    glImpact,
    systemLog,
  } = useSelector((state) => state);
  const [companyId] = useState(ReadCookie("defaultCompany"));
  const [oDate, setOrderDate] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment().format("MMM YYYY"));
  // use to re-render component to get form updated fields
  const [formDataValues, setFormDataValues] = useState({});
  const [dDate, setDeliveryDate] = useState(moment());

  useLayoutEffect(() => {
    dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
      let result = res?.payload?.find((item) => {
        return item.period_name === currentDate;
      });
      setCurrentDate(result?.period_id);
      form.setFieldsValue({ postingPeriod: result?.period_id });
    });
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(getBrands(token))
  }, []);

  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        asnNo: null,
        department: null,
        fromLocation: null,
        shipVia: 4,
        memo: null,
        billNo: null,
        postingPeriod: currentDate,
        toLocation: null,
        brand_id: null,
        transferDate: oDate,
        dileveryDate: dDate,
      });
      setOrderItems([]);
    }
  }, [formState]);

  useEffect(() => {
    if (formState === "formView") {
      setLoading(true)
      dispatch(getOneItemTransferLineDetail(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS") {
          setOrderItems(res.payload.data);
          setLoading(false)
        }
      });
      dispatch(
        getSystemLog(token, {
          table_name: "inv_item_transfer_header",
          record_id: saleOrderID,
        })
      );
      dispatch(
        getGLImpact(token, {
          transaction_header_id: saleOrderID,
          transaction_type: "ITEMTRANSFER",
        })
      );
    }
  }, [formState]);

  useEffect(() => {
    if (formData) {
      const initialValues = {
        asnNo: formData.asn_no,
        transferDate: moment(formData?.transfer_date),
        dileveryDate: moment(formData?.delivery_date),
        memo: formData.memo,
        billNo: formData?.bill_no,
        postingPeriod: formData.gl_period_id,
        fromLocation: formData.from_location_id,
        department: formData.department,
        shipVia: formData.ship_via,
        toLocation: formData.to_location_id,
        transfer_header_no: formData.transfer_header_no,
        brand_id: formData.brand_id,
      }
      form.setFieldsValue(initialValues);
      setFormDataValues(initialValues)
    }
  }, [formData]);

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);

  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  const handleDeleteOrder = (record) => {
    const dataSource = [...orderItems];
    const deleteDataSource = [...deleteItems];
    if (orderItems.length > 1) {
      deleteDataSource.push(record);
      setDeleteItems(deleteDataSource);
      if (dataSource.length > 0) {
        setOrderItems(
          dataSource.filter((item) => item.item_id !== record.item_id)
        );
      } else {
        const dataSource = [...visibleItems];
        setOrderItems(
          dataSource.filter((item) => item.item_id !== record.item_id)
        );
      }
      notification("success", "Item Transfer Deleted");
    } else {
      return Swal.fire({
        icon: "error",
        title: "There is only 1 Line.",
        text: "Last line of the document cannot be deleted",
      });
    }
  };

  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  function downloadExcelFileData(data) {
    if (data) {
      return data.map((v) => {
        return {
          "Item Code": v.item_code,
          "Style Name": v.style_name,
          "Wash Name": v.wash_name,
          Quantity: v.quantity,
          Rate: v.rate,
          "Available Quantity": v.quantity_available,
          "Quantity Committed": v.quantity_committed,
          "Quantity On Hand": v.quantity_on_hand,
        };
      });
    }
  }

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        setExcelFileError("Please select only excel file types");
      }
    } else {
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      setLoading(true);
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      if (form.getFieldsValue().toLocation === null || undefined) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Select To Location",
        });
        setLoading(false);
        return;
      }
      if (formDataValues.fromLocation === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Select From Location",
        });
        setLoading(false);
        return;
      } else {
        dispatch(
          getProductForInt(token, data, saleOrderID, formDataValues.fromLocation, formDataValues.brand_id)
        ).then((res) => {
          res?.payload?.data.map((value, index) => {
            setLoading(false);
            if (data[index].quantity > value?.quantity_available) {
              data[index].quantity = value?.quantity_available;
            } else if (data[index].quantity < 0) {
              data[index].quantity = 0;
            } else {
              data[index].quantity = data[index].quantity;
            }
          });
          const check = data.map((val, index) => ({
            ...val,
            item_id: res?.payload?.data[index]?.item_id,
          }));
          let response = [];
          for (let i = 0; i < check.length; i++) {
            response[i] = {
              item_code: check[i]?.item_code,
              quantity: check[i]?.quantity,
              style_name: res?.payload?.data[i]?.style_name,
              wash_name: res?.payload?.data[i]?.wash_name,
              rate: res?.payload?.data[i]?.rate,
              quantity_available: res?.payload?.data[i]?.quantity_available,
              quantity_on_hand: res?.payload?.data[i]?.quantity_on_hand,
              item_id: res?.payload?.data[i]?.item_id,
            };
          }
          let result = orderItems.concat(response);
          setOrderItems(result.filter((v) => v.item_id && v.quantity_available > 0));
          setExcelFile(null);
        });
      }
    }
  };

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0]);
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      };
    });
  };

  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }
    if (form.getFieldsValue().fromLocation === form.getFieldsValue().toLocation) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "From Location and To Location is Same",
      });
      form.setFieldsValue({ toLocation: null });
    }
  }

  function handleChangeFromLocation(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }
    if (form.getFieldsValue().fromLocation === form.getFieldsValue().toLocation) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "From Location and To Location is Same",
      });
      form.setFieldsValue({ fromLocation: null });
    }
  }

  const onFinish = async (e) => {
    submit(false);
    if (orderItems.length === 0) {
      submit(true);
      return Swal.fire({
        icon: "error",
        title: "Add Line Items",
        text: "The line item shouldn't be empty.",
      });
    }
    await form.validateFields().then((values) => {
      setLoading(true);
      setDisable(true);
      if (formState === "add") {
        if (orderItems.length > 0) {
          dispatch(
            addItemTransferHeader(token, values, parseInt(companyId))
          ).then((res) => {
            let headerId = res.payload.transfer_header_no;
            let headerIdd = res.payload.transfer_header_id;
            if (res.type === "ADD_ITEM_TRANSFER_HEADER_SUCCESS") {
              dispatch(
                addItemTransferLine(
                  token,
                  orderItems.map((item) => {
                    let avgCost = item.rate;
                    delete item.style_name;
                    delete item.cut_name;
                    delete item.fabric_name;
                    delete item.gender_category_name;
                    delete item.gender_name;
                    delete item.inseam;
                    delete item.isSelected;
                    delete item.quantity_available;
                    delete item.quantity_backorder;
                    delete item.quantity_committed;
                    delete item.quantity_on_hand;
                    delete item.rate;
                    delete item.size;
                    delete item.brand_id;
                    delete item.brand_name;
                    delete item.style_id;
                    delete item.wash_id;
                    delete item.wash_name;
                    delete item.amount;
                    delete item.commit_status;
                    delete item.quantity_packed;
                    delete item.quantity_shipped;
                    delete item.quantity_billed;
                    delete item.already;
                    return {
                      ...item,
                      transfer_header_id: res.payload.transfer_header_id,
                      avg_cost: avgCost,
                    };
                  })
                )
              ).then((res) => {
                if (res.type === "ADD_ITEM_TRANSFER_LINE_SUCCESS") {
                  notification(
                    "success",
                    "Item Transfer",
                    "Item Transfer created successfully!"
                  );
                  setLoading(false);
                  setDisable(false);
                  form.setFieldsValue({ transfer_header_no: headerId });
                  history.push({
                    pathname: `/item-transfer/edit/${headerIdd}`,
                    search: `?edit=yes`,
                  });
                } else if (res.type === "ADD_ITEM_TRANSFER_LINE_ERROR") {
                  setLoading(false);
                  setDisable(false);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.payload?.data?.data,
                  });
                }
              });
            } else if (res.type === "ADD_ITEM_TRANSFER_HEADER_ERROR") {
              setLoading(false);
              setDisable(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.payload?.data?.data,
              });
            }
          });
        } else {
          setLoading(false);
          setDisable(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "select atleast one Bill line",
          });
          return;
        }
      } else if (formState === "edit") {
        if (deleteItems.length > 0) {
          dispatch(deleteItemTransferLine(token, deleteItems, saleOrderID));
        }
        dispatch(updateItemTransferHeader(token, values, saleOrderID)).then(
          (res) => {
            if (res.type === "UPDATE_ITEM_TRANSFER_HEADER_SUCCESS") {
              if (orderItems.length > 0) {
                dispatch(
                  updateItemTransferLine(token, orderItems, saleOrderID)
                ).then((res) => {
                  if (res.type === "UPDATE_ITEM_TRANSFER_LINE_SUCCESS") {
                    setLoading(false);
                    setDisable(false);
                    changeState("formView");
                    notification("success", "Item Transfer Updated");
                  }
                });
              }
            } else if (res.type === "UPDATE_ITEM_TRANSFER_HEADER_ERROR") {
              setLoading(false);
              setDisable(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.payload?.data?.data,
              });
            }
          }
        );
      }
    });
  };

  const handleTab = (key) => {
    if (key === "header") {
      dispatch(
        getSystemLog(token, {
          table_name: "inv_item_transfer_header",
          record_id: saleOrderID,
        })
      );
    } else if (key === "line") {
      dispatch(
        getSystemLog(token, {
          table_name: "inv_item_transfer_lines",
          record_id: saleOrderID,
        })
      );
    }
  };

  const showModal = () => {
    if (formDataValues.fromLocation === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select From Location",
      });
      setLoading(false);
    } else if (!formDataValues.brand_id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Brand.",
      });
      setLoading(false);
    } else {
      setIsModalVisible(true);
    }
  };

  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const onlyKey = [...value].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => {
      return onlyKey.find((i) => i === f.item_code);
    });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...value];

      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    } else {
      let uniqueOrderItems = [...visibleItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
    }
  };

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
    setLoading(false);
    setDisable(false);
  };

  const glImpcatCols = [
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.debit).format("0,0.00")}</>;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) => {
        return <>{currencySymbol} {numeral(record.credit).format("0,0.00")}</>;
      },
    },

    {
      title: "created_date",
      dataIndex: "created_date",
    },
  ];

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record) => parseInt(record.quantity),
      editable: true,
    },
    {
      title: "Rate",
      dataIndex: "rate",
    },
    {
      title: "Available Quantity",
      dataIndex: "quantity_available",
    },
    {
      title: "Quantity Committed",
      dataIndex: "quantity_committed",
    },
    {
      title: "Quantity On Hand",
      dataIndex: "quantity_on_hand",
    },

    {

      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => (
        <Tooltip title="Delete" color={"white"}>
          {formState === "formView" ? "" :
            <Popconfirm
              title={`Are you sure to delete the item ${record.item_id}?`}
              onConfirm={() => handleDeleteOrder(record)}
            >
              <DeleteOutlined className="delete-Button" />
            </Popconfirm>
          }
        </Tooltip>
      ),

    }

  ];

  function checkTransactionDate(e) {
    let transaction_date = moment(e).format("YYYY-MM-DD")
    setLoading(true)
    dispatch(getTransationLock(token, transaction_date)).then((r) => {
      setLoading(false)
      if (r.payload.data.length > 0) {
        setOrderDate(moment())
        form.setFieldsValue({
          transferDate: moment()
        })
        Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
      }
      else {
        setOrderDate(moment(e))
      }
    });
  }

  return (
    <>
      <EditableTable
        token={token}
        companyId={companyId}
        brandId={formDataValues.brand_id}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        setShowGrid={() => { }}
        fromLocation={formDataValues.fromLocation}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        onValuesChange={(values, fields) => {
          if (values?.brand_id && formDataValues?.brand_id !== values?.brand_id && formState === 'add') {
            setOrderItems([])
          }
          setFormDataValues({ ...fields })
        }}
        name="basic"
      >
        {itemTransferFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <Form.Item
                      name="transfer_header_no"
                      label="Transfer No"
                      labelAlign="left"
                      labelCol={{ span: 7 }}
                    >
                      <Input
                        tabIndex={18}
                        size="small"
                        className="customInputs"
                        disabled={true}
                      />
                    </Form.Item>
                  </Bcol>

                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Transfer Date"
                        name="transferDate"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          { required: true, message: "Bill Date is required" },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          format="MM-DD-YYYY"

                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().transferDate
                              : moment(oDate)
                          }
                          onChange={(e) => {
                            setOrderDate(moment(e));
                            let result = invoiceGlPeriod?.payload?.find(
                              (item) => {
                                return (
                                  item.period_name ===
                                  moment(e).format("MMM YYYY")
                                );
                              }
                            );
                            setCurrentDate(result?.period_id);
                            form.setFieldsValue({
                              postingPeriod: result?.period_id,
                            });
                            checkTransactionDate(e);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="fromLocation"
                        label="From Location"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: "From Location is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          showSearch
                          allowClear
                          loading={itemTransferFormData.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().fromLocation}
                          onChange={(value) => {
                            handleChangeFromLocation(value);
                          }}
                        >
                          {itemTransferFormData?.payload?.location.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.location_id}
                                  value={item.location_id}
                                >
                                  {item.location_name}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="toLocation"
                        label="To Location"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: "To Location is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          showSearch
                          allowClear
                          loading={itemTransferFormData.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().toLocation}

                          onChange={(value) => handleChange(value)}
                        >
                          {itemTransferFormData?.payload?.location.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.location_id}
                                  value={item.location_id}
                                >
                                  {item.location_name}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Brand"
                        name="brand_id"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: "Brand is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add")
                          }
                          loading={brand.loading}
                          defaultValue={form.getFieldValue().brand_id}
                        >
                          {brand?.payload?.data.map((item) => {
                            return (
                              <Option
                                key={item.brand_id}
                                value={item.brand_id}
                              >
                                {item.brand_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Posting Period"
                        name="postingPeriod"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        defaultValue={form.getFieldValue().postingPeriod}
                        rules={[
                          {
                            required: true,
                            message: "PostingPeriod is required",
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          tabIndex={18}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          className="filter-fields"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          loading={invoiceGlPeriod.loading}
                          defaultValue={form.getFieldValue().postingPeriod}


                          onChange={(value) => {
                            form.setFieldsValue({
                              postingPeriod: value,
                            });
                          }}
                        >
                          {invoiceGlPeriod?.payload?.map((item) => {
                            return (
                              <Option
                                key={item.period_id}
                                value={item.period_id}
                              >
                                {item.period_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="shipVia"
                        label="Ship Via"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          showSearch
                          allowClear
                          loading={itemTransferFormData.loading}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().shipVia}

                          onChange={(value) => handleChange(value)}
                        >
                          {itemTransferFormData?.payload?.shipVia.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.ship_via_id}
                                  value={item.ship_via_id}
                                >
                                  {item.ship_via_description}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="asnNo"
                        label="Asn No"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="memo"
                        label="Memo"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: "Memo is required",
                          },
                        ]}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Delivery Date"
                        name="dileveryDate"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: "Delivery Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          format="MM-DD-YYYY"

                          allowClear
                          className="dateInput customInputs"
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().dileveryDate
                              : moment(oDate)
                          }
                          onChange={(e) => setDeliveryDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="billNo"
                        label="Bill No"
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                      >
                        <Input
                          tabIndex={18}
                          size="small"
                          className="customInputs"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={1}>
                    <div>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        style={{ marginTop: 5 + "px" }}
                        onClick={() => { }}
                      >
                        Bill No Submit
                      </Button>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={3}>
                    <div className="firstSectionRo">
                      <Form.Item>
                        <Input
                          type="file"
                          ClassName="form-control"
                          onChange={handleFile}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                        {excelFileError && (
                          <div className="text-danger">{excelFileError}</div>
                        )}
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={1}>
                    <div>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        style={{ marginTop: 5 + "px" }}
                        onClick={handleSubmit}
                        disabled={excelFile === null}
                      >
                        File Submit
                      </Button>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <Summary
                  orderItems={orderItems.length > 0 ? orderItems : visibleItems}
                  shippingCost={
                    form.getFieldValue().shippingCost
                      ? form.getFieldValue().shippingCost
                      : 0
                  }
                />
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>
          <Tabs type="card" size="small" defaultActiveKey="1" centered>
            <TabPane tab="Product Information" key="1">
              <div className="pbpx-20">
                <Button
                  type="primary"
                  className="modalButton"
                  disabled={!(formState === "edit" || formState === "add")}
                  onClick={showModal}
                >
                  Add Item
                </Button>
              </div>
              <div className="viewer">
                {orderItems === null && <>No file selected</>}
                {orderItems !== null && (
                  <div className="table-responsive">
                    {orderItems?.length > 0 && (formState !== "add" && formState !== "edit") && (
                      <CSVLink
                        filename={"Item_Transfer.csv"}
                        disabled={formState === "add"}
                        data={downloadExcelFileData(orderItems) || []}
                        onClick={() => { }}
                      >
                        Download Excel
                      </CSVLink>
                    )}
                    <Table
                      type={"company"}
                      loading={ProductSale.loading}
                      scroll={({ x: 600 }, { y: 400 })}
                      size="small"
                      bordered
                      dataSource={orderItems ? orderItems : []}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                )}
              </div>
            </TabPane>
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="GL Impact" key="gl_impact">
                <div className="tabs">
                  <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={glImpact?.payload?.data}
                    col={glImpcatCols}
                    load={glImpact?.loading}
                    paginate
                  />
                </div>
              </TabPane>
            )}
            {(formState === "edit" || formState === "formView") && (
              <TabPane tab="System Information" key="logs">
                <Tabs
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  centered
                  onChange={(key) => handleTab(key)}
                >
                  <TabPane tab="Header" key="header">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && (
                        <Tables
                          type="company"
                          scrollX={"50rem"}
                          scroll={360}
                          perPage={15}
                          source={systemLog?.payload?.data}
                          dataSource={[]}
                          col={renderColumns()}
                          load={systemLog.loading}
                          paginate
                        />
                      )}
                    </div>
                  </TabPane>
                  <TabPane tab="Line" key="line">
                    <div className="tabs">
                      {systemLog?.payload?.data?.length > 0 && (
                        <Tables
                          type="company"
                          scrollX={"50rem"}
                          scroll={360}
                          perPage={15}
                          source={systemLog?.payload?.data}
                          dataSource={[]}
                          col={renderColumns()}
                          load={systemLog.loading}
                          paginate
                        />
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Form>
    </>
  );
}
