import {
    GET_LEADER_BOARD_REQUEST,
    GET_LEADER_BOARD_SUCCESS,
    GET_LEADER_BOARD_ERROR,
} from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LEADER_BOARD_REQUEST:
            return {
                loading: true,
            };
        case GET_LEADER_BOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_LEADER_BOARD_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default: {
            return state;
        }
    }
};