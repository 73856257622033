import {
    GET_STATE_REQUEST, GET_STATE_SUCCESS, GET_STATE_ERROR,
    ADD_STATE_REQUEST, ADD_STATE_SUCCESS, ADD_STATE_ERROR,
    UPDATE_STATE_REQUEST, UPDATE_STATE_SUCCESS, UPDATE_STATE_ERROR,
    DELETE_STATE_REQUEST, DELETE_STATE_SUCCESS, DELETE_STATE_ERROR,
} from './types'

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");




export const getState = (token) => (dispatch) => {
    dispatch({ type: GET_STATE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminState`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: GET_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_STATE_ERROR,
                payload: error.response,
            });
        });
};

export const getStateUnique = (token, id) => (dispatch) => {
    dispatch({ type: GET_STATE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminStateUnique`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_STATE_ERROR,
                payload: error.response,
            });
        });
};



export const addState = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_STATE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminState`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_STATE_ERROR,
                payload: error.response,
            });
        });
};



export const updateState = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_STATE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminState/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_STATE_ERROR,
                payload: error.response,
            });
        });
};



export const deleteState = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_STATE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminState/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_STATE_ERROR,
                payload: error.response,
            });
        });
};