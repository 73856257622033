import { GET_SHIPPED_DETAIL_REPORT_REQUEST, GET_SHIPPED_DETAIL_REPORT_SUCCESS, GET_SHIPPED_DETAIL_REPORT_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js"
import axios from "axios";
import moment from "moment";

export const getShipppedDetailReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_SHIPPED_DETAIL_REPORT_REQUEST });
    const newData = {
        customer_cat_id: values.cutomer_category === "All" ? null : values.cutomer_category,
        // date_from: moment(values.from_date).format("MM-DD-YYYY"),
        date_from: "11-24-2020",
        date_from: moment(values.from_to).format("MM-DD-YYYY"),
        date_to: moment(values.date_to).format("MM-DD-YYYY"),
        order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
        reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
        customer: values.customer === undefined || values.customer === "" ? null : values.customer,
        billcustomer: values.billing_addressee === undefined || values.billing_addressee === "" ? null : values.billing_addressee,
        sp_id: values.sale_person === "All" ? null : values.sale_person,
        region_id: values.region === "All" ? null : values.region,
        gender_id: values.gender === "All" ? null : values.gender,
        payment_term_id: values.terms === "All" ? null : values.terms,
        store_id: values.store === undefined || values.store === "" ? null : values.store,
        company_id: values.subsidary === undefined ? 2 : 2,
        source_type: values.source === undefined || values.source === "" ? null : values.source,
        sku: values.sku === undefined || values.sku === "" ? null : values.sku,
        status: values.status === undefined || values.status === "" ? null : values.status,
        invoice_header_no: values.invoice_header_no === undefined || values.invoice_header_no === "" ? null : values.invoice_header_no,
        ship_header_no: values.ship_header_no === undefined || values.ship_header_no === "" ? null : values.ship_header_no,
        location_id: values.location === undefined || values.location === "All" ? null : values.location
    };

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/omsShippedDetail`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_SHIPPED_DETAIL_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_SHIPPED_DETAIL_REPORT_ERROR,
                payload: [],
            });

        });
};
