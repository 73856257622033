import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Input, Select, DatePicker, Table, Spin } from "antd";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { ReadCookie } from "../../utils/readCookie";
import { getCreditOpenInvoice } from "../../redux/actions/CreditOpenInvoiceDetailReport.action copy"
import moment from "moment";
import { TabTitle } from "../../utils/GeneralFunction";
import * as XLSX from 'xlsx';
const { Option } = Select;

const CreditOpenInvoicedetailReport = () => {
    TabTitle("Credit Open Invoice")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [creditOpenDetailList, setCreditOpenDetailList] = useState([]);
    const submitButton = useRef(null);
    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const { adminLocation, creditOpenInvoice } = useSelector((state) => state);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");


    useEffect(() => {
        dispatch(getAdminLocation(token, menuId));
    }, [dispatch, menuId, token]);

    const BDCols = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'SKU',
            dataIndex: 'parent_item_code',
            key: 'parent_item_code',
            width: 70,
        },
        {
            title: 'Inseam',
            dataIndex: 'inseam',
            key: 'inseam',
            width: 80,
            align: "center",
            render: (text, record, index) => record.inseam === null ? "N/A" : record.inseam
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 150,
        },
        {
            title: 'Billing Addressee',
            dataIndex: 'billing_addressee',
            key: 'billing_addressee',
            width: 150,
        },
        {
            title: 'Billing City',
            dataIndex: 'billing_city',
            key: 'billing_city',
            width: 150,
        },
        {
            title: 'Shipping City',
            dataIndex: 'shipping_city',
            key: 'shipping_city',
            width: 150,
        },
        {
            title: 'Invoice #',
            dataIndex: 'invoice_header_no',
            key: 'invoice_header_no',
            width: 80,
        },
        {
            title: 'Date',
            width: 80,
            render: (text, record) => {
                return <>{moment(record.invoice_date).format("MM-DD-YY")}</>
            }
        },
        {
            children: [
              {
                title: 'T-Jacket',
                dataIndex: 'stage',
                key: 'stage',
                width: 90,
                children: [
                  {
                    title: 'Shirt/RTW-P',
                    dataIndex: 'stage',
                    key: 'stage',
                    width: 90,
                    children: [
                      {
                        title: 'Shirt/RTW-R',
                        dataIndex: 'stage',
                        key: 'stage',
                        width: 90,
                        children: [
                          {
                            title: 'Y-Adult',
                            dataIndex: 'stage',
                            key: 'stage',
                            width: 90,
                            children: [
                              {
                                title: 'W-Plus',
                                dataIndex: 'stage',
                                key: 'stage',
                                width: 90,
                                children: [
                                  {
                                    title: 'Kids',
                                    dataIndex: 'stage',
                                    key: 'stage',
                                    width: 90,
                                    children: [
                                      {
                                        title: 'Men',
                                        dataIndex: 'stage',
                                        key: 'stage',
                                        width: 90,
                                        children: [
                                          {
                                            title: 'Women',
                                            dataIndex: 'stage',
                                            key: 'stage',
                                            width: 90,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '"2/3"',
                align: 'center',
                dataIndex: 'size1qty1',
                key: 'size1qty1',
                width: 50,
                children: [
                  {
                    title: '1XL',
                    dataIndex: 'size1qty1',
                    key: 'size1qty1',
                    width: 50,
                    align: 'center',
      
                    children: [
                      {
                        title: 'XS',
                        dataIndex: 'size1qty1',
                        key: 'size1qty1',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '00',
                            dataIndex: 'size1qty1',
                            key: 'size1qty1',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '10W',
                                dataIndex: 'size1qty1',
                                key: 'size1qty1',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '2',
                                    dataIndex: 'size1qty1',
                                    key: 'size1qty1',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '27',
                                        dataIndex: 'size1qty1',
                                        key: 'size1qty1',
                                        align: 'center',
                                        width: 50,
                                        children: [
                                          {
                                            title: '23',
                                            dataIndex: 'size1qty1',
                                            key: 'size1qty1',
                                            width: 50,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '"4/5"',
                align: 'center',
                dataIndex: 'size1qty2',
                key: 'size1qty2',
                width: 50,
                children: [
                  {
                    title: '2XL',
                    align: 'center',
                    dataIndex: 'size1qty2',
                    key: 'size1qty2',
                    width: 55,
                    children: [
                      {
                        title: 'S',
                        align: 'center',
                        dataIndex: 'size1qty2',
                        key: 'size1qty2',
                        width: 55,
                        children: [
                          {
                            title: '0',
                            align: 'center',
                            dataIndex: 'size1qty2',
                            key: 'size1qty2',
                            width: 55,
                            children: [
                              {
                                title: '12W',
                                align: 'center',
                                dataIndex: 'size1qty2',
                                key: 'size1qty2',
                                width: 55,
                                children: [
                                  {
                                    title: '3',
                                    align: 'center',
                                    dataIndex: 'size1qty2',
                                    key: 'size1qty2',
                                    width: 50,
                                    children: [
                                      {
                                        title: '28',
                                        align: 'center',
                                        dataIndex: 'size1qty2',
                                        key: 'size1qty2',
                                        width: 50,
                                        children: [
                                          {
                                            title: '24',
                                            align: 'center',
                                            dataIndex: 'size1qty2',
                                            key: 'size1qty2',
                                            width: 50,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '"6/6X"',
                dataIndex: 'size1qty3',
                key: 'size1qty3',
                align: 'center',
                width: 60,
                children: [
                  {
                    title: '3XL',
                    dataIndex: 'size1qty3',
                    key: 'size1qty3',
                    width: 50,
                    align: 'center',
      
                    children: [
                      {
                        title: 'M',
                        dataIndex: 'size1qty3',
                        key: 'size1qty3',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '1',
                            dataIndex: 'size1qty3',
                            key: 'size1qty3',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '14W',
                                dataIndex: 'size1qty3',
                                key: 'size1qty3',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '4',
                                    dataIndex: 'size1qty3',
                                    key: 'size1qty3',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '29',
                                        dataIndex: 'size1qty3',
                                        key: 'size1qty3',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '25',
                                            dataIndex: 'size1qty3',
                                            key: 'size1qty3',
                                            width: 55,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty4',
                key: 'size1qty4',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty4',
                    key: 'size1qty4',
                    width: 50,
                    align: 'center',
      
                    children: [
                      {
                        title: 'L',
                        dataIndex: 'size1qty4',
                        key: 'size1qty4',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '2',
                            dataIndex: 'size1qty4',
                            key: 'size1qty4',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '16W',
                                dataIndex: 'size1qty4',
                                key: 'size1qty4',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '5',
                                    dataIndex: 'size1qty4',
                                    key: 'size1qty4',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '30',
                                        dataIndex: 'size1qty4',
                                        key: 'size1qty4',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '26',
                                            dataIndex: 'size1qty4',
                                            key: 'size1qty4',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty5',
                key: 'size1qty5',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty5',
                    key: 'size1qty5',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: 'XL',
                        dataIndex: 'size1qty5',
                        key: 'size1qty5',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '3',
                            dataIndex: 'size1qty5',
                            key: 'size1qty5',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '18W',
                                dataIndex: 'size1qty5',
                                key: 'size1qty5',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '6',
                                    dataIndex: 'size1qty5',
                                    key: 'size1qty5',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '31',
                                        dataIndex: 'size1qty5',
                                        key: 'size1qty5',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '27',
                                            dataIndex: 'size1qty5',
                                            key: 'size1qty5',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty6',
                key: 'size1qty6',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty6',
                    key: 'size1qty6',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: 'XXL',
                        dataIndex: 'size1qty6',
                        key: 'size1qty6',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '4',
                            dataIndex: 'size1qty6',
                            key: 'size1qty6',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '20W',
                                dataIndex: 'size1qty6',
                                key: 'size1qty6',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '6x',
                                    dataIndex: 'size1qty6',
                                    key: 'size1qty6',
                                    width: 50,
                                    align: 'center',
      
                                    children: [
                                      {
                                        title: '32',
                                        dataIndex: 'size1qty6',
                                        key: 'size1qty6',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '28',
                                            dataIndex: 'size1qty6',
                                            key: 'size1qty6',
                                            width: 50,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty7',
                key: 'size1qty7',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty7',
                    key: 'size1qty7',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty7',
                        key: 'size1qty7',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '5',
                            dataIndex: 'size1qty7',
                            key: 'size1qty7',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '22W',
                                dataIndex: 'size1qty7',
                                key: 'size1qty7',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '7',
                                    dataIndex: 'size1qty7',
                                    key: 'size1qty7',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '33',
                                        dataIndex: 'size1qty7',
                                        key: 'size1qty7',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '29',
                                            dataIndex: 'size1qty7',
                                            key: 'size1qty7',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty8',
                key: 'size1qty8',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty8',
                    key: 'size1qty8',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty8',
                        key: 'size1qty8',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '6',
                            dataIndex: 'size1qty8',
                            key: 'size1qty8',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '24W',
                                dataIndex: 'size1qty8',
                                key: 'size1qty8',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '7x',
                                    dataIndex: 'size1qty8',
                                    key: 'size1qty8',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '34',
                                        dataIndex: 'size1qty8',
                                        key: 'size1qty8',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '30',
                                            dataIndex: 'size1qty8',
                                            key: 'size1qty8',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty9',
                key: 'size1qty9',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty9',
                    key: 'size1qty9',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty9',
                        key: 'size1qty9',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '7',
                            dataIndex: 'size1qty9',
                            key: 'size1qty9',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '26W',
                                dataIndex: 'size1qty9',
                                key: 'size1qty9',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '8',
                                    dataIndex: 'size1qty9',
                                    key: 'size1qty9',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '35',
                                        dataIndex: 'size1qty9',
                                        key: 'size1qty9',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '31',
                                            dataIndex: 'size1qty9',
                                            key: 'size1qty9',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty10',
                key: 'size1qty10',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty10',
                    key: 'size1qty10',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty10',
                        key: 'size1qty10',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '8',
                            dataIndex: 'size1qty10',
                            key: 'size1qty10',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '28W',
                                dataIndex: 'size1qty10',
                                key: 'size1qty10',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '10',
                                    dataIndex: 'size1qty10',
                                    key: 'size1qty10',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '36',
                                        dataIndex: 'size1qty10',
                                        key: 'size1qty10',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '32',
                                            dataIndex: 'size1qty10',
                                            key: 'size1qty10',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty11',
                key: 'size1qty11',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty11',
                    key: 'size1qty11',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty11',
                        key: 'size1qty11',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '9',
                            dataIndex: 'size1qty11',
                            key: 'size1qty11',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '30W',
                                dataIndex: 'size1qty11',
                                key: 'size1qty11',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '12',
                                    dataIndex: 'size1qty11',
                                    key: 'size1qty11',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '38',
                                        dataIndex: 'size1qty11',
                                        key: 'size1qty11',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '33',
                                            dataIndex: 'size1qty11',
                                            key: 'size1qty11',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty12',
                key: 'size1qty12',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty12',
                    key: 'size1qty12',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty12',
                        key: 'size1qty12',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '10',
                            dataIndex: 'size1qty12',
                            key: 'size1qty12',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '32W',
                                dataIndex: 'size1qty12',
                                key: 'size1qty12',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '14',
                                    dataIndex: 'size1qty12',
                                    key: 'size1qty12',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '40',
                                        dataIndex: 'size1qty12',
                                        key: 'size1qty12',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '34',
                                            dataIndex: 'size1qty12',
                                            key: 'size1qty12',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty13',
                key: 'size1qty13',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty13',
                    key: 'size1qty13',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty13',
                        key: 'size1qty13',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '-',
                            dataIndex: 'size1qty13',
                            key: 'size1qty13',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '-',
                                dataIndex: 'size1qty13',
                                key: 'size1qty13',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '16',
                                    dataIndex: 'size1qty13',
                                    key: 'size1qty13',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '42',
                                        dataIndex: 'size1qty13',
                                        key: 'size1qty13',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '35',
                                            dataIndex: 'size1qty13',
                                            key: 'size1qty13',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty14',
                key: 'size1qty14',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty14',
                    key: 'size1qty14',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty14',
                        key: 'size1qty14',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '-',
                            dataIndex: 'size1qty14',
                            key: 'size1qty14',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '-',
                                dataIndex: 'size1qty14',
                                key: 'size1qty14',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '18',
                                    dataIndex: 'size1qty14',
                                    key: 'size1qty14',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '44',
                                        dataIndex: 'size1qty14',
                                        key: 'size1qty14',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '36',
                                            dataIndex: 'size1qty14',
                                            key: 'size1qty14',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty15',
                key: 'size1qty15',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty15',
                    key: 'size1qty15',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty15',
                        key: 'size1qty15',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '-',
                            dataIndex: 'size1qty15',
                            key: 'size1qty15',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '-',
                                dataIndex: 'size1qty15',
                                key: 'size1qty15',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '20',
                                    dataIndex: 'size1qty15',
                                    key: 'size1qty15',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                      {
                                        title: '46',
                                        dataIndex: 'size1qty15',
                                        key: 'size1qty15',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '37',
                                            dataIndex: 'size1qty15',
                                            key: 'size1qty15',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
              {
                title: '-',
                dataIndex: 'size1qty16',
                key: 'size1qty16',
                width: 50,
                align: 'center',
                children: [
                  {
                    title: '-',
                    dataIndex: 'size1qty16',
                    key: 'size1qty16',
                    width: 50,
                    align: 'center',
                    children: [
                      {
                        title: '-',
                        dataIndex: 'size1qty16',
                        key: 'size1qty16',
                        width: 50,
                        align: 'center',
                        children: [
                          {
                            title: '-',
                            dataIndex: 'size1qty16',
                            key: 'size1qty16',
                            width: 50,
                            align: 'center',
                            children: [
                              {
                                title: '-',
                                dataIndex: 'size1qty16',
                                key: 'size1qty16',
                                width: 50,
                                align: 'center',
                                children: [
                                  {
                                    title: '-',
                                    dataIndex: 'size1qty16',
                                    key: 'size1qty16',
                                    width: 50,
                                    align: 'center',
      
                                    children: [
                                      {
                                        title: '48',
                                        dataIndex: 'size1qty16',
                                        key: 'size1qty16',
                                        width: 50,
                                        align: 'center',
                                        children: [
                                          {
                                            title: '38',
                                            dataIndex: 'size1qty16',
                                            key: 'size1qty16',
                                            width: 45,
                                            align: 'center',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },]
                          },
                        ],
                      }
                    ]
                  }
                ]
              },
            ],
          },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location_name',
            width: 150,
        },
        {
            title: 'Store No.',
            dataIndex: 'store_id',
            key: 'store_id',
            width: 150,
        },

    ];

    const onFinish = async () => {
        await form.validateFields().then((values) => {
            setLoading(true)
            dispatch(getCreditOpenInvoice(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setCreditOpenDetailList(res?.payload?.data);
            });
        })
    }

    function downloadExcelFileData() {
        const data = creditOpenDetailList
        const workbook = XLSX.utils.book_new(); // Create a new workbook
        const worksheetData = [
            ['SKU', 'Inseam', 'Customer', 'Billing Addressee', 'Billing City', 'Shipping City', 'Invoice #', 'Date', 'Store No.', 'Location', 'Infant', '3m', '6m', '12m', '18m', '24m', ...Array(11).fill("-")
            ],
            [...Array(10).fill(""), 'T-Jacket', '"2/3"', '"4/5"', '"6/6X"', ...Array(13).fill("-")],
            [...Array(10).fill(""), 'Shirt/RTW-P', '1XL', '2XL', '3XL', ...Array(13).fill("-")],
            [...Array(10).fill(""), 'Shirt/RTW-R', 'XS', 'S', 'M', 'L', 'XL', ...Array(11).fill("-")],
            [...Array(10).fill(""), 'Y-Adult', '00', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', ...Array(4).fill("-")],
            [...Array(10).fill(""), 'W-Pus', '10W', '12W', '14W', '16W', '18W', '20W', '22W', '24W', '26W', '28W', '30W', '32W', ...Array(4).fill("-")],
            [...Array(10).fill(""), 'Kids', '2', '3', '4', '5', '6', '6x', '7', '7x', '8', '10', '12', '14', '16', '18', '20', '-'],
            [...Array(10).fill(""), 'Men', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '38', '40', '42', '44', '46', '48'],
            [...Array(10).fill(""), 'Women', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38'],
        ];
        data.forEach((v) => {
            worksheetData.push([
                v.parent_item_code, v.inseam, v.customer_name, v.billing_addressee, v.billing_city, v.shipping_city, v.invoice_header_no, v.invoice_date, v.location_name, v.store_id, '',
                v.size1qty1, v.size1qty2, v.size1qty3, v.size1qty4, v.size1qty5,
                v.size1qty6, v.size1qty7, v.size1qty8, v.size1qty9, v.size1qty10,
                v.size1qty11, v.size1qty12, v.size1qty13, v.size1qty14, v.size1qty15, v.size1qty16
            ]);
        });
        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        // Merge cells
        worksheet['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 8, c: 0 } },
            { s: { r: 0, c: 1 }, e: { r: 8, c: 1 } },
            { s: { r: 0, c: 2 }, e: { r: 8, c: 2 } },
            { s: { r: 0, c: 3 }, e: { r: 8, c: 3 } },
            { s: { r: 0, c: 4 }, e: { r: 8, c: 4 } },
            { s: { r: 0, c: 5 }, e: { r: 8, c: 5 } },
            { s: { r: 0, c: 6 }, e: { r: 8, c: 6 } },
            { s: { r: 0, c: 7 }, e: { r: 8, c: 7 } },
            { s: { r: 0, c: 8 }, e: { r: 8, c: 8 } },
            { s: { r: 0, c: 9 }, e: { r: 8, c: 9 } },
        ];

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Export the workbook
        XLSX.writeFile(workbook, `Credit-Open-Invoice-Detail-Report-${moment().format("MM-DD-YY")}.xlsx`);
    }

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Credit Open-Invoice"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().startOf('year')}

                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}


                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment().endOf('year')}

                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="parent_sku"
                                                        label="Parent SKU #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value!==null?
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (creditOpenDetailList.length > 0 ? (
                        < Table
                            title={() =>
                                <div
                                    role='button'
                                    title="Credit Open Invoice Detail Report"
                                    onClick={downloadExcelFileData}
                                >
                                    <span
                                        className="print_icon printing_class">
                                        <input style={{ height: "25px" }}
                                            type="image"
                                            name="ImgExcel"
                                            alt="Export to excel"
                                            id="ImgExcel"
                                            title="Export to excel"
                                            src={xlsx}
                                        />
                                    </span>
                                </div>
                            }
                            ref={tableRef}
                            columns={BDCols}
                            loading={creditOpenInvoice?.loading}
                            dataSource={creditOpenDetailList}
                            size="small"
                            bordered
                            scroll={{
                                x: 'calc(400px + 80%)',
                                y: 450,
                            }}
                            pagination={{ pageSize: 1000 }}
                            summary={(pageData) => {
                                let Size1 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty1
                                    ));
                                },
                                    0);
                                let Size2 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty2
                                    ));
                                },
                                    0);
                                let Size3 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty3
                                    ));
                                },
                                    0);
                                let Size4 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty4

                                    ));
                                },
                                    0);
                                let Size5 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty5

                                    ));
                                },
                                    0);
                                let Size6 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty6

                                    ));
                                },
                                    0);
                                let Size7 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty7

                                    ));
                                },
                                    0);
                                let Size8 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty8

                                    ));
                                },
                                    0);
                                let Size9 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty9

                                    ));
                                },
                                    0);
                                let Size10 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty10

                                    ));
                                },
                                    0);
                                let Size11 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty11

                                    ));
                                },
                                    0);
                                let Size12 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty12

                                    ));
                                },
                                    0);
                                let Size13 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty13

                                    ));
                                },
                                    0);
                                let Size14 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty14

                                    ));
                                },
                                    0);
                                let Size15 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty15

                                    ));
                                },
                                    0);
                                let Size16 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty16

                                    ));
                                },
                                    0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size1.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size2.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size3.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size4.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size5.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size6.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size7.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size8.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size9.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size10.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size11.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size12.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size13.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size14.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size15.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size16.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    ) : <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                    )
                }
            </div>
        </div>
    )
}
export default CreditOpenInvoicedetailReport;