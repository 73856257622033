import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllInvAdjustmentHeader } from "../../redux/actions/inventoryAdjustment.action";
import { FilterOutlined, FullscreenExitOutlined, CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Tables from "../../components/Table/Tables";
import { invAdjustmentTransferColumns } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { checkDate, dateChecker } from "../../components/CheckDate/checkDate";
import { getBrands } from "../../redux/actions/brand.action";

export default function InventoryAdjustmentNewList({ token }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { invStockAdjustmentList, brand } = useSelector((state) => {
		return state
	});
	const { Panel } = Collapse;
	const dateFormat = "MM-DD-YYYY";
	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		invAdjustmentNo: null,
		adjustmentAccount: null,
		postingPeriod: null,
		adjustmentLocation: null,
		brand_id: null,
		memo: null
	};
	
	useEffect(() => {
		dispatch(getBrands(token));
	}, [])

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Adjustment Header No": v.adjustment_header_no,
					"Transaction Date": moment(v.adjust_date).format("MM-DD-YYYY"),
					"Posting Period": v.period_name,
					"Memo": v.memo,
					"Brand": v.brand_name,
					"Adjustment Location": v.location_name,
					"Adjustment Account Name": v.account_name,
				};
			});
		}
	}

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "invAdjustmentList", token, values, dispatch);
		})
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Inventory Adjustment List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "99%", margin: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<Button
							onClick={() => {
								history.push("/inventory-adjustment");
							}}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<PlusOutlined />}
						/>}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="invAdjustmentNo"
											label="Adjustment #:"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											label="Adjustment Account"
											name="adjustmentAccount"
										>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="postingPeriod"
											label="Posting Period"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="adjustmentLocation"
											label="Adjustment Location"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="memo"
											label="Memo"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="brand_id" label="Brand">
											<Select
												size="small"
												showSearch
												allowClear
												optionFilterProp="children"
												filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())} loading={brand.loading} className="w-100">
												{brand?.payload?.data?.map((res) => (
													<Option key={res.brand_id} value={res.brand_id}>
														{res.brand_name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{invStockAdjustmentList?.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{invStockAdjustmentList?.payload?.data.length > 0 && (
									<CSVLink
										filename={"Inventory_Adjustment.csv"}
										data={
											downloadExcelFileData(invStockAdjustmentList?.payload?.data) || []
										}
										onClick={() => { }}
									>
										Download Excel
									</CSVLink>
								)}
								<Tables
									loadWidth={40}
									type={"customer"}
									scroll={450}
									perPage={100}
									scrollX={"80rem"}
									col={invAdjustmentTransferColumns}
									source={invStockAdjustmentList?.payload?.data}
									load={invStockAdjustmentList?.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
